import React from 'react'
import './render-elements.scss'

interface RenderElementsProps {
}

export default function RenderElements({}: RenderElementsProps) {
     return (
     <table className="table text-white">
          <thead>
               <tr>
                    <th scope="col">No</th>
                    <th scope="col">Element name</th>
                    <th scope="col">Enabled</th>
                    <th scope="col">Element Filename</th>
               </tr>
          </thead>
          <tbody>
               <tr>
                    <th scope="row">1</th>
                    <td>Mark</td>
                    <td>True</td>
                    <td></td>
               </tr>
          
          </tbody>
     </table>

     )
}