import { SOFTWARES_TYPE } from 'config';
import { DetectSoftwareFromPath } from 'Helpers/DetectSoftware';
import { useState } from 'react';
import Modal from 'react-modal';
import AfterEfectRenderNow from 'screens/RenderWithoutAnalyze/AfterEfectRenderNow';
import { SpaceItem } from 'types/spaces.type';
import RenderWithoutAnalyze from '../../../../RenderWithoutAnalyze';
export type RenderWithoutAnalyzeProp = {
     setIsOpenModal: (state: boolean) => void,
     isOpenModal: boolean,
     item: SpaceItem,
     workspace_mel: any,
}

export function RenderSceneModal({ item, setIsOpenModal, isOpenModal, workspace_mel}: RenderWithoutAnalyzeProp) {
     function closeModal() {
          setIsOpenModal(false)
     }
     return <Modal
          isOpen={isOpenModal}
          onRequestClose={() => setIsOpenModal(false)}
          closeTimeoutMS={250}
          style={{
               overlay: {
                    zIndex: 20,
                    position: 'fixed',
                    top: 35,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
               },
               content: {
                    top: '49%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    background: '#343434',
                    transform: 'translate(-50%, -50%)',
                    border: 'none',
                    height: '88vh',
                    width: '1100px',
                    padding: '0',
                    cursor: 'default',
               },
          }}
          contentLabel="Open Render Scene Modal"
     >
          <div className="title d-flex" style={{backgroundColor:"#303030"}}>
               <div className="col m-auto ms-3">
                    <h6 className="m-0 pt-1">Start Render Job: {item.name}</h6>
               </div>
               <button className="btn button-close ms-auto" type="button" onClick={() => setIsOpenModal(false)} >
                    <i className="fal fa-times"></i>
               </button>
          </div>
          {DetectSoftwareFromPath(item.key) === SOFTWARES_TYPE.AFTEREFECT ? <AfterEfectRenderNow item={item} closeModal={closeModal} /> :
           <RenderWithoutAnalyze item={item} closeModal={closeModal} workspace={workspace_mel} /> }
         
     </Modal>
}