import { API } from "apis/base.api";
import axios from "axios";
import Loading from "components/Loading";
import { useEffect, useState } from "react";
import { AuthorizationRequest } from "../../../../apis/default";
import {
  InvokeHandleMutation,
  IpcSender,
} from "../../../../Interfaces/IpcRenderer.enum";
import DefineIcon from "../CloudStorage/DefineIcon";
import { A3dsMaxList } from "./A3dsMax";
import { AMayaList} from "./AMaya";
import {
  getVersionBySoftwareName,
  LatestVersionItemTypes,
  LatestVersionReturnTypes,
} from "./latest-from-api.interface";
import { PluginBrowser, PluginBrowserTypes } from "./PluginBrowser";
import "./plugins-manager.scss";
import { AllPluginsResponseBody, I3dsApp } from "./plugins.interfaces";
import { GetElementType } from "./type-helperts";

const { ipcRenderer } = window.electron;
interface PluginsManagerProps {}

export interface LoadVersionPayload {
  app: I3dsApp;
  language: GetElementType<I3dsApp["languages"]>;
  software: LatestVersionItemTypes["software"];
}

export default function PluginsManager({}: PluginsManagerProps) {
  const [isWaiting, setIsWaiting] = useState<boolean>(true);
  const [Applications, setApplications] = useState<I3dsApp[]>([]);
  const [latestVersionConfigs, setLatestVersionConfigs] =
    useState<LatestVersionReturnTypes>();
  const [activePluginLang, setActivePluginLang] =
    useState<LoadVersionPayload>();
  /**
   * Fetch list plugins from web
   */
  function fetchPlugins() {
    axios
      .get<AllPluginsResponseBody>("http://local-app-api.superrendersfarm.com:2546/plugins")
      .then((res) => {
        // set apps
        setApplications(res.data.result);
      });
  }

  /**
   * Fetch latest version from api
   */
  function fetchLatestVersionFromAPI() {
    API.public
      .get<LatestVersionReturnTypes>("/plugin/latest-version/all")
      .then((res) => {
        setLatestVersionConfigs(res.data);
        return res;
      })
      .catch();
  }

  /**
   * Send plugin infors to server
   */
  function sendPluginInfors() {
    ipcRenderer
      .invoke(InvokeHandleMutation.IsSwitchedFromAdmin)
      .then(async (isSwitchFromAdmin: boolean) => {
        console.log("is switch from admin", isSwitchFromAdmin);
        if (!isSwitchFromAdmin) {
          // Update PC Infor
          ipcRenderer.send(IpcSender.UpdatePcInfor);

          // Update application infor
          ipcRenderer.invoke("app-version").then((_ver) => {
            // send app version to api
            AuthorizationRequest.post(
              "/user/me/update-my-current-plugin-version",
              {
                current_client_app_version: _ver,
              }
            );
          });
        }
      });
  }

  useEffect(() => {
    // Fetch plugins
    fetchLatestVersionFromAPI();
    sendPluginInfors();
    setTimeout(() => {
      fetchPlugins();
      setIsWaiting(false);
    }, 250);
  }, []);


  // runtime init
  const maxLatestVersion = getVersionBySoftwareName({
    latestVersionConfigs,
    name: "3dsmax",
  });
  const mayaLatestVersion = getVersionBySoftwareName({
    latestVersionConfigs,
    name: "maya",
  });

  const A3dsMaxApps = Applications.filter((app) => app.name == "3dsMax");
  const AMayaApps = Applications.filter((app) => app.name == "maya");
  const detailActiveApp: PluginBrowserTypes["detail"] = activePluginLang
    ? {
        app: activePluginLang.app,
        language: activePluginLang.language,
        latest: maxLatestVersion,
        software: activePluginLang.software,
      }
    : undefined;

  // has data
  return (
    <div className="plugins-manager">
      <div className="plugin-topbar">
        <div className="plugin-button d-flex">
          {/* <button
            type="button"
            className="btn rounded-btn"
            onClick={() => {  
              setActivePluginLang(undefined)
            }}
          >
            <i className="fad fa-home me-2"></i> Home page
          </button> */}
          <button
            type="button"
            className="btn rounded-btn px-3"
            disabled={isWaiting}
            onClick={async () => {
              setIsWaiting(true);
              await fetchPlugins();
              await axios.get("http://localhost:2546/plugins/updates/maya/add/superrenders");
              await axios.get("http://localhost:2546/plugins/updates/3dsMax/add/superrenders");
              setActivePluginLang(undefined)
              setTimeout(() => {
                setIsWaiting(false);
              }, 250);
            }}
          >
            <i className="fad fa-sync me-2"></i> RESCAN TO UPDATE
          </button>
        </div>
      </div>
      <div className="d-flex">
        <div className="col list-plugins">
          {
            // is wait for request
            isWaiting ? <Loading />
            :<>{Applications.length == 0 ? (
                <div className="text-center d-flex h-100">
                  <div className="m-auto">
                    <h5>There are no plugin found!</h5>
                    <p>please click "Rescan".</p>
                  </div>
                </div>
              ) : <>
              <A3dsMaxList
                apps={A3dsMaxApps}
                loadVersion={(payload) => {
                  setActivePluginLang(payload);
                }}
                maxLatestVersion={maxLatestVersion}
                reload={async () => {
                  setIsWaiting(true);
                  await fetchPlugins();
                  setIsWaiting(false);
                }}
              />
              <AMayaList
                apps={AMayaApps}
                loadVersion={(payload) => {
                  setActivePluginLang(payload);
                }}
                mayaLatestVersion={mayaLatestVersion}
                reload={async () => {
                  setIsWaiting(true);
                  await fetchPlugins();
                  setIsWaiting(false);
                }}
              />
              </>
              }
            </>
          }
        </div>
        <div className="col">
          <PluginBrowser
            page={activePluginLang ? "properties" : "first-open"}
            detail={detailActiveApp}
          />
        </div>
      </div>
    </div>
  );
}
