import Tippy from '@tippyjs/react'
import React from 'react'
import './render-info.scss'

interface RenderInfoProps {
     id : number
     name : string
     frame : string
     camera :string
     priority : string
     renderBy : string
     submitted : string
}
export enum EPriority {
     Bronze = '10',
     Silver = '20',
     Gold = '30',
}
export default function RenderInfo({id,name,frame,camera,priority,renderBy,submitted}: RenderInfoProps) {
     var Priority = priority as EPriority
     return (
          <div className="render-info">
               <div className="job-name">
                    <>ID #{id} </>
                         <span> - {name}</span>
               </div>
               <div>
                    <div className="badge bg-success-bright me-2">Priority: {
                         Priority === EPriority.Bronze ? 'Bronze' :
                         Priority === EPriority.Silver ? 'Silver' :
                         Priority === EPriority.Gold ? 'Gold' :
                         'Unknown'
                    }</div>
                    <div className="badge bg-success-bright me-2">Frames: {frame}</div>
                    <div className="badge bg-success-bright me-2 d-none">Camera: {camera}</div>
                    <div className="badge bg-success-bright me-2">Render by: {renderBy}</div>
                    <div className="badge bg-success-bright me-2">Submitted: {submitted}</div>
               </div>
        </div>
     )
}