import { ISceneAnalyze } from "screens/MainWindow/Components/SceneAnalysis/SceneAnalysis.interface";
import { AnalysisAction, AnalysisActionType, AnalysisStates } from "./analysis.interface";

const AnalysisInitialState : AnalysisStates = {
    isLoading : false,
    query_results : [],
    query : "",
    items : [],
}

export const AnalysisReducer =  (state = AnalysisInitialState, action : AnalysisAction<any> ) => {
    var NewState = state
    switch (action.type) {
        //
        case AnalysisActionType.SetLoading:
            NewState.isLoading = action.payload as boolean
            return { ...state ,...NewState};

        // Remove items from list
        case AnalysisActionType.RemoveItems:
            var RemoveId = action.payload as number
            NewState.items = state.items.filter( (item : ISceneAnalyze) =>  item.id !== RemoveId)
            return { ...state ,...NewState};

        // Find from data
        case AnalysisActionType.Search :
            var FinderQuery = action.payload as string
            NewState.query = FinderQuery
            NewState.isLoading = false
            NewState.query_results = NewState.items.filter(item => item.scene_path.toLowerCase().includes(FinderQuery.toLowerCase()))
            return {...state, ...NewState};
        //
        case AnalysisActionType.SetItems:
            NewState.items = action.payload as ISceneAnalyze[]
            return {...state,NewState}

        // Unkown typed
        default:
            return {...state}
    }

};
