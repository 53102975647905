import React from 'react'
import { connect } from 'react-redux'
import { RenderOptionsInterface } from 'screens/RenderNow/RenderNow.interface'
import { ImageSamplerType,ImageSamplerName } from '../../../../../../Interfaces/App.enums'
import { RootState } from 'stores/stores'
import { VrayDispatchMapper } from '../vrayDispatch'
import './image-samples-and-filter.scss'

const MapStates = (States: RootState) => ({
     Render: States.Render
})

type ImageSamplesAndFilterProps = ReturnType<typeof MapStates> & typeof VrayDispatchMapper & {
     renderOptions: RenderOptionsInterface
}

function getImageSamplerName(type: ImageSamplerType) {
     switch (type) {
          case ImageSamplerType.Bucket:
               return ImageSamplerName.Bucket
          case ImageSamplerType.Progressive:
               return ImageSamplerName.Progressive
     }
}
function ImageSamplesAndFilter(Props:ImageSamplesAndFilterProps) {
   
     const { vray_option } = Props.renderOptions.body.scene.params
     if ( vray_option?.imageSampler_type.imageSampler_type_key == undefined) return <div></div>
     return (
          <div className="card mb-3" >
               <div className="card-header">
                    <h6 className="card-title">Image Samples and Image Filter</h6>
               </div>
               <div className="card-body">
                    <div className="row mb-3">
                         <div className="col-6">
                              <span className="input-group-text" id="scene-path">Image Samples( Antialiasing ) </span>
                              <select className="form-select"  onChange={e => {
                                   Props.updateVrayProps(
                                        "imageSampler_type",
                                        {
                                             imageSampler_type_key: parseInt(e.target.value) as ImageSamplerType,
                                             imageSampler_type_name: getImageSamplerName(parseInt(e.target.value) as ImageSamplerType)
                                        }
                                   )
                              }}>
                                   <option value={ImageSamplerType.Bucket} selected={vray_option?.imageSampler_type.imageSampler_type_key==ImageSamplerType.Bucket}>{ImageSamplerName.Bucket}</option>
                                   <option value={ImageSamplerType.Progressive} selected={vray_option?.imageSampler_type.imageSampler_type_key==ImageSamplerType.Progressive} >{ImageSamplerName.Progressive}</option>
                              </select>
                         </div>
                         <div className="col-6">
                              <span className="input-group-text" id="scene-path">Image Filter </span>
                              <div className="form-check">
                                   <input className="form-check-input" type="checkbox" id="filter_on" 
                                   checked={vray_option.filter_on == true} 
                                   onClick={ () =>{
                                        Props.SetImageFilter(!vray_option.filter_on)}} />
                                   <label className="form-check-label" htmlFor="filter_on">
                                        Filter: {vray_option.filter_kernel}
                                   </label>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     )
}
export default connect(MapStates, VrayDispatchMapper)(ImageSamplesAndFilter)