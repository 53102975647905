import React from 'react'
import NoAction from "../../../../../assets/SVG/style-dark-1/36.svg";
const shell = window.electron.shell;

interface AnalysisEmptyProps {
}

export default function AnalysisEmpty({}: AnalysisEmptyProps) {
     return (
          <div className="d-flex h-100">
               <div className="text-center m-auto">
                    <div className="logo mx-auto mb-3"
                    style={{
                         backgroundSize: "cover",
                         height: "200px",
                         width: "200px",
                         backgroundRepeat: "no-repeat",
                         backgroundImage: `url("${NoAction}")`,
                    }}
                    />
                    <h5 className="mb-3">
                         You have no scene analysis at the moment. 
                    </h5>
                    <p>Please add scene analysis or review support portal!</p>
                    {/* <button className="btn rounded-btn me-3"  >ADD SCENE</button> */}
                    <button
                         className="btn rounded-btn"
                         onClick={() =>shell.openExternal("https://support.superrendersfarm.com/hc/en-us/categories/360001811753-Rendering")}
                         >
                         SUPPORT PORTAL
                    </button>
               </div>
          </div>
     )
}