import {isJSON} from "modules/JSON/validator";

export namespace AppLocalStorage {
  export function get<Type>(key : string) {


    const value = localStorage.getItem(key);

    if (isJSON(value)) {
      return JSON.parse(localStorage.getItem(key) || "null")as Type;
    }

    if(value?.startsWith('"') && value?.endsWith('"')) {
      return value.substring(1, value.length - 1);
    }

    return value;
  }

  export function set<T>(key : string, value : T) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  export function remove(key : string) {
    localStorage.removeItem(key);
  }

  export function clear() {
    localStorage.clear();
  }

  export function getKeys() {
    return Object.keys(localStorage);
  }

  export function getValues() {
    return Object.values(localStorage);
  }

  export function getEntries() {
    return Object.entries(localStorage);
  }

  export function has(key : string): boolean {
    return localStorage.hasOwnProperty(key);
  }

  export function size() {
    return localStorage.length;
  }

  export function isEmpty() {
    return localStorage.length === 0;
  }

  export function isNotEmpty() {
    return localStorage.length !== 0;
  }

  export function isFull() {
    return localStorage.length === localStorage.limit;
  }

  export function isNotFull() {
    return localStorage.length !== localStorage.limit;
  }
}
