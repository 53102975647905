
import { useEffect } from 'react';
import Modal from 'react-modal';
export type AskForAnalyzeSceneProp = {
    setIsOpenModalAnalyze: (state: boolean) => void,
    isOpenModalAnalyze: boolean,
    openRenderModalCallback: (state: boolean) => void,
    openAnalyzeModalCallback: (state: boolean) => void,
}
export function AskForAnalyzeScene({ setIsOpenModalAnalyze, isOpenModalAnalyze, openAnalyzeModalCallback, openRenderModalCallback }: AskForAnalyzeSceneProp) {
    return <Modal
        isOpen={isOpenModalAnalyze}
        onRequestClose={() => setIsOpenModalAnalyze(false)}
        closeTimeoutMS={250}
        style={{
            overlay: {
                zIndex: 10000,
                position: 'fixed',
                top: 35,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
            },
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                background: '#343434',
                transform: 'translate(-50%, -50%)',
                border: 'none',
                maxHeight: '720px',
                minHeight: '200px',
                width: '500px',
                padding: '0',
                cursor: 'default',
            },
        }}
        contentLabel="Open Scene Modal"
    >

    <div className="modal-header">
        <div className="text-center">
            <h3>Analyze your scene?</h3>
            <p>It is recommended that you should analyze the scene before starting to render your project!</p>
        </div>
    </div>
    <div className="modal-body text-center" >
        <i className="fad fa-exclamation-triangle fa-6x pb-4 mb-5"></i>

        {/* {activeScene?.key} */}
    </div>
    <div className="modal-footer">
        <button className="ms-auto btn" onClick={() => setIsOpenModalAnalyze(false)}>Cancel</button>


        <button className="submit btn btn-success ms-2" type="submit"
            onClick={
                () => {
                    setIsOpenModalAnalyze(false)
                    setTimeout(() => {
                        openRenderModalCallback(true)
                    }, 500);
                }

            }>No! Render now</button>

        <button className="submit btn btn-success ms-2" type="submit"
            onClick={
                () => {
                    setIsOpenModalAnalyze(false)
                    setTimeout(() => {
                        openAnalyzeModalCallback(true)
                     
                    }, 500);
                }
            } >Yes! Analyze it</button>
    </div>

</Modal>
}