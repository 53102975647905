import React, { ReactElement, useEffect, useState } from 'react'
import './tray-window.scss'
import fileIcon from "../../assets/file.svg"
import * as path from "path"
import RelativeTime from '../MainWindow/Components/CloudStorage/RelativeTime';
import { IpcSender, IProgress, RunningTaskEvents, RunningTaskTypes, SuperTransferEvent, UPDATE_TASK_EVENT } from '../../Interfaces/IpcRenderer.enum';
import NoActions from './NoActions';
import logo from '../../assets/logo.png'

const { ipcRenderer } = window.electron;
interface TrayWindowProps {

}


const TrayWindowHeader = () => {
    return (
        <div className="title-brand my-auto d-flex">
            <div className="logo ms-1" style={{backgroundSize:"cover",height:"25px",width:"25px",backgroundRepeat:"no-repeat",backgroundImage:`url("${logo}")`}}/>
            <div className="mt-1 ms-1">
                <span className="logo-title-bar-text-super">SUPER </span>
                <span className="logo-title-bar-text-renders">RENDERS </span>
                <span className="logo-title-bar-text-farm">FARM</span>
            </div>
        </div>
    )
}

function UploadingItem(): JSX.Element {
    const [uploadFiles, setUploadFiles] = useState<IProgress[]>([])

    useEffect(() => {

        ipcRenderer.on(SuperTransferEvent.TOTAL_PROGRESS_WITH_FULL_INFO, (event: any, {id, progressDetail, progress}) => {
            setUploadFiles(progressDetail)
        })

    }, []);


    return <div>
        {
        uploadFiles.reverse().filter(file => file.progress != 100  ).map(uploadFile => (
            uploadFile.progress != 100 ?
                <div className="tray-item">
                    <div className="item-thumbnail">
                        <div className="plugins-max" style={{ backgroundImage: `url(${fileIcon})` }} />
                    </div>
                    <div className="item-info">
                        <div className="filename">{path.basename(uploadFile.name)}</div>
                        <div className="d-flex">
                            <div className="modified">{uploadFile.type}</div>
                            <div className="path ms-auto">{Math.floor(uploadFile.progress) + "%"}</div>
                        </div>
                    </div>
                </div>
                : <div></div>
        ))}
    </div>
}

export default function TrayWindow({ }: TrayWindowProps) {

    const [Tasks, setTasks] = useState<RunningTaskTypes[]>([])

    useEffect(() => {

        ipcRenderer.on( UPDATE_TASK_EVENT , (event, data: RunningTaskTypes[]) => {
            setTasks(data)
        })

    }, []);

    function ActiveRenderingQueue(): JSX.Element {
        if (Tasks.length == 0 )return <div></div>
            return <div>{
                Tasks.map((task, index) => (
                    <div className="rendering-item">
                        <div className="item-thumbnail">
                        {
                            task.type === "UPLOADING_ASSETS" ? 
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-cloud-upload-fill ld mt-1" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.5 14.5V11h1v3.5a.5.5 0 0 1-1 0z"/>
                            </svg>
                            
                            :task.type === "DOWNLOAD_OUTPUT" ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-cloud-download-fill" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.5a.5.5 0 0 1 1 0V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.354 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V11h-1v3.293l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/>
                            </svg>
                            :task.type === "WAITING_TO_UPLOAD" ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-hourglass-split" viewBox="0 0 16 16">
                                <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/>
                            </svg>
                            : <></>
                        }
                        </div>
                        <div className="item-info">
                            <div className="d-flex">
                                <div className="filename">{task.title}</div>
                                <small className="ms-auto"  hidden={task.type === "WAITING_TO_UPLOAD"}>{Math.round(task.progress)}%</small>
                            </div>
                            {
                                // if is has task
                                task.hasProgress ?
                                    <div className="path">
                                        <div className="progress" hidden={task.type === "WAITING_TO_UPLOAD"}>
                                            <div 
                                                className="progress-bar progress-bar-striped progress-bar-animated fast bg-primary"
                                                role="progressbar" 
                                                style={{ width: `${(task.progress)}%` }}
                                            />
                                        </div>
                                    </div>
                                : <div></div>
                            }
                            <div className="modified d-flex">
                                <div className="mt-auto">
                                    {task.description}
                                </div>
                                <div className="ms-auto">
                                    <small className="cancel-btn text-danger" 
                                        hidden={task.type === "DOWNLOAD_OUTPUT"}
                                        onClick={() => {if (window.confirm("Are you sure you want to cancel this upload?")) {
                                            const id = task.context.id
                                            console.log(`Sending stop request to task id: `,id)
                                            ipcRenderer.send(SuperTransferEvent.REQUEST_STOP_UPLOAD, id)
                                        }}}>
                                        CANCEL
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
            </div>
    }
   
    const [Tab, setTab] = useState(1)

    return (
        <div className="tray-window">
            <TrayWindowHeader />
            {
                Tasks.length !== 0 ? (
                    <div>
                        <div className="row">
                            <button className={`btn col-6 btn-dark py-3 ${Tab === 1 ? "active" : ""}`} onClick={() => setTab(1)}>TASKS</button>
                            <button className={`btn col-6 btn-dark py-3 ${Tab === 2 ? "active" : ""}`} onClick={() => setTab(2)}>DETAIL</button>
                        </div>
                        <div className="tray-windows-body">
                        {
                            Tab === 1 ? <ActiveRenderingQueue />:
                            Tab === 2 ? <UploadingItem /> : <></>
                        }
                        </div>
                    </div> ):<NoActions />
            }
            <div className="tray-window-footer fixed-bottom">
                <div className="tray-footer-button">
                    <button type="button" onClick={() => { ipcRenderer.send(IpcSender.ShowDashboard, "", null) }}  >
                        <div><i className="fal fa-home mb-2" style={{ fontWeight: 100, fontSize: 19 }}></i></div>
                        Open App
                    </button>
                    <button type="button" onClick={() => { ipcRenderer.send(IpcSender.OpenFolderDialog, "", true) }} >
                        <div><i className="fal fa-chevron-double-up mb-2" style={{ fontWeight: 100, fontSize: 19 }}></i></div>
                        Upload Now
                    </button>
                    <button type="button" onClick={() => { ipcRenderer.send(IpcSender.OpenOutputFolder, "", null) }} >
                        <div><i className="fal fa-folder mb-2" style={{ fontWeight: 100, fontSize: 19 }}></i></div>
                        Output Folder
                    </button>
                </div>
            </div>

        </div>
    )
}