import React, { useState , useEffect} from 'react'
import { saveAs } from 'file-saver';
import './render-collapse.scss'
import { API } from 'apis/base.api';
import { AxiosResponse } from 'axios';
import { ParseJson } from 'modules/JSON/parse';
import Loading from 'components/Loading';
import { IRender } from 'stores/reducers/render_list.interface';
import AnalysisResult from './AnalysisResult';
import NotfoundIcon from "../../../../../../assets/SVG/style-dark-1/12.svg"

const electron = window.electron
interface RenderCollapseProps {
     toggleState : boolean,
     item : IRender,
}

type ErrorResponseTypes = {
     message: string
     status: "failed"
}

type SuccessResponseTypes = {
     data : {
          render_log : string
     }

}

export default function RenderCollapse({toggleState,item}: RenderCollapseProps) {
     
     const [renderLog, setRenderLog] = useState<string>("");
     const [navTab, setNavTab] = useState<number>(1);
     const fetchRenderLog = async () => {          
          try {
               const response = await API.guard().get<SuccessResponseTypes, AxiosResponse<SuccessResponseTypes,ErrorResponseTypes> >(`/render-job/render-log/${item.id}`);
               setRenderLog(response.data.data.render_log);
          }catch (err: any) {
               setRenderLog(ParseJson<ErrorResponseTypes>(err.request.response).message)
          }
     }
 
     useEffect(() => {
          if(navTab===2) fetchRenderLog();
     }, [navTab])

     function saveDynamicDataToFile() {
          var blob = new Blob([renderLog.replace(/<[^>]+>/g, '')], { type: "text/plain;charset=utf-8" });
          saveAs(blob, item.id + " - " + item.scene_name +".txt");
     }
     return (
          <div className={toggleState ? "render-collapse show mt-2 mb-1": "render-collapse" }>
               <div className="card bg-dark">
                    <div className="card-body">
                         <div className="d-flex mb-2">
                              <nav className="nav nav-pills">
                                   <div className={navTab===1?"nav-link active":"nav-link"} onClick={()=>setNavTab(1)}>Analysis Result</div>
                                   <div className={navTab===2?"nav-link active":"nav-link"} onClick={()=>setNavTab(2)}>Render Log</div>
                              </nav>
                              <div className="ms-auto d-flex gap-2">
                                   <button   className="btn rounded-btn"
                                             onClick={() => { electron.shell.openExternal('https://superrendersfarm.com/account/support-tickets')}}
                                        >Support Ticket<i className="fad fa-clipboard-list ms-2"></i>
                                   </button>
                                   <button className="btn rounded-btn" disabled={renderLog.includes("Render log not found.") || renderLog===""} onClick={()=>saveDynamicDataToFile()}>
                                        Download Log
                                        <i className="fad fa-download ms-2"></i>
                                   </button>
                              </div>
                         </div>
                         <div className="body-content" style={{minHeight:200}}>
                              {
                                   navTab===1 ?
                                        <AnalysisResult isToggle={ toggleState && navTab===1 } item={item} />
                                   :
                                   navTab===2 ?
                                        renderLog === "" ?
                                        <div style={{height:200}}>
                                             <Loading />
                                        </div>
                                        : renderLog === "Render log not found." ?
                                             <div className="d-flex">
                                                  <div className="m-auto">
                                                       <div className="d-flex my-2 gap-3">
                                                            <div className="logo mt-4" style={{ backgroundSize: "cover", height: "160px", width: "148px", backgroundRepeat: "no-repeat", backgroundImage: `url("${NotfoundIcon}")` }} />
                                                            <h4 className="my-auto fw-bolder">Render log not found.</h4>
                                                       </div>
                                                  </div>
                                             </div> :
                                        renderLog.split("<br />").map((log, index) => <div 
                                             className={`${log.startsWith('Error') ? "text-danger" 
                                                       :log.startsWith('File') ? "text-warning" : "text-light"}`}
                                             key={index}>{log}</div>)
                                   :
                                   <div style={{height:200}}>
                                        ERROR !
                                   </div>
                              }
                         </div>
                    </div>
               </div>
          </div>
     )
    
}