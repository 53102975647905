import Icon from "../../../../../assets/SVG/style-dark-1/01.svg"


export function RootDirEmpty() {
    return (
          <div className="notification-screen">
               <div className="text-center m-auto">
                    <h4>This root folder is empty !</h4>
                    <div className="logo mx-auto my-4" style={{backgroundSize:"cover",height:"200px",width:"360px",backgroundRepeat:"no-repeat",backgroundImage:`url("${Icon}")`}}/>
                    <p>Please upload a folder to see its contents</p>
               </div>
          </div>
     )
}