import React from "react";
import { modalScreenActions, modalScreenType } from "./screen_modal.interface";

const modalDefaultState: modalScreenType = {
    Node: null,
    show: false,
    isFocused: false
}

export type modalAction<T> = {
    type: string,
    payload ?: T
}

export const ScreenModalReducer = ( state = modalDefaultState, Action : modalAction<any> ) : modalScreenType =>  {
    switch (Action.type) {

        case modalScreenActions.RenderModal:
            return {
                ...state,
                show : Action.payload.show,
                Node: Action.payload.Node
            }
        case modalScreenActions.Clear:
            return {
                ...state,
                Node: React.createElement("div", {}, "")
            }
        case modalScreenActions.Focus:
            console.log("Focus on modal :",Action.payload)
            return {
                ...state,
                isFocused: Action.payload as boolean
            }

        case modalScreenActions.Hide:
            return {
                ...state,
                show : Action.payload.show
            }
        default:
            return state
    }
}
