import maxIcon from "../../../../assets/plugins-extensions/3dsmax.png"
import mayaIcon from "../../../../assets/plugins-extensions/maya.png"
import houdiniIcon from "../../../../assets/plugins-extensions/houdini.png"
import blenderIcon from "../../../../assets/plugins-extensions/blender.png"
import c4dIcon from "../../../../assets/plugins-extensions/c4d.png"
import AEIcon from "../../../../assets/plugins-extensions/ae.png"
import folderIcon from "../../../../assets/plugins-extensions/folder.png"
import fileIcon from "../../../../assets/plugins-extensions/file.png"
import driveIcon from "../../../../assets/plugins-extensions/drive.png"
import { SpaceItemType } from "../../../../stores/reducers/spaces.interface"

const DefineIcon = (type ?: SpaceItemType,extension?:string | undefined) => {
     if(type ===SpaceItemType.Drive) return driveIcon
     if(type ===SpaceItemType.Folder) return folderIcon
     else if (type === SpaceItemType.Scene){
          if(extension === "max") return  maxIcon
          else if(extension === "ma" || extension === "mb") return mayaIcon
          else if(extension === "hip" || extension === "hipnc" || extension === "hiplc") return houdiniIcon
          else if(extension === "blend") return blenderIcon
          else if(extension === "c4d") return c4dIcon
          else if(extension === "aep") return AEIcon
          else return fileIcon
     }
     else return fileIcon
}
export default DefineIcon