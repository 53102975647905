export type LatestVersionItemTypes = {
    software: "3dsmax" | "maya",
    latest_version: number
}


export type LatestVersionReturnTypes =
{
    latest_version: LatestVersionItemTypes[]
}

export interface getVersionBySoftwareNameDto {latestVersionConfigs?: LatestVersionReturnTypes, name: LatestVersionItemTypes["software"]}

/**
 *  get the latest version from json, if cannot find latest version, then return 0.
 * @param param0  the parameter
 * @returns 
 */
export function getVersionBySoftwareName({ latestVersionConfigs, name } : getVersionBySoftwareNameDto)
  {

    if (!latestVersionConfigs) return 0;


    return (
      latestVersionConfigs?.latest_version.find((v) => v.software === name)
        ?.latest_version || 0
    );
  }