import axios from "axios";
import { app } from "electron";
import DefineIcon from "../CloudStorage/DefineIcon";
import { getIconType, UpToDateChecker } from "./Bases";
import { ILCConvert } from "./international-language-codes";
import { LatestVersionItemTypes } from "./latest-from-api.interface";
import { I3dsApp } from "./plugins.interfaces";
import { LoadVersionPayload } from "./PluginsManager";
import { GetElementType } from "./type-helperts";


export interface A3dsMaxViewProp {
  app: LoadVersionPayload["app"];
  maxLatestVersion: number;
  loadVersion: (payload: LoadVersionPayload) => void;
  reload?: () => void;
}

export function MapListLanguages({
  app,
  loadVersion,
  maxLatestVersion,
  reload,
}: A3dsMaxViewProp) {

  return <>
    {app.languages.map((language) => {

const srfPlugins = language.startup_scripts.find(
  (script) => script.name === "superrenders"
);

const version = Number(srfPlugins?.version) || 0;
return (
  <div className="children-item">
    <div className="col" 
      onClick={() => {
        loadVersion({
          app,
          language,
          software: "3dsmax",
        });
      }}>
      <div className="children-item-body px-3">
        <div className="col">
          <div className="children-title">
            {`${app.name} ${app.version} - ${ILCConvert(
              language.language
            )}`}
          </div>
          <div className="children-detail">
            <small>
              {"Current 📦 v." + version + " of " + maxLatestVersion}
            </small>
          </div>
        </div>
        <UpToDateChecker current={version} latest={maxLatestVersion} />
      </div>
    </div>
      {/* <button
          className="btn my-auto"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i
            className="fad fa-cog px-2"
            style={{
              fontSize: 20,
              lineHeight: "30px",
            }}
          ></i>
        </button> */}
        <ul className="dropdown-menu dropdown-menu-dark  dropdown-menu-end">
          <li>
            <button className="dropdown-item" type="button">
              Update
            </button>
          </li>
          <li>
            <button className="dropdown-item" type="button">
              Force Update
            </button>
          </li>
          <li>
            <button className="dropdown-item" type="button">
              Uninstall
            </button>
          </li>
        </ul>
  </div>
);
})}
  </>
}



async function updateAllVersions(version: number, app: string) {
  const url = `http://local-app-api.superrendersfarm.com:2546/plugins/updates?app=${app}&version=${version}&plugin=superrenders`;
  await axios.get(url);
}
async function installPlugin(software: string, plugin: string) {
  const url = `http://local-app-api.superrendersfarm.com:2546/plugins/updates/${software}/add/${plugin}`;
  await axios.get(url);
}
export function A3dsMaxView(Props: A3dsMaxViewProp) {
  const {
    app,
    loadVersion,
    maxLatestVersion,
    reload,
  } = Props
  const isHasNoStartupScripts = app.languages.reduce((prv, cLang) => prv + cLang.startup_scripts.length,0 ) === 0
  return (
    <>
      <div className="plugin-item">
        <div className="plugin-item-body">
          <div className="plugin-thumbnail">
            <div
              style={{
                backgroundImage: `url(${DefineIcon(
                  80,
                  getIconType("3dsmax")
                )})`,
              }}
            />
          </div>
          <div className="plugin-title">
            <div>{"Autodesk 3ds Max " + app.version}</div>
            <span>{"Latest version: v." + maxLatestVersion}</span>
          </div>
          <div className="ms-auto my-auto">
          {
            // isHasNoStartupScripts && app.languages.length !== 0 ? 
            // <button className="btn rounded-btn" 
            //     onClick={async()=> await installPlugin(app.name,"superrenders")}>
            //       <i className="fad fa-download me-2"></i> install
            // </button> : 
            
            // <button
            //   className="btn rounded-btn"
            //   disabled={app.languages.length === 0}
            //   onClick={async () => {
            //     await updateAllVersions(app.version, "3dsMax");
            //   }}
            // >
            //   <i className="fad fa-download me-2"></i> Update all
            // </button>

          }
          </div>
        </div>
        <div className="child">
        {/* No languages was found */}
        { app.languages.length == 0 ? (<div className="alert bg-warning-bright alert-left-border text-warning mt-3">
          <strong><i className="fad fa-exclamation-triangle me-3"></i></strong>
          Please try to open once time your 3ds max <br />
              <span className="text-light">
          <small>Fell free to join us : https://supperrendesfarm.com</small>

              </span>
        </div>) : 
          isHasNoStartupScripts ? <></> : <MapListLanguages {...Props}  />
        }
        
        </div>
      </div>


    </>
  );
}

export interface A3dsMaxListProp {
  apps: A3dsMaxViewProp["app"][];
  maxLatestVersion: A3dsMaxViewProp["maxLatestVersion"];
  loadVersion: A3dsMaxViewProp["loadVersion"];
  reload: () => void;
}

export function A3dsMaxList({
  apps,
  loadVersion,
  maxLatestVersion,
  reload,
}: A3dsMaxListProp) {
  return (
    <>
      {apps.map((app) => (
        <A3dsMaxView
          app={app}
          maxLatestVersion={maxLatestVersion}
          loadVersion={loadVersion}
          reload={reload}
        />
      ))}
    </>
  );
}
