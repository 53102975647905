import { IAccount, IAccountAction } from "./accounting.interface";

const AccountInitialState:IAccount = {
    fullname : "Loading...",
    credits : 0
}
export enum AccountReducerType {
    SetCredits = "SET_CREDITS",
    SetFullname = "SET_FULLNAME"
}

export function AccountReducer (state = AccountInitialState, action : IAccountAction) :IAccount  {
    switch (action.type) {

        // Update user credit
        case AccountReducerType.SetCredits:
            return { ...state, credits : action.value  };

            // Update user credit
        case AccountReducerType.SetFullname:
            return { ...state, fullname : action.value  };

        default:
            return state

    }
};
