import React, { useState, ChangeEvent } from 'react'
import { connect } from 'react-redux'
import { Supported3dApps } from 'screens/RenderNow/SceneAnalysis.interface'
import { PHPBOOL } from '../../../../../Interfaces/App.enums'
import { BmpType, ExrCompression, ExrMainFormat, ExrType, PngType, RenderOutputType, TifCompression, TifType } from '../../../../../Interfaces/Renderer'
import { OutputParamsPropertyKeys } from '../../../../../screens/RenderNow/RenderNow.interface'
import { RootState, Stores } from '../../../../../stores/stores'
import './output-settings.scss'
import { OutputSettingsDistpatchs } from './OutputSettings.distpatchs'
const MapStates = (States: RootState) => ({
     Scene: States.Render
})
type OutputSettingsProps = typeof OutputSettingsDistpatchs & ReturnType<typeof MapStates>
function OutputSettings(Renders: OutputSettingsProps) {
     const [Scene, setScene] = useState(Renders.Scene)

     // Slide components rerender too quickly.
     // to avoid performace problems.
     // use state instead of redux store
     const [cjpgquality, setCjpgQuality] = useState(Renders?.Scene?.body?.scene?.params?.output_param?.jpg_quality)
     const [cjpgSmoothing, setCjpgSmoothing] = useState(Renders?.Scene?.body?.scene?.params?.output_param?.jpg_smoothing)
     
     const ListAcceptedExtensions: RenderOutputType[] = [".png", ".jpg", ".jpeg", ".gif", ".uhd", ".exr", ".bmp", ".cjpg", ".cjp", ".tga", ".tif", ".pic", ".cxr", ".rla"]
     // console.table({...Scene.body.scene.params.output_param, extensions: Scene.body.scene.params.scene_param.outputNameType})
     // Listen on file type changed
     const ChangeOutputFileType = (event: React.ChangeEvent<HTMLSelectElement>) => {
          Renders.SetOutputFileType(event.target.value)
     }
     const renderSceneOutputGroup = () => {
          if (Scene.body.scene.params.scene_param.outputNameType === ".bmp") {
               return (
                    <div className="card">
                         <div className="card-header">
                              <label id="titleOutputGroup" className="card-title">BMP Configuration</label>
                         </div>
                         <div id="contentOutputGroup" className="card-body">
                              <div className="row" id="div_ext_bmp">
                                   <div className="col-2 mt-auto">
                                        <label className="form-label">Colors: </label>
                                   </div>
                                   <div className="row col">
                                        <div className="col-6">
                                             <div className="form-check form-check-inline">
                                                  <input
                                                       checked={Renders?.Scene?.body?.scene?.params?.output_param?.bmp_type === BmpType.Paletted}
                                                       onChange={e => {
                                                            Renders.updateOutputProperties(OutputParamsPropertyKeys.bmp_type, BmpType.Paletted)
                                                       }}
                                                       className="form-check-input" type="radio" name="bmp_type" id="bmp_type_bmp_8" value="paletted" />
                                                  <label className="form-check-label" htmlFor="bmp_type_bmp_8">8 Bit Optimized palette (256 colors)</label>
                                             </div>
                                        </div>
                                        <div className="col-6">
                                             <div className="form-check form-check-inline">
                                                  <input
                                                       checked={Renders?.Scene?.body?.scene?.params?.output_param?.bmp_type === BmpType.True24}
                                                       onChange={e => {
                                                            Renders.updateOutputProperties(OutputParamsPropertyKeys.bmp_type, BmpType.True24)
                                                       }}
                                                       className="form-check-input" type="radio" name="bmp_type" id="bmp_type_bmp_24" value="true24" />
                                                  <label className="form-check-label" htmlFor="bmp_type_bmp_24">RGB 24 bit (16.7 Million Colors)</label>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               )
          }
          else if (Scene.body.scene.params.scene_param.outputNameType === ".jpg" || Scene.body.scene.params.scene_param.outputNameType === ".cjpg" || Scene.body.scene.params.scene_param.outputNameType === ".cjp") {
               return (
                    <div className="card">
                         <div className="card-header">
                              <label id="titleOutputGroup" className="card-title">Image Configuration</label>
                         </div>
                         <div id="contentOutputGroup" className="card-body">
                              <div className="row">
                                   <div className="col-6">
                                        <label htmlFor="range-slider-quality" className="form-label"> Quality* : {cjpgquality}</label>
                                        <input type="range" id="range-slider-quality" name="quality" data-from="100" className="form-range"
                                             value={cjpgquality}
                                             onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                  setCjpgQuality(parseInt(e.target.value))
                                                  Renders.updateOutputPropertiesNoReRender(OutputParamsPropertyKeys.jpg_quality, parseInt(e.target.value))
                                             }}
                                        />
                                   </div>
                                   <div className="col-6">
                                        <label className="form-label" htmlFor="range-slider-smoothing"> Smoothing* : {cjpgSmoothing}</label>
                                        <input type="range" id="range-slismoothing" name="smoothing" data-from="0" className="form-range"
                                             value={cjpgSmoothing}
                                             onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                  setCjpgSmoothing(parseInt(e.target.value))
                                                  Renders.updateOutputPropertiesNoReRender(OutputParamsPropertyKeys.jpg_smoothing, parseInt(e.target.value))
                                             }}
                                        />
                                   </div>
                              </div>
                         </div>
                    </div>
               )
          }
          else if (Scene.body.scene.params.scene_param.outputNameType === ".png") {
               return (
                    <div className="card">
                         <div className="card-header">
                              <label id="titleOutputGroup" className="card-title">PNG Configuration</label>
                         </div>
                         <div id="contentOutputGroup" className="card-body">
                              <div id="div_ext_png">
                                   <div className="row">
                                        <div className="col-6">
                                             <span className="input-group-text" >Colors</span>
                                             <select className="form-select" onChange={e => {
                                                  var PngTypeSelected = e.target.value as PngType
                                                  Renders.updateOutputProperties(OutputParamsPropertyKeys.png_type, PngTypeSelected)
                                             }} aria-label="Colors">
                                                  <option value={PngType.OptimizedPaletted}>Optimized palette</option>
                                                  <option value={PngType.RGB24Bit} selected>RGB 24 bit (16.7 Million)</option>
                                                  <option value={PngType.RGB48Bit}>RGB 48 bit (281 Million)</option>
                                                  <option value={PngType.Grayscale8Bit}>Grayscale 8 bit (256)</option>
                                                  <option value={PngType.Grayscale16Bit}>Grayscale 16 bit (65,536)</option>
                                             </select>
                                        </div>
                                        <div className="col-6">
                                             <span className="input-group-text mb-2" >Properties</span>
                                             <div className="form-check form-check-inline">
                                                  <input onChange={e => {
                                                       Renders.updateOutputProperties(OutputParamsPropertyKeys.png_alpha, e.target.checked)
                                                  }} className="form-check-input" type="checkbox" id="pngAlphaChannel" name="png_alpha" />
                                                  <label className="form-check-label" htmlFor="pngAlphaChannel">Alpha channel</label>
                                             </div>
                                             <div className="form-check form-check-inline">
                                                  <input onChange={e => {
                                                       Renders.updateOutputProperties(OutputParamsPropertyKeys.png_interlaced, e.target.checked)
                                                  }} className="form-check-input" type="checkbox" id="pngInterlaced" name="png_interlaced" />
                                                  <label className="form-check-label" htmlFor="pngInterlaced">Interlaced</label>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               )
          }
          else if (Scene.body.scene.params.scene_param.outputNameType === ".tga") {
               return (
                    <div className="card">
                         <div className="card-header">
                              <label id="titleOutputGroup" className="card-title">TGA Configuration</label>
                         </div>
                         <div id="contentOutputGroup" className="card-body">
                              <div id="div_ext_tga">
                                   <div className="d-flex mb-3">
                                        <div className="col-2">
                                             <label className="form-label">Bits-Per-Pixel:</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                             <input
                                                  checked={Scene?.body?.scene?.params?.output_param?.tga_colordepth == 16}
                                                  onChange={e => {
                                                       Renders.updateOutputProperties(OutputParamsPropertyKeys.tga_colordepth, 16)
                                                  }} className="form-check-input" type="radio" name="tga_colordepth" id="radio-tga_colordepth-1" value="16" />
                                             <label className="form-check-label" htmlFor="radio-tga_colordepth-1">16</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                             <input
                                                  checked={Scene?.body?.scene?.params?.output_param?.tga_colordepth == 24}
                                                  onChange={e => {
                                                       Renders.updateOutputProperties(OutputParamsPropertyKeys.tga_colordepth, 24)
                                                  }} className="form-check-input" type="radio" name="tga_colordepth" id="radio-tga_colordepth-2" value="24" />
                                             <label className="form-check-label" htmlFor="radio-tga_colordepth-2">24</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                             <input
                                                  checked={Scene?.body?.scene?.params?.output_param?.tga_colordepth == 32}
                                                  onChange={e => {
                                                       Renders.updateOutputProperties(OutputParamsPropertyKeys.tga_colordepth, 32)
                                                  }} className="form-check-input" type="radio" name="tga_colordepth" id="radio-tga_colordepth-3" value="32" />
                                             <label className="form-check-label" htmlFor="radio-tga_colordepth-3">32</label>
                                        </div>
                                   </div>
                                   <div className="d-flex mb-3">
                                        <div className="col-2">
                                             <label className="form-label">Properties:</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                             <input
                                                  checked={Scene?.body?.scene?.params?.output_param?.tga_compressed}
                                                  onChange={e => {
                                                       Renders.updateOutputProperties(OutputParamsPropertyKeys.tga_compressed, e.target.checked)
                                                  }} className="form-check-input" type="checkbox" id="tga_compressed" name="tga_compressed" />
                                             <label className="form-check-label" htmlFor="tga_compressed">Compress</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                             <input
                                                  checked={Scene?.body?.scene?.params?.output_param?.tga_alphasplit}
                                                  onChange={e => {
                                                       Renders.updateOutputProperties(OutputParamsPropertyKeys.tga_alphasplit, e.target.checked)
                                                  }} className="form-check-input" type="checkbox" id="tga_alphasplit" name="tga_alphasplit" />
                                             <label className="form-check-label" htmlFor="tga_alphasplit">Alpha Split</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                             <input
                                                  checked={Scene?.body?.scene?.params?.output_param?.tga_premultalpha}
                                                  onChange={e => {
                                                       Renders.updateOutputProperties(OutputParamsPropertyKeys.tga_premultalpha, e.target.checked)
                                                  }} className="form-check-input" type="checkbox" id="tga_premultalpha" name="tga_premultalpha" />
                                             <label className="form-check-label" htmlFor="tga_premultalpha">Pre-Multiplied Alpha</label>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               )
          }
          else if (Scene.body.scene.params.scene_param.outputNameType === ".tif") {
               return (
                    <div className="card">
                         <div className="card-header">
                              <label id="titleOutputGroup" className="card-title">TIF Configuration</label>
                         </div>
                         <div id="contentOutputGroup" className="card-body">
                              <div id="div_ext_tif">
                                   <div className="d-flex mb-3">
                                        <div className="row col">
                                             <div className="col-6">
                                                  <span className="input-group-text" >Image type</span>
                                                  <select onChange={e => {
                                                       var TIFTypeSelected = e.target.value as TifType
                                                       Renders.updateOutputProperties(OutputParamsPropertyKeys.tif_type, TIFTypeSelected)
                                                  }} className="form-select" aria-label="Colors" name="tif_type">
                                                       <option value={TifType._8BitGrayScale} selected={Renders.Scene.body.scene.params.output_param.tif_type == TifType._8BitGrayScale} >8-bit Greyscale</option>
                                                       <option value={TifType._8BitColor} selected={Renders.Scene.body.scene.params.output_param.tif_type == TifType._8BitColor} >8-bit Color</option>
                                                       <option value={TifType._16BitColor} selected={Renders.Scene.body.scene.params.output_param.tif_type == TifType._16BitColor} >16-bit Color</option>
                                                       <option value={TifType._16BitSGILogL} selected={Renders.Scene.body.scene.params.output_param.tif_type == TifType._16BitSGILogL} >16-bit SGI LogL</option>
                                                       <option value={TifType._32BitSGILogLUV} selected={Renders.Scene.body.scene.params.output_param.tif_type == TifType._32BitSGILogLUV} >32-bit SGI LogLUV</option>
                                                  </select>
                                             </div>
                                             <div className="col-6">
                                                  <span className="input-group-text mb-2" >Properties</span>
                                                  <div className="form-check form-check-inline">
                                                       <input className="form-check-input"
                                                            onChange={e => {
                                                                 Renders.updateOutputProperties(OutputParamsPropertyKeys.tif_alpha, e.target.checked)
                                                            }}
                                                            checked={Renders.Scene.body.scene.params.output_param.tif_alpha} type="checkbox" name="tif_alpha" id="tif_store_alpha_channel" />
                                                       <label className="form-check-label" htmlFor="tif_store_alpha_channel">Store Alpha Channel</label>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="d-flex mb-3">
                                        <div className="row col">
                                             <div className="col-6">
                                                  <label className="input-group-text" htmlFor="tif_dots_per_inch">Dots per inch</label>
                                                  <input
                                                       onChange={e => {
                                                            Renders.updateOutputProperties(OutputParamsPropertyKeys.tif_dpi, e.target.value)
                                                       }}
                                                       value={Scene?.body?.scene?.params?.output_param?.tif_dpi}
                                                       className="form-control" type="number" id="tif_dots_per_inch" name="tif_dots_per_inch" />
                                             </div>
                                             <div className="col-6">
                                                  <span className="input-group-text mb-2" >Compression type</span>
                                                  <div className="form-check form-check-inline">
                                                       <input
                                                            onChange={e => {
                                                                 Renders.updateOutputProperties(OutputParamsPropertyKeys.tif_compression, e.target.value as TifCompression)
                                                            }}
                                                            className="form-check-input" type="radio" name="tif_compression" id="tif_compression_type_radio1" value="none" checked={Renders.Scene.body.scene.params.output_param.tif_compression == TifCompression.None} />
                                                       <label className="form-check-label" htmlFor="tif_compression_type_radio1">No Compression</label>
                                                  </div>
                                                  <div className="form-check form-check-inline">
                                                       <input
                                                            onChange={e => {
                                                                 Renders.updateOutputProperties(OutputParamsPropertyKeys.tif_compression, e.target.value as TifCompression)
                                                            }}
                                                            className="form-check-input" type="radio" name="tif_compression" id="tif_compression_type_radio2" value="packBits" checked={Renders.Scene.body.scene.params.output_param.tif_compression == TifCompression.PackBits} />
                                                       <label className="form-check-label" htmlFor="tif_compression_type_radio2">PackBits</label>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               )
          }
          else if (Scene.body.scene.params.scene_param.outputNameType === ".exr") {
               return (
                    <div className="card">
                         <div className="card-header">
                              <label id="titleOutputGroup" className="card-title">EXR Configuration</label>
                         </div>
                         <div id="contentOutputGroup" className="card-body">
                              <div className="row" id="div_ext_exr">
                                   <div className="col-6">
                                        <div className="card mb-3">
                                             <div className="card-header">
                                                  <label className="card-title">
                                                       Main Render Output
                                                  </label>
                                             </div>
                                             <div className="card-body">
                                                  <span className="input-group-text" >Format</span>
                                                  <select id="exr_format" className="form-select" name="exr_main_layer_format"
                                                       onChange={e => {
                                                            Renders.updateOutputProperties(OutputParamsPropertyKeys.exr_main_layer_format, parseInt(e.target.value) as ExrMainFormat)
                                                       }}
                                                       value={Renders.Scene.body.scene.params.output_param.exr_main_layer_format}
                                                  >
                                                       <option value={ExrMainFormat.FullFloat}>Full Float (32 bits/Channel)</option>
                                                       <option value={ExrMainFormat.HalfFloat}>Half Float (16 bits/Channel)</option>
                                                       <option value={ExrMainFormat.Integer}>Integer (32 bits/Channel)</option>
                                                  </select>
                                                  <span className="input-group-text" >Type</span>
                                                  <select
                                                       onChange={e => {
                                                            Renders.updateOutputProperties(OutputParamsPropertyKeys.exr_type, e.target.value as ExrType)
                                                       }}
                                                       value={Renders.Scene.body.scene.params.output_param.exr_main_layer_type}
                                                       id="exr_type" className="form-select" >
                                                       <option value={ExrType.ARGB} selected>RGBA</option>
                                                       <option value={ExrType.RGB}>RGB</option>
                                                       <option value={ExrType.Mono}>Mono</option>
                                                       <option value={ExrType.XYZ}>XYZ</option>
                                                       <option value={ExrType.XY}>XY</option>
                                                       <option value={ExrType.UV}>UV</option>
                                                  </select>
                                             </div>
                                        </div>
                                        <span className="input-group-text" >Render Elements</span>
                                        <div className="form-check">
                                             <input className="form-check-input"
                                                  onChange={e => {
                                                       Renders.updateOutputProperties(OutputParamsPropertyKeys.exr_isAutoAddRenderElements, e.target.checked)
                                                  }}
                                                  checked={Renders.Scene.body.scene.params.output_param.exr_isAutoAddRenderElements} type="checkbox" name="exr_auto_add_render_element" id="exr_auto_add_render_element" />
                                             <label className="form-check-label" htmlFor="exr_auto_add_render_element">Automatically Add/Remove Render Elements from Render Dialog</label>
                                        </div>
                                   </div>
                                   <div className="col-6">
                                        <div className="card">
                                             <div className="card-header">
                                                  <label className="card-title">
                                                       Global Options
                                                  </label>
                                             </div>
                                             <div className="card-body">
                                                  <span className="input-group-text" >Compression</span>
                                                  <select id="exr_compression" name="exr_compression" className="form-select"
                                                       onChange={e => {
                                                            Renders.updateOutputProperties(OutputParamsPropertyKeys.exr_compression, parseInt(e.target.value) as ExrCompression)
                                                       }}
                                                       value={Renders.Scene.body.scene.params.output_param.exr_compression}
                                                  >
                                                       <option value={ExrCompression.None}>No Compression</option>
                                                       <option value={ExrCompression.RLE}>Run-length Encoding</option>
                                                       <option value={ExrCompression.ZLIBPerScanLine}>ZLIB, per scanline</option>
                                                       <option value={ExrCompression.ZIPS16Scanline}>ZLIB, 16 scanlines</option>
                                                       <option value={ExrCompression.PIZ}>PIZ</option>
                                                       <option value={ExrCompression.Lossy4x4}>Lossy 4x4 Blocks</option>
                                                       <option value={ExrCompression.Lossy4x4FixedRate}>Lossy 4x4 Blocks(fixed rate)</option>
                                                  </select>
                                                  <span className="input-group-text" >Storage Type</span>
                                                  <select id="exr_storge-type" className="form-select"
                                                       onChange={e => {
                                                            Renders.updateOutputProperties(OutputParamsPropertyKeys.exr_storage, e.target.value == "true")
                                                       }}
                                                       value={Renders?.Scene?.body?.scene?.params?.output_param.exr_storage ? "true" : "false"}
                                                  >
                                                       <option value="true">Store Image as Scanlines</option>
                                                       <option value="false" selected>Store Image as Tiles</option>
                                                  </select>
                                                  <span className="input-group-text" >Image Region</span>
                                                  <select id="exr_image_region" className="form-select"
                                                       onChange={e => {
                                                            Renders.updateOutputProperties(OutputParamsPropertyKeys.exr_image_region, e.target.value == "true")
                                                       }}
                                                       value={Renders?.Scene?.body?.scene?.params?.output_param?.exr_image_region ? "true" : "false"}
                                                  >
                                                       <option value="true">Save Region</option>
                                                       <option value="false" selected>Save Full Image</option>
                                                  </select>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="col-12 mt-3">
                                        <table className="table table-dark table-striped rounded overflow-hidden">
                                             <thead className="bg-dark">
                                                  <tr>
                                                       <th>No</th>
                                                       <th>Name</th>
                                                       <th>Type</th>
                                                       <th>Format</th>
                                                  </tr>
                                             </thead>
                                             <tbody>
                                                  <tr><td colSpan={4} className="text-center"><strong>No Elements</strong></td></tr>
                                             </tbody>
                                        </table>
                                   </div>
                              </div>
                         </div>
                    </div>
               )
          }
     }
     return (
          <div className="d-flex mb-3">
               <label className="form-label mb-2">Output Settings</label>
               <div className="row col">
                    <div className="col-8">
                         <span className="input-group-text" id="scene-path">Output file name*</span>
                         <input
                              onChange={e => {
                                   Renders.updateOutputProperties(OutputParamsPropertyKeys.outputNameFile, e.target.value)
                              }}
                              value={Scene?.body?.scene?.params?.output_param?.outputNameFile }
                              type="text" className="form-control" id="scene-path" aria-describedby="scene-path" placeholder="File name..." />
                    </div>
                    <div className="col-4">
                         <span className="input-group-text">Output extension* </span>
                         <select disabled={Scene._3dEngine === Supported3dApps.C4d} id="output_extension" name="output_extension" onChange={ChangeOutputFileType} className="form-select validate"  >
                              {ListAcceptedExtensions.map((extension) => <option value={extension} selected={Scene.body.scene.params.scene_param.outputNameType === extension }>{extension}</option>)}
                         </select>
                    </div>
               </div>
          </div>
    
     )
}
export default connect(MapStates, OutputSettingsDistpatchs)(React.memo(OutputSettings))