// Declare type of dispatch
export const CHANGE_WIDTH :string = "CHANGE_WIDTH"

export type RenderReducerType =  ""

export interface RenderReducerActionInterface {
    type : RenderReducerActionEnum
    value ?: any
}

export enum RenderReducerActionEnum {
    UpdateScene = "Render.Reducer/UPDATE_SCENE",
    ChangeWidth = "Render.Reducer/CHANGE_WIDTH",
    ChangeHeight = "Render.Reducer/CHANGE_HEIGHT",
    ChangeStartFrame = "Render.Reducer/CHANGE_START_FRAME",
    ChangeEndFrame = "Render.Reducer/CHANGE_END_FRAME",
    ChangeFrameStep = "Render.Reducer/CHANGE_FRAME_STEP",
    SetRenderCamera = "Render.Reducer/SET_RENDER_CAMERA",
    SetRenderCameraMaya = "Render.Reducer/SET_RENDER_CAMERA_MAYA",
    SetRenderTile = "Render.Reducer/SET_RENDER_TILE",
    SetFramePerTask = "Render.Reducer/SET_FRAME_PER_TASK",
    SwitchLightState = "Render.Reducer/SWITCH_LIGHT_STATE",
    SetOutputFileType = "Render.Reducer/SET_OUTPUT_FILE_TYPE",
    SwitchRenderFlags = "Render.Reducer/switch_render_flags",
    SetRenderPriority = "Render.Reducer/SET_RENDER_PRIORITY",
    SetFarmDirectory = "Render.Reducer/SET_FARM_DIRECTORY",
    UpdateOutputProperties = "Render.Reducer/UPDATE_OUTPUT_PROPERTIES",
    SetUseMissingAssets = "Render.Reducer/ANALYZE::SET_USE_MISSING_ASSETS",
    // Gamma
    ChangeUseGammaCorrection = "Render.Reducer/CHANGE_USE_GAMMA_CORRECTION",
    ChangeInputGamma = "Render.Reducer/CHANGE_INPUT_GAMMA",
    ChangeOutputGamma = "Render.Reducer/CHANGE_OUTPUT_GAMMA",
    
    SetSceneParams = "Render.Reducer/SET_SCENE_PARAMS",
    UpdateNrrOutputProperties = "Render.Reducer/UPDATE_NRR_OUTPUT_PROPERTIES",

    SetStartFrameRange = "Render.Reducer/ANALYZE::SET_START_FRAME_RANGE",
    SetEndFrameRange = "Render.Reducer/ANALYZE::SET_END_FRAME_RANGE",
    SetIncrementalStep = "Render.Reducer/ANALYZE::SET_INCREMENTAL_STEP",
    
    UpdateVrayConfigure = "Render.Reducer/UPDATE_VRAY_CONFIGURE",

    IsEnableBuildInFrameBuffer = "Render.Reducer/IS_ENABLE_BUILD_IN_FRAME_BUFFER",

    // Vray options
    SetVrayOptions = "Render.Reducer/ANALYZE::SET_VRAY_OPTIONS",

    SetPrimaryEngine = "Render.Reducer/VRAY::SET_PRIMARY_ENGINE",
    SetSecondaryEngine = "Render.Reducer/VRAY::SET_SECONDARY_ENGINE",
    SetIrradianceMap = "Render.Reducer/VRAY::SET_IRRADIANCE_MAP",
    SetImageFilter = "Render.Reducer/VRAY::SET_IMAGE_FILTER",
    SetIsRenderRawImageFile = "Render.Reducer/ANALYZE::SET_IS_RENDER_RAW_IMAGE_FILE",
    SetVrayRawFileName = "Render.Reducer/ANALYZE::SET_VRAY_RAW_FILE_NAME",
    SeparateRenderChannel = "Render.Reducer/ANALYZE::SET_SEPARATE_RENDER_CHANNEL",
    SetSplitChannelFileName = "Render.Reducer/ANALYZE::SET_SPLIT_CHANNEL_FILE_NAME",

    //Arnold Options 
    SetArnoldOptions = "Render.Reducer/ANALYZE::SET_ARNOLD_OPTIONS",

    SetAASamples = "Render.Reducer/ARNOLD::SET_AA_SAMPLES",
    SetGIDiffuseSamples = "Render.Reducer/ARNOLD::SET_GI_DIFFUSE_SAMPLE",
    SetGIDiffuseDepth = "Render.Reducer/ARNOLD::SET_GI_DIFFUSE_DEPTH",
    SetGISpecularSamples = "Render.Reducer/ARNOLD::SET_GI_SPECULAR_SAMPLE",
    SetGISpecularDepth = "Render.Reducer/ARNOLD::SET_GI_SPECULAR_DEPTH",
    SetGITransmissionSamples = "Render.Reducer/ARNOLD::SET_GI_TRANSMISSION_SAMPLE",
    SetGITransmissionDepth = "Render.Reducer/ARNOLD::SET_GI_TRANSMISSION_DEPTH",
    SetGISSSSamples = "Render.Reducer/ARNOLD::SET_GI_SSS_SAMPLE", 
    SetGIVolumeSamples = "Render.Reducer/ARNOLD::SET_GI_VOLUME_SAMPLE",
    SetGIVolumeDepth = "Render.Reducer/ARNOLD::SET_GI_VOLUME_DEPTH",

    // Corona Options

    SetCoronaOptions = "Render.Reducer/ANALYZE::SET_CORONA_OPTIONS",

    // Cinema4d Update 
    SetOutputMainFileName = "Render.Reducer/SET_OUTPUT_MAIN_FILE_NAME",
    SetOutputMultipassFileName = "Render.Reducer/SET_OUTPUT_MULTIPASS_FILE_NAME",

}