/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { CoronaUHDPresetType, CoronaUHDPresetName, CoronaUhdCachePrecomputationType, CoronaUhdCachePrecomputationName, CoronaPrimarySolverType, CoronaSecondarySolverType, CoronaPrimarySolverName, CoronaSecondarySolverName } from '../../../../../../Interfaces/App.enums'
import './global-illumination.scss'
import { RootState, Stores } from '../../../../../../stores/stores'
import { RenderOptionsInterface } from 'screens/RenderNow/RenderNow.interface'
import { pathToFileURL } from 'url'
import { RenderReducerActionEnum } from 'stores/reducers/render.interface'
import { GiUHDCachePreCalcMode, GiUHDCachePreset, ICoronaOptions, ShadingPrimarySolver,ShadingSecondarySolver } from 'Interfaces/Renderer'
import Modal from "react-modal";
import FileExplorer from 'components/FileExplorer'


const MapStates = (States: RootState) => ({
     Render: States.Render
})
const CoronaDispatchMapper = {
     updateCoronaProps : <Type extends any >(name : string , value: Type ) => ({ type : RenderReducerActionEnum.SetCoronaOptions, value : {name,value} })
}


type GlobalIlluminationProps = ReturnType<typeof MapStates> & typeof CoronaDispatchMapper & {
     renderOptions: RenderOptionsInterface
}


function getCoronaPrimarySolver(primary: CoronaPrimarySolverType) {
     switch (primary) {
          case CoronaPrimarySolverType.None:
               return CoronaPrimarySolverName.None
          case CoronaPrimarySolverType.PathTracing:
               return CoronaPrimarySolverName.PathTracing
     }
}

function getCoronaSecondarySolver(secondary: CoronaSecondarySolverType) {
     switch (secondary) {
          case CoronaSecondarySolverType.None:
               return CoronaSecondarySolverName.None
          case CoronaSecondarySolverType.PathTracing:
               return CoronaSecondarySolverName.PathTracing
          case CoronaSecondarySolverType.UHDCache:
               return CoronaSecondarySolverName.UHDCache
          case CoronaSecondarySolverType.FourKCache:
               return CoronaSecondarySolverName.FourKCache

     }
}

function getCoronaUHDPreset(mode: CoronaUHDPresetType) {
     switch (mode) {
          case CoronaUHDPresetType.StillFrame:
               return CoronaUHDPresetName.StillFrame
          case CoronaUHDPresetType.Animation:
               return CoronaUHDPresetName.Animation
        
     }
}

function getCoronaUhdCachePrecomputation(mode: CoronaUhdCachePrecomputationType) {
     switch (mode) {
          case CoronaUhdCachePrecomputationType.CalculateFromeScratch:
               return CoronaUhdCachePrecomputationName.CalculateFromeScratch
          case CoronaUhdCachePrecomputationType.LoadFromFile:
               return CoronaUhdCachePrecomputationName.LoadFromFile
          case CoronaUhdCachePrecomputationType.TryToLoadAndAppend:
               return CoronaUhdCachePrecomputationName.TryToLoadAndAppend
     }
}

function GlobalIllumination(Props: GlobalIlluminationProps) {
     if (!Props.renderOptions.body?.scene?.params?.corona_option) {
          return <></>
     }
     const { corona_option } = Props.renderOptions.body.scene.params
     console.log(corona_option)
     const [primarySolver, setPrimarySolver] = useState<ShadingPrimarySolver>(corona_option.shading_primarySolver)
     const [secondarySolver, setSecondarySolver] = useState<ShadingSecondarySolver>(corona_option.shading_secondarySolver)
     const [GIUHDCachePreset, setGIUHDCachePreset] = useState<GiUHDCachePreset>(corona_option.gi_uhdcache_preset)
     const [precomputation, setPrecomputation] = useState<GiUHDCachePreCalcMode>(corona_option.gi_uhdCache_precalcMode)
     const [GIUHDCacheFile, setGIUHDCacheFile] = useState<ICoronaOptions["gi_uhdCache_file"]>(corona_option.gi_uhdCache_file)
     const [ModalFileExplorer, setModalFileExplorer] = useState(false)

     return (
          <div className="card mb-3 collapse show" id="global-illumination">
          <div className="card-header">
               <h6 className="card-title">Global Illumination</h6>
          </div>
          <div className="card-body">
                    
               <div className="row mb-3">
                    <div className="col-6">
                         <span className="input-group-text" >Primary solver </span>
                         <select className="form-select" aria-label="Primary solver" id="corona_primary_solver" onChange={e => {
                                   const primaryNewResolver = {
                                        shading_primarySolver_key: parseInt(e.target.value) as CoronaPrimarySolverType,
                                        shading_primarySolver_name: getCoronaPrimarySolver(parseInt(e.target.value) as CoronaPrimarySolverType)
                                   } as ShadingPrimarySolver

                                   setPrimarySolver(primaryNewResolver)
                                   Props.updateCoronaProps(
                                        "shading_primarySolver",
                                        primaryNewResolver
                                   )
                              }}>
                              <option value={CoronaPrimarySolverType.None} selected={primarySolver.shading_primarySolver_key == CoronaPrimarySolverType.None} >{CoronaPrimarySolverName.None}</option>
                              <option value={CoronaPrimarySolverType.PathTracing} selected={primarySolver.shading_primarySolver_key == CoronaPrimarySolverType.PathTracing}>{CoronaPrimarySolverName.PathTracing}</option>
                         </select>
                    </div>
                    {
                         primarySolver.shading_primarySolver_key == CoronaPrimarySolverType.PathTracing ?(
                              <div className="col-6">
                                   <span className="input-group-text" >Secondary solver </span>
                                   <select className="form-select" aria-label="Secondary solver" id="corona_secondary_solver" onChange={e => {
                                   const secondaryNewResolver = {
                                        shading_secondarySolver_key: parseInt(e.target.value) as CoronaSecondarySolverType,
                                        shading_secondarySolver_name: getCoronaSecondarySolver(parseInt(e.target.value) as CoronaSecondarySolverType)
                                   } as ShadingSecondarySolver

                                   setSecondarySolver(secondaryNewResolver)
                                   Props.updateCoronaProps(
                                        "shading_secondarySolver",
                                        secondaryNewResolver
                                   )
                              }} >
                                        <option value={CoronaSecondarySolverType.None} selected={secondarySolver.shading_secondarySolver_key == CoronaSecondarySolverType.None}>{CoronaSecondarySolverName.None}</option>
                                        <option value={CoronaSecondarySolverType.PathTracing} selected={secondarySolver.shading_secondarySolver_key == CoronaSecondarySolverType.PathTracing}>{CoronaSecondarySolverName.PathTracing}</option>
                                        <option value={CoronaSecondarySolverType.UHDCache} selected={secondarySolver.shading_secondarySolver_key == CoronaSecondarySolverType.UHDCache}>{CoronaSecondarySolverName.UHDCache}</option>
                                        <option value={CoronaSecondarySolverType.FourKCache} selected={secondarySolver.shading_secondarySolver_key == CoronaSecondarySolverType.FourKCache}>{CoronaSecondarySolverName.FourKCache}</option>
                                   </select>
                              </div>
                         ):""
                    }
                    
               </div>
               {
                    (secondarySolver.shading_secondarySolver_key == CoronaSecondarySolverType.UHDCache && primarySolver.shading_primarySolver_key == CoronaPrimarySolverType.PathTracing ||
                    secondarySolver.shading_secondarySolver_key == CoronaSecondarySolverType.FourKCache && primarySolver.shading_primarySolver_key == CoronaPrimarySolverType.PathTracing)
                    ?
                    (
                         <div className="card mb-3 border-secondary border" id="UHD-Cache">
                              <div className="card-header">
                                   <h6 className="card-title">
                                        {secondarySolver.shading_secondarySolver_key == CoronaSecondarySolverType.UHDCache ? CoronaSecondarySolverName.UHDCache :
                                         secondarySolver.shading_secondarySolver_key == CoronaSecondarySolverType.FourKCache ? CoronaSecondarySolverName.FourKCache : CoronaSecondarySolverName.None }
                                   </h6>
                              </div>
                              <div className="card-body">
                                   <div className="d-flex mb-3">
                                        <label className="form-label">Preset: </label>
                                        <div className="form-check form-check-inline ms-3">
                                             <input className="form-check-input" type="radio" name="corona_uhd_preset" id="radio_uhd_preset_still_frame" 
                                             value={CoronaUHDPresetType.StillFrame} 
                                             checked={GIUHDCachePreset?.gi_uhdcache_preset_key==CoronaUHDPresetType.StillFrame || true}
                                             onChange={e => {
                                                  const GIUHDCachePresetNewResolver = {
                                                       gi_uhdcache_preset_key: parseInt(e.target.value) as CoronaUHDPresetType,
                                                       gi_uhdcache_preset_name: getCoronaUHDPreset(parseInt(e.target.value) as CoronaUHDPresetType)
                                                  } as GiUHDCachePreset
               
                                                  setGIUHDCachePreset(GIUHDCachePresetNewResolver)
                                                  Props.updateCoronaProps(
                                                       "gi_uhdcache_preset",
                                                       GIUHDCachePresetNewResolver
                                                  )
                                             }} />
                                             <label className="form-check-label" htmlFor="radio_uhd_preset_still_frame">{CoronaUHDPresetName.StillFrame}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                             <input className="form-check-input" type="radio" name="corona_uhd_preset" id="radio_uhd_preset_animation" 
                                             value={CoronaUHDPresetType.Animation}
                                             checked={GIUHDCachePreset?.gi_uhdcache_preset_key==CoronaUHDPresetType.Animation}
                                             onChange={e => {
                                                  const GIUHDCachePresetNewResolver = {
                                                       gi_uhdcache_preset_key: parseInt(e.target.value) as CoronaUHDPresetType,
                                                       gi_uhdcache_preset_name: getCoronaUHDPreset(parseInt(e.target.value) as CoronaUHDPresetType)
                                                  } as GiUHDCachePreset
               
                                                  setGIUHDCachePreset(GIUHDCachePresetNewResolver)
                                                  Props.updateCoronaProps(
                                                       "gi_uhdcache_preset",
                                                       GIUHDCachePresetNewResolver
                                                  )
                                             }} />
                                             <label className="form-check-label" htmlFor="radio_uhd_preset_animation">{CoronaUHDPresetName.Animation}</label>
                                        </div>
                                   </div>
                                   <div className="row mb-3">
                                        <div className="col-12 mb-3">
                                             <span className="input-group-text" >Precomputation </span>
                                             <select id="corona_uhd_cache_precomputation" className="form-select" 
                                             defaultValue={CoronaUhdCachePrecomputationType.CalculateFromeScratch}
                                             onChange={e => {
                                                  const GiUHDCachePreCalcModeNewResolver = {
                                                       gi_uhdCache_precalcMode_key: parseInt(e.target.value) as CoronaUhdCachePrecomputationType,
                                                       gi_uhdCache_precalcMode_name: getCoronaUhdCachePrecomputation(parseInt(e.target.value) as CoronaUhdCachePrecomputationType)
                                                  } as GiUHDCachePreCalcMode
               
                                                  setPrecomputation(GiUHDCachePreCalcModeNewResolver)
                                                  Props.updateCoronaProps(
                                                       "gi_uhdCache_precalcMode",
                                                       GiUHDCachePreCalcModeNewResolver
                                                  )
                                             }}
                                             >
                                                  <option value={CoronaUhdCachePrecomputationType.CalculateFromeScratch}
                                                       selected={precomputation?.gi_uhdCache_precalcMode_key===CoronaUhdCachePrecomputationType.CalculateFromeScratch}>
                                                       {CoronaUhdCachePrecomputationName.CalculateFromeScratch}
                                                  </option>
                                                  <option value={CoronaUhdCachePrecomputationType.LoadFromFile}
                                                       selected={precomputation?.gi_uhdCache_precalcMode_key===CoronaUhdCachePrecomputationType.LoadFromFile}>            
                                                       {CoronaUhdCachePrecomputationName.LoadFromFile}
                                                  </option>
                                                  <option value={CoronaUhdCachePrecomputationType.TryToLoadAndAppend}
                                                       selected={precomputation?.gi_uhdCache_precalcMode_key===CoronaUhdCachePrecomputationType.TryToLoadAndAppend}>                                                                   
                                                       {CoronaUhdCachePrecomputationName.TryToLoadAndAppend}
                                                  </option>
                                             </select>
                                        </div>
                                        {
                                             (
                                                  precomputation?.gi_uhdCache_precalcMode_key===CoronaUhdCachePrecomputationType.LoadFromFile 
                                                  ||
                                                  precomputation?.gi_uhdCache_precalcMode_key===CoronaUhdCachePrecomputationType.TryToLoadAndAppend
                                             )
                                             ?
                                             (
                                                  <div className="col-12">
                                                       <span className="input-group-text" >File path* </span>
                                                       <div className="input-group">
                                                            <input type="text" className="form-control" value={GIUHDCacheFile}/>
                                                            <button className="btn btn-dark" onClick={()=>setModalFileExplorer(true)}>Browse File</button>
                                                       </div>
                                                       <Modal
                                                            isOpen={ModalFileExplorer}
                                                            onRequestClose={() => setModalFileExplorer(false)}
                                                            closeTimeoutMS={250}
                                                            style={{
                                                            overlay: {
                                                                 zIndex: 10000,
                                                                 position: "fixed",
                                                                 top: 35,
                                                                 left: 0,
                                                                 right: 0,
                                                                 bottom: 0,
                                                                 backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                            },
                                                            content: {
                                                                 top: "50%",
                                                                 left: "50%",
                                                                 right: "auto",
                                                                 bottom: "auto",
                                                                 marginRight: "-50%",
                                                                 background: "#343434",
                                                                 transform: "translate(-50%, -50%)",
                                                                 border: "none",
                                                                 width: "90%",
                                                                 height: "90%",
                                                                 padding: "0",
                                                                 cursor: "default",
                                                                 overflow: "hidden",
                                                                 borderRadius: "10px",
                                                            },
                                                            }}
                                                            contentLabel="File Explorer"
                                                       >
                                                            <div className="close-button">
                                                            <button className="btn" onClick={() => setModalFileExplorer(false)}>
                                                                 <i className="fal fa-times" />
                                                            </button>
                                                            </div>
                                                            <FileExplorer URLPath={setGIUHDCacheFile}/>
                                                            
                                                            <div className="fixed-bottom m-5">
                                                            <div className="input-group">
                                                                 <span className="input-group-text">Select file</span>
                                                                 <input className="form-control" disabled value={GIUHDCacheFile} />
                                                                 <button
                                                                 className="btn btn-success"
                                                                 onClick={ ()=>{
                                                                      
                                                                      Props.updateCoronaProps(
                                                                           "gi_uhdCache_file",
                                                                           GIUHDCacheFile
                                                                      )
                                                                      &&

                                                                      setModalFileExplorer(false)
                                                                 }}
                                                                 >
                                                                 Select
                                                                 </button>
                                                            </div>
                                                            </div>
                                                       </Modal>
                                                  </div>
                                             ):""
                                        }
                                       
                                   </div>
          
                              </div>
                         </div>
               
                    ):""
               }
          
          </div>
     </div>
     )
}
export default connect(MapStates, CoronaDispatchMapper)(GlobalIllumination)