// Idk what is this.
// :>
export enum PHPBOOL {
    True = "1",
    False = "0",
    Yes = "yes",
    No = "no",
    On = "on",
    Off = "off",
    One = "1",
    Zero = "0",
}

export enum CloudType {
    AwsS3 = "awss3",
    Dropbox = "dbx",
    GoogleDrive = "gdrive"
}

export enum JobType {
    Animation = "Animation",
    StillImage = "Stillimage",
    Single = "Single"
}
// Corona enum

export enum CoronaPrimarySolverName {
    None = "None",
    PathTracing = "Path Tracing"
}

export enum CoronaPrimarySolverType {
    None = 0,
    PathTracing = 1,
}

export enum CoronaSecondarySolverName {
    None = "None",
    PathTracing = "Path Tracing",
    UHDCache = "UHD Cache",
    FourKCache = "4K Cache"
}

export enum CoronaSecondarySolverType {
    None = 0,
    PathTracing = 1,
    UHDCache = 2,
    FourKCache = 3
}

export enum CoronaUHDPresetName {
    StillFrame = "Still frame (fast precomputation)",
    Animation = "Animation (flicker free)"
}

export enum CoronaUHDPresetType {
    StillFrame = 0,
    Animation = 1
}

export enum CoronaUhdCachePrecomputationName {
    CalculateFromeScratch = "Calculate from scratch",
    LoadFromFile = "Load from file",
    TryToLoadAndAppend = "Try to load and append"
}

export enum CoronaUhdCachePrecomputationType {
    CalculateFromeScratch = 0,
    LoadFromFile = 1,
    TryToLoadAndAppend = 2
}

// Vray enum
export enum VrayGlobalIlluminationPrimaryName {
    IrradianceMap = "Irradiance Map",
    PhotonMap = "Photon Map",
    BruteForce = "Brute Force",
    LightCache = "Light Cache"
}

export enum VrayGlobalIlluminationSecondaryName {
    None = "None",
    PhotonMap = "Photon Map",
    BruteForce = "Brute Force",
    LightCache = "Light Cache"
}

export enum VrayGIPrimaryType {
    IrradianceMap = 0,
    PhotonMap = 1,
    BruteForce = 2,
    LightCache = 3
}

export enum VrayGISecondaryType {
    None = 0,
    PhotonMap = 1,
    BruteForce = 2,
    LightCache = 3
}

export enum IrradMapMode {
    SingleFrame = 0,
    MultiFrameIncremental = 1,
    FromFile = 2,
    AddToCurrentMap = 3,
    IncrementalAddToCurrentMap = 4,
    Bucket = 5,
    AnimationPrepass = 6,
    AnimationRendering = 7,
}

export enum IrradMapModeName {
    SingleFrame = "Single frame",
    MultiFrameIncremental = "Multiframe incremental",
    FromFile = "From file",
    AddToCurrentMap = "Add to current map",
    IncrementalAddToCurrentMap = "Incremental add to current map",
    Bucket = "Bucket",
    AnimationPrepass = "Animation (Prepass)",
    AnimationRendering = "Animation (Rendering)",
    Path = "Path"
}

export enum LightCacheMode {
    SingleFrame = 0 ,
    FlyThrough = 1,
    FromFile = 2,
    ProgressivePathTracking = 3,
}

export enum LightCacheModeName {
    SingleFrame = "Single frame",
    FlyThrough = "Fly fhrough",
    FromFile = "From file",
    ProgressivePathTracking = "Progressive path tracking"
}

export enum ImageSamplerType {
    Bucket = 1,
    Progressive = 3
}
export enum ImageSamplerName {
    Bucket = "Bucket",
    Progressive = "Progressive"
}

export enum FarmManagerSoftware {
    Deadline = "deadline"
}

export enum SceneAnalyzeStatus {
    SceneAnalyzed = "scene_analyze",
    FromLocal = "from_local"
}

export enum RenderProcessor {
    CPU = "cpu",
    GPU = "gpu"
}

export enum IndexOf {
    First = 0,
    Second = 1,
    Third = 2,
    NotFound = -1,
    NotListedIn = -1,
    NotMatch = -1,
}
export const SceneExtension = [
    "ma", // Maya
    "mb", // Maya
    "max",
    "hip",
    "hiplc",
    "hipnc",
    "blend",
    "aep", //
    "c4d",
]


export enum SoftwareVersion {
    Undefined = "undefined",
    Autodesk3dsMax2023 = "3dsmax_2023",
    Autodesk3dsMax2022 = "3dsmax_2022",
    Autodesk3dsMax2021 = "3dsmax_2021",
    Autodesk3dsMax2020 = "3dsmax_2020",
    Autodesk3dsMax2019 = "3dsmax_2019",
    Autodesk3dsMax2018 = "3dsmax_2018",
    Autodesk3dsMax2017 = "3dsmax_2017",
    Autodesk3dsMax2016 = "3dsmax_2016",
    Autodesk3dsMax2015 = "3dsmax_2015",
    Maya_2023 = "maya_2023",
    Maya_2022 = "maya_2022",
    Maya_2020 = "maya_2020",
    Maya_2019 = "maya_2019",
    Maya_2018 = "maya_2018",
    Maya_2017 = "maya_2017",
    Maya_2015 = "maya_2015",
    Houdini_19_0 = "houdini_19_0",
    Houdini_18_5 = "houdini_18_5",
    Houdini_18_0 = "houdini_18_0",
    Houdini_17_5 = "houdini_17_5",
    Blend_32 = "blend_32",
    Blend_31 = "blend_31",
    Blend_30 = "blend_30",
    C4d_R26 = "c4d_r26",
    C4d_R25 = "c4d_r25",
    C4d_R24 = "c4d_r24",
    C4d_R23 = "c4d_r23",
    C4d_R22 = "c4d_r22",
    C4d_R21 = "c4d_r21",
    C4d_R20 = "c4d_r20",
    C4d_R19 = "c4d_r19",
    C4d_R18 = "c4d_r18",
    C4d_R17 = "c4d_r17",
    C4d_R16 = "c4d_r16",
}
export enum RenderEngineVersion {
    Undefined = "undefined",
    // VRAY
    Vray_5_2_Cpu = "vray_5_2_cpu",
    Vray_5_Cpu = "vray_5_cpu",
    Vray_5_Gpu= "vray_5_gpu",
    Vray_5 = "vray_5",
    Vray_Next_4_3_cpu = "vray_next",
    Vray_Next_4_3_Gpu = "vray_next_43_gpu",
    Vray_4_2_Cpu = "vray_4_2_cpu",
    Vray_4_1_Cpu = "vray_4_1_cpu",
    Vray_4_1_Gpu = "vray_4_1_gpu",
    Vray_3 = "vray_3",
    Vray_Gpu = "vray_gpu",
    Vray4phoenix4 = "vray4phoenix4",
    Vray3phoenix3 = "vray3phoenix3",
    // Arnold
    Arnold5_0_1_Cpu = "arnold5_0_1_cpu",
    Arnold4_0_1_Cpu = "arnold4_0_1_cpu",
    Arnold4_0_1_Gpu = "arnold4_0_1_gpu",
    Arnold4 = "arnold4",
    Arnold3 = "arnold3",
    Arnold2 = "arnold2",
    Arnold = "arnold",

    // Corona
    Corona8 = "corona8",
    Corona7 = "corona7",
    Corona6 = "corona6",
    Corona5 = "corona5",
    Corona4 = "corona4",

    Mtoa5 = "mtoa5",
    Mtoa4 = "mtoa4",
    Mtoa4_Gpu = "mtoa4_gpu",
    Mtoa3 = "mtoa3",
    Mtoa2 = "mtoa2",
 
    Maya_Software = "maya_software",
    Maya_Hardware_2 = "maya_hardware_2",
    Maya_Vector = "maya_vector",
 
    Blend_Standard = "blend_standard",
    Blend_Cycles_Cpu = "blend_cycles_cpu",
    Blend_Cycles_Gpu = "blend_cycles_gpu",

    Eevee_Cpu = "eevee_cpu",
    Eevee_Gpu = "eevee_gpu",

    Octane = "octane",
    Octane_3_6_Gpu = "octane_3_6_gpu",
    
    C4d_Standard = "c4d_standard",
    C4d_Physical = "c4d_physical",
    C4d_Prorender = "c4d_prorender",
    C4d_Prorender_Gpu = "c4d_prorender_gpu",

    Redshift_3_6_Cpu = "redshift_3_6_cpu",
    Redshift_3_0_Gpu = "redshift_3_0_gpu",

    Cycles_4d = "cycles_4d",
    Cycles = "cycles",
    
    Quicksilver_Hardware = "quicksilver_hardware",
    Art = "art",
    Scanline = "scanline",
    Veu_File = "veu_file",
    Mentalray = "mentalray",
    Mantra_Cpu = "mantra_cpu",
    Renderman = "renderman",
    Redshift = "redshift",
    
}

export enum Processor {
    CPU = "cpu",
    GPU = "gpu"
}