import { SceneInterface } from 'Interfaces/Renderer'
import React from 'react'
import Swal from 'sweetalert2'
import './dropdown-button.scss'

interface DropdownButtonProps {
     disable: boolean
     CreateRenderJob? : () => void,
     Reanalyze : () => void,
     HandleDeleteAnalyze : () => void,
}

export default function DropdownButton({CreateRenderJob,Reanalyze,HandleDeleteAnalyze,disable}: DropdownButtonProps) {
     return(
          <div className="btn-group analysis-btn ms-auto">
               <button type="button" className="btn bg-success-bright text-uppercase front-btn" onClick={CreateRenderJob} disabled={disable}>
                    <i className="fad fa-play me-2"></i>Start render job
               </button>
               <button type="button" className="btn bg-success-bright dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                    <span className="visually-hidden">Toggle Dropdown</span>
               </button>

               <div className="dropdown-menu dropdown-menu-dark">
                    <button className="dropdown-item text-uppercase" onClick={() => {
                         Reanalyze()
                         Swal.fire({
                              title: 'Reanalyze Success!',
                              text: '',
                              icon: 'success',
                              confirmButtonColor: '#218c61',
                              confirmButtonText: 'OK',
                         
                         })
                    }}>
                         <i className="fad fa-repeat me-2"></i>Reanalyze
                    </button>
                    <button className="dropdown-item text-uppercase" onClick={() => {
                         Swal.fire({
                              title: 'Are you sure?',
                              text: "You won't be able to revert this!",
                              icon: 'warning',
                              showCancelButton: true,
                              reverseButtons: true,
                              confirmButtonColor: '#218c61',
                              cancelButtonColor: '#d33',
                              confirmButtonText: 'Yes, delete it!',
                         
                         }).then((result) => {
                              if (result.isConfirmed) {
                                   HandleDeleteAnalyze()
                                   Swal.fire(
                                        {
                                         title : 'Deleted!',
                                         text: 'Your analyze scene has been deleted.',
                                         icon: 'success',
                                         confirmButtonColor: '#218c61',
                                        }
                                      )
                              }
                         })
                    }} >
                         <i className="fad fa-trash me-2"></i>Delete
                    </button>
               </div>
          </div>
                    
     )
}