import {useState} from "react"
import Swal from "sweetalert2"

export type useStateMiddlewareDispatch < Type, PayloadType > = [
    state: Type,
    setStateMiddleware: (state: Type, Payload: PayloadType) => void
]

export type ReturnError = {
    title ?: string,
    message ?:string,
    next  : boolean
}

export function useStateMiddlware < Type, PayloadType > (
    Default: Type,
    Condition: (Default: Type, Payload?: PayloadType) => ReturnError,
    ErrorHandle: (error:string) => void
): useStateMiddlewareDispatch < Type, PayloadType > {
    // create state
    const [state, setState] = useState < Type > (Default)

    // Check condition before update state
    function setStateMiddleware(newState: Type, data: PayloadType) {
        const Checking = Condition(newState, data) as ReturnError

        if (Checking.next) {
            setState(newState)
        } else
            // Return error
            Swal.fire({
                title: Checking?.title || 'Error',
                text: Checking?.message || 'Something missing please contact to the admin!',
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#218c61',
           })
    }

    // return state
    return [state, setStateMiddleware]
}