import React, {useState} from 'react'
import { connect } from 'react-redux'
import { RenderNowMapDispatchs } from '../../../../../screens/RenderNow/RenderNow.dispatch'
import { RenderOptionsInterface } from '../../../../../screens/RenderNow/RenderNow.interface'
import { RootState } from '../../../../../stores/stores'
import { MapDispatchs } from './Resolution.dispatch'
import './resolution.scss'

const MapStates = (States: RootState) => ({
     RenderScene : States.Render
})

type MapStatesInterface = ReturnType<typeof MapStates>
type MapDispatchInterface = typeof MapDispatchs
type ResolutionProps = MapStatesInterface & MapDispatchInterface

function Resolution(Renders:ResolutionProps) {
     const [RenderScene, setRenderScene] = useState(Renders.RenderScene)
     const [frameWidth, setFrameWidth] = useState(RenderScene.body.scene.params.scene_param.width)
     const [frameHeight, setFrameHeight] = useState(RenderScene.body.scene.params.scene_param.height)

     const UpdateWidth = (inputChange:React.ChangeEvent<HTMLInputElement>) => {
          let width : number = parseInt(inputChange.target.value)
          setFrameWidth(width)
          Renders.SetWidth(width)
     }

     const UpdateHeight = (inputChange:React.ChangeEvent<HTMLInputElement>) => {
          let height : number = parseInt(inputChange.target.value)
          setFrameHeight(height)
          Renders.SetHeight(height)
     }

     return (
          <div className="d-flex mb-3">
               <label className="form-label mb-2">Output Resolution</label>
               <div className="row col">
                    <div className="col-4">
                         <span className="input-group-text" id="scene-path">Width  <span className="text-danger">*</span></span>
                         <input type="number" onChange={UpdateWidth} className="form-control" id="scene-path" aria-describedby="scene-path" value={ frameWidth} />
                    </div>
                    <div className="col-4">
                         <span className="input-group-text" id="scene-path">Height  <span className="text-danger">*</span></span>
                         <input type="number" onChange={UpdateHeight} value={frameHeight} className="form-control" id="scene-path" aria-describedby="scene-path" />
                    </div>
                    {
                         RenderScene._3dEngine === "3dsmax" ? 
                              <div className="col-4">
                                   <span className="input-group-text" id="scene-path">Ratio </span>
                                   <input type="number" className="form-control" id="scene-path" aria-describedby="scene-path" value={ RenderScene.body.scene.params.scene_param.width / RenderScene.body.scene.params.scene_param.height}/>
                              </div>
                              : 
                              <></>
                    }
                  
               </div>
          </div>
     )
}
export default connect( MapStates , MapDispatchs )(React.memo(Resolution))