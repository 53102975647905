export function useShortcut( handle_shortcut:string, callback : () => void ){
    const handler = ( event : KeyboardEvent) => {
        // Prevent default action
        event.preventDefault();

        // make shortcut string
        let shortcut = '';
        if (event.ctrlKey) shortcut += 'Ctrl+';
        if (event.shiftKey) shortcut += 'Shift+';
        if (event.altKey) shortcut += 'Alt+';
        shortcut += event.key.toUpperCase();

        // Check if shortcut is mapped
        if (shortcut === handle_shortcut) {
            callback();
        }
    }
    document.addEventListener('keyup', handler);
}

export function useShortcutMap( callback : (shortcut:string) => void ) {

    const handler = ( event : KeyboardEvent) => {
        // Prevent default action
        event.preventDefault();

        // make shortcut string
        let shortcut = '';
        if (event.ctrlKey) shortcut += 'Ctrl+';
        if (event.shiftKey) shortcut += 'Shift+';
        if (event.altKey) shortcut += 'Alt+';
        shortcut += event.key.toUpperCase();
        // Check if shortcut is mapped
        callback(shortcut);
    }
    document.addEventListener('keyup', handler);
}