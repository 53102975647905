import React from 'react'
import { API } from 'apis/base.api'
import Loading from 'components/Loading'
import { SceneInterface, SrfMaxError, SrfMaxInfo, SrfMaxWarning } from 'Interfaces/Renderer'
import NiceFileIcon from "../../../../../../assets/SVG/style-dark-1/17.svg"
import './analysis-collapse.scss'

interface AnalysisCollapseProps {
    items : SceneInterface,
    id: number , 
    isToggle : boolean
}
export default function AnalysisCollapse({items,id,isToggle}: AnalysisCollapseProps) {
    const AnalyzeLog = ({id} : {id:number}) => {
        const [log, setLog] = React.useState<string>("");
        const fetchLog = async () => {
            try {
                const response = await API.guard().get<{
                    data : {
                        render_log : string
                    }
                }>(`/analyze-job/get/scene-analyze-render-log/${id}`);
                setLog(response.data.data.render_log);
            }catch (e) {
                setLog("Cannot load log: \n " + e);
            }
        }
    
        React.useEffect(() => {
            if (isToggle) fetchLog();
        }, [])
    

        if (!isToggle || !!!log) return <div className="content" style={{height:200}}>
            <Loading/>
        </div>
        
        return(
            <div className="content">
                <div className="title text-danger sticky-top">
                    <i className="fad fa-exclamation-square me-2"></i>Error log
                </div>
                <div className="logs mx-3 my-2">
                    {log.split("<br />").map((log, index) => <div 
                        className={`${log.startsWith('Error') ? "text-danger" :
                                log.startsWith('File') ? "text-warning" :
                                "text-light"}`}
                        key={index}>
                        {log}
                    </div>)}
                </div>
            </div>
        )
    }

  

    function ErrorsItems({ items }: { items?: SceneInterface }) {
        // errors item was not found or empty
        if (!items || items?.error.length === 0) return <></>

        return (
            <div className="content">
                <div className="title text-danger">
                    <i className="fad fa-exclamation-square me-2"></i>Error
                </div>
                <ul>
                    {
                        items && items.error.map((item, index) => {
                            return <li key={index}>{item[Object.keys(item)[0] as keyof SrfMaxError]?.message}</li>
                        })
                    }
                </ul>
            </div>
        )
    }
    function WarningItems({ items }: { items?: SceneInterface }) {
        // errors item was not found or empty
        if (!items || items?.warning.length === 0) return <></>

        return (
            <div className="content">
                <div className="title text-warning">
                    <i className="fad fa-exclamation-square me-2"></i>Warnings
                </div>
                <div className="content-body">
                    {
                    items && items.warning.map((item, index) => {
                        const srf_value = item[Object.keys(item)[0] as keyof SrfMaxWarning]?.value || 0
                        return <div  key={index}>
                            <ul>
                                <li>
                                    {item[Object.keys(item)[0] as keyof SrfMaxWarning]?.message.replace('___srf_value___',srf_value.toString())}
                                </li>
                            </ul>
                            {
                                item[Object.keys(item)[0] as keyof SrfMaxWarning]?.items.length !== 0 ?
                                    <div className="card card-body m-3 ">
                                        {
                                            
                                            item[Object.keys(item)[0] as keyof SrfMaxWarning]?.items.map((o,index)=> <div className="logs" key={index}>{o}</div>  )
                                        }
                                    </div>
                                : <></>
                            }
                            
                        </div>
                    })
                    }
                </div>
            </div>
        )
    }
    function InformationItems({ items }: { items?: SceneInterface }) {
        if (!items || items?.info.length === 0) return <></>
        return (
            <div className="content">
                <div className="title text-primary">
                    <i className="fad fa-exclamation-square me-2"></i>Info
                </div>
                <ul>
                    {
                        items && items.info.map((item, index) => {
                            return <li key={index}>{item[Object.keys(item)[0] as keyof SrfMaxInfo]?.message}</li>
                        })
                    }
                </ul>
            </div>
        )
    }
    return(
        <div className={`analysis-more ${isToggle ? " show" : ""}`}>
            {
                items && (items.error.length == 0 && items.warning.length == 0 && items.info.length == 0) ? (
                <div className="content">
                        <div className="d-flex my-4 gap-5">
                                <div className="logo ms-5 mt-4" style={{ backgroundSize: "cover", height: "160px", width: "148px", backgroundRepeat: "no-repeat", backgroundImage: `url("${NiceFileIcon}")` }} />
                                <h4 className="my-auto">Your scene looks good !</h4>
                        </div>
                    </div>
                )
                : !items ? <AnalyzeLog id={id} />
                :
                (
                <>
                    <ErrorsItems items={items} />
                    <WarningItems items={items} />
                    <InformationItems items={items} />
                </>
                )
            }
           
        </div>
    )
}