import Electron from 'electron'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Logger from '../../Helpers/Log'
import AnalysisResults from '../../components/RenderNowComponent/AnalysisResults'
import Max from '../../components/RenderNowComponent/ParamSettings/Max'
import { RootState } from '../../stores/stores'
import './render-now.scss'
import { RenderOptionsInterface } from './RenderNow.interface'
import { RenderNowMapDispatchs } from './RenderNow.dispatch'
import { IpcSender } from '../../Interfaces/IpcRenderer.enum'
import { StartRenderAnalyzedSceneNow } from './RenderAnalyzedScene.handle'
import { useStateMiddlware } from '../../Helpers/SuperReact'
import { CloseScreenModal } from '../../Helpers/Modal'
import Maya from 'components/RenderNowComponent/ParamSettings/Maya'
import Houdini from 'components/RenderNowComponent/ParamSettings/Houdini'
import Cinema4d from 'components/RenderNowComponent/ParamSettings/Cinema4d'
import Blender from 'components/RenderNowComponent/ParamSettings/Blender'
const { ipcRenderer } = window.electron;


// Map redux store states to component props
// If redux store has been changed, this component will be re-rendered
const MapStates = (States: RootState) => ({
     Renderings: States.Render
})

// Mapping dispatcher to component props
// In component, can call to dispatch using props of component.

// Render component to show settings to client render
// Components interfaces
type MapStateInterface = ReturnType<typeof MapStates>
type MapDispatchInterface = typeof RenderNowMapDispatchs
export type RenderNowProps = MapStateInterface & MapDispatchInterface & {
     RenderScene: RenderOptionsInterface
}
function StartRenderAnalyzedScene(Renders: RenderNowProps) {
     const RenderScene = Renders.RenderScene
     // use state with middleware
     const [navPill, setNavPill] = useStateMiddlware<number, RenderOptionsInterface>(1, (newState, data) => {

          // Data can be undefined. then if data was undefined. no thing to check
          if (!data)
               return { next: true }

          // No Error was found, then update the state
          return { next: true }
     }, (err) => {
          // On Error return
          return { message: err,
                    next: false }
     })

     if (!RenderScene) {
          return (
               <div className="vh-100">
                    <div className="d-flex h-100">
                         <div className="h1 m-auto">No scene information available !</div>
                    </div>
               </div>
          )
     }

     return (
          <div className="analyzed-scene sceene-from-server">

               <div className="render-now">
                    <div className="title d-flex">
                         <div className="col m-auto ms-3">
                              <h6 className="m-0">Start Render Job</h6>
                         </div>
                         <button className="btn button-close ms-auto" type="button" onClick={() => CloseScreenModal()} >
                              <i className="fal fa-times"></i>
                         </button>
                    </div>

                    <ul className="nav nav-pills" id="RenderNowNav" role="tablist">
                         <li className="nav-item" role="presentation">
                              <button className={`nav-link ${navPill === 1 ? "active" : ""}`} type="button" onClick={() => setNavPill(1, Renders?.Renderings)} >ANALYSIS RESULTS</button>
                         </li>
                         <li className="my-auto mx-2">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" /></svg>
                         </li>
                         <li className="nav-item" role="presentation">
                              <button className={`nav-link ${navPill === 2 ? "active" : ""}`} type="button" onClick={() => setNavPill(typeof Renders?.Renderings?.body?.scene?.params?.scene_param !== "undefined" ? 2 : 1, Renders?.Renderings)}>SCENE PARAMS</button>
                         </li>
                         <li className="my-auto ms-auto">
                              <div className="badge bg-success-bright me-2">Software Version : {RenderScene.Analysis?.software_version}</div>
                              <div className="badge bg-success-bright me-2">Renderer/Plugins version: {RenderScene.Analysis?.renderer_version}</div>
                         </li>

                    </ul>
                    <div className="RenderSetting tab-content">
                         {
                              navPill === 1 ? (
                                   <div className="tab-pane show active">
                                        <AnalysisResults scene_analyze={RenderScene.body.scene} />
                                   </div>
                              ) : (
                                   <div className="tab-pane show active">
                                        {
                                        RenderScene._3dEngine === "3dsmax" ? <Max /> :
                                        RenderScene._3dEngine === "maya" ? <Maya /> :
                                        RenderScene._3dEngine === "houdini" ? <Houdini /> :
                                        RenderScene._3dEngine === "c4d" ? <Cinema4d /> :
                                        RenderScene._3dEngine === "blend" ? <Blender /> : "Not Support"
                                        }
                                   </div>
                              )
                         }
                    </div>
                    <div className="fixed-bottom">
                         <div className="d-flex m-2">
                              <div className="ms-auto">
                                   {
                                        navPill === 1 ? (<>
                                             <button type="button" className="btn btn-sm me-2 close-back-btn" onClick={() => CloseScreenModal()}>Close</button>
                                             <button type="button" 
                                                  className="btn btn-sm btn-success btn-continue" onClick={() => setNavPill(2, Renders?.Renderings)} >Continue</button>
                                        </>)
                                             : (<>
                                                  <button type="button" className="btn btn-sm me-2 close-back-btn" onClick={() => setNavPill(1, Renders?.Renderings)} >Back</button>
                                                  <button type="button" className="btn btn-sm btn-success btn-start-render ms-auto" onClick={StartRenderAnalyzedSceneNow} >Start Render Now</button>
                                             </>)
                                   }
                              </div>

                         </div>
                    </div>
               </div>               </div>
     )
}

// Export using redux store to re-render via dispatcher
// using React.memo to memories static variables, then we dont need to re-render a component if that component's prop
// was not changed
export default connect(MapStates, RenderNowMapDispatchs)(React.memo(StartRenderAnalyzedScene))