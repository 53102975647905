import { RenderReducerActionEnum } from "../../../../../stores/reducers/render.interface";

export const MapDispatchs = {

    // Set start frame
    SetStartFrame : (frame: number) => ({ type : RenderReducerActionEnum.ChangeStartFrame , value : frame}),

    // Set end frame
    SetEndFrame : (frame: number) => ({ type : RenderReducerActionEnum.ChangeEndFrame, value : frame}),

    // Change increment frame step
    SetFrameStep : (step: number) => ({ type : RenderReducerActionEnum.ChangeFrameStep, value : step}),

}