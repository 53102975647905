import { useShortcut } from "./Helpers/Shortcut";

export enum Shortcut {
    // File
    Save = 'Ctrl+S',
    Open = 'Ctrl+O',
    New = 'Ctrl+N',
    Quit = 'Ctrl+Q',
    // Edit
    Undo = 'Ctrl+Z',
    Redo = 'Ctrl+Y',
    Copy = 'Ctrl+C',
    Cut = 'Ctrl+X',
    // View
    Reload = 'Ctrl+R',
    FileStorage = 'Ctrl+1',
}
function MapShortcut(key: string): boolean {
    const shortcuts =  new Map<string, string>();
    shortcuts.set('Ctrl+S', 'Save');
    shortcuts.set('Ctrl+O', 'Open');
    shortcuts.set('Ctrl+N', 'New');
    shortcuts.set('Ctrl+Q', 'Quit');
    shortcuts.set('Ctrl+Z', 'Undo');
    shortcuts.set('Ctrl+Y', 'Redo');
    shortcuts.set('Ctrl+C', 'Copy');
    shortcuts.set('Ctrl+X', 'Cut');
    shortcuts.set('Ctrl+R', 'Reload');
    if (shortcuts.has(key)) {
        console.log(shortcuts.get(key));
        return true;
    }
    return false;
}

function ShortcutHandlers( event : KeyboardEvent) {
    // Prevent default action
    event.preventDefault();
}

export function InitializeShortcuts(){
    document.addEventListener('keyup', ShortcutHandlers);
}

