import React from "react";
import { SSAction, SSActionType, SupportedSoftwareInitialState, SupportedSoftwareTypes } from "./supported-software.types";

const modalDefaultState: SupportedSoftwareInitialState = {
    list : []
}

export const SupportedSoftwareReducer = ( state = modalDefaultState, { type, payload} : SSAction<any> )  =>  {
    switch (type) {
        // set list
        case SSActionType.SetList:
            return {...state, list: payload  as SupportedSoftwareTypes[] };

        default:
            return state
    }
}
