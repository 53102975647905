import {AUTHENTICATION_KEY_NAME, AUTH_FAKER_TOKEN} from "config";
import {JwtPayload} from "./auth.types";
import base64 from "base-64";
import {ParseJson} from "modules/JSON/parse";
import {AppLocalStorage} from "modules/storages/localstorage";

export class Auth {
  public static init() {}

  public static get JwtPayload(): JwtPayload {
    const token = this.getAccessToken();
    const parts = token.split(".");
    const decodedToken = ParseJson<JwtPayload>(base64.decode(parts[1]));
    decodedToken.srfspaces = this.getSrfSpaces(decodedToken);
    return decodedToken;
  }

  public static getSrfSpaces(decodedToken : JwtPayload): string {
    return `superrenders_${decodedToken.user_name}_srfspaces`;
  }

  // is access token available
  public static get isAuthenticated(): boolean {
    return (!!AppLocalStorage.get<string>(AUTHENTICATION_KEY_NAME) && this.validateAccessToken());
  }

  // validate access token
  public static validateAccessToken(): boolean {
    const accessToken = AppLocalStorage.get<string>(AUTHENTICATION_KEY_NAME);
    if (!accessToken) {
      return false;
    }

    const decoded = this.JwtPayload;

    if (!decoded.exp) 
      return false;
    
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      return false;
    }

    return true;
  }

  // get access token
  public static getAccessToken(): string {
    const accessToken = AppLocalStorage.get<string>(AUTHENTICATION_KEY_NAME) || "";
    if (!accessToken) {
      throw new Error("No access token found");
    }

    return accessToken;
  }

  // set access token
  public static setAccessToken(accessToken : string) {
    localStorage.setItem(AUTHENTICATION_KEY_NAME, accessToken);
  }

  // remove access token
  public static removeAccessToken() {
    localStorage.removeItem(AUTHENTICATION_KEY_NAME);
  }

  // get refresh token
  public static getRefreshToken(): string {
    return AppLocalStorage.get<string>("refresh_token") || "";
  }

  // set refresh token
  public static setRefreshToken(refreshToken : string) {
    localStorage.setItem("refresh_token", refreshToken);
  }

  public static fakeLogin() {
    this.setAccessToken(AUTH_FAKER_TOKEN);
  }
}
