import React from 'react'
import { connect } from 'react-redux'
import { RenderReducerActionEnum } from 'stores/reducers/render.interface'
import { RootState } from 'stores/stores'
import FrameRange from '../components/FrameRange'
import OutputSettings from '../components/OutputSettings'
import RenderSetting from '../components/RenderSetting'
import ScenePath from '../components/ScenePath'
import './houdini.scss'


export function HoudiniStates(States: RootState) {
     return {
          renderOptions: States.Render
     }
}
export const RenderNowMapDispatchs = {
     switch_render_flags: (key: string) => ({ type: RenderReducerActionEnum.SwitchRenderFlags, value: key }),
     set_start_frame_range: (value: number) => ({ type: RenderReducerActionEnum.SetStartFrameRange, value }),
     set_end_frame_range: (value: number) => ({ type: RenderReducerActionEnum.SetEndFrameRange, value }),
     set_incremental_step: (value: number) => ({ type: RenderReducerActionEnum.SetIncrementalStep, value }),
     set_frame_pertask: (value: number) => ({ type: RenderReducerActionEnum.SetFramePerTask, value }),
     set_render_priority: (value: number) => ({ type: RenderReducerActionEnum.SetRenderPriority, value }),

}
export type HoudiniProps = {} & ReturnType<typeof HoudiniStates> & typeof RenderNowMapDispatchs

function Houdini(Props: HoudiniProps) {
     const { renderOptions, switch_render_flags, ...render } = Props

     return <>
     <ScenePath />
     <FrameRange 
          EndFrameRange={renderOptions.body.scene.params.scene_param.endFrame}
          IncrementalStep={renderOptions.body.scene.params.scene_param.incrementStep}
          StartFrameRange={renderOptions.body.scene.params.scene_param.startFrame} 
          setEndFrameRange={function (n: number): void {
               render.set_end_frame_range(n)}}
          setStartFrameRange={
               function (n: number): void {
                    render.set_start_frame_range(n)} }
          setIncrementalStep={function (n: number): void {
               render.set_incremental_step(n)}}
     />
            
     ROP NODE PARAM
     <OutputSettings />
     Output name
     
    
     <RenderSetting
          framePerTask={renderOptions.render_configuration.frame_per_task}
          renderPriority={renderOptions.render_configuration.render_priority || 10}
          usePreviousAssetsIfMissing={false}
          setFramePerTask={function (n: number): void {
               render.set_frame_pertask(n)}} 
          setRenderPriority={function (n: number): void {
               render.set_render_priority(n)}} 
          setUsePreviousAssetsIfMissing={function (n: boolean): void {
               throw new Error('Function not implemented.')}} 
     />
</>
}


export default connect(HoudiniStates, RenderNowMapDispatchs)(Houdini)