import React from 'react'
import { PHPBOOL } from '../../../../../Interfaces/App.enums'
import './auto-gi-mode.scss'

type AutoGIModeProps = {
     setGiMode:any,
     giMode: boolean
}

export default function AutoGIMode({setGiMode,giMode}: AutoGIModeProps) {
     return (
          <div className="form-check form-switch mb-3">
               <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="auto-gi-mode"
                    checked={giMode}
                    onClick={()=> setGiMode(!giMode)}
                    />
               <label className="form-check-label" htmlFor="auto-gi-mode">GI Mode animation Prepass/Rendering</label>
          </div>
     )
}