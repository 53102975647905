/* eslint-disable import/no-anonymous-default-export */


function addCamera(camera) {
  if (!isCameraAdded(camera)) getCameras().push(camera);
}

function toggleCamera(camera) {
  if (isCameraAdded(camera)) removeCamera(camera);
  else addCamera(camera);
}

function removeCamera(camera) {
  const cameras = getCameras();
  const index = cameras.indexOf(camera);
  if (index > -1) cameras.splice(index, 1);
}

function isCameraAdded(camera) {
  return getCameras().includes(camera);
}

function clearCameras() {
  window.cameras = [];
}
function getCameras() {
  if (!window?.cameras) window.cameras = []
  if (window.cameras.includes(undefined)) window.cameras = [];
  return window.cameras;
}
export default {
  clearCameras,
  getCameras,
  addCamera,
  isCameraAdded,
  toggleCamera,
};
