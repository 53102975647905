import DefineIcon from "../CloudStorage/DefineIcon";
import { getIconType } from "./Bases";
import { ILCConvert } from "./international-language-codes";
import { LatestVersionItemTypes } from "./latest-from-api.interface";
import { I3dsApp } from "./plugins.interfaces";
import { GetElementType } from "./type-helperts";

export type PluginBrowserTypes = {
  page: "first-open" | "properties";
  detail?: {
    software: LatestVersionItemTypes["software"];
    app: I3dsApp;
    latest: number;
    language: GetElementType<I3dsApp["languages"]>;
  };
};

export function FirstOpenComp() {
  return (
    <div className="card rounded h-100">
      <div className="card-body">
        <div className="title-right-menu d-flex gap-3">
          <div>
            <i className="fab fa-app-store fa-2x"></i>
          </div>
          <h4 className="my-auto">Super Renders Farm Plugins Manager</h4>
        </div>
        <div className="mt-4">
          <h6>Walkthrough:</h6>
          <div className="card">
            <div className="card-body">
              <h6>
                <strong>How super renders plugin work</strong>
              </h6>
              <p>
                <small>
                  Learn how to use Autodeck 3ds max with Super Renders Farm for
                  Rendering service.
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export interface GetChangeLogsDto {}
export function GetChangeLogs(dto: GetChangeLogsDto) {
  return <div className="alert bg-warning-bright alert-left-border text-warning">Stay tuned. We are <strong>launching soon</strong>. We are working hard. <br /> 
  <div className="smal text-light">We are almost ready to launch. Something awesome is coming soon.</div></div>;

  // return (
  //   <>
  //     <h6>
  //       <strong>Version 2.3.0</strong>{" "}
  //     </h6>
  //     <p>
  //       <ul>
  //         <li>Add supported for Partical 6X</li>
  //       </ul>
  //     </p>
  //   </>
  // );
}

export function PluginBrowser({ page, detail }: PluginBrowserTypes) {
  if (page === "first-open" || !detail)
    return <FirstOpenComp />
  const { software, latest, app, language } = detail;

  return (
      <div className="right-menu card rounded h-100">
        <div className="card-body">
          <div className="d-flex">
            <div className="plugin-thumbnail">
              <div
                style={{
                  backgroundImage: `url(${DefineIcon(
                    80,
                    getIconType(software)
                  )})`,
                }}
              />
            </div>
            <div className="col plugin-title">
              <div><a className="text-capitalize">{app.name}</a> {app.version} - {ILCConvert(language.language)}</div>
              <span>Plugin latest version: {latest}</span>
            </div>
        
          </div>
          <div className="alert alert-warning my-3" role="alert">
            Detected
            <span className="text-dark">
              <strong> Autodesk {app.name} </strong>
            </span>
            is opening, please close your app first before install plugin.
          </div>
          <div className="mb-3">
              <strong>CHANGE LOG</strong>
          </div>
          <GetChangeLogs />
        </div>
      </div>
  );
}
