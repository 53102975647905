export enum SocketEvent {
    CONNECT = 'CONNECT_TO_SOCKET_IO',
    SUBCRIBE = 'subcribe',
    FORCE_LOGOUT_CLIENT_APP = "force_logout_client_app",
    FORCE_UPDATE_CLIENT_APP = "force_app_update",
    FORCE_UNINSTALL_CLIENT_APP = "force_uninstall_app",
    RENDER_JOB_UPDATE = "render_job_update",
    IS_CLIENT_ACTIVE_APPS = "is_client_active",
    REQUEST_SYNC_SRF_SPACE = "sync_cloud_to_farm",
    REQUEST_SYNC_JOB_OUTPUT= "render_job_update_output_progress"
}