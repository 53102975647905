// File Size function
export default function FileSize(b:any) {
     var u = 0, s=1024;
     while (b >= s || -b >= s) {
          b /= s;
          u++;
     }
     if( b === undefined ){
          return undefined
     }
     else return (u ? b.toFixed(1) + ' ' : b) + ' KMGTPEZY'[u] + 'B';
}