import axios from "axios";
import { SceneAnalyzeResponse } from '../../screens/RenderNow/SceneAnalysis.interface';
import { API } from "../../Interfaces/Api.enum";
import { AnalysisActionType } from "../reducers/analysis.interface";
import { ENotification } from "../reducers/nofification.interface";
import { Stores } from "../stores";
export async function SagaFetchListAnalysisAsync() {
    //
    var startFetchTime = Date.now()
    // Get list scene analysis from api
    axios.get<SceneAnalyzeResponse>(API.ListAnalyzeScenes,{
        headers : {
            'Authorization' : `Bearer ${localStorage.getItem("access_token")}`
        }
    })
    .then(res => {
        // Set loading to default
        Stores.dispatch({type : AnalysisActionType.SetLoading, payload : false})
        // Dispatch action to store
        Stores.dispatch({
            type : AnalysisActionType.SetItems,
            payload : res.data.data
        })
        Stores.dispatch( { type : ENotification.SetLoadTime , payload : ( Date.now() - startFetchTime ) } )

    })
}