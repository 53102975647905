import {Auth} from "modules/auth/auth";
import axios from "axios";
import {BASE_API_URL} from "config";

export const OpenRequest = axios.create({
  baseURL: BASE_API_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json"
  }
});

export class API {
  // Non-authenticated requests
  public static public = axios.create({
    baseURL: BASE_API_URL,
    timeout: 30000,
    headers: {
      "Content-Type": "application/json"
    }
  });

  // Authenticated request
  public static guard = () => {
    if (!Auth.isAuthenticated) {
      throw new Error("Not authenticated");
    }

    return axios.create({
      baseURL: BASE_API_URL,
      timeout: 30000,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth.getAccessToken()}`
      }
    });
  };
}
