export namespace SagaActions {
  export enum SRFSPACE {
    Fetch = "SAGA_MIDDLEWARE:SagaFetch"
  }

  export enum JOB {
    Fetch = "SAGA_MIDDLEWARE:JobFetch",
    Remove = "SAGA_MIDDLEWARE:JobRemove"
  }

  export enum ANALYSIS {
    Fetch = "SAGA_MIDDLEWARE:AnalysisFetch",
    RemoveById = "SAGA_MIDDLEWARE:AnalysisRemoveById"
  }

  export enum CONFIGS {
    FetchSoftwareConfigs = "SAGA_MIDDLEWARE:ConfigsFetchSoftwareConfigs"
  }
}
