import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TrayWindow from './screens/TrayWindow/TrayWindow';
import MainWindow from './screens/MainWindow';
import Offline from './screens/Offline';
import LoginForm from './screens/LoginForm';
import RenderNow from './screens/RenderNow/RenderNow';
import { Provider } from 'react-redux';
import { Stores } from './stores/stores';
import ChooseTemporaryDirectory from './screens/ChooseTemporaryDirectory';
import { SocketConfiguration } from './Socket';
import '@fontsource/josefin-sans/100.css';
import '@fontsource/josefin-sans/200.css';
import '@fontsource/josefin-sans/300.css';
import '@fontsource/josefin-sans/400.css';
import '@fontsource/josefin-sans/500.css';
import '@fontsource/josefin-sans/600.css';
import '@fontsource/josefin-sans/700.css';
import '@fontsource/josefin-sans/100-italic.css';
import '@fontsource/josefin-sans/200-italic.css';
import '@fontsource/josefin-sans/300-italic.css';
import '@fontsource/josefin-sans/400-italic.css';
import '@fontsource/josefin-sans/500-italic.css';
import '@fontsource/josefin-sans/600-italic.css';
import '@fontsource/josefin-sans/700-italic.css';
import './App.scss';
import ESImport from "./esimport";
import StartRenderAnalyzedScene from "./screens/RenderNow/StartRenderAnalyzedScene";
import { InitializeShortcuts } from "./Shortcuts";
import RenderWithoutAnalyze from "./screens/RenderWithoutAnalyze";
import ScreenModal from "./components/ScreenModal";
import { IpcEventHandler } from "./IpcHandler";
import Page404 from "./screens/Page404";
import Modal from 'react-modal';

Modal.setAppElement('#root');
const { ipcRenderer } = window.electron;
require("dotenv").config();

function App() {
  // Handle listen online and offline events
  const [isApplicationOnline, setIsApplicationOnline] = useState(true)
  const [isBuilding, setIsBuilding] = useState(true)
  function updateOnlineStatus() {
    setIsApplicationOnline(navigator.onLine ? true : false)
  }
  window.addEventListener("online", updateOnlineStatus)
  window.addEventListener('offline', updateOnlineStatus)
  window.addEventListener('offline', () => {
    ipcRenderer.send("disconnected")
  })

  // Bootstrap initial supported helpers
  useEffect(() => {
    // Connect to the web socket
    // Config websocket with redux stores
    SocketConfiguration()
    // Use the shortcuts
    // Ignore Ctrl+R etc,..
    InitializeShortcuts()
    // Ipc Response reponse handlers
    IpcEventHandler();

    ipcRenderer.on("main.ready", () => {
      setIsBuilding(false)
    })
  })

  return (
    <Provider store={Stores}>
      <Router>
        <Switch>
          <Route path="/login" exact={true} component={LoginForm} />
          <Route path="/tray" exact={true} component={TrayWindow} />
          <Route path="/render/:software" exact={true} component={RenderNow} />
          <Route path="/render.analyzed/:software" exact={true} component={StartRenderAnalyzedScene} />
          <Route path="/modal/choose-temporary-directory" exact={true} component={ChooseTemporaryDirectory} />
          <Route path="/render-without-analyze/:application" exact={true} component={RenderWithoutAnalyze} />
          <Route path="/" component={MainWindow} />
          <Route path="*">
            <Page404 />
          </Route>
        </Switch>
      </Router>
      <ScreenModal />
    </Provider>
  );
}

export default App;
