import React from "react";
import "./frame-range.scss";

type RenderSettingProps = {
  StartFrameRange: number,
  EndFrameRange: number,
  IncrementalStep: number,
  setStartFrameRange: (n: number) => void,
  setEndFrameRange: (n: number) => void,
  setIncrementalStep: (n: number) => void,
}

export default function FrameRange(Props: RenderSettingProps) {
  return (
    <div className="d-flex mb-3">
     <label className="form-label mb-2">Frame Range </label>
      <div className="row col">
        <div className="col-4">
          <span className="input-group-text" id="start-frame">
            Start Frame <span className="text-danger">*</span>
          </span>
          <input
            type="number"
            className="form-control"
            id="start-frame"
            aria-describedby="scene-path"
            value={Props.StartFrameRange}
            min={1}
            onChange={(e) => Props.setStartFrameRange(parseInt(e.target.value))}
          />
        </div>
        <div className="col-4">
          <span className="input-group-text" id="end-frame">
            End Frame <span className="text-danger">*</span>
          </span>
          <input
            type="number"
            className="form-control"
            id="end-frame"
            aria-describedby="scene-path"
            value={Props.EndFrameRange}
            min={0}
            onChange={(e) => Props.setEndFrameRange(parseInt(e.target.value))}
          />
        </div>
        <div className="col-4">
          <span className="input-group-text" id="incremental-step">
            Incremental Step <span className="text-danger">*</span>
          </span>
          <input
            type="number"
            className="form-control"
            id="incremental-step"
            aria-describedby="scene-path"
            value={Props.IncrementalStep}
            min={0}
            onChange={(e) => Props.setIncrementalStep(parseInt(e.target.value))}
          />
        </div>
      </div>
    </div>
  );
}
