import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
import { IpcSender } from '../../Interfaces/IpcRenderer.enum';
import FolderPlusIcon from "../../assets/SVG/style-dark-1/2.svg"
import PluginUpdatedIcon from "../../assets/SVG/style-dark-1/12.svg"

const { ipcRenderer } = window.electron;

interface NotificateCenterProps {
}

export default function NotificationCenter({ }: NotificateCenterProps) {
    const [isInstall3dsMaxPluginOK, setIsInstall3dsMaxPluginOK] = useState<boolean>(false)
    const [isInstallMayaPluginOK, setIsInstallMayaPluginOK] = useState<boolean>(false)
    const [isInstallPluginFailed, setIsInstallPluginFailed] = useState<string>("")

    useEffect(() => {

        ipcRenderer.on(IpcSender.OnInstallPluginOK, (event, {software, message}) => {
            if(software == "maya") {
                setIsInstallMayaPluginOK(true)
                return
            }

            if(software == "3dsmax") {
                setIsInstall3dsMaxPluginOK(true)
                return
            }

        })

        ipcRenderer.on(IpcSender.OnInstallPluginError, (event, context) => {
            setIsInstallPluginFailed(context.message)
        })


    }, [])

    return (<div>

        {/* Install Failed */}
        <Modal
            isOpen={isInstallPluginFailed !== ""}
            onRequestClose={() => setIsInstallPluginFailed("")}
            closeTimeoutMS={250}
            style={{
                overlay: {
                    zIndex: 10000,
                    position: 'fixed',
                    top: 35,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    background: '#343434',
                    transform: 'translate(-50%, -50%)',
                    border: 'none',
                    width: '700px',
                    padding: '0',
                    cursor: 'default',
                    overflow: "hidden",
                    borderRadius: '10px',
                },
            }}
            contentLabel="Upload Success"
        >
            <div className="d-flex p-5 gap-5">
                <div className="col-5">
                    <div className="logo mx-auto my-4" style={{ backgroundSize: "cover", height: "160px", width: "180px", backgroundRepeat: "no-repeat", backgroundImage: `url("${FolderPlusIcon}")` }} />
                </div>
                <div className="my-auto">
                    <div className="title-a pb-3">
                        <h4 className="fw-bold">Upgrading failed</h4>
                        <p>The upgrading plugin progress has been failed. Please close your 3D Software and try to update it again.</p>
                    </div>
                    <button className="rounded-btn" onClick={() => setIsInstallPluginFailed("")}> ok </button>
                </div>
            </div>
        </Modal>

        {/* Install 3ds Max Plugin Success */}
        <Modal
            isOpen={isInstall3dsMaxPluginOK}
            onRequestClose={() => setIsInstall3dsMaxPluginOK(false)}
            closeTimeoutMS={250}
            style={{
                overlay: {
                    zIndex: 10000,
                    position: 'fixed',
                    top: 35,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    background: '#343434',
                    transform: 'translate(-50%, -50%)',
                    border: 'none',
                    width: '700px',
                    padding: '0',
                    cursor: 'default',
                    overflow: "hidden",
                    borderRadius: '10px',
                },
            }}
            contentLabel="PluginUpdatedIcon"
        >
            <div className="d-flex p-5 ">
                <div className="col-3">
                    <div className="logo mx-auto my-4" style={{ backgroundSize: "cover", height: "160px", width: "150px", backgroundRepeat: "no-repeat", backgroundImage: `url("${PluginUpdatedIcon}")` }} />
                </div>
                <div className="my-auto ms-3">
                    <div className="title-a pb-3">
                        <h4 className="fw-bold mb-5">Plugin has been updated</h4>
                        <p>SuperRenders 3dsMax Plugin has been updated successfully. <br /> The new menu bar SuperRenders will be added to your 3dsMax. <br /> Please open (or restart) your 3dsMax to get this update.</p>
                    </div>
                    <button className="rounded-btn" onClick={() => setIsInstall3dsMaxPluginOK(false)}> ok </button>
                </div>
            </div>
        </Modal>


        {/* Install Maya Plugin Success */}
        <Modal
            isOpen={isInstallMayaPluginOK}
            onRequestClose={() => setIsInstallMayaPluginOK(false)}
            closeTimeoutMS={250}
            style={{
                overlay: {
                    zIndex: 10000,
                    position: 'fixed',
                    top: 35,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    background: '#343434',
                    transform: 'translate(-50%, -50%)',
                    border: 'none',
                    width: '700px',
                    padding: '0',
                    cursor: 'default',
                    overflow: "hidden",
                    borderRadius: '10px',
                },
            }}
            contentLabel="Upload Success"
        >
            <div className="d-flex p-5 gap-5">
                <div className="col-5">
                    <div className="logo mx-auto my-4" style={{ backgroundSize: "cover", height: "160px", width: "180px", backgroundRepeat: "no-repeat", backgroundImage: `url("${FolderPlusIcon}")` }} />
                </div>
                <div className="my-auto">
                    <div className="title-a pb-3">
                        <h4 className="fw-bold">Plugin has been updated</h4>
                        <p>SuperRenders Maya Plugin has been updated successfully. The new menu bar SuperRenders will be added to your Maya. Please open (or restart) your Maya to get this update.</p>
                    </div>
                    <button className="rounded-btn" onClick={() => setIsInstallMayaPluginOK(false)}> ok </button>
                </div>
            </div>
        </Modal>

    </div>)
}