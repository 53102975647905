import "./render-job.scss";
import axios from "axios";
import { basename, join } from "path";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import {
  IpcSender,
  IpcResponse,
  SuperTransferEvent,
  IProgress,
} from "../../../../Interfaces/IpcRenderer.enum";
import {
  IRender,
  RenderJobState,
  RenderListActionType,
  IRenderTask,
} from "../../../../stores/reducers/render_list.interface";

import { RootState, Stores } from "../../../../stores/stores";
import RenderJobItem from "./RenderJobItem";
import Loading from "components/Loading";
import EmptyRenderJob from "./EmptyRenderJob";

const { ipcRenderer } = window.electron;
const electron = window.electron;

const MapStates = (state: RootState) => ({
  RenderList: state.RenderList,
});

const MapDispatch = {
  SagaFetch: () => ({ type: RenderListActionType.SagaFetch }),
  SetLoading: (loading: boolean) => ({
    type: RenderListActionType.SetLoading,
    payload: loading,
  }),
  Load: () => ({ type: RenderListActionType.Load }),
};

type RenderJobProps = ReturnType<typeof MapStates> & typeof MapDispatch & {};

function RenderList(Props: RenderJobProps) {
  const [OutputFolderPath, setOutputFolderPath] = useState("");
  const RenderList = Props.RenderList.items;
  const [quickTip, setQuickTip] = useState<boolean>(localStorage.getItem("quick-tip2") === "true");
  const [uploadFiles, setUploadFiles] = useState<IProgress[]>([]);
  const [toggleDropdown, setToggleDropdown] = useState(true)

  useEffect(() => {
    localStorage.setItem("quick-tip2", quickTip.toString());
  }, [quickTip]);
  ipcRenderer.on(IpcSender.JobHasbeenCreated, (event, context, body) => {
    Props.SagaFetch();
  })

  useEffect(() => {
    ipcRenderer.on(
      SuperTransferEvent.TOTAL_PROGRESS_WITH_FULL_INFO,
      (event: any, data: IProgress[]) => {
        setUploadFiles(data);
      }
    );
    Props.SetLoading(true);
    // Fetch list
    Props.SagaFetch();
    ipcRenderer.invoke(IpcSender.GetOutputFolderPath).then((data) => {
      setOutputFolderPath(data);
    });
    // Request open change temporary folder dialog
    ipcRenderer.on(IpcResponse.OpenDirectoryDialog, (event: any, args: any) => {
      const NewDirectory = args.filePaths[0] || "null";
      setOutputFolderPath(NewDirectory);
    });
  }, []);
  // Loading
  if (Props.RenderList.isLoading) {
    return <Loading />
  }
  // Empty Render job
  if (RenderList.length === 0) {
    return <EmptyRenderJob />
  }

  return (
    <>
      <div className="quick-tips-renderJobs alert alert-success" role="alert">
        <div className={`content ${!quickTip ? "show" : "hide"}`}>
          <ul className="m-0">
            <li>
              Render Job tab in this application just provide some basic feature
              (quick update render progress and download render output).
              Full operations of the render job features, feel free to visit{" "}
              <strong
                onClick={() => {electron.shell.openExternal("https://superrendersfarm.com/account/render-dashboard")}}
              >
                our website version
              </strong>
              .
            </li>
          </ul>
        </div>
        <span className="toggle-button" onClick={()=>setQuickTip(!quickTip)}>
          {!quickTip ? (
            <i className="fad fa-times"></i>
          ) : (
            <i className="fad fa-arrow-down"></i>
          )}
        </span>
      </div>
      <div
        className={`render-jobs ${!quickTip ? "quick-tips-show" : "quick-tips-hide"
          }`}
      >
        {/* Render Job */}
        <div className="render-body">
          {RenderList.map((item, index) => {
            return (<RenderJobItem item={item} key={item.id} SagaFetch={Props.SagaFetch} OutputFolderPath={OutputFolderPath} />)
          })}
        </div>
      </div>
    </>
  );
}

export default connect(MapStates, MapDispatch)(React.memo(RenderList));
