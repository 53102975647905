import axios from "axios"

export enum API {
    ListAnalyzeScenes = "https://olapi.superrendersfarm.com/api/analyze-job/list/scene-analyze",
    GetCurrentUserProfile = "https://olapi.superrendersfarm.com/api/user/me",
    SingIn = "https://olapi.superrendersfarm.com/api/user/login",
    SingUp = "https://olapi.superrendersfarm.com/api/user/register",
    UserProfile = "http://localhost:6789/user_configs",
    UpdateProfile = "https://olapi.superrendersfarm.com/api/user/me/update-profile",
    SelfDisable = "https://olapi.superrendersfarm.com/api/user/me/self-disable",

    // Scene
    AnalyzeScene = "https://olapi.superrendersfarm.com/api/analyze-job/get/scene-analyze-status/",
    DeleteAnalyzeScene = "https://olapi.superrendersfarm.com/api/analyze-job/delete/scene-analyze/",
    GetTasks = "https://olapi.superrendersfarm.com/test/jt",
    ListRecentRenderJobs = "https://olapi.superrendersfarm.com/api/render-job/recent-render-job",
    ListHistoryRenderJobs = "https://olapi.superrendersfarm.com/api/render-job/render-job-history",

    // Actions
    DeleteRenderJob = 'https://olapi.superrendersfarm.com/api/render-job/action/delete',
    ResumeRenderJob = 'https://olapi.superrendersfarm.com/api/render-job/action/resume',
    PauseRenderJob = 'https://olapi.superrendersfarm.com/api/render-job/action/pause'
}

