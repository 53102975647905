import React from 'react'
import './loading.scss'

interface LoadingProps {
}

export default function Loading({}: LoadingProps) {
     return (
          <div className="d-flex h-100">
               <div className="text-center m-auto">
                    <div className="spinner-border spinner-border-lg fast" role="status">
                         <span className="sr-only">Loading...</span>
                    </div>
               </div>
          </div>
     )
}