import { Reducer } from "react";
import { CHANGE_WIDTH, RenderReducerActionEnum, RenderReducerActionInterface } from "./render.interface";
import { BmpType, ExrCompression, ExrMainFormat, ExrType, MaxLighting, PngType, SceneInterface, TifCompression, TifType, _3dSoftwareInterface } from '../../Interfaces/Renderer';
import {  RenderConfigurationInterface, RenderOptionsInterface, SceneResponseInterface } from "../../screens/RenderNow/RenderNow.interface";
import { PHPBOOL, RenderEngineVersion } from "../../Interfaces/App.enums";
import { Supported3dApps } from "screens/RenderNow/SceneAnalysis.interface";

/**
 * Start render with default datas
 * this is a sample scene json
 */
const RenderReducerIntialStates : RenderOptionsInterface =  {
    body: {
        scene: {
            MaxPath: "",
            error: [],
            warning: [],
            info: [],
            cameras: [],
            params: {
                scene_param: {
                    startFrame: 0,
                    endFrame: 0,
                    height: 0,
                    width: 0,
                    maxPath: "",
                    incrementStep: 0,
                    sceneNameClean: "",
                    render_engine: RenderEngineVersion.Undefined,
                    output_full_path: "",
                    outputNameFile: "",
                    outputNameType: ".png",
                    take: []
                },
                camera_param: {
                    activeCamera: "",
                    cameras_list: [],
                    num_cameras: 0
                },
                output_param: {
                    // C4d Output 
                    output_format_extension: "tif",
                    output_main_enabled: true,
                    output_main_file_name: "test.tif",
                    output_multipass_enabled: false,
                    output_multipass_file_name: "",
                    // 3dsmax Output
                    output_file_name: "",
                    output_extension: "",
                    multipassoutput_folder: "",
                    outputNameFile: "",
                    // Png
                    png_type: "", // Colors
                    png_alpha: true, // ratio
                    png_interlaced: true, // ratio
                    // Bmp
                    bmp_type: BmpType.Paletted,
                    // Jpg / Jpeg
                    jpg_quality: 0,
                    jpg_smoothing: 0,
                    // Cjpg
                    cjpg_quality: "",
                    cjpg_smoothing: "",
                    // Cjp
                    cjp_quality: "",
                    cjp_smoothing: "",
                    // Tga
                    tga_alphasplit: true,
                    tga_colordepth: 0,
                    tga_compressed : true,
                    tga_premultalpha: true,
                    // Exr
                    exr_compression: ExrCompression.None,
                    exr_main_layer_format: ExrMainFormat.Integer,
                    exr_main_layer_type :ExrType.ARGB,
                    exr_storage : true,
                    exr_image_region: true,
                    exr_render_elements: true,
                    // Tif
                    tif_dpi: 72,// Dots per inch
                    tif_type: TifType._8BitGrayScale,
                    tif_alpha:true,
                    tif_compression: TifCompression.None,

                    // Gamma
                    input_gamma: 2.2,
                    output_gamma: 1.0,
                    gamma_correction: false,
                },
                lighting_param: {
                    lighting_list: [],
                    num_lightings: 0
                },
                element_param: {},
                // Maya param
                incrementStep: 1,
                startFrame: 0,
                endFrame: 0,
                height: 0,
                width: 0,
                render_layers: [
                    {
                        is_default_layer: true,
                        layer_name: ""
                    }
                ],
                render_cameras: [
                    {
                        camera_name:""
                    }
                ],
            },
            
        },
        files: [],
        maxPath: "",
        version: 2020,
        FarmDirectory : "",
        autoStartRender : false
    },
    _3dEngine:  Supported3dApps.Max,
    render_configuration: {
        frame_per_task: 1,
        rf_compute_advanced_lighting: PHPBOOL.False,
        rf_convert_area_lights_to_points_sources: PHPBOOL.False,
        rf_force_two_sided: PHPBOOL.False,
        rf_perform_color_check: PHPBOOL.False,
        rf_perform_displacement_mapping: PHPBOOL.False,
        rf_perform_render_effects: PHPBOOL.False,
        rf_render_flags: PHPBOOL.False,
        rf_render_hidden_objects: PHPBOOL.False,
        rf_toggles_output_dithering_paletted: PHPBOOL.False,
        rf_toggles_output_dithering_true_color: PHPBOOL.False,
        rf_use_advanced_lighting: PHPBOOL.False,
        rf_use_atmospherics_effects: PHPBOOL.False,
        rf_use_super_black: PHPBOOL.False,
    },
    FarmDirectory: "",
    scene_params: {}
}

export function RenderReducer( state = RenderReducerIntialStates , action  : RenderReducerActionInterface ) : RenderOptionsInterface  {
    var RefreshState :RenderOptionsInterface = state
    // Use handlers with params
    switch (action.type) {

        case RenderReducerActionEnum.SetSceneParams:
            RefreshState.scene_params = action.value
            return RefreshState

        case RenderReducerActionEnum.SetFarmDirectory :
            RefreshState.FarmDirectory = action.value
            return RefreshState

        case RenderReducerActionEnum.ChangeWidth : //"CHANGE_WIDTH":
            RefreshState.body.scene.params.scene_param.width = action.value
            return {...state,...RefreshState}

        case RenderReducerActionEnum.ChangeHeight : //"CHANGE_HEIGHT":
            RefreshState.body.scene.params.scene_param.height = action.value
            return {...state,...RefreshState}

        case RenderReducerActionEnum.UpdateScene : //"UPDATE_SCENE" :
            // Global override initial state
            const ReturnValue :RenderOptionsInterface = {...state,...action.value}
            // // Override output params
            ReturnValue.body.scene.params.output_param = {
                ...state.body.scene.params.output_param,
                ...action.value.body.scene.params.output_param
            }
            // // Override scene params
            ReturnValue.body.scene.params.scene_param = {
                ...state.body.scene.params.scene_param,
                ...action.value.body.scene.params.scene_param
            }
            // Override vray options
            ReturnValue.body.scene.params.vray_option = {
                ...state.body.scene.params.vray_option,
                ...action.value.body.scene.params.vray_option
            }
            //
            console.log(action.value)
            return {...state,...ReturnValue}

        case RenderReducerActionEnum.SetStartFrameRange : //"CHANGE_START_FRAME":
            RefreshState.body.scene.params.scene_param.startFrame = action.value
            return {...state,...RefreshState}

        case RenderReducerActionEnum.SetEndFrameRange : //"CHANGE_END_FRAME":
            RefreshState.body.scene.params.scene_param.endFrame = action.value
            return {...state,...RefreshState}

        case RenderReducerActionEnum.SetIncrementalStep : //"CHANGE_IncrementalStep":
            RefreshState.body.scene.params.scene_param.incrementStep = action.value
            return {...state,...RefreshState}
            
        case RenderReducerActionEnum.ChangeStartFrame : //"CHANGE_START_FRAME":
            RefreshState.body.scene.params.scene_param.startFrame = action.value
            return RefreshState

        case RenderReducerActionEnum.ChangeEndFrame: //"CHANGE_END_FRAME":
            RefreshState.body.scene.params.scene_param.endFrame = action.value
            return RefreshState

        case RenderReducerActionEnum.ChangeFrameStep : //"CHANGE_FRAME_STEP":
            RefreshState.body.scene.params.scene_param.incrementStep = action.value
            return RefreshState

        case RenderReducerActionEnum.SetRenderCamera : //"SET_RENDER_CAMERA":
            RefreshState.body.scene.params.camera_param.activeCamera = action.value
            return RefreshState
            
        case RenderReducerActionEnum.SetRenderCameraMaya : //"SET_RENDER_CAMERA_MAYA":
            const targetCamera = action.value as string
            RefreshState.body.scene.cameras = RefreshState.body.scene.cameras.map( cam => {
                if( cam.name == targetCamera) cam.selected = !cam.selected
                return cam
            })
            return RefreshState


        case RenderReducerActionEnum.SetRenderTile : //"SET_RENDER_TILE":
            RefreshState.body.scene.params.scene_param.endFrame = action.value
            return RefreshState

        case RenderReducerActionEnum.ChangeUseGammaCorrection:
            RefreshState.body.scene.params.output_param.gamma_correction = action.value
            return {...state,...RefreshState}

        case RenderReducerActionEnum.ChangeInputGamma:
            RefreshState.body.scene.params.output_param.input_gamma = action.value
            return {...state,...RefreshState}

        case RenderReducerActionEnum.ChangeOutputGamma:
            RefreshState.body.scene.params.output_param.output_gamma = action.value
            return {...state,...RefreshState}

        case RenderReducerActionEnum.SetFramePerTask : //"SET_FRAME_PER_TASK":
            RefreshState.render_configuration.frame_per_task = action.value
            return {...state,...RefreshState}

        case RenderReducerActionEnum.SetRenderPriority : //"SET_RENDER_PRIORITY" :
            RefreshState.render_configuration.render_priority = action.value as number
            return {...state,...RefreshState}

        case RenderReducerActionEnum.SetUseMissingAssets : //"SET_RENDER_PRIORITY" :
            RefreshState.render_configuration.use_missing_assets = action.value as number
            return {...state,...RefreshState}

        case RenderReducerActionEnum.SwitchLightState : //"SWITCH_LIGHT_STATE" :
            // Find light index
            var LightIndex = <number>action.value
            // Get Current Light State
            var CurrentLight = state.body.scene.params.lighting_param.lighting_list[LightIndex]
            // Update light index state
            RefreshState.body.scene.params.lighting_param.lighting_list[LightIndex] = {
                light_name : CurrentLight.light_name,
                light_state : !CurrentLight.light_state
            }
            console.log(`Changed light ${CurrentLight.light_name} to ${!CurrentLight.light_state}`)
            // Return new light list states
            return {...state,...RefreshState}

        case RenderReducerActionEnum.SwitchRenderFlags : //"switch_render_flags":
            var RefreshState = state
            var Field = <keyof RenderConfigurationInterface> action.value
            var PreState = state.render_configuration[Field] as PHPBOOL
            var ReverseState = (Pre:PHPBOOL) : PHPBOOL => Pre == PHPBOOL.True ? PHPBOOL.False : PHPBOOL.True
            RefreshState.render_configuration = { ...RefreshState.render_configuration, ... {[Field] : ReverseState(PreState)} }
            return {...state,...RefreshState}

        case RenderReducerActionEnum.SetOutputFileType : //"SET_OUTPUT_FILE_TYPE":
            var RefreshState :RenderOptionsInterface = state
            RefreshState.body.scene.params.scene_param.outputNameType = action.value
            return RefreshState

        case RenderReducerActionEnum.UpdateOutputProperties : //"UPDATE_OUTPUT_PROPERTIES":
            var RefreshState :RenderOptionsInterface = state
            RefreshState.body.scene.params.output_param = {
                ...RefreshState.body.scene.params.output_param,
                [action.value.name] : action.value.value
            }
            return {...state,...RefreshState}

        case RenderReducerActionEnum.UpdateNrrOutputProperties : //"UPDATE_NRR_OUTPUT_PROPERTIES":
            var RefreshState :RenderOptionsInterface = state
            RefreshState.body.scene.params.output_param = {
                ...RefreshState.body.scene.params.output_param,
                [action.value.name] : action.value.value
            }
            return RefreshState

        case RenderReducerActionEnum.SetVrayOptions : //"ANALYZE::SET_VRAY_OPTIONS":
            var RefreshState :RenderOptionsInterface = state
            if(RefreshState.body.scene.params.vray_option)
            RefreshState.body.scene.params.vray_option = {
                ...RefreshState.body.scene.params.vray_option,
                [action.value.name] : action.value.value
            }
            return {...state,...RefreshState}

        case RenderReducerActionEnum.IsEnableBuildInFrameBuffer :
            var RefreshState :RenderOptionsInterface = state
            if(RefreshState.body.scene.params.vray_option)
            RefreshState.body.scene.params.vray_option.output_on = action.value
            return {...state,...RefreshState}

        case RenderReducerActionEnum.SetIsRenderRawImageFile : //"ANALYZE::SET_IS_RENDER_RAW_IMAGE_FILE" :
            var RefreshState :RenderOptionsInterface = state
            if(RefreshState.body.scene.params.vray_option)
            RefreshState.body.scene.params.vray_option.output_saveRawFile = action.value
            return {...state,...RefreshState}

        case RenderReducerActionEnum.SetVrayRawFileName :
            var RefreshState :RenderOptionsInterface = state
            if(RefreshState.body.scene.params.vray_option)
            RefreshState.body.scene.params.vray_option.output_rawFileName = action.value
            return {...state,...RefreshState}

        case RenderReducerActionEnum.SeparateRenderChannel :
            var RefreshState :RenderOptionsInterface = state
            if(RefreshState.body.scene.params.vray_option)
            RefreshState.body.scene.params.vray_option.output_splitgbuffer = action.value
            return {...state,...RefreshState}

        case RenderReducerActionEnum.SetSplitChannelFileName :
            var RefreshState :RenderOptionsInterface = state
            if(RefreshState.body.scene.params.vray_option)
            RefreshState.body.scene.params.vray_option.output_splitfilename = action.value
            return {...state,...RefreshState}
            
        case RenderReducerActionEnum.SetImageFilter :
            var RefreshState :RenderOptionsInterface = state
            if(RefreshState.body.scene.params.vray_option)
            RefreshState.body.scene.params.vray_option.filter_on = action.value
            return {...state,...RefreshState}
        // ARNOLD 
        case RenderReducerActionEnum.SetArnoldOptions : //"ANALYZE::SET_ARNOLD_OPTIONS":
            var RefreshState :RenderOptionsInterface = state
            if(RefreshState.body.scene.params.arnold_option)
            RefreshState.body.scene.params.arnold_option = {
                ...RefreshState.body.scene.params.arnold_option,
                [action.value.name] : action.value.value
            }
            return {...state,...RefreshState}
        case RenderReducerActionEnum.SetAASamples :
            var RefreshState :RenderOptionsInterface = state
            if(RefreshState.body.scene.params.arnold_option)
            RefreshState.body.scene.params.arnold_option.AA_samples = action.value
            return {...state,...RefreshState}
        case RenderReducerActionEnum.SetGIDiffuseSamples :
            var RefreshState :RenderOptionsInterface = state
            if(RefreshState.body.scene.params.arnold_option)
            RefreshState.body.scene.params.arnold_option.GI_diffuse_samples = action.value
            return {...state,...RefreshState}    
        case RenderReducerActionEnum.SetGIDiffuseDepth :
            var RefreshState :RenderOptionsInterface = state
            if(RefreshState.body.scene.params.arnold_option)
            RefreshState.body.scene.params.arnold_option.GI_diffuse_depth = action.value
            return {...state,...RefreshState}
        case RenderReducerActionEnum.SetGIDiffuseDepth :
            var RefreshState :RenderOptionsInterface = state
            if(RefreshState.body.scene.params.arnold_option)
            RefreshState.body.scene.params.arnold_option.GI_diffuse_depth = action.value
            return {...state,...RefreshState}
        case RenderReducerActionEnum.SetGISpecularSamples :
            var RefreshState :RenderOptionsInterface = state
            if(RefreshState.body.scene.params.arnold_option)
            RefreshState.body.scene.params.arnold_option.GI_specular_samples = action.value
            return {...state,...RefreshState}
        case RenderReducerActionEnum.SetGISpecularDepth :
            var RefreshState :RenderOptionsInterface = state
            if(RefreshState.body.scene.params.arnold_option)
            RefreshState.body.scene.params.arnold_option.GI_specular_depth = action.value
            return {...state,...RefreshState}
        case RenderReducerActionEnum.SetGITransmissionSamples :
            var RefreshState :RenderOptionsInterface = state
            if(RefreshState.body.scene.params.arnold_option)
            RefreshState.body.scene.params.arnold_option.GI_transmission_samples = action.value
            return {...state,...RefreshState}
        case RenderReducerActionEnum.SetGITransmissionDepth :
            var RefreshState :RenderOptionsInterface = state
            if(RefreshState.body.scene.params.arnold_option)
            RefreshState.body.scene.params.arnold_option.GI_transmission_depth = action.value
            return {...state,...RefreshState}
        case RenderReducerActionEnum.SetGISSSSamples :
            var RefreshState :RenderOptionsInterface = state
            if(RefreshState.body.scene.params.arnold_option)
            RefreshState.body.scene.params.arnold_option.GI_sss_samples = action.value
            return {...state,...RefreshState}
        case RenderReducerActionEnum.SetGIVolumeSamples :
            var RefreshState :RenderOptionsInterface = state
            if(RefreshState.body.scene.params.arnold_option)
            RefreshState.body.scene.params.arnold_option.GI_volume_samples = action.value
            return {...state,...RefreshState}
        case RenderReducerActionEnum.SetGIVolumeDepth :
            var RefreshState :RenderOptionsInterface = state
            if(RefreshState.body.scene.params.arnold_option)
            RefreshState.body.scene.params.arnold_option.GI_volume_depth = action.value
            return {...state,...RefreshState}
        // CORONA 
        case RenderReducerActionEnum.SetCoronaOptions : //"ANALYZE::SET_CORONA_OPTIONS":
            var RefreshState :RenderOptionsInterface = state
            if(RefreshState.body.scene.params.corona_option)
            RefreshState.body.scene.params.corona_option = {
                ...RefreshState.body.scene.params.corona_option,
                [action.value.name] : action.value.value
            }
            return {...state,...RefreshState}
        // SetOutputMainFileName Cinema4d 
        case RenderReducerActionEnum.SetOutputMainFileName :
                var RefreshState :RenderOptionsInterface = state
            RefreshState.body.scene.params.output_param.output_main_file_name = action.value
            return {...state,...RefreshState}

        case RenderReducerActionEnum.SetOutputMainFileName :
                var RefreshState :RenderOptionsInterface = state
            RefreshState.body.scene.params.output_param.output_multipass_file_name = action.value
            return {...state,...RefreshState}
        default:
          return state
        }
}