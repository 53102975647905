import { API } from 'apis/base.api'
import { EncryptMd5String } from 'modules/crypto/md5files';
import React, { useEffect, useState } from 'react'
import './scene-analyze-status.scss'

interface SceneAnalyzeStatusProps {
     path : string ;
}

export default function SceneAnalyzeStatus({ path }: SceneAnalyzeStatusProps) {
     const [onSceneStatus, setOnSceneStatus] = useState({})
     const [isLoading, setIsLoading] = useState(true)
     const [isAnalyzedSuccess, setIsAnalyzedSuccess] = useState(false)
     const [isNotAnalyzed, setIsNotAnalyzed] = useState(false)
 
     useEffect(() => {
         /** Get Analyze scene status */
         API.guard().get(`analyze-job/get/scene-analyze-status/${EncryptMd5String('/'+path)}`,
         /** Authorization Bearer */
         { headers : { Authorization : `Bearer ${localStorage.getItem("access_token")}` }})
         /** Response */
         .then( (res:any) => {
 
 
             /**
              * Scene Analyzed and return ok state :3
              */
             if(res.data.scene_status == "AnalyzeCompleted")
             {
                 setIsNotAnalyzed(false)
                 setIsLoading(false)
                 setIsAnalyzedSuccess(true)
                 return 0
             }
 
             /** If Current are analyzing */
             if(  ['WaitingForAnalyzing' , 'InAnalyzing' ].indexOf(res.data.scene_status) == -1 )
                 setIsLoading(false)
 
 
         }).catch((err) => {
 
             /** TODO: Replace message to status code */
             /** Is current scene is not analyzed */
             if( !err.response || err.response.data.message == "Scene not found" ){ 
                 setIsLoading(false)
                 setIsNotAnalyzed(true)
                 
                 /** Call a callback to set state of scene to analyzed complete */
                    setOnSceneStatus( { scene_status : "SceneNotFound"} )
 
                 return 0
 
             }
             
             /**
              * Analyze failed
              */
              if(err.response.data.message == "AnalyzeFailed")
              {
                  setIsNotAnalyzed(false)
                  setIsLoading(false)
                  setIsAnalyzedSuccess(false)
                                  
                 /** Call a callback to set state of scene to analyzed complete */
                    setOnSceneStatus( {scene_status : "AnalyzeFailed"} )
                 return 0
              }
              
         })
 
     }, [])
 
     /** Is loaded */
     return <div className="hide-on-hover" >
         {
             isLoading ? // Check is loading state
               <div className="d-flex h-100">
                    <div className="spinner-border spinner-border-sm fast m-auto text-secondary" role="status">
                         <span className="visually-hidden">Loading...</span>
                    </div>
               </div> : 
 
             isNotAnalyzed ?  // Current scene was not analyed
                 <i className="fas fa-question text-secondary p-2"></i> :
 
             isAnalyzedSuccess  ?  //check is current scene is AnalyzeComplete 
                 <i className="fad fa-check text-success p-2"></i> :
 
             // If current scene was fail
                 <i className="fad fa-exclamation bg-danger p-2"></i>
         }
     </div>
 }
 