export interface RendererEngineVersionTypes {
    id: number;
    name: string;
    description: string;
    renderer_version_value: string;
    type: number;
    status: number;
    seq: number;
    gpu: number;
    cpu: number;
    apply_for: string;
    created_at: Date;
    updated_at: Date;
}

export interface SupportedEngineTypes {
    id: number;
    software: string;
    name: string;
    description: string;
    seq: number;
    status: number;
    created_at: Date;
    updated_at: Date;
    versions: RendererEngineVersionTypes[];
}

export interface SupportedSoftwareTypes {
    id: number;
    type: string;
    description?: any;
    display_name: string;
    software_version_value: string;
    status: number;
    seq: number;
    created_at: Date;
    updated_at: Date;
    supported_engines: SupportedEngineTypes[];
}

export interface SupportedSoftwareInitialState {
    list : SupportedSoftwareTypes[]
}

export enum SSActionType {
    Fetch = 'SSR::fetch',
    SagaFetch = 'SSR::saga-fetch',
    SetList = 'SSR::set-list',
}

export type SSAction<T> = {
    type : SSActionType,
    payload : T
}