import React, { useState } from 'react'
import { API } from 'apis/base.api'
import Loading from 'components/Loading'
import NiceFileIcon from "../../../../../../../assets/SVG/style-dark-1/17.svg"
import { IRender, ResponseAnalysisResult } from 'stores/reducers/render_list.interface'
import { AxiosResponse } from 'axios'

interface AnalysisResultProps {
     item : IRender,
     isToggle : boolean
}


export default function AnalysisResult({item,isToggle}: AnalysisResultProps) {
     const [analysisResult, setAnalysisResult] = useState<string>("");
     const fetchLog = async () => {
          try {
          const response = await API.guard().get<{
               data : {
                    analyze_result : string
               }
               }>(`/render-job/analyzed-data/${item.id}`);
          setAnalysisResult(response.data.data.analyze_result);
          }catch(err:any) {
               setAnalysisResult(err);
          }
     }
     React.useEffect(() => {
          if (isToggle) fetchLog();
          }, [isToggle])

     if (analysisResult === "") {
          return (
               <div className="content" style={{height:200}}>
                    <Loading/>
               </div>
          )
     }
     if ( analysisResult === null || analysisResult === "0" ) {
          return (
               <div className="d-flex">
                         <div className="m-auto">
                              <div className="d-flex my-2 gap-3">
                                   <div className="logo mt-4" style={{ backgroundSize: "cover", height: "160px", width: "148px", backgroundRepeat: "no-repeat", backgroundImage: `url("${NiceFileIcon}")` }} />
                                   <h4 className="my-auto fw-bolder">Your scene looks good !</h4>
                              </div>
                         </div>
                    </div>
          )
     }
     function RenderSceneLog( {sceneResult} : { sceneResult : string }){
          let SceneLogs = JSON.parse(sceneResult);
          /**
          * List log infomation
          *
          */
          function Parser(originJSON: any) : ResponseAnalysisResult[]{
               // get keys
               const keys = Object.keys(originJSON)

               // map to obserable
               const response = keys.map( key => ({
                    type: key,
                    data: originJSON[key]
               }))
               
               const data = response.map(log => {
                    
                    log.data = log.data.map( (info: any) => {
                         const keys = Object.keys(info);
                         if(keys.length > 0 ) {
                              const item = info[keys[0]]
                              return item
                         }
                    });
               
                    return log
               })
               return data
              
          }
 
          function SceneLogParser(){
               
               // Check list is empty
               const data = Parser(SceneLogs)
               
               return <div>
                    {data.map( (item,index) => {
                         if(item.data.length > 0)
                              return(
                              <div key={index} className="mb-3 pt-2 px-2">
                                   {item.type === "error" ?
                                   <h6 className="font-weight-bold text-danger text-capitalize">
                                        <i className="fad fa-bug"></i> {item.type}:
                                   </h6>
                                   :item.type === "warning" ?
                                   <h6 className="font-weight-bold text-warning text-capitalize">
                                        <i className="fad fa-exclamation-triangle"></i> {item.type}:
                                   </h6>
                                   :item.type === "info" ?
                                   <h6 className="font-weight-bold text-info text-capitalize">
                                        <i className="fad fa-info"></i> {item.type}:
                                   </h6>
                                   :<></>
                                   }
                                   {item.data.map( (data,index) => (
                                   <div key={index}>
                                        <div>
                                             <i className="fad fa-angle-double-right me-2"></i>
                                             {data.message.replace('___srf_value___', data.value)}
                                             <button className="btn rounded-btn" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse-"+data.items.length} aria-expanded="false" aria-controls={"collapse-"+data.items.length} hidden={data.items.length === 0}>
                                                  <i className="fad fa-caret-down me-2"></i>View Details 
                                             </button>
                                        </div>
                                        <div className="collapse" id={"collapse-"+data.items.length}>
                                             <div className="card card-body bg-dark mt-2 p-3">
                                                  {data.items.map((item,index) => (<div key={index}>{item}</div>))}
                                             </div>
                                        </div>
                                   </div>    
                                   ))}
                              </div>
                    )})}
               </div>    
          }
          
          if (!SceneLogs?.error && !SceneLogs?.warning && !SceneLogs?.info) {
               return (
                    <div className="d-flex">
                         <div className="m-auto">
                              <div className="d-flex my-2 gap-3">
                                   <div className="logo mt-4" style={{ backgroundSize: "cover", height: "160px", width: "148px", backgroundRepeat: "no-repeat", backgroundImage: `url("${NiceFileIcon}")` }} />
                                   <h4 className="my-auto fw-bolder">Your scene looks good !</h4>
                              </div>
                         </div>
                    </div>
               )
          }
          if (SceneLogs.error.length === 0 && SceneLogs.warning.length === 0 && SceneLogs.info.length === 0) {
               return (
                    <div className="d-flex">
                         <div className="m-auto">
                              <div className="d-flex my-2 gap-3">
                                   <div className="logo mt-4" style={{ backgroundSize: "cover", height: "160px", width: "148px", backgroundRepeat: "no-repeat", backgroundImage: `url("${NiceFileIcon}")` }} />
                                   <h4 className="my-auto fw-bolder">Your scene looks good !</h4>
                              </div>
                         </div>
                    </div>
               )
          }
          return (
              <> <SceneLogParser /></>
          )
          
     }
     return <RenderSceneLog sceneResult={analysisResult} />
}