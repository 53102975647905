import { IpcSender } from 'Interfaces/IpcRenderer.enum';
import { IWebUserConfig } from 'Interfaces/User';
import { basename, join } from 'path';
import { useState } from 'react'
import { IRender, IRenderTask, RenderJobState } from 'stores/reducers/render_list.interface';
import { DeleteJob, PauseJob, ResumeJob } from "../../../../../apis/Job";
import Swal from 'sweetalert2';
import RelativeTime from '../../CloudStorage/RelativeTime';
import RenderInfo from './RenderInfo';
import RenderStatus from './RenderStatus';
import RenderCollapse from './RenderCollapse';
import './render-job-item.scss'
import Tippy from '@tippyjs/react';

const { ipcRenderer } = window.electron;

interface RenderJobItemProps {
     item: IRender
     SagaFetch: () => void
     OutputFolderPath:string
}

export default function RenderJobItem({item,SagaFetch,OutputFolderPath}: RenderJobItemProps) {
     const [Dropdown, setDropdown] = useState(false)
     
     function OpenOutput(item:IRender) {
               ipcRenderer.send("open", join(OutputFolderPath, "SuperRendersOutput", item.renderoutput_folder_name, "/"))
          }
     async function DownloadOutput(item:IRender) {
          if (!item.renderoutput_folder_name) return;
          const Profile = JSON.parse(
          localStorage.getItem("user_profiles") as string
          ) as IWebUserConfig;
          const SrfSpaces = Profile.user.srfspaces;
          const OutputPath = join(
          SrfSpaces,
          "SuperRendersOutput",
          item.renderoutput_folder_name,
          "/"
          );
          ipcRenderer.send(IpcSender.SaveOutput, { Key: OutputPath, JobId: item.id, AutoSync: false });
          SagaFetch();
     }
     async function PauseRenderJob(id:number) {
          await PauseJob(id)
          SagaFetch()
     }
     async function ResumeRenderJob(id:number) {
          await ResumeJob(id)
          SagaFetch()
     }

     function DeleteRenderJob(id:number) {
          Swal.fire({
               title: 'Are you sure?',
               text: `You want to delete render job id: ${id}`,
               icon: 'warning',
               showCancelButton: true,
               reverseButtons: true,
               confirmButtonColor: '#218c61',
               cancelButtonColor: '#d33',
               confirmButtonText: 'Yes, delete it!',
          }).then(async(result) => {
               if (result.isConfirmed) {
                    await DeleteJob(id)
                    Swal.fire(
                         {
                         title : 'Success!',
                         text: `Your render job ${id} has been deleted.`,
                         icon: 'success',
                         confirmButtonColor: '#218c61',
                         preConfirm() {
                              SagaFetch()
                         },
                         }
                    )
               }
          })
     }
   
     return (
          <div className="render-item">
               <div className="d-flex">
                    <Tippy content="View detail">
                         <button type="button" className="btn toggle-button" onClick={() => Dropdown ? setDropdown(false) : setDropdown(true)} >
                              <i   className="mt-1 fad fa-chevron-right" 
                                   style={
                                        Dropdown?{transform: "rotate(90deg)",transition:"all .25s",color:"#20c997"} : 
                                                  {transform: "rotate(0deg)",transition:"all .25s"}
                                        }
                                   >
                              </i>
                         </button>
                    </Tippy>
                   
                    <div className="col">
                         <RenderInfo
                              id={item.id}
                              name={basename(item.scene_name)}
                              frame={`${item.FrameRangeStart} - ${item.FrameRangeEnd}`}
                              camera={item.camera_v2}
                              priority={item.job_priority}
                              renderBy={item.gcpu_type}
                              submitted={RelativeTime(item.created_at)}
                         />
                    </div>
                    <div className="col-3 me-3">
                         <RenderStatus
                              tasks={item.tasks || ({} as IRenderTask)}
                              cost={item.render_credits_v2}
                              status={item.job_status}
                              percentage={item.render_progress}
                              numNodeRun={item.num_nodes_run}
                              estimatedRemainingTime={item.estimated_remaining_time}
                              renderoutput_folder_name={item.renderoutput_folder_name}
                              jobId={item.id}
                         />
                    </div>
                    <div className="btn-group more-btn">
                         {
                              item.is_output_folder_available ?
                                   <button type="button" className="btn bg-success-bright text-uppercase front-btn" onClick={()=>OpenOutput(item)} disabled={!item.is_output_folder_available}>
                                        <i className="fad fa-folder-download me-2"></i>Located Output
                                   </button>
                              :
                                   item.job_status !== RenderJobState.JobPaused ? 
                                        <button type="button" className="btn bg-success-bright text-uppercase front-btn" onClick={()=>DownloadOutput(item)} disabled={item.job_status !== RenderJobState.JobCompleted}>
                                             <i className="fad fa-download me-2"></i>Download Output
                                        </button>
                                   :
                                        <button type="button" className="btn bg-success-bright text-uppercase front-btn" onClick={() => ResumeRenderJob(item.id)} >
                                             <i className="fad fa-play me-2"></i>Resume render job
                                        </button>
                         }
                              
                         <button type="button" className="btn bg-success-bright dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                              <span className="visually-hidden">Toggle Dropdown</span>
                         </button>

                         <div className="dropdown-menu dropdown-menu-dark">
                              {
                                   item.is_output_folder_available ? 
                                   <button className="dropdown-item text-uppercase" onClick={() => DownloadOutput(item)}>
                                        <i className="fad fa-sync me-2"></i> Resync output
                                   </button>
                                   : <></>
                              }  
                              {
                                   item.job_status === RenderJobState.InRendering ?
                                        <button className="dropdown-item text-uppercase" onClick={() => PauseRenderJob(item.id)}>
                                             <i className="fad fa-pause me-2"></i> Pause render job
                                        </button>
                                   :<></>
                              }
                              <button className="dropdown-item text-uppercase" onClick={() =>DeleteRenderJob(item.id)} >
                                   <i className="fad fa-trash me-2"></i> Delete Render job
                              </button>
                         </div>
                    </div>
               </div>
               <RenderCollapse toggleState={Dropdown} item={item} />
          </div>
               
     )
}