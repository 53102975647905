import { Supported3dApps } from 'screens/RenderNow/SceneAnalysis.interface';
import { OutputParamsProperty, OutputParamsBoolean } from '../screens/RenderNow/RenderNow.interface';
import { ImageSamplerType, ImageSamplerName, IrradMapMode, IrradMapModeName, VrayGlobalIlluminationPrimaryName, VrayGIPrimaryType, VrayGlobalIlluminationSecondaryName, VrayGISecondaryType, LightCacheMode, LightCacheModeName, PHPBOOL, RenderEngineVersion, CoronaPrimarySolverType, CoronaPrimarySolverName, CoronaSecondarySolverType, CoronaSecondarySolverName, CoronaUHDPresetType, CoronaUHDPresetName, CoronaUhdCachePrecomputationType, CoronaUhdCachePrecomputationName } from './App.enums';

/**
 * 3d software
 */
export type _3dSoftwareInterface = Supported3dApps
/**
 * Render engine versions for 3d software
 */

/**
 * Allowed render output formats
 */
export type RenderOutputType = ".png" | ".jpg" | ".jpeg" | ".gif" | ".uhd" | ".exr" | ".bmp" | ".cjpg" | ".cjp" | ".tga" | ".tif" | ".pic" | ".cxr" |".rla" | ".rpf" | string

export enum RenderOutputTypeKeys {
    png = ".png",
    jpg = ".jpg",
    jpeg = ".jpeg",
    gif = ".gif",
    uhd = ".uhd",
    exr = ".exr",
    bmp = ".bmp",
    cjpg = ".cjpg",
    cjp = ".cjp",
    tga = ".tga",
    tif = ".tif",
    pic = ".pic",
    cxr = ".cxr",
    rla = ".rla",
    rpf = ".rpf"
}
/**
 * Camera item 
 */
 export interface MaxCamera {
    camera_key : string
    camera_name : string
}

/**
 * 
 */
export interface MaxLighting {
    light_name: string
    light_state: boolean
}

/**
 * 
 */
export interface MaxLight {
    lighting_list : MaxLighting[]
    num_lightings:number
}

// Default type of warning, error, info
// All listing info ,... must be extended from this type
export interface SceneItemInterface {
    items : string[] // list of items 
    message : string //
    value : string | number
    expandable: boolean // expand
}

// Scene camera info
// Show information of activing camera, list of camera
// and Number of camera
export interface SceneCamera{
    activeCamera : string
    cameras_list : MaxCamera[]
    num_cameras : number
}

// Show errors of scene
// If error count is greater than 0 items, then we doesn't allow user continue to render scene
// User must be resolve all errors before start render
export interface SrfMaxError{
    empty_scene?:SceneItemInterface
}

// Warning for item
export interface SrfMaxWarning{

    // List assets was missing from client pc
    assets_missing?:SceneItemInterface

    // Area to Render was set to Selected,
    // this might cause the render result is not correct.
    // Recommend to change Area to Render to View.
    area_to_render_selected?:SceneItemInterface

    // Area to Render was set to Region,
    // this might cause the render result is not correct.
    // Recommend to change Area to Render to View.
    area_to_render_region?:SceneItemInterface

    // Area to Render was set to Crop,
    // this might cause the render result is not correct.
    // Recommend to change Area to Render to View.
    area_to_render_crop?:SceneItemInterface

    // Area to Render was set to Blowup,
    // this might cause the render result is not correct. 
    // Recommend to change Area to Render to View.
    area_to_render_blowup?:SceneItemInterface

    // Vray default light was set to ON,
    // this might cause the render result is not correct.
    // We recommend you should goto Render Setup > Vray > Global switches then set Default lights to off!
    vray_default_light_on?:SceneItemInterface

    // Textures/assets unsupported. Recommend?: review these assets and correct them.
    assets_unsupported?:SceneItemInterface

    // No cameras found in your scene,
    no_camera?:SceneItemInterface

    no_lights?:SceneItemInterface
}

// Info of the scene
export interface SrfMaxInfo {
    vray_region_render ?: SceneItemInterface
}

// Declare type restrictions for png types
// Only accepted types are listed here
export enum PngType {
    OptimizedPaletted = "paletted",
    RGB24Bit = "true24",
    RGB48Bit = "true48",
    Grayscale8Bit = "gray8",
    Grayscale16Bit = "gray16",
}

export enum TifType {
    _8BitGrayScale = "mono",
    _8BitColor = "color",
    _16BitColor = "color16",
    _16BitSGILogL = "logL",
    _32BitSGILogLUV = "logLUV",
}

export enum TifCompression {
    None = "none",
    PackBits = "packBits"
}
export enum BmpType {
    True24 = "true24",
    Paletted = "paletted",
}

export enum ExrType {
    ARGB = "ARGB",
    RGB = "RGB",
    Mono = "Mono",
    XYZ = "XYZ",
    XY = "XY",
    UV = "UV",
}
export enum ExrMainFormat {
    FullFloat = 0,
    HalfFloat = 1,
    Integer = 2,
}
export enum ExrCompression {
    None = 0,
    RLE = 1,
    ZLIBPerScanLine = 2,
    ZIPS16Scanline = 3,
    PIZ = 4,
    Lossy4x4 = 5,
    Lossy4x4FixedRate = 6,
}
export interface MaxParams {
    endFrame:number;
    startFrame:number;
    incrementStep:number
    maxPath: string
    outputNameFile: string
    outputNameType: RenderOutputType
    output_full_path: string
    render_engine: RenderEngineVersion
    sceneNameClean: string
    width:number
    height:number
    frame?: number
    take: string []
}
export type OutputParams = OutputParamsBoolean & {
    // 3dsMax Output
    output_file_name?: string,
    /**
     * Output extension configuration
     * @example
     */
    multipassoutput_folder?: "",
    outputNameFile?: string,
    output_extension?: string,

    // Cinema4d Ouput
    output_format_extension: string,
    output_main_enabled: boolean,
    output_main_file_name: string,
    output_multipass_enabled: boolean,
    output_multipass_file_name: string

}
export interface IGammaConfig {
    gamma_correction:number
    input_gamma: number
    output_gamma : number
}

export interface IRenderOptions {
    gamma_correction?: IGammaConfig
    render_engine : "cpu" | "gpu"

}
export interface IElementsOptions {

}


/**
 * Vray renderer engine interfaces
 */
export interface IGiPrimary {
    gi_primary_type_key ?: VrayGIPrimaryType
    gi_primary_type_name ?: VrayGlobalIlluminationPrimaryName
}
export interface IGiSecondary {
    gi_secondary_type_key ?: VrayGISecondaryType
    gi_secondary_type_name ?: VrayGlobalIlluminationSecondaryName
}
export interface IImageSampler {
    imageSampler_type_key ?: ImageSamplerType
    imageSampler_type_name ?: ImageSamplerName
}
export interface ILightCache {
    lightcache_mode_key ?: LightCacheMode
    lightcache_mode_name ?: LightCacheModeName
}
export interface IIrradmapMode {
    adv_irradmap_mode_key?: IrradMapMode
    adv_irradmap_mode_name?: IrradMapModeName
}

//
export type FrontVrayConfiguration = {
    output_saveRawFile?: PHPBOOL
    output_splitgbuffer?: PHPBOOL
    output_on ?: PHPBOOL
    filter_on ?: PHPBOOL
    imageSampler_type ?: ImageSamplerType;
    lightcache_mode ?: LightCacheMode;
    adv_irradmap_mode ?: IrradMapMode;
    gi_on ?: PHPBOOL
    lightcache_loadFileName ?: string
    adv_irradmap_loadFileName ?: string
    gi_primary_type ?: VrayGIPrimaryType
    gi_secondary_type ?: VrayGISecondaryType
}

/**
 * Corona renderer engine interfaces
 */

export type ShadingPrimarySolver = {
    shading_primarySolver_key: CoronaPrimarySolverType
    shading_primarySolver_name: CoronaPrimarySolverName
}
export type ShadingSecondarySolver = {
    shading_secondarySolver_key: CoronaSecondarySolverType
    shading_secondarySolver_name: CoronaSecondarySolverName
}
export type GiUHDCachePreset = {
    gi_uhdcache_preset_key: CoronaUHDPresetType
    gi_uhdcache_preset_name: CoronaUHDPresetName
}
export type GiUHDCachePreCalcMode = {
    gi_uhdCache_precalcMode_key: CoronaUhdCachePrecomputationType
    gi_uhdCache_precalcMode_name: CoronaUhdCachePrecomputationName
}

// using with redux store states
// all states in stores will using this type interface
export interface IVrayOptions {
    adv_irradmap_autoSaveFileName: string
    adv_irradmap_loadFileName:string
    adv_irradmap_mode: IIrradmapMode
    adv_irradmap_saveFileName :string
    filter_kernel: string
    filter_on: boolean
    output_on: boolean
    gi_on : boolean
    gi_primary_type : IGiPrimary
    gi_secondary_type : IGiSecondary
    imageSampler_type : IImageSampler
    lightcache_autoSaveFileName:string
    lightcache_loadFileName: string
    lightcache_mode : ILightCache
    lightcache_saveFileName : string
    output_saveRawFile: boolean
    output_splitgbuffer: boolean
    output_rawFileName: string
    output_splitfilename: string
    user_select_auto_mode: boolean
}


export interface ICoronaOptions {
    user_select_auto_mode: boolean
    shading_primarySolver: ShadingPrimarySolver
    shading_secondarySolver: ShadingSecondarySolver
    gi_uhdcache_preset: GiUHDCachePreset
    gi_uhdCache_precalcMode: GiUHDCachePreCalcMode
    gi_uhdCache_file: string
}
/**
 * Arnol renderer engine interface.
 */
 export interface IArnoldOptions {
    AA_samples: number
    GI_diffuse_depth: number
    GI_diffuse_samples: number
    GI_specular_depth: number
    GI_specular_samples: number
    GI_sss_samples: number
    GI_transmission_depth: number
    GI_transmission_samples: number
    GI_volume_depth: number
    GI_volume_samples: number
}

export interface ISceneInfo{
    scene_param: MaxParams,
    output_param: OutputParams,
    camera_param: SceneCamera,
    lighting_param: MaxLight,
    element_param: IElementsOptions,
    vray_option?:IVrayOptions,
    corona_option?:ICoronaOptions,
    arnold_option?:IArnoldOptions,
    // Maya interface
    render_layers: RenderLayers[],
    render_cameras: RenderCameras[],
    incrementStep: number,
    startFrame: number,
    endFrame: number,
    height: number,
    width: number,
}

/**
 * Maya interface
 */
export interface RenderLayers {
    is_default_layer: boolean,
    layer_name: string
}
export interface RenderCameras {
    camera_name: string
}

export type SelectableMayaCamera = {
    name: string
    selected: boolean
}

// this is params of
export interface SceneInterface {
    MaxPath: string
    error : SrfMaxError[]
    warning :SrfMaxWarning[]
    info : SrfMaxInfo[]
    params :ISceneInfo
    cameras : SelectableMayaCamera[]
}