import axios from "axios"

export enum ApiStatus {
    Success = "success",
    Error = "error"
}
export enum ResponseCode {
    Success = "OK",
    Error = "ERROR",
    OFM = "OUT_OF_MONEY",
}
export interface ApiResponse<T>  {
    status : ApiStatus
    data ?: T
    code : ResponseCode
    message : string 
}

export const RestApi = axios.create({
    baseURL: "https://olapi.superrendersfarm.com/api/",
    timeout: 10000,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
    }
})

export const AuthorizationRequest = axios.create({
    baseURL: "https://olapi.superrendersfarm.com/api/",
    timeout: 10000,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        'Authorization':`Bearer ${localStorage.getItem('access_token')}`
    }
})