import { ISceneAnalyze } from "screens/MainWindow/Components/SceneAnalysis/SceneAnalysis.interface";
import { CloudType, RenderEngineVersion, RenderProcessor } from "../../Interfaces/App.enums";

export enum AnalyzeResult {
    CmdReady = "cmd_is_ready"
}

export enum AnalyzeStatus {
    Completed = "AnalyzeCompleted",
    Failed = "AnalyzeFailed"
}
export enum FarmServers {
    Server3 = "SRFSERVER3"
}

export enum DeadlinePool {
    General = "general_pool"
}

export enum RendererVersions {

}

export enum Supported3dApps {
    Maya = "maya",
    Max = "3dsmax",
    Nuke = "nuke",
    Substance = "substance",
    Houdini = "houdini",
    Blender = "blend",
    C4d = "c4d",
}

export interface SceneAnalyzeResponse {
    data : ISceneAnalyze[]
    message : string
}