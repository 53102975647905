import { SOFTWARES_TYPE } from "config";

/**
 * Detect software name from object_path
 * @param object_path path to s3 object
 * 
 * @returns {string}
 */

export function DetectSoftwareFromPath(object_path: string) {

     const ext = object_path.split('.')[object_path.split('.').length -1]

     if ( ext === 'ma' || ext === 'mb'  ) {
          return SOFTWARES_TYPE.AUTODESK_MAYA
     }
     if ( ext === 'c4d') {
          return SOFTWARES_TYPE.C4D
     }
     if ( ext === 'max') {
          return SOFTWARES_TYPE.AUTODESK_3DS_MAX
     }
     if ( ext === 'blend' ) {
          return SOFTWARES_TYPE.BLENDER
     }
     if ( ext === 'aep' ) {
          return SOFTWARES_TYPE.AFTEREFECT
     }
     if ( ext === 'hip' || ext === 'hipnc' || ext === 'hiplc' ) {
          return SOFTWARES_TYPE.HOUDINI
     }
     throw Error("object_path invalid!")
}