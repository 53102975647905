import React from 'react'
import Camera from '../components/Camera'
import Resolution from '../components/Resolution'
import ScenePath from '../components/ScenePath'
import OutputSettings from '../components/OutputSettings'
import RenderFlags from '../components/RenderFlags'
import { RenderFlagDispatcher } from "../components/RenderFlags/RenderFlags.dispatchs"
import RenderSetting from '../components/RenderSetting'
import Vray from './Vray'
import Corona from './Corona'
import Arnold from './Arnold'
import { connect } from 'react-redux'
import { RootState } from '../../../../stores/stores'
import { RenderOptionsInterface } from '../../../../screens/RenderNow/RenderNow.interface'
import RenderType from '../components/RenderType'
import GammaCorrection from '../components/GammaCorrection'
import { PHPBOOL, RenderEngineVersion } from 'Interfaces/App.enums'
import { RenderReducerActionEnum } from 'stores/reducers/render.interface'
import LightsManagement from '../components/LightsManagement'
import RenderElements from '../components/RenderElements'

export function MapMaxStates(States: RootState) {
     return {
          renderOptions: States.Render
     }
}

export const RenderNowMapDispatchs = {
     switch_render_flags: (key: string) => ({ type: RenderReducerActionEnum.SwitchRenderFlags, value: key }),
     switch_use_gamma_correction: (value: boolean) => ({ type: RenderReducerActionEnum.ChangeUseGammaCorrection, value }),
     set_input_gamma: (value: number) => ({ type: RenderReducerActionEnum.ChangeInputGamma, value }),
     set_output_gamma: (value: number) => ({ type: RenderReducerActionEnum.ChangeOutputGamma, value }),
     set_frame_pertask: (value: number) => ({ type: RenderReducerActionEnum.SetFramePerTask, value }),
     set_render_priority: (value: number) => ({ type: RenderReducerActionEnum.SetRenderPriority, value }),
     set_use_prerender_assets: (value: boolean) => ({ type: RenderReducerActionEnum.SetUseMissingAssets, value }),
     set_use_missing_assets: (value: number) => ({ type: RenderReducerActionEnum.SetUseMissingAssets, value }),

}

export type MaxProps = {} & ReturnType<typeof MapMaxStates> & typeof RenderNowMapDispatchs

function Max(Props: MaxProps) {
     const { renderOptions, switch_render_flags, ...render } = Props
     return (
          <>
               <ScenePath />
               <Resolution />
               <RenderType />
               <Camera />
               <OutputSettings />
               {/* Renderer/Plugins option  */}
              
               <Arnold renderOptions={Props.renderOptions} />
               <Corona renderOptions={Props.renderOptions} />
               <Vray renderOptions={Props.renderOptions} />
               <div className="accordion pb-3" id="param-settings">
                    {/* Lights Management */}
                    <div className="accordion-item">
                         <h2 className="accordion-header" id="heading1">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                                   Lights Management
                              </button>
                         </h2>
                         <div id="collapse1" className="accordion-collapse collapse" aria-labelledby="heading1" data-bs-parent="#param-settings">
                              <div className="accordion-body">
                                   <LightsManagement />
                              </div>
                         </div>
                    </div>
                    {/* Render Elements */}
                    {
                    Props.renderOptions.body.scene.params.scene_param.render_engine === RenderEngineVersion.Arnold4_0_1_Gpu ||
                    Props.renderOptions.body.scene.params.scene_param.render_engine === RenderEngineVersion.Arnold4_0_1_Cpu ||
                    Props.renderOptions.body.scene.params.scene_param.render_engine === RenderEngineVersion.Arnold3 ?<></>
                    :<div className="accordion-item">
                         <h2 className="accordion-header" id="heading2">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                                   Render Elements
                              </button>
                         </h2>
                         <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#param-settings">
                              <div className="accordion-body">
                                   <RenderElements />
                              </div>
                         </div>
                    </div>
                    }
               
                    {/* Gamma Correction */}
                    <div className="accordion-item">
                         <h2 className="accordion-header" id="heading3">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                                   Gamma Correction
                              </button>
                         </h2>
                         <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#param-settings">
                              <div className="accordion-body">
                                   <GammaCorrection
                                        inputGamma={renderOptions.body.scene.params.output_param.input_gamma || 0}
                                        outputGamma={renderOptions.body.scene.params.output_param.output_gamma || 0}
                                        isUseGammaCorrection={!!renderOptions.body.scene.params.output_param.gamma_correction}
                                        changeInputGamma={function (value: number): void {
                                             render.set_input_gamma(value)
                                        }} changeOutputGamma={function (value: number): void {
                                             render.set_output_gamma(value)
                                        }} changeUseGammaCorrection={function (value: boolean): void {
                                             render.switch_use_gamma_correction(!renderOptions.body.scene.params.output_param.gamma_correction)
                                        }}
                                   />
                              </div>
                         </div>
                    </div>

                    {/* Render Flags */}
                    <div className="accordion-item">
                         <h2 className="accordion-header" id="heading4">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                                   Render Flags
                              </button>
                         </h2>
                         <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#param-settings">
                              <div className="accordion-body">
                                   <RenderFlags
                                        performColorCheck={renderOptions.render_configuration.rf_perform_color_check === PHPBOOL.True}
                                        useAsmosphericsEffects={renderOptions.render_configuration.rf_use_atmospherics_effects === PHPBOOL.True}
                                        performRenderEffect={renderOptions.render_configuration.rf_perform_render_effects === PHPBOOL.True}
                                        togglesOutputDithering={renderOptions.render_configuration.rf_toggles_output_dithering_paletted === PHPBOOL.True}
                                        forceTwoSide={renderOptions.render_configuration.rf_force_two_sided === PHPBOOL.True}
                                        useSuperBlack={renderOptions.render_configuration.rf_use_super_black === PHPBOOL.True}
                                        useAdvancedLight={renderOptions.render_configuration.rf_use_advanced_lighting === PHPBOOL.True}
                                        togglesOutputDitheringTrueColor={renderOptions.render_configuration.rf_toggles_output_dithering_true_color === PHPBOOL.True}
                                        renderHiddenObjects={renderOptions.render_configuration.rf_render_hidden_objects === PHPBOOL.True}
                                        performDisplacementMapping={renderOptions.render_configuration.rf_perform_displacement_mapping === PHPBOOL.True}
                                        computeAdvancedLightng={renderOptions.render_configuration.rf_compute_advanced_lighting === PHPBOOL.True}
                                        converAreaLightsToPointsSources={renderOptions.render_configuration.rf_convert_area_lights_to_points_sources === PHPBOOL.True}
                                        setPerformColorCheck={function (state: boolean): void {
                                             switch_render_flags("rf_perform_color_check")
                                        }} setUseAsmosphericEffects={function (state: boolean): void {
                                             switch_render_flags("rf_use_atmospherics_effects")
                                        }} setPerformRenderEffect={function (state: boolean): void {
                                             switch_render_flags("rf_perform_render_effects")
                                        }} setTogglesOutputDithering={function (state: boolean): void {
                                             switch_render_flags("rf_toggles_output_dithering_paletted")
                                        }} setForceTwoSide={function (state: boolean): void {
                                             switch_render_flags("rf_force_two_sided")
                                        }} setUseSuperBlack={function (state: boolean): void {
                                             switch_render_flags("rf_use_super_black")
                                        }} setUseAdvancedLight={function (state: boolean): void {
                                             switch_render_flags("rf_use_advanced_lighting")
                                        }} setTogglesOutputDitheringTrueColor={function (state: boolean): void {
                                             switch_render_flags("rf_toggles_output_dithering_true_color")
                                        }} setrenderHiddenObject={function (state: boolean): void {
                                             switch_render_flags("rf_render_hidden_objects")
                                        }} setPerformDisplacementMapping={function (state: boolean): void {
                                             switch_render_flags("rf_perform_displacement_mapping")
                                        }} setComputeAdvancedLightng={function (state: boolean): void {
                                             switch_render_flags("rf_compute_advanced_lighting")
                                        }} setConverAreaLightsToPointsSources={function (state: boolean): void {
                                             switch_render_flags("rf_convert_area_lights_to_points_sources")
                                        }} />
                              </div>
                         </div>
                    </div>
                    {/* Render Setting */}
                    <div className="accordion-item">
                         <h2 className="accordion-header" id="heading5">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                   Render Setting
                              </button>
                         </h2>
                         <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#param-settings">
                              <div className="accordion-body">
                                   <RenderSetting
                                        framePerTask={renderOptions.render_configuration.frame_per_task}
                                        renderPriority={renderOptions.render_configuration.render_priority || 10}
                                        usePreviousAssetsIfMissing={renderOptions.render_configuration.use_missing_assets===1?true:false}
                                        setFramePerTask={function (n: number): void {
                                             render.set_frame_pertask(n)
                                        }} setRenderPriority={function (n: number): void {
                                             render.set_render_priority(n)
                                        }}   setUsePreviousAssetsIfMissing={function (n: boolean): void {
                                             n ? render.set_use_missing_assets(1) : render.set_use_missing_assets(0) }} 
                                   />
                              </div>
                         </div>
                    </div>
               </div>
          </>
     )
}

export default connect(MapMaxStates, RenderNowMapDispatchs)(Max)