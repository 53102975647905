import { RenderReducerActionEnum } from "../../stores/reducers/render.interface";
import { SceneResponseInterface } from "./RenderNow.interface";

export const RenderNowMapDispatchs = {

    // Test compoent dispatch handles
    // Change width
    ChangeWidth : ( width: number ) => ({ type : RenderReducerActionEnum.ChangeWidth , value : width }),

    // Set scene data to redux store,
    // Caution: this use once time only.
    // If component re-call to this dispatch will reset all component props to default.
    SetScene : ( scene: SceneResponseInterface ) => ({ type : RenderReducerActionEnum.UpdateScene, value : scene}),

    // Set frame resolution height
    SetHeight : (height: number) => ({ type : RenderReducerActionEnum.ChangeHeight , value : height}),

    // Set start frame
    SetStartFrame : (frame: number) => ({ type : RenderReducerActionEnum.ChangeStartFrame , value : frame}),

    // Set end frame
    SetEndFrame : (frame: number) => ({ type : RenderReducerActionEnum.ChangeEndFrame, value : frame}),

    // Change increment frame step
    SetFrameStep : (step: number) => ({ type :RenderReducerActionEnum.ChangeFrameStep, value : step}),

    // set render camera
    SetRenderCamera : (camera:string) => ({ type : RenderReducerActionEnum.SetRenderCamera, value : camera}),

    // is render tiles
    SetIsRenderTile : (isRenderTile : boolean) => ({ type :RenderReducerActionEnum.SetRenderTile, value : isRenderTile ? "yes" : "no"}),

    // Set frame per task
    SetFramePerTask : (frame : number) => ({ type :RenderReducerActionEnum.SetFramePerTask, value : frame})

}