import React, { ReactChild, ReactChildren, useEffect, useRef, useState } from 'react'
import { IpcSender } from '../../Interfaces/IpcRenderer.enum';
import Lottie from 'react-lottie';
import './dropable.scss'

const { ipcRenderer } = window.electron;
const template = require("./file.json")
interface DropableProps {
    children?:  JSX.Element[] | JSX.Element| JSX.Element | JSX.Element[] | string | string[];
}

var dragCounter : number = 0 ;
export default function Dropable( {children}: DropableProps) {
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: template,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        },
      };
    const [isDrag, setIsDrag] = useState(false);
    const divRef = useRef<HTMLDivElement>(null)
    const animatedRef = useRef<HTMLDivElement>(null)

    const handleDrag = (e: DragEvent) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDragIn = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
        dragCounter++

        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            console.log(e.dataTransfer.items[0])
            setIsDrag(true);
        }

    }

    const handleDragOut = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
        dragCounter--
        if (dragCounter === 0) {
            setIsDrag(false)
        }
      
    }

    const handleDrop = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
        setIsDrag(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {

            /**
             * Handle upload
             */
            const FileList:string[] = []
            for (const f of e.dataTransfer.files) {
                // Using the path attribute to get absolute file path
                console.log('File Path of dragged files: ', f)
                FileList.push(f.path); // assemble array for main.js
            }
            // Send ipc to main.js
            ipcRenderer.send(IpcSender.dropUploadFiles, FileList, window.location.pathname)
            e.dataTransfer.clearData()
            dragCounter = 0
        }
    }

    useEffect(() => {
        let div = divRef.current
        if(div != null){
            div.addEventListener('dragenter', handleDragIn)
            div.addEventListener('dragleave', handleDragOut)
            div.addEventListener('dragover', handleDrag)
            div.addEventListener('drop', handleDrop)
        }
    }, [] )

    return (
        <div ref={divRef} >
            {children}
            {isDrag && (
            <div className="fade show">
                <div className="overlay"></div>
                <div className="dropable">
                    <div className="dropzone-drop">
                        <div className="screen-modal-fade-in">
                            <Lottie 
                            options={defaultOptions}
                            height={250}
                            width={300}
                            />
                            <span>Drop file here to upload!</span>
                        </div>
                    </div>
                </div>
            </div>
            )   
             
            }
        </div>
    )
}

