import React, { useEffect, useState } from "react";
import { SceneResponseInterface } from "../../../../../screens/RenderNow/RenderNow.interface";
import Switch from "react-switch";
import "./camera.scss";
import { connect } from "react-redux";
import { RootState, Stores } from "../../../../../stores/stores";
import { CameraDispatchs } from "./Camera.dispatch";
import camerasUtils from "./camerasUtils";
import { MaxCamera } from "Interfaces/Renderer";

const MapStates = (States: RootState) => ({
  RenderScene: States.Render,
});

type CameraProps = ReturnType<typeof MapStates> & typeof CameraDispatchs;

function Camera(Renders: CameraProps) {
  const [RenderScene, setRenderScene] = useState(Renders.RenderScene)
  const [rerender, setRerender] = useState(false);
  const [overrideCamera , setOverrideCamera] = useState(false);
  const [CameraMaya, setCameraMaya] = useState<string>("")

  useEffect(() => {
    if (RenderScene._3dEngine !== "maya" ) {
      camerasUtils.clearCameras();
      camerasUtils.addCamera(RenderScene.body.scene.params.camera_param?.activeCamera);
      setRerender(!rerender);
    }  
  }, [])
  // Render list of camera if scene json available
    return (
      <>
      { RenderScene.body.scene.params.render_cameras !== undefined && RenderScene.body.scene.params.render_cameras.length !== 0 ?
      <div className="d-flex mb-3">
        <label className="form-label">Override Camera </label>
        <div className="row col">
          <Switch onChange={()=>setOverrideCamera(!overrideCamera)} checked={overrideCamera} className="my-auto" onColor="#399f65" />
        </div>
      </div>
     :<></> 
        
      }
      
      <div className="d-flex mb-3 camera">
        <label className="form-label">Camera </label>
        <div className="row col">
          <div className="col-8">
            {
              RenderScene._3dEngine === "3dsmax"? 
              <div className="dropdown">
              <button type="button" className="form-select text-start" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                {camerasUtils.getCameras() == 0 ? "Select camera(s)" : `${camerasUtils.getCameras().length} camera selected`}
              </button>
              <div className="dropdown-menu dropdown-menu-dark">
                <div className="list-group" >
                  {RenderScene.body.scene.params.camera_param?.cameras_list === undefined ? <div className="m-3">This scene don't have any camera!</div> 
                  :RenderScene.body.scene.params.camera_param?.cameras_list.map(
                    (camera, camera_index) => (
                      <label className="list-group-item">
                        <input
                          className="form-check-input me-1"
                          type="checkbox"
                          key={camera_index}
                          checked={
                            camerasUtils.getCameras().includes(camera.camera_key)
                          }
                          value={camera.camera_key}
                          onClick={() => {
                            camerasUtils.toggleCamera(camera.camera_key);
                            setRerender(!rerender);
                          }}
                        />
                        {camera.camera_name}
                      </label>
                    )
                  )}
                </div>
              </div>

            </div>
            :RenderScene._3dEngine === "maya" ? RenderScene.body.scene.params.render_cameras.length !== 0 || RenderScene.body.scene.params.render_cameras !== undefined ?
             <select
                className="form-select"
                aria-label="Colors"
                disabled={!overrideCamera}
                onChange={e=>{
                  Renders.toggleSelectCameraMaya(e.target.value)
                  }}
                >
                {
                  RenderScene.body.scene.cameras !== undefined ? 
                  RenderScene.body.scene.cameras.map((camera) => (
                    <option key={camera.name} value={camera.name} selected={camera.selected}>
                      {camera.name}
                    </option>
                  )
                ): <></> 
                }
              </select>
              : <></>
            : <> </>

            }


          </div>
      
        </div>
      </div>
      </>
    
    )

}

export default connect(MapStates, CameraDispatchs)(React.memo(Camera));
