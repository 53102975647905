import { all } from 'redux-saga/effects'
import { sagaGetSupportedSoftware } from './sagas/default.saga'
import { watchFetchAnalysisList, watchFetchCloudObjects, watchFetchListRenderList } from './sagas/watcher'

export function* DefaultSaga() {
    yield all([
        sagaGetSupportedSoftware(),
        watchFetchCloudObjects(),
        watchFetchAnalysisList(),
        watchFetchListRenderList()
    ])
}