import React, { useState } from 'react'
import './dropable-zone.scss'
import { IpcResponse, IpcSender } from '../../Interfaces/IpcRenderer.enum'
import Dropable from '../Dropable'
import UploadMediasIcon from "../../assets/SVG/style-dark-1/Upload-Media.svg"

const electron = window.electron;
const { ipcRenderer } = window.electron;
interface DropableZoneProps {
}

export default function DropableZone({ }: DropableZoneProps) {

     const [isUploading, setIsUploading] = useState(false)
     const [autoSimulateLocalPath, setAutoSimulateLocalPath] = useState(true)

     const ToggleAutoSimulateLocalPath = () => {
          autoSimulateLocalPath ? setAutoSimulateLocalPath(false) : setAutoSimulateLocalPath(true)
     }

     function UploadFiles() {
          ipcRenderer.send(IpcSender.OpenFileDialog, {
               simulate: autoSimulateLocalPath
               }
          )
     }
     function UploadFolder() {
          ipcRenderer.send(IpcSender.OpenFolderDialog, {
               simulate: autoSimulateLocalPath
               }
          )
     }

     if (isUploading) {
          return <div>Uploading...</div>
     }
     return (
          <Dropable>
               <div className="dropable-zone">
                    <div className="col-12">
                         <div className="mb-3">
                              <h4 className="fw-bold mb-3 text-center">Upload your assets</h4>
                              <ul className="m-0 ps-3">
                                   <li>
                                        Add the files and folders you want to upload to SuperRenders Farm
                                   </li>
                                   <li>
                                   Auto keep local path is used to simulate an absolute path just like one on your local computer <span className='link-success' style={{ cursor: 'pointer' }} onClick={() => {electron.shell.openExternal('https://support.superrendersfarm.com/hc/en-us/articles/360062299047-how-to-add-an-absolute-path-with-simulate-local-path-feature')}}>(learn more)</span>
                                   </li>
                              </ul>
                         </div>
                         <div className="form-check mb-3">
                              <input type="checkbox" className="form-check-input" name="" id="s" checked={autoSimulateLocalPath} onChange={() => ToggleAutoSimulateLocalPath()} />
                              <label className="form-check-label" htmlFor="s">
                                   Auto keep local path
                              </label>
                         </div>
                         <div className="d-flex gap-4">
                              <button type="button" className="add-files-button me-auto" onClick={() => UploadFiles()}>
                                   ADD FILES
                              </button>
                              <button type="button" className="add-files-button ms-auto" onClick={() => UploadFolder()}>
                                   ADD FOLDERS
                              </button>
                         </div>
                    </div>
               </div>
          </Dropable>
     )
}