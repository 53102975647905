import { API } from "apis/base.api";
import { Stores } from "stores/stores";
import { SupportedSoftwareTypes, SSActionType } from '../reducers/supported-software.types'

export interface CommonSoftwareResponse {
    status: "success",
    data : SupportedSoftwareTypes[]
}

export function* GetSupportedSoftwareSaga( {payload}:any ) {
    API.public.get<CommonSoftwareResponse>('common/softwares')
    .then( ({data}) => {
        // get list of supported software
        Stores.dispatch({
            type: SSActionType.SetList,
            payload: data.data
        })
    })
    .catch((exp) => {
        console.log('cannot get supported software', exp.message)
    })
}