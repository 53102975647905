
export interface ILCTypes {
    ilc : string,
    title : string
}
export const ILC : ILCTypes[] = [
    {
        ilc: "ENG",
        title: "UK English"
    },
    {
        ilc: "PTB",
        title: "Brazilian Portuguese"
    },
    {
        ilc: "CHS",
        title: "Simplified Chinese"
    },
    {
        ilc: "DAN",
        title: "Danish"
    },
    {
        ilc: "ENU",
        title: "US English"
    },
    {
        ilc: "FRA",
        title: "French"
    },
    {
        ilc: "DEU",
        title: "German"
    },
    {
        ilc: "KOR",
        title: "Korean"
    },
    {
        ilc: "ITA",
        title: "Italian"
    },
    {
        ilc: "ISL",
        title: "Icelandic"
    },
    {
        ilc: "JPN",
        title: "Japanese"
    },
    {
        ilc: "BGR",
        title: "Bulgarian"
    },
    {
        ilc: "MKD",
        title: "Macedonian"
    },
    {
        ilc: "NOR",
        title: "Norwegian"
    },
    {
        ilc: "ELL",
        title: "Greek"
    },
    {
        ilc: "PTG",
        title: "Portuguese"
    },
    {
        ilc: "ARA",
        title: "Arabic"
    },
    {
        ilc: "RUS",
        title: "Russian"
    },
    {
        ilc: "ESP",
        title: "Spanish"
    },
    {
        ilc: "CHT",
        title: "Traditional Chinese"
    },
    {
        ilc: "UKR",
        title: "Ukrainian"
    },
    {
        ilc: "SVE",
        title: "Swedish"
    },
    {
        ilc: "FIN",
        title: "Finnish"
    },
    {
        ilc: "HEB",
        title: "Hebrew"
    },
    {
        ilc: "SHC",
        title: "Serbo-Croatian (Cyrillic)"
    },
    {
        ilc: "THA",
        title: "Thai"
    },
    {
        ilc: "BEL",
        title: "Byelorussian"
    },
    {
        ilc: "CSY",
        title: "Czech"
    },
    {
        ilc: "HRV",
        title: "Croatian"
    },
    {
        ilc: "HUN",
        title: "Hungarian"
    },
    {
        ilc: "PLK",
        title: "Polish"
    },
    {
        ilc: "ROM",
        title: "Romanian"
    },
    {
        ilc: "SHL",
        title: "Serbo-Croatian (Latin)"
    },
    {
        ilc: "TRK",
        title: "Turkish"
    },
    {
        ilc: "NLD",
        title: "Dutch"
    },
]

export function ILCConvert(ilc: string): string {
    return ILC.find(il => il.ilc === ilc)?.title || "ENU"
}