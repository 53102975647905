import React from 'react'
import { connect } from 'react-redux'
import { RenderReducerActionEnum } from 'stores/reducers/render.interface'
import { RootState } from 'stores/stores'
import FrameRange from '../components/FrameRange'
import OutputSettings from '../components/OutputSettings'
import RenderSetting from '../components/RenderSetting'
import Resolution from '../components/Resolution'
import ScenePath from '../components/ScenePath'
import './cinema-4d.scss'

export function Cinema4dStates(States: RootState) {
      return {
           renderOptions: States.Render
      }
}
export const RenderNowMapDispatchs = {
      switch_render_flags: (key: string) => ({ type: RenderReducerActionEnum.SwitchRenderFlags, value: key }),
      set_start_frame_range: (value: number) => ({ type: RenderReducerActionEnum.SetStartFrameRange, value }),
      set_end_frame_range: (value: number) => ({ type: RenderReducerActionEnum.SetEndFrameRange, value }),
      set_incremental_step: (value: number) => ({ type: RenderReducerActionEnum.SetIncrementalStep, value }),
      set_frame_pertask: (value: number) => ({ type: RenderReducerActionEnum.SetFramePerTask, value }),
      set_render_priority: (value: number) => ({ type: RenderReducerActionEnum.SetRenderPriority, value }),
      set_use_missing_assets: (value: number) => ({ type: RenderReducerActionEnum.SetUseMissingAssets, value }),
      set_output_main_file_name: (value: string) => ({ type: RenderReducerActionEnum.SetOutputMainFileName, value }),
      set_output_multipass_file_name: (value: string) => ({ type: RenderReducerActionEnum.SetOutputMultipassFileName, value }),
}

export type Cinema4dProps = {} & ReturnType<typeof Cinema4dStates> & typeof RenderNowMapDispatchs

function Cinema4d(Props: Cinema4dProps) {
      const { renderOptions, switch_render_flags, ...render } = Props
      return  <>
                  <ScenePath />
                  {
                        renderOptions.body.scene.params.output_param.output_main_enabled ?
                        <div className="d-flex mb-3">
                              <label className="form-label mb-2">Output Settings</label>
                              <div className="row col">
                                    <div className="col-8">
                                          <span className="input-group-text" id="output-file-name">Output file name <span className="text-danger">*</span></span>
                                          <input
                                                onChange={e => Props.set_output_main_file_name(e.target.value)}
                                                value={renderOptions.body.scene.params.output_param.output_main_file_name }
                                                type="text" className="form-control" id="output-file-name" aria-describedby="output-file-name" placeholder="File name..." />
                                    </div>
                                    <div className="col-4">
                                          <span className="input-group-text">Output extension</span>
                                          <input type="text" className="form-control" value={renderOptions.body.scene.params.output_param.output_format_extension} disabled />
                                    </div>
                              </div>
                        </div>
                        
                        : <></>
                  }
                  {
                        renderOptions.body.scene.params.output_param.output_multipass_enabled ?
                        <div className="d-flex mb-3">
                              <label className="form-label mb-2">Multipass Prefix</label>
                              <div className="row col">
                                    <div className="col-8">
                                          <input
                                                onChange={e => Props.set_output_multipass_file_name(e.target.value)}
                                                value={renderOptions.body.scene.params.output_param.output_multipass_file_name}
                                                type="text" className="form-control" id="multipass-prefix" aria-describedby="multipass-prefix" placeholder="File name..." />
                                    </div>
                                  
                              </div>
                        </div>
                        : <></>
                  }
                  <Resolution />
                  <FrameRange 
                        EndFrameRange={renderOptions.body.scene.params.scene_param.endFrame}
                        IncrementalStep={renderOptions.body.scene.params.scene_param.incrementStep}
                        StartFrameRange={renderOptions.body.scene.params.scene_param.startFrame} 
                        setEndFrameRange={function (n: number): void {
                              render.set_end_frame_range(n)}}
                        setStartFrameRange={
                              function (n: number): void {
                               render.set_start_frame_range(n)} }
                        setIncrementalStep={function (n: number): void {
                              render.set_incremental_step(n)}}
                  />
                  <RenderSetting
                        framePerTask={renderOptions.render_configuration.frame_per_task}
                        renderPriority={renderOptions.render_configuration.render_priority || 10}
                        usePreviousAssetsIfMissing={renderOptions.render_configuration.use_missing_assets===1?true:false}
                        setFramePerTask={function (n: number): void {
                              render.set_frame_pertask(n)
                        }} setRenderPriority={function (n: number): void {
                              render.set_render_priority(n)
                        }}   setUsePreviousAssetsIfMissing={function (n: boolean): void {
                              n ? render.set_use_missing_assets(1) : render.set_use_missing_assets(0) }} 
                  />
            </>
}

export default connect(Cinema4dStates, RenderNowMapDispatchs)(Cinema4d)