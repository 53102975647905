import { _3dSoftwareInterface } from "Interfaces/Renderer";
import { CloudType, RenderEngineVersion, RenderProcessor } from "../../../../Interfaces/App.enums";

export enum AnalyzeResult {
    CmdReady = "cmd_is_ready"
}

export enum AnalyzeStatus {
    Completed = "AnalyzeCompleted",
    Failed = "AnalyzeFailed",
    WaitingForAnalyzing = "WaitingForAnalyzing",
    InAnalyzing = "InAnalyzing",
    Restarting = "Restarting"
}
export enum FarmServers {
    Server3 = "SRFSERVER3"
}

export enum DeadlinePool {
    General = "general_pool"
}

export enum RendererVersions {

}

export enum Supported3dApps {
    Maya = "maya",
    Max = "3dsmax",
    Nuke = "nuke",
    Substance = "substance",
    Houdini = "houdini",
    C4d = "c4d",
    Blender = "blend"
}

export interface ISceneAnalyze {
    analysis_internal_result : AnalyzeResult
    analysis_status : AnalyzeStatus
    analyze_result : string
    by_farm : FarmServers
    created_at :string
    deadline_job_id : string | number
    deadline_pool  : DeadlinePool
    end_time : null | string
    gcpu_type?: RenderProcessor
    id : number
    renderer_version : RenderEngineVersion // use new renderer version
    scene_analysis_history_id :number
    scene_analysis_history_status : AnalyzeStatus
    scene_analysis_id :number
    scene_info :string
    scene_path : string
    scene_path_md5 : string
    software_app : Supported3dApps
    software_version : string
    source_cloud_type : CloudType
    start_time : null | string
    updated_at : string
    user_name : string
    working_path_v2 :string
}

export interface SceneAnalyzeResponse {
    data : ISceneAnalyze[]
    message : string
}