import { RENDERER_VERSIONS, SOFTWARES_TYPE, SOFTWARES_VERSION} from "config";
import React, { useEffect, useState } from "react";
import path from 'path';
import { dirname } from 'path';
import { SpaceItem } from "types/spaces.type";
import GammaCorrection from "../../components/RenderNowComponent/ParamSettings/components/GammaCorrection";
import RenderFlags from "../../components/RenderNowComponent/ParamSettings/components/RenderFlags";
import RenderSetting from "../../components/RenderNowComponent/ParamSettings/components/RenderSetting";
import "./render-without-analyze.scss";
import { API } from "apis/base.api";
import Swal from "sweetalert2";
import FrameRange from "components/RenderNowComponent/ParamSettings/components/FrameRange";
import { Renderer } from "electron";
import { Processor, RenderEngineVersion, SoftwareVersion } from "Interfaces/App.enums";
import { RenderOutputType } from "Interfaces/Renderer";
import { DetectSoftwareFromPath } from "Helpers/DetectSoftware";
import { connect } from "react-redux";
import { RootState, Stores } from "stores/stores";
import { CloudStorageAction } from "stores/reducers/spaces.interface";
import Tippy from "@tippyjs/react";

const electron = window.electron;
const { ipcRenderer } = window.electron;

const mapState = (state: RootState) => ({
  supportedSoftwares: state.SupportedSoftwares.list || []
})


export type  RenderWithoutAnalyzeProps = ReturnType<typeof mapState> &  {
  item: SpaceItem,
  closeModal : () => void,
  workspace : string[],
}

function RenderWithoutAnalyze({item, closeModal, workspace , supportedSoftwares}: RenderWithoutAnalyzeProps) {
  const [hardware, setHardware] = useState(Processor.CPU);
  const [scenePath, setScenePath] = useState<string>(item.key);
  const [software, setSoftware] = useState<SOFTWARES_VERSION>(
    SOFTWARES_VERSION.UNDEFINED
  );
  const [renderer, setRenderer] = useState<RENDERER_VERSIONS>(
    RENDERER_VERSIONS.UNDEFINED
  );
  const [startFrame, setStartFrame] = useState<number>(1);
  const [endFrame, setEndFrame] = useState<number>(1);
  const [frameStep, setFrameStep] = useState<number>(1);
  const [outputFileName, setOutputFileName] = useState<string>(``);
  // Gamma Correction
  const [useGammaCorrection, setUseGammaCorrection] = useState<boolean>(false);
  const [inputGamma, setInputGama] = useState<number>(1);
  const [outputGamma, setOutputGamma] = useState<number>(1);
  // resolution
  const [resolutionWidth, setResolutionWidth] = useState<number>(1920);
  const [resolutionHeight, setResolutionHeight] = useState<number>(1080);
  // Render Flags
  const [performColorCheck, setPerformColorCheck] = useState<boolean>(false);
  const [useAsmosphericsEffects, setUseAsmosphericEffects] = useState<boolean>(false);
  const [performRenderEffect, setPerformRenderEffect] = useState<boolean>(false);
  const [togglesOutputDithering, setTogglesOutputDithering] = useState<boolean>(false);
  const [forceTwoSide, setForceTwoSide] = useState<boolean>(false);
  const [useSuperBlack, setUseSuperBlack] = useState<boolean>(false);
  const [useAdvancedLight, setUseAdvancedLight] = useState<boolean>(false);
  const [togglesOutputDitheringTrueColor, setTogglesOutputDitheringTrueColor] = useState<boolean>(false);
  const [renderHiddenObjects, setrenderHiddenObject] = useState<boolean>(false);
  const [performDisplacementMapping, setPerformDisplacementMapping] = useState<boolean>(false);
  const [computeAdvancedLightng, setComputeAdvancedLightng] = useState<boolean>(false);
  const [converAreaLightsToPointsSources, setConverAreaLightsToPointsSources] = useState<boolean>(false);
  // Render configs
  // use ones that found in previous render jobs
  const [useMissingTextureFromPreviusRenderJob, setUseMissingTextureFromPreviusRenderJob] = useState<boolean>(true);
  const [renderPriority, setRenderPriority] = useState<number>(10);
  const [framePerTask, setFramePerTask] = useState<number>(1);
  // Maya config
  const [workspacePath, setWorkspacePath] = useState<string>(dirname(scenePath));
  const [multipassOutput, setMultipassOutput] = useState<string>("");
  
  useEffect(() => {
    setSoftware(SOFTWARES_VERSION.UNDEFINED)
    setRenderer(RENDERER_VERSIONS.UNDEFINED)
        /// select redprojeck directory
        const notMatchAny = workspace.map( ws => {
          const wsRegex = new RegExp(dirname(ws))
          const isMyWorkspace = wsRegex.test(item.key)
          if(isMyWorkspace) {
              setWorkspacePath(dirname(ws))
          }
        })


}, [item?.key])
  // handle on render
  async function handleRenderNow() {
    const ext = `.${outputFileName.split('.')[outputFileName.split('.').length - 1]}`
    const name = outputFileName.substr(0, outputFileName.length - ext.length)
    try {
      // reload window
      if(isNaN(resolutionWidth) ) throw {
        name : "Missing width field",
        message : "Width must be not empty"
      };
      if(isNaN(resolutionHeight) ) throw {
        name : "Missing height field",
        message : "Height must be not empty"
      };
      if(isNaN(startFrame) ) throw {
        name : "Missing field",
        message : "Start frame must be not empty"
      };
      if(isNaN(endFrame) ) throw {
        name : "Missing field",
        message : "End frame must be not empty"
      };
      if(isNaN(frameStep) ) throw {
        name : "Missing field",
        message : "Increment step must be not empty"
      };
      if(renderer === RENDERER_VERSIONS.UNDEFINED || software === SOFTWARES_VERSION.UNDEFINED) throw {
        name : "Missing field",
        message : "Software version & engine version must be selected"
      };
  
      if(DetectSoftwareFromPath(item.key)=== SOFTWARES_TYPE.AUTODESK_3DS_MAX) {
        if(outputFileName === "" && typeof item.extension !== "undefined" )
          throw {
          name : "Missing output file name",
          message : "Output file name must be not empty"
        }
        
        const ListAcceptedExtensions = ["png", "jpg", "jpeg", "gif", "uhd", "exr", "bmp", "cjpg", "cjp", "tga", "tif", "pic", "cxr", "rla"]
        const allowedRegex = `^(([\\d\\w\/\\._+ -]+)\\.([${ListAcceptedExtensions.map( ext => ext + "|").join('').slice(0,-1)}]*))$`
        const OutputRegex = new RegExp(allowedRegex, "gm");
        if(!OutputRegex.test(outputFileName)) throw {
          name : "Invalid Output extension",
          message : "Supported file types: png/tif/tga/bmp/jpg/exr and hdr."
        } 
      }
      // Start create job
      closeModal()
      Swal.fire({
          title: 'Render job created!',
          text: `Your render job: ${path.basename( item.key || "")} has been created!`,
          icon: 'success',
          confirmButtonText: 'OK',
          confirmButtonColor: '#218c61',
          preConfirm(inputValue) {
            
          },
      })
      if(DetectSoftwareFromPath(item.key) === SOFTWARES_TYPE.AUTODESK_MAYA) {
        await API.guard().post('/render-job/app/create-new',{
          "send_from_scene_analyze": "off",
          "scene_analyze_id": null,
          "job_gcpu": hardware,
          "scene_3dApp": DetectSoftwareFromPath(item.key),
          "selected_layers" : [],
          "override_camera" : "off" ,
          "render_cameras": "",
          "scene_name" :  item.name,
          "scenePath" : path.join("A:/", item.key) ,
          "working_folder_path" : path.join("A:/",workspacePath),
          "software":   software ,
          "renderer":  renderer ,
          "startFrame": startFrame,
          "endFrame": endFrame,
          "incrementStep": frameStep,
          "width": resolutionWidth,
          "height": resolutionHeight,
          "render_priority": renderPriority,
          "rf_use_default_asset_collection": useMissingTextureFromPreviusRenderJob? 1 : 0,
          "render_type": "Animation",
          "render_frame_per_machine": framePerTask,
          "client_id": await ipcRenderer.invoke("InvokeHandle::getPcName")
        })
      }
      if(DetectSoftwareFromPath(item.key) === SOFTWARES_TYPE.AUTODESK_3DS_MAX) {
        await API.guard().post('/render-job/app/create-new',{
          "send_from_scene_analyze": "off",
          "scene_analyze_id": null,
          "scene_3dApp": DetectSoftwareFromPath(item.key),
          "job_gcpu": hardware,
          "software": software ,
          "renderer": renderer ,
          "scenePath": path.join("A:/", item.key),
          "scene_name" :  item.name,
          "projectPath": path.dirname(require("path").join("N:/", item.key)),
          "working_folder_path" : path.join("A:/",workspacePath),
          "render_type": "Animation",
          "startFrame": startFrame,
          "endFrame": endFrame,
          "incrementStep": frameStep,
          "width": resolutionWidth,
          "height": resolutionHeight,
          "outputNameFile": name,
          "outputNameType": ext,
          "gamma_correction": useGammaCorrection,
          "gamma_bitmap_in": inputGamma,
          "gamma_bitmap_out": outputGamma,
          "render_priority": renderPriority,
          "render_frame_per_machine": framePerTask,
          "rf_use_default_asset_collection": useMissingTextureFromPreviusRenderJob? 1 : 0,
          "client_id": await ipcRenderer.invoke("InvokeHandle::getPcName")
        })
      }
      if(DetectSoftwareFromPath(item.key) === SOFTWARES_TYPE.C4D) {
        await API.guard().post('/render-job/app/create-new',{
          "send_from_scene_analyze": "off",
          "scene_analyze_id": null,
          "scene_3dApp": DetectSoftwareFromPath(item.key),
          "job_gcpu": hardware,
          "software": software ,
          "renderer": renderer ,
          "scenePath": path.join("A:/", item.key),
          "scene_name" :  item.name,
          "projectPath": path.dirname(require("path").join("N:/", item.key)),
          "working_folder_path" : path.join("A:/",workspacePath),
          "render_type": "Animation",
          "startFrame": startFrame,
          "endFrame": endFrame,
          "incrementStep": frameStep,
          "width": resolutionWidth,
          "height": resolutionHeight,
          "outputNameFile": name,
          "outputNameType": ext,
          "gamma_correction": useGammaCorrection,
          "gamma_bitmap_in": inputGamma,
          "gamma_bitmap_out": outputGamma,
          "render_priority": renderPriority,
          "render_frame_per_machine": framePerTask,
          "rf_use_default_asset_collection": useMissingTextureFromPreviusRenderJob? 1 : 0,
          "client_id": await ipcRenderer.invoke("InvokeHandle::getPcName"),
          "multipassoutput_folder" : multipassOutput
        })
      }
      
    }
    catch(e:unknown | any){
      Swal.fire({
        title: e.name,
        text: e.message ,
        icon: 'warning',
        confirmButtonText: 'OK',
        confirmButtonColor: '#218c61',
      })
    }
  }
  // still image resolution
  return (
    <div className="render-without-analyze">
      <div className="p-3 py-0">
        <div className="form-group mb-3">
          <small className="form-label semibold">
            Scene Path <span className="text-danger">*</span>
            <small className="text-muted text-capitalize">
              <i className="fad fa-folder ms-2" /> Your project saved in Farm
              hard drive as A:/.
            </small>
          </small>
          <div
            className="form-control-wrapper form-control-icon-left"
            style={{ position: "relative" }}
          >
            <input
              id="scene_path"
              type="text"
              className="form-control srf_require_field"
              disabled
              value={scenePath}
              placeholder="Scene Path"
              style={{ paddingLeft: "35px", paddingTop: "7px" }}
            />
            <div
              className="text-light"
              style={{
                width: "38px",
                height: "37px",
                lineHeight: "37px",
                textAlign: "center",
                position: "absolute",
                top: "0px",
              }}
            >
              <i className="fa fa-folder" />
            </div>
          </div>
        </div>
        <div className="form-group mb-3">
          <small className="form-label semibold">Select a hardware</small>
          <div className="hardware-select">
            <div
              className={`radio-button btn btn-dark me-3 ${
                hardware === Processor.CPU ? "active" : ""
              }`}
              onClick={() => {
                setHardware(Processor.CPU);
                setRenderer(RENDERER_VERSIONS.UNDEFINED);
              }}
            >
              <div className="form-check mb-0">
                <input
                  className="form-check-input"
                  id="CPU"
                  type="radio"
                  name="hardware"
                  checked={hardware === Processor.CPU}
                />
                <label className="form-check-label" htmlFor="CPU">
                  CPU
                </label>
              </div>
            </div>
            <div
              className={`radio-button btn btn-dark ${
                hardware === Processor.GPU ? "active" : ""
              }`}
              onClick={() => {
                setHardware(Processor.GPU);
                setRenderer(RENDERER_VERSIONS.UNDEFINED);
              }}
            >
              <div className="form-check mb-0">
                <input
                  className="form-check-input"
                  id="GPU"
                  type="radio"
                  name="hardware"
                  checked={hardware === Processor.GPU}
                />
                <label className="form-check-label" htmlFor="GPU">
                  GPU
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group row mb-3">
          <div className="form-group col-6">
            <small className="form-label semibold">
              Software Version <span className="text-danger">*</span>
            </small>
            <select
              className="form-select"
              onChange={(e) => {
                const value = e.target.value as SOFTWARES_VERSION;
                setSoftware(value);
              }}
            >
              <option value={SOFTWARES_VERSION.UNDEFINED}>Choose software version...</option>
              {
                supportedSoftwares.map((version, index) => {
                  if(version.type.includes(DetectSoftwareFromPath(scenePath))) {
                    return (
                      <option key={index}  value={version.software_version_value}>
                        {version.display_name}
                      </option>
                    )}})
             
                  }
       
            </select>
          </div>
          <Tippy disabled={software!==SOFTWARES_VERSION.UNDEFINED} content={"Please choose software version first!"}>
            <div className="form-group col-6">
              <small className="form-label semibold">Render Engine Version <span className="text-danger">*</span></small>
              <select
                className="form-select"
                disabled={software===SOFTWARES_VERSION.UNDEFINED}
                onChange={(e) => {
                  const value = e.target.value as RENDERER_VERSIONS;
                  setRenderer(value);
                }}
              >
                <option value={RENDERER_VERSIONS.UNDEFINED}>Choose engine version...</option>
                {
                  supportedSoftwares
                    .filter( rv => rv.software_version_value === software)
                    .map((renderer) => 
                      renderer.supported_engines.map((opt,key) => 
                        <optgroup key={opt.id} label={opt.name}>
                          {
                            opt.versions.map( (select) => {
                              const optProcessor = select.cpu ? Processor.CPU : Processor.GPU
                              return <option key={select.id} value={select.renderer_version_value} disabled={hardware!==optProcessor}>{select.name}</option>
                            })
                          }
                        </optgroup>
                      )
                    )
                }
              </select>
            </div>
          </Tippy>
        </div>
        {
          DetectSoftwareFromPath(item.key) === SOFTWARES_TYPE.AUTODESK_MAYA ? 
          <>
            <div className="form-group mb-3">
                <small className="form-label semibold">Project Directory<span className="text-danger">*</span></small>
                <select className="form-select" onChange={(e) =>setWorkspacePath(e.target.value)}>
                { workspace.map((i,key) => {
                        if(i!==''){
                            return (
                                <option key={key} selected={ path.dirname(i) === workspacePath} value={path.dirname(i)} >
                                        {path.dirname(i)}
                                </option>
                            )
                        }
                    })}
                    <option 
                        value={path.dirname(scenePath)} 
                        selected={workspacePath===path.dirname(scenePath) }>
                            {path.dirname(scenePath)}
                    </option>
                </select>
            </div>
            <div className="row">
              <div className="col-4" id="start_frame_form_group">
                <div className="form-group">
                  <label
                    id="start_frame_label"
                    className="form-label semibold"
                  >
                    Start Frame<span className="text-danger">*</span>
                  </label>
                  <div className="form-control-wrapper">
                    <input
                      id="start_frame"
                      type="number"
                      className="form-control srf_require_field"
                      placeholder="Start From"
                      min={0}
                      value={startFrame}
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        setStartFrame(value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-4" id="end_frame_form_group">
                <div className="form-group">
                  <label
                    id="end_frame_label"
                    className="form-label semibold"
                  >
                    End Frame <span className="text-danger">*</span>
                  </label>
                  <div className="form-control-wrapper">
                    <input
                      id="end_frame"
                      type="number"
                      min={0}
                      className="form-control srf_require_field"
                      placeholder="To"
                      value={endFrame}
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        setEndFrame(value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-4" id="frame_steps_form_group">
                <div className="form-group">
                  <label
                    id="frame_steps_label"
                    className="form-label semibold"
                  >
                    Frame Steps<span className="text-danger">*</span>
                  </label>
                  <div className="form-control-wrapper">
                    <input
                      id="frame_steps"
                      type="number"
                      min={1}
                      className="form-control srf_require_field"
                      placeholder="Frame Steps"
                      value={frameStep}
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        setFrameStep(value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <RenderSetting
              framePerTask={framePerTask}
              renderPriority={renderPriority}
              usePreviousAssetsIfMissing={
                useMissingTextureFromPreviusRenderJob
              }
              setFramePerTask={setFramePerTask}
              setRenderPriority={setRenderPriority}
              setUsePreviousAssetsIfMissing={
                setUseMissingTextureFromPreviusRenderJob
              }
            />
          </>
          : DetectSoftwareFromPath(item.key) === SOFTWARES_TYPE.AUTODESK_3DS_MAX ?
          <>
            <div className="form-group row mb-3">
              <div className="col-6">
                <div
                  className="nav nav-tabs"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <a
                    className="nav-item nav-link active"
                    id="v-pills-render-max"
                    data-bs-toggle="pill"
                    href="#render_type_animation"
                    role="tab"
                    aria-controls="render_type_animation"
                    aria-selected="true"
                  >
                    Animation
                  </a>
                  <a
                    className="nav-item nav-link"
                    id="v-pills-render-maya"
                    data-bs-toggle="pill"
                    href="#render_type_singlestill"
                    role="tab"
                    aria-controls="render_type_singlestill"
                    aria-selected="false"
                  >
                    Still Image
                  </a>
                </div>
              </div>

              <div className="col-6">
                {renderer.startsWith("vray") ? (
                  <>
                    <small className="form-label semibold">V-Ray Options</small>
                    <div className="form-check m-0">
                      <input
                        type="checkbox"
                        id="check-prepass-vray"
                        className="form-check-input"
                      />
                      <label
                        htmlFor="check-prepass-vray"
                        className="form-check-label"
                      >
                        GI: Mode Animation Prepass/Rendering (
                        <span
                          className="link-success"
                          onClick={() => {
                            electron.shell.openExternal(
                              "https://support.superrendersfarm.com/hc/en-us/articles/360040794194-Animation-prepass-rendering-with-V-Ray-Next"
                            );
                          }}
                        >
                          see more
                        </span>
                        )
                      </label>
                    </div>
                  </>
                ) : renderer.startsWith("corona") ? (
                  <>
                    <small className="form-label semibold">
                      Corona Options
                    </small>
                    <div className="form-check m-0">
                      <input
                        type="checkbox"
                        id="check-prepass-vray"
                        className="form-check-input"
                      />
                      <label
                        htmlFor="check-prepass-vray"
                        className="form-check-label"
                      >
                        GI: use UHD Cache Mode (
                        <span
                          className="link-success"
                          onClick={() => {
                            electron.shell.openExternal(
                              "https://support.superrendersfarm.com/hc/en-us/articles/360041315573-How-to-use-UHD-Cache-at-Super-Renders-Farm"
                            );
                          }}
                        >
                          see more
                        </span>
                        )
                      </label>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div
              className="form-group tab-content card card-body mb-3"
              id="v-pills-tabContent"
            >
              <div
                className="tab-pane fade show active mb-3"
                id="render_type_animation"
                role="tabpanel"
                aria-labelledby="render_type_animation-tab"
              >
                <div className="row">
                  <div className="col-4" id="start_frame_form_group">
                    <div className="form-group">
                      <label
                        id="start_frame_label"
                        className="form-label semibold"
                      >
                        Start Frame<span className="text-danger">*</span>
                      </label>
                      <div className="form-control-wrapper">
                        <input
                          id="start_frame"
                          type="number"
                          className="form-control srf_require_field"
                          placeholder="Start From"
                          value={startFrame}
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setStartFrame(value);
                          }}
                        />
                        <small className="text-muted" />
                      </div>
                    </div>
                  </div>
                  <div className="col-4" id="end_frame_form_group">
                    <div className="form-group">
                      <label
                        id="end_frame_label"
                        className="form-label semibold"
                      >
                        End Frame <span className="text-danger">*</span>
                      </label>
                      <div className="form-control-wrapper">
                        <input
                          id="end_frame"
                          type="number"
                          className="form-control srf_require_field"
                          placeholder="To"
                          value={endFrame}
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setEndFrame(value);
                          }}
                        />
                        <small className="text-muted" />
                      </div>
                    </div>
                  </div>
                  <div className="col-4" id="frame_steps_form_group">
                    <div className="form-group">
                      <label
                        id="frame_steps_label"
                        className="form-label semibold"
                      >
                        Frame Steps<span className="text-danger">*</span>
                      </label>
                      <div className="form-control-wrapper">
                        <input
                          id="frame_steps"
                          type="number"
                          min={1}
                          className="form-control srf_require_field"
                          placeholder="Frame Steps"
                          value={frameStep}
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setFrameStep(value);
                          }}
                        />
                        <small className="text-muted" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade mb-3"
                id="render_type_singlestill"
                role="tabpanel"
                aria-labelledby="render_type_singlestill-tab"
              >
                <div className="row">
                  <div className="col-6" id="image_width_form_group">
                    <div className="form-group">
                      <label
                        id="image_width_label"
                        className="form-label semibold"
                      >
                        Width <span className="text-danger">*</span>
                      </label>
                      <div className="form-control-wrapper">
                        <input
                          id="image_width"
                          type="number"
                          className="form-control"
                          placeholder="Image Width"
                        />
                        <small className="text-muted" />
                      </div>
                    </div>
                  </div>
                  <div className="col-6" id="image_height_form_group">
                    <div className="form-group">
                      <label
                        id="image_height_label"
                        className="form-label semibold"
                      >
                        Height <span className="text-danger">*</span>
                      </label>
                      <div className="form-control-wrapper">
                        <input
                          id="image_height"
                          type="number"
                          className="form-control"
                          placeholder="Image Height"
                        />
                        <small className="text-muted" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-label semibold">
                  Render Output File <span className="text-danger">*</span>
                </label>
                <div className="form-control-wrapper">
                  <input
                    value={outputFileName}
                    onChange={(e) => {
                      setOutputFileName(e.target.value);
                    }}
                    id="renderoutput_file"
                    type="text"
                    className="form-control"
                    placeholder="e.g: filename"
                  />
                  <small className="text-muted">
                    Output File allowed: png/tif/tga/bmp/jpg/exr and hdr
                  </small>
                </div>
              </div>
            </div>

            {/* Accordion */}

            <div className="form-group accordion pb-3" id="param-settings">
              {/* Gamma Corection*/}
              <div className="accordion-item">
                <h2 className="accordion-header" id="heading3">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse1"
                    aria-expanded="false"
                    aria-controls="collapse1"
                  >
                    Gamma Corection
                  </button>
                </h2>
                <div
                  id="collapse1"
                  className="accordion-collapse collapse"
                  aria-labelledby="heading3"
                  data-bs-parent="#param-settings"
                >
                  <div className="accordion-body">
                    <GammaCorrection
                      inputGamma={inputGamma}
                      outputGamma={outputGamma}
                      isUseGammaCorrection={useGammaCorrection}
                      changeInputGamma={(e) => setInputGama(e)}
                      changeOutputGamma={(e) => setOutputGamma(e)}
                      changeUseGammaCorrection={(e) => setUseGammaCorrection(e)}
                    />
                  </div>
                </div>
              </div>

              {/* Render Flags */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="heading5">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse2"
                    aria-expanded="false"
                    aria-controls="collapse2"
                  >
                    Render Flags
                  </button>
                </h2>
                <div
                  id="collapse2"
                  className="accordion-collapse collapse"
                  aria-labelledby="heading5"
                  data-bs-parent="#param-settings"
                >
                  <div className="accordion-body">
                    <RenderFlags
                      performColorCheck={performColorCheck}
                      useAsmosphericsEffects={useAsmosphericsEffects}
                      performRenderEffect={performRenderEffect}
                      togglesOutputDithering={togglesOutputDithering}
                      forceTwoSide={forceTwoSide}
                      useSuperBlack={useSuperBlack}
                      useAdvancedLight={useAdvancedLight}
                      togglesOutputDitheringTrueColor={
                        togglesOutputDitheringTrueColor
                      }
                      renderHiddenObjects={renderHiddenObjects}
                      performDisplacementMapping={performDisplacementMapping}
                      computeAdvancedLightng={computeAdvancedLightng}
                      converAreaLightsToPointsSources={
                        converAreaLightsToPointsSources
                      }
                      setPerformColorCheck={setPerformColorCheck}
                      setUseAsmosphericEffects={setUseAsmosphericEffects}
                      setPerformRenderEffect={setPerformRenderEffect}
                      setTogglesOutputDithering={setTogglesOutputDithering}
                      setForceTwoSide={setForceTwoSide}
                      setUseSuperBlack={setUseSuperBlack}
                      setUseAdvancedLight={setUseAdvancedLight}
                      setTogglesOutputDitheringTrueColor={
                        setTogglesOutputDitheringTrueColor
                      }
                      setrenderHiddenObject={setrenderHiddenObject}
                      setPerformDisplacementMapping={
                        setPerformDisplacementMapping
                      }
                      setComputeAdvancedLightng={setComputeAdvancedLightng}
                      setConverAreaLightsToPointsSources={
                        setConverAreaLightsToPointsSources
                      }
                    />
                  </div>
                </div>
              </div>

              {/* Render Setting */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="heading5">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse3"
                    aria-expanded="false"
                    aria-controls="collapse3"
                  >
                    Render Setting
                  </button>
                </h2>
                <div
                  id="collapse3"
                  className="accordion-collapse collapse"
                  aria-labelledby="heading5"
                  data-bs-parent="#param-settings"
                >
                  <div className="accordion-body">
                    <RenderSetting
                      framePerTask={framePerTask}
                      renderPriority={renderPriority}
                      usePreviousAssetsIfMissing={
                        useMissingTextureFromPreviusRenderJob
                      }
                      setFramePerTask={setFramePerTask}
                      setRenderPriority={setRenderPriority}
                      setUsePreviousAssetsIfMissing={
                        setUseMissingTextureFromPreviusRenderJob
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
          : DetectSoftwareFromPath(item.key) === SOFTWARES_TYPE.C4D ?  <>

            <div className="row mb-3">
              <div className="col-4" id="start_frame_form_group">
                <div className="form-group">
                  <label
                    id="start_frame_label"
                    className="form-label semibold"
                  >
                    Start Frame<span className="text-danger">*</span>
                  </label>
                  <div className="form-control-wrapper">
                    <input
                      id="start_frame"
                      type="number"
                      className="form-control srf_require_field"
                      placeholder="Start From"
                      value={startFrame}
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        setStartFrame(value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-4" id="end_frame_form_group">
                <div className="form-group">
                  <label
                    id="end_frame_label"
                    className="form-label semibold"
                  >
                    End Frame <span className="text-danger">*</span>
                  </label>
                  <div className="form-control-wrapper">
                    <input
                      id="end_frame"
                      type="number"
                      className="form-control srf_require_field"
                      placeholder="To"
                      value={endFrame}
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        setEndFrame(value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-4" id="frame_steps_form_group">
                <div className="form-group">
                  <label
                    id="frame_steps_label"
                    className="form-label semibold"
                  >
                    Frame Steps<span className="text-danger">*</span>
                  </label>
                  <div className="form-control-wrapper">
                    <input
                      id="frame_steps"
                      type="number"
                      min={1}
                      className="form-control srf_require_field"
                      placeholder="Frame Steps"
                      value={frameStep}
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        setFrameStep(value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div id="multipassoutput_folder_form_group mb-3" className="form-group">
              <label id="multipassoutput_folder_label" className="form-label semibold">Multipass Prefix</label>
              <div className="form-control-wrapper">
                <input id="multipassoutput_folder" 
                  type="text" 
                  className="form-control" 
                  placeholder="Enter multipass prefix name or leave blank if multipass not use" 
                  value={multipassOutput} 
                  onChange={e=>setMultipassOutput(e.target.value)}
                />
                <small className="text-muted">Multipass (if have) will be returned in GoogleDrive/SuperRendersOutput/[Render Job Folder]</small>
              </div>
            </div>         
            <RenderSetting
              framePerTask={framePerTask}
              renderPriority={renderPriority}
              usePreviousAssetsIfMissing={
                useMissingTextureFromPreviusRenderJob
              }
              setFramePerTask={setFramePerTask}
              setRenderPriority={setRenderPriority}
              setUsePreviousAssetsIfMissing={
                setUseMissingTextureFromPreviusRenderJob
              }
            />
          

          </>
          : <></>
        }
      </div>
      <div className="fixed-bottom">
        <div className="d-flex m-2">
          <div className="ms-auto">
            <button className="btn btn-sm " onClick={() => closeModal()}>Cancel</button>
            <button className="btn btn-sm btn-success ms-2" type="submit"
                onClick={()=>handleRenderNow()} >Render Now
            </button>
          </div>
        </div>
      </div>
    </div >
  );
}


export default connect(mapState, {})(RenderWithoutAnalyze)