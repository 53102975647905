import Electron from 'electron'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Logger from '../../Helpers/Log'
import AnalysisResults from '../../components/RenderNowComponent/AnalysisResults'
import Max from '../../components/RenderNowComponent/ParamSettings/Max'
import { SceneInterface, _3dSoftwareInterface } from '../../Interfaces/Renderer'
import { RootState } from '../../stores/stores'
import './render-now.scss'
import { SceneResponseInterface, RenderOptionsInterface } from './RenderNow.interface'
import { VrayOptions } from './VrayOptions'
import { RenderNowMapDispatchs } from './RenderNow.dispatch'
import { parseInt } from 'lodash'
import { RenderNow } from './RenderNow.handle'
import { IpcSender } from '../../Interfaces/IpcRenderer.enum'
import ESImport from '../../esimport'
const { ipcRenderer } = window.electron;


// Map redux store states to component props
// If redux store has been changed, this component will be re-rendered
const MapStates = (States: RootState) => ({
})

// Mapping dispatcher to component props
// In component, can call to dispatch using props of component.

// Render component to show settings to client render
// Components interfaces
interface ComponentProps {
     RenderScene: RenderOptionsInterface
}
type MapStateInterface = ReturnType<typeof MapStates>
type MapDispatchInterface = typeof RenderNowMapDispatchs
export type RenderNowProps = MapStateInterface & MapDispatchInterface & ComponentProps
function RenderNowComponent(Renders: RenderNowProps) {
     const [navPill, setNavPill] = useState(2)
     // Get Scene json from prop
     const [RenderScene, setRenderScene] = useState<RenderOptionsInterface>(Renders.RenderScene)

     // Run only one time at start rendering this component
     useEffect(() => {
          // listen for ipcMain send scene json to view
          ipcRenderer.on("data", (event: Electron.Event, SceneData: RenderOptionsInterface) => {
               // log scene data
               Logger("REDUX:SUCCESS", "Scene data received")
               console.log(JSON.stringify(SceneData))

               // Set scene data to redux store
               Renders.SetScene(SceneData)

               // Rerender scene
               setRenderScene(SceneData)
          })
     }, [])

     function CloseWindow() {
          ipcRenderer.send("Sender::CloseThisWindow")
     }

     // Vray options make call to callback to update scene params
     function UpdateSceneData(updater: SceneInterface) {
          // check is scene null
          if (RenderScene) {
          }
     }

     if (!RenderScene) {
          return (
               <>
                    <div className="title d-flex">
                         <div className="drag"></div>
                         <button className="btn button-close" type="button" onClick={CloseWindow} >
                              <i className="fal fa-times"></i>
                         </button>
                    </div>
                    <div style={{ height: "calc(100vh - 38px)" }}>
                         <div className="d-flex h-100">
                              <div className="h1 m-auto">No scene information available !</div>
                         </div>
                    </div>
               </>
          )
     }

     return (
          <div className="analyzed-scene">

               <div className="render-now">
                    <div className="title d-flex">

                         <div className="my-auto drag">
                              <h6 className="mb-0 ms-3 py-2">Start Render Job: {RenderScene.body.scene.params.scene_param.sceneNameClean}</h6>
                         </div>
                         <div className="drag col"></div>
                         <button className="btn button-close" type="button" onClick={CloseWindow} >
                              <i className="fal fa-times"></i>
                         </button>
                    </div>
                    <ul className="nav nav-pills" id="RenderNowNav" role="tablist">
                         <li className="nav-item" role="presentation">
                              <button className={`nav-link fw-bold ${navPill === 1 ? "active" : ""}`} type="button" onClick={() => setNavPill(1)} >ANALYSIS RESULTS</button>
                         </li>
                         <li className="my-auto mx-2">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" /></svg>
                         </li>
                         <li className="nav-item" role="presentation">
                              <button className={`nav-link fw-bold ${navPill === 2 ? "active" : ""}`} type="button" onClick={() => setNavPill(2)}>SCENE PARAMS</button>
                         </li>

                         <li className="my-auto ms-auto">
                              <div className="badge bg-success-bright me-2">Software Version : {RenderScene.body.version}</div>
                              <div className="badge bg-success-bright me-2">Renderer/Plugins version: {RenderScene.body.scene.params.scene_param.render_engine}</div>
                         </li>
                    </ul>
                    <div className="RenderSetting tab-content">
                         {
                              navPill === 1 ? (
                                   <div className="tab-pane show active">
                                        <AnalysisResults scene_analyze={RenderScene.body.scene} />
                                   </div>
                              ) : (
                                   <div className="tab-pane show active">
                                        <Max />
                                   </div>
                              )
                         }
                    </div>
                    <div className="fixed-bottom">
                         <div className="d-flex m-2">
                              <div className="ms-auto">
                                   {
                                        navPill === 1 ? (<>
                                             <button type="button" className="btn btn-sm me-2 close-back-btn" onClick={CloseWindow}>Close</button>
                                             <button type="button"
                                                  className="btn btn-sm btn-success btn-continue" onClick={() => setNavPill(2)} >Continue</button>
                                        </>)
                                             : (<>
                                                  <button type="button" className="btn btn-sm me-2 close-back-btn" onClick={() => setNavPill(1)} >Back</button>
                                                  <button type="button" className="btn btn-sm btn-success btn-start-render ms-auto" onClick={RenderNow} >Start Render Now</button>
                                             </>)
                                   }

                              </div>
                         </div>
                    </div>
               </div>
          </div>
     )
}

// Export using redux store to re-render via dispatcher
// using React.memo to memories static variables, then we dont need to re-render a component if that component's prop
// was not changed
export default connect(MapStates, RenderNowMapDispatchs)(React.memo(RenderNowComponent))