/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect,useRef,useState } from 'react'
import './titlebar.scss'
import logo from '../../../../assets/logo.png'
import AccountTitleBar from './AccountTitleBar';
import { IndexOf } from '../../../../Interfaces/App.enums';
import ESImport from '../../../../esimport';
import { IpcSender } from '../../../../Interfaces/IpcRenderer.enum';
import { Stores } from '../../../../stores/stores';

const electron = window.electron;
const { ipcRenderer } = window.electron;

interface TitlebarProps {
}
function usePrevious<Type>(value:Type) {
    const ref = useRef<Type>();
    useEffect(() => {
      ref.current = value;
      
    });
    return ref.current;
}

export default function Titlebar({}: TitlebarProps) {

    return (
        ["/tray", "/login" , "/setting"].indexOf(window.location.pathname) == IndexOf.NotFound ?
        <div className="title-bar">
            <div className="d-flex">
                <div className="title-brand d-flex">
                    <div className="logo" style={{backgroundImage:`url("${logo}")`}}/>
                    <div className="mt-2">
                        <span className="logo-title-bar-text-super">SUPER</span>
                        <span className="logo-title-bar-text-renders">RENDERS</span>
                    </div>
                </div>
            </div>
            <div className="drag col"></div>
            <button className="titlebar-button helpBtn" type="button" onClick={() => { 
                    electron.shell.openExternal('https://support.superrendersfarm.com/hc/en-us/sections/360007451455-superrenders-client-app')
                }}>
                <i className="fas fa-question-circle"></i> How it works
                </button>
            <AccountTitleBar  />
            <div style={{width:138}}></div>

        </div>
        :
        <div></div>
    )
}