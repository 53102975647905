import { IndexOf, PHPBOOL, RenderEngineVersion } from 'Interfaces/App.enums'
import { ICoronaOptions } from 'Interfaces/Renderer'
import React from 'react'
import { connect } from 'react-redux'
import { RenderOptionsInterface } from 'screens/RenderNow/RenderNow.interface'
import { RenderReducerActionEnum } from 'stores/reducers/render.interface'
import { RootState } from 'stores/stores'
import AutoGIMode from '../../components/AutoGIMode'
import Tips from '../../components/Tips'
import GlobalIllumination from './GlobalIllumination'
import './corona.scss'


const mapStates = (states: RootState) => ({
     Render: states.Render
})
const coronaDispatchMapper = {
     updateCoronaOption: <Type extends any>(name: keyof ICoronaOptions, value: Type) => ({ type: RenderReducerActionEnum.SetCoronaOptions, value: { name, value } })
}
type CoronaProps = ReturnType<typeof mapStates> & typeof coronaDispatchMapper & {
     renderOptions: RenderOptionsInterface
}
function Corona(Props:CoronaProps) {
     const Scene = Props.Render?.body?.scene
     if (Scene && !Scene?.params?.scene_param?.render_engine.toLowerCase().includes('corona') ) {
          return <div></div>
     }

     if (JSON.stringify(Props.renderOptions.body.scene.params.corona_option) == "{}") {
          return <></>
     }

     return (
          <div className="accordion-item">

               <h2 className="accordion-header" id="heading0">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse0" aria-expanded="false" aria-controls="collapse0">
                         Corona Option
                    </button>
               </h2>
               <div id="collapse0" className="accordion-collapse collapse" aria-labelledby="heading0" data-bs-parent="#param-settings">
                    <div className="accordion-body">
                         {/* GI MODE */}
                         <AutoGIMode 
                              giMode={Props?.Render?.body?.scene?.params?.corona_option?.user_select_auto_mode || false}
                              setGiMode={(user_select_auto_mode: PHPBOOL) => {
                                   console.log(user_select_auto_mode);
                                   Props.updateCoronaOption("user_select_auto_mode", user_select_auto_mode)
                              }} />

                         {/* Tips */}
                         <Tips />

                         {/* Global Illumination */}
                         {
                              !Props?.Render?.body?.scene?.params?.corona_option?.user_select_auto_mode ? 
                              <GlobalIllumination renderOptions={Props.renderOptions} /> : <></>
                         }

                    </div>
               </div>

          </div>
     )
}

export default connect(mapStates, coronaDispatchMapper)(Corona)