export enum ENotification {
    SetLoadTime = "Notification::SetLoadTime",
    SendMessage = "Notification::SendMessage",
}

export interface INotificationDispatch<T> {
    type : ENotification,
    payload : T | any
}

export interface INotification {
    isLoading : boolean
    loadTime : number
    message ?:string
}