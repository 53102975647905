import { Supported3dApps } from './SceneAnalysis.interface';
import { ExrMainFormat, ExrType, PngType } from './../../Interfaces/Renderer';
import axios from "axios";
import { Stores } from "../../stores/stores";
import { HttpSceneBody, RenderDatabaseQuery, RenderConfigurationInterface, JwtPayload, SceneBodyInterface} from './RenderNow.interface';
import * as path from "path"
import { CloudType, VrayGlobalIlluminationPrimaryName, VrayGIPrimaryType, VrayGlobalIlluminationSecondaryName, VrayGISecondaryType, IrradMapMode, IrradMapModeName, JobType, LightCacheMode, FarmManagerSoftware, SceneAnalyzeStatus, RenderProcessor, IndexOf, PHPBOOL} from '../../Interfaces/App.enums';
import Logger from "../../Helpers/Log";
import ESImport from "../../esimport";
import { IpcSender } from "../../Interfaces/IpcRenderer.enum";
import * as jwt from 'jsonwebtoken'
import { IWebUserConfig } from '../../Interfaces/User';
import { GetWaiter } from '../../Helpers/Waiter';
import camerasUtils from 'components/RenderNowComponent/ParamSettings/components/Camera/camerasUtils';
import { API } from 'apis/base.api';
import Swal from 'sweetalert2'
import { CloseScreenModal } from 'Helpers/Modal';
const { ipcRenderer } = window.electron;

    // // Check is this job need to be rendering with prepass
    function AnimationPrepassMode( Job : SceneBodyInterface) : PHPBOOL {
      // If this job is using Vray Engine
      // Check for using prepass rendering
      // And check is custom GI mode was on
      if( Job.scene.params.vray_option?.gi_on ||
          (
              Job.scene.params.vray_option?.gi_primary_type.gi_primary_type_key == VrayGIPrimaryType.IrradianceMap &&
              [IrradMapMode.FromFile , IrradMapMode.AnimationRendering , undefined].indexOf(Job.scene.params.vray_option.adv_irradmap_mode.adv_irradmap_mode_key) == IndexOf.NotFound
          ) ||
          (
              Job.scene.params.vray_option?.lightcache_mode.lightcache_mode_key != LightCacheMode.FromFile &&
              Job.scene.params.vray_option?.gi_primary_type.gi_primary_type_key == VrayGIPrimaryType.LightCache ||
              Job.scene.params.vray_option?.gi_secondary_type.gi_secondary_type_key == VrayGISecondaryType.LightCache
          )
          ){
              return PHPBOOL.True
          }
  // Use prepass for all other jobs type not supported yet
  return PHPBOOL.False
}
export async function StartRenderAnalyzedSceneNow( ) {
  const {FarmDirectory,body,_3dEngine,render_configuration,Analysis} = Stores.getState().Render
  const fullPathScene = path.join("A:", Analysis?.scene_path  || "")
  const projectPath = path.dirname(fullPathScene) 
  try {
    if (Analysis?.software_app === Supported3dApps.Max) {
      const sender_json_skeleton_3dmax:any = {
        "user_name": Analysis?.user_name ,
        "send_from_scene_analyze": "on",
        "scene_analyze_id": Analysis?.id,
        "job_gcpu": Analysis?.gcpu_type,
        "scene_3dApp": Analysis?.software_app,
        "render_cameras": camerasUtils.getCameras() ,
        "scene_name" :  path.basename( Analysis?.scene_path || ""),
        "scenePath" : fullPathScene,
        "working_folder_path" : path.join("A:/", Analysis?.working_path_v2 || ""),
        "software":  Analysis?.software_version ,
        "renderer":  Analysis?.renderer_version ,
        "startFrame": body.scene.params.scene_param.startFrame,
        "endFrame": body.scene.params.scene_param.endFrame,
        "incrementStep": body.scene.params.scene_param.incrementStep,
        "width": body.scene.params.scene_param.width,
        "height": body.scene.params.scene_param.height,
        "render_priority": render_configuration.render_priority || 10,
        "rf_use_default_asset_collection": render_configuration.use_missing_assets || 0,
        "render_type": "Animation",
        "render_frame_per_machine": render_configuration.frame_per_task,
        "outputNameFile": body.scene.params.output_param.outputNameFile,
        "outputNameType": body.scene.params.scene_param.outputNameType,
        "gamma_correction": body.scene.params.output_param.gamma_correction,
        "gamma_bitmap_in": body.scene.params.output_param.input_gamma,
        "gamma_bitmap_out": body.scene.params.output_param.output_gamma,
        "vray_option": body.scene.params.vray_option || "",
        "corona_option": body.scene.params.corona_option || "",
        "arnold_option": body.scene.params.arnold_option || "",
        "client_id": await ipcRenderer.invoke("InvokeHandle::getPcName"),
      }
      // Validate
      if(isNaN(body.scene.params.scene_param.width) ) throw {
        name : "Missing width field",
        message : "Width must be not empty"
      };
      if(isNaN(body.scene.params.scene_param.height) ) throw {
        name : "Missing height field",
        message : "Height must be not empty"
      };
      if(isNaN(body.scene.params.scene_param.startFrame) ) throw {
        name : "Missing field",
        message : "Start frame must be not empty"
      };
      if(isNaN(body.scene.params.scene_param.endFrame) ) throw {
        name : "Missing field",
        message : "End frame must be not empty"
      };
      if(isNaN(body.scene.params.scene_param.incrementStep) ) throw {
        name : "Missing field",
        message : "Increment step must be not empty"
      };
      if(body.scene.params.output_param.outputNameFile === "" && typeof Analysis?.software_app !== "undefined" )
          throw {
          name : "Missing output file name",
          message : "Output file name must be not empty"
      }
      if(body.scene.params.vray_option?.gi_on){
        sender_json_skeleton_3dmax.mod_animation_prepass = AnimationPrepassMode(body);
      }
      // Render flag check
      if (render_configuration.rf_use_atmospherics_effects == PHPBOOL.On) sender_json_skeleton_3dmax.Atmospherics = true
      if (render_configuration.rf_perform_render_effects == PHPBOOL.On) sender_json_skeleton_3dmax.Effects = true
      if (render_configuration.rf_perform_displacement_mapping == PHPBOOL.On) sender_json_skeleton_3dmax.Displacements = true
      if (render_configuration.rf_perform_color_check == PHPBOOL.On) sender_json_skeleton_3dmax.VideoColorCheck = true
      if (render_configuration.rf_render_hidden_objects == PHPBOOL.On) sender_json_skeleton_3dmax.HiddenGeometry = true
      if (render_configuration.rf_convert_area_lights_to_points_sources == PHPBOOL.On) sender_json_skeleton_3dmax.AreaLights = true
      if (render_configuration.rf_force_two_sided == PHPBOOL.On) sender_json_skeleton_3dmax.TwoSided = true
      if (render_configuration.rf_use_super_black == PHPBOOL.On) sender_json_skeleton_3dmax.SuperBlack = true
      if (render_configuration.rf_use_advanced_lighting == PHPBOOL.On) sender_json_skeleton_3dmax.UseAdvLighting = true
      if (render_configuration.rf_compute_advanced_lighting == PHPBOOL.On) sender_json_skeleton_3dmax.ComputeAdvLighting = true

      console.log('json',sender_json_skeleton_3dmax)
      await API.guard().post('/render-job/app/create-new',sender_json_skeleton_3dmax)
    }
    if (Analysis?.software_app === Supported3dApps.Maya) {
      const sender_json_skeleton_maya:any = {
        "user_name": Analysis?.user_name ,
        "send_from_scene_analyze": "on",
        "scene_analyze_id": Analysis?.id,
        "job_gcpu": Analysis?.gcpu_type,
        "scene_3dApp": Analysis?.software_app,
        "selected_layers" : camerasUtils.getCameras(),
        "override_camera" : body===undefined? "off": body?.scene?.cameras?.filter(cam => cam.selected).length == 0 ? "off": "on" ,
        "render_cameras":  body===undefined? "": body?.scene?.cameras?.filter(cam => cam.selected).find(cam=>cam.name)?.name ,
        "scene_name" :  path.basename( Analysis?.scene_path || ""),
        "scenePath" : fullPathScene ,
        "working_folder_path" : path.join("A:/", Analysis?.working_path_v2 || ""),
        "software":   Analysis?.software_version ,
        "renderer":  Analysis?.renderer_version ,
        "startFrame": body.scene.params.scene_param.startFrame,
        "endFrame": body.scene.params.scene_param.endFrame,
        "incrementStep": body.scene.params.scene_param.incrementStep,
        "width": body.scene.params.scene_param.width,
        "height": body.scene.params.scene_param.height,
        "render_priority": render_configuration.render_priority || 10,
        "rf_use_default_asset_collection":  render_configuration.use_missing_assets || 0,
        "render_type": "Animation",
        "render_frame_per_machine": render_configuration.frame_per_task,
        "client_id": await ipcRenderer.invoke("InvokeHandle::getPcName")
      }
      console.log('json',sender_json_skeleton_maya)
      // Validate
      if(isNaN(body.scene.params.width) ) throw {
        name : "Missing width field",
        message : "Width must be not empty"
      };
      if(isNaN(body.scene.params.height) ) throw {
        name : "Missing height field",
        message : "Height must be not empty"
      };
      if(isNaN(body.scene.params.startFrame) ) throw {
        name : "Missing field",
        message : "Start frame must be not empty"
      };
      if(isNaN(body.scene.params.endFrame) ) throw {
        name : "Missing field",
        message : "End frame must be not empty"
      };
      if(isNaN(body.scene.params.incrementStep) ) throw {
        name : "Missing field",
        message : "Increment step must be not empty"
      };

      await API.guard().post('/render-job/app/create-new',sender_json_skeleton_maya)
    }
    if (Analysis?.software_app === Supported3dApps.C4d) {
      const sender_json_skeleton_c4d:any = {
        "user_name": Analysis?.user_name ,
        "send_from_scene_analyze": "on",
        "scene_analyze_id": Analysis?.id,
        "job_gcpu": Analysis?.gcpu_type,
        "scene_3dApp": Analysis?.software_app,
        "render_type": "Animation",
        "scene_name" :  path.basename( Analysis?.scene_path || ""),
        "scenePath" : fullPathScene,
        "software":   Analysis?.software_version ,
        "renderer":  Analysis?.renderer_version ,
        "startFrame": body.scene.params.scene_param.startFrame,
        "endFrame": body.scene.params.scene_param.endFrame,
        "incrementStep": body.scene.params.scene_param.incrementStep,
        "width": body.scene.params.scene_param.width,
        "height": body.scene.params.scene_param.height,
        "working_folder_path" : path.join("A:/", Analysis?.working_path_v2 || ""),
        "render_cameras": [],
        "render_layers": [],
        "render_takes" : body.scene.params.scene_param.take,
        "outputNameFile": path.basename(body.scene.params.output_param.output_main_file_name),
        "outputNameType": body.scene.params.output_param.output_format_extension,
        "outputFile": path.basename(body.scene.params.output_param.output_main_file_name),
        "outputFolder": Analysis.deadline_job_id,
        "output_format_extension": body.scene.params.output_param.output_format_extension,
        "output_main_enabled" : body.scene.params.output_param.output_main_enabled ,
        "output_main_file_name": path.basename(body.scene.params.output_param.output_main_file_name),
        "output_multipass_enabled" :   body.scene.params.output_param.output_multipass_enabled,
        "output_multipass_file_name":  body.scene.params.output_param.output_multipass_file_name,
        "gamma_correction": false,
        "gamma_bitmap_in": 2.2,
        "gamma_bitmap_out": 1,
        "render_priority": render_configuration.render_priority || 10,
        "render_frame_per_machine": render_configuration.frame_per_task,
        "rf_use_default_asset_collection": render_configuration.use_missing_assets || 0,
        "srf_user_accepted_to_use_default_asset": render_configuration.use_missing_assets === 0 ? false : true || false ,
        "vray_option": [],
        "arnold_option": [],
        "corona_option": [],
        "client_id": await ipcRenderer.invoke("InvokeHandle::getPcName"),
      }
      // Validate
      if(isNaN(body.scene.params.scene_param.width) ) throw {
        name : "Missing width field",
        message : "Width must be not empty"
      };
      if(isNaN(body.scene.params.scene_param.height) ) throw {
        name : "Missing height field",
        message : "Height must be not empty"
      };
      if(isNaN(body.scene.params.scene_param.startFrame) ) throw {
        name : "Missing field",
        message : "Start frame must be not empty"
      };
      if(isNaN(body.scene.params.scene_param.endFrame) ) throw {
        name : "Missing field",
        message : "End frame must be not empty"
      };
      if(isNaN(body.scene.params.scene_param.incrementStep) ) throw {
        name : "Missing field",
        message : "Increment step must be not empty"
      };
      // if(body.scene.params.output_param.output_main_file_name === "" )
      //     throw {
      //     name : "Missing output file name",
      //     message : "Output file name must be not empty"
      // }
      console.log('json',sender_json_skeleton_c4d)
      await API.guard().post('/render-job/app/create-new',sender_json_skeleton_c4d)
    }
    // sweetalert2
    Swal.fire({
      title: 'Render job created!',
      text: `Your render job: ${path.basename(path.basename( Analysis?.scene_path || ""))} has been created!`,
      icon: 'success',
      confirmButtonText: 'OK',
      confirmButtonColor: '#218c61',
      preConfirm() {
        CloseScreenModal()
      },
      })
  }
  catch(e:unknown | any){
    Swal.fire({
      title: e.name,
      text: e.message ,
      icon: 'warning',
      confirmButtonText: 'OK',
      confirmButtonColor: '#218c61',
    })
  }
}
