import React, { useState } from 'react'
import './gamma-correction.scss'

type GammaCorrectionProps = {
     inputGamma: number,
     outputGamma: number,
     isUseGammaCorrection: boolean,
     changeInputGamma: (value: number) => void,
     changeOutputGamma: (value: number) => void,
     changeUseGammaCorrection: (value: boolean) => void
}

function GammaCorrection(Renders: GammaCorrectionProps) {

     return (
          <>
               <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch" id="gamma" onClick={() => Renders.changeUseGammaCorrection(!Renders.isUseGammaCorrection)}
                         checked={Renders.isUseGammaCorrection}
                    />
                    <label className="form-check-label" htmlFor="gamma">Gamma Correction</label>
               </div>
               {
                    Renders.isUseGammaCorrection ? <div className="row">
                         <div className="form-group col-6">
                              <label htmlFor="input_gamma" className="control-label">
                                   Input Gamma*
                              </label>
                              <input id="input_gamma" type="number"
                                   className="form-control srf_require_field" placeholder="Input Gamma"
                                   defaultValue="2.2"
                                   onChange={e => {
                                        Renders.changeInputGamma(parseFloat(e.target.value));
                                   }}
                                   value={Renders.inputGamma}
                              />
                         </div>
                         <div className="form-group col-6">
                              <label htmlFor="output_gamma" className="control-label">
                                   Output Gamma*
                              </label>
                              <input id="output_gamma" type="number"
                                   className="form-control srf_require_field" placeholder="Output Gamma"
                                   defaultValue={1}
                                   onChange={e => {
                                        Renders.changeOutputGamma(parseFloat(e.target.value));
                                   }}
                                   value={Renders.outputGamma} />
                         </div>
                    </div> : <></>
               }
          </>
     )
}

export default GammaCorrection