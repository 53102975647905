import { RenderReducerActionEnum } from "../../../../../stores/reducers/render.interface";

export const VrayDispatchMapper = {
    updateOutputProperties : <Type extends any >(name : string , value: Type ) => ({ type : RenderReducerActionEnum.UpdateOutputProperties, value : {name,value} }),
    updateVrayProps : <Type extends any >(name : string , value: Type ) => ({ type : RenderReducerActionEnum.SetVrayOptions, value : {name,value} }),
    SetImageFilter: (value : boolean) =>({ type : RenderReducerActionEnum.SetImageFilter, value : value }),
    SetIsRenderRawImageFile : (isRenderRawImageFile : boolean) => ({ type : RenderReducerActionEnum.SetIsRenderRawImageFile, value : isRenderRawImageFile }),
    SetVrayRawFileName : (filename : string) => ({ type : RenderReducerActionEnum.SetVrayRawFileName, value : filename }),
    SetSeparateRenderChannel : (splitChannel : boolean) => ({ type : RenderReducerActionEnum.SeparateRenderChannel, value : splitChannel }),
    SetSeparateFileName : (filename : string) => ({ type : RenderReducerActionEnum.SetSplitChannelFileName, value : filename }),
    SetIsEnableBuildInFrameBuffer: (isUseBuildInFrameBuffer : boolean) => ({ type : RenderReducerActionEnum.IsEnableBuildInFrameBuffer, value : isUseBuildInFrameBuffer }),
}