import { IRender, ReduxRenderAction, RenderListActionType, RenderListInitialState } from "./render_list.interface"
const AnalysisInitialState : RenderListInitialState = {
    isLoading : false,
    query_results : [],
    query : "",
    items : [],
}

export const RenderListReducer =  (state = AnalysisInitialState, action : ReduxRenderAction<any> ) => {
    var NewState = state
    switch (action.type) {

        case RenderListActionType.setJobStatus:
            // update by job id
            const _itemIndex = state.items.findIndex(item => item.id == action.payload.id)
            if(_itemIndex != -1){
                NewState.items[_itemIndex].job_status = action.payload.status
                // update num nodes
                if(action.payload.nodes){
                    NewState.items[_itemIndex].num_nodes_run = action.payload.nodes
                }
                if(action.payload.estimated_remaining_time){
                    NewState.items[_itemIndex].estimated_remaining_time = action.payload.estimated_remaining_time
                }
                // render_progress
                if(action.payload.render_progress){
                    NewState.items[_itemIndex].render_progress = action.payload.render_progress
                }

                // job credits
                if(action.payload.job_credits){
                    NewState.items[_itemIndex].render_credits_v2 = action.payload.job_credits
                }
            }
            return { ...state, ...NewState }

        // Set loading state
        case RenderListActionType.SetLoading:
            NewState.isLoading = action.payload as boolean
            return { ...state ,...NewState};

        // Render Items
        case RenderListActionType.SetItems :
            NewState.items = action.payload as IRender[]
            return {...state,NewState}

        case RenderListActionType.Load :
            return {...state}

        // Set number node rendering for specified job
        case RenderListActionType.SetNode :
            return {...state}
        default:
            return state
    }
}