import axios from "axios"
import { API } from "../Interfaces/Api.enum"
import { ApiResponse, ApiStatus, ResponseCode } from "./default"
const Authenticated = { Authorization : "Bearer " + localStorage.getItem("access_token")}
export async function DeleteJob(job_id : number) : Promise<any> {
    try{
        // Try delete a render job
        const response = await axios.delete<{ status : ApiStatus }>( `${API.DeleteRenderJob}/${job_id}`, { headers : Authenticated })
        return response
    }catch( Error) {
        return {
            status : ApiStatus.Error,
            code : ResponseCode.Success,
            message : Error
        }
    }
}

export async function ResumeJob( job_id : number ) : Promise<any> {
    try {
        // Try to resume job
        const response = await axios.post<{ status : ApiStatus }>( API.ResumeRenderJob, {
            job_id
        }, { headers : Authenticated })
        return response
    }catch{
        return {
            status : ApiStatus.Error,
            code : ResponseCode.Success,
            message : "",
        }
    }
}

export async function PauseJob( job_id : number ) : Promise<any> {
    try {
        // Try to pause job
        const response = await axios.post<{ status : ApiStatus }>( API.PauseRenderJob, {
            job_id
        }, { headers : Authenticated })
        return response
    }catch{
        return {
            status : ApiStatus.Error,
            code : ResponseCode.Success,
            message : "",
        }
    }
}