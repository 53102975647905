import React from 'react'
import './tips.scss'

interface TipsProps {
}

export default function Tips({}: TipsProps) {
     return (
          <div className="alert alert-success" role="alert">
               <h5 className="alert-heading">System will render your scene with 2 steps:</h5>
               <div className="ms-2">
                    - Step 1: render presspass mode <br/>
                    - Step 2: render final step
               </div>
               {/* <a href="#" className="link-success"><small>(see more how it work)</small></a> */}
          </div>
     )
}