import { ENotification, INotification, INotificationDispatch } from "./nofification.interface"

const initialState : INotification = {
    isLoading: false,
    loadTime : 0,
    message : "",
}

export function NotificationReducer(state = initialState, { type, payload } :INotificationDispatch<any> ){
    var MiddlwareState = state
    switch (type) {

        // Update loading time
        case ENotification.SetLoadTime:
            MiddlwareState.loadTime = payload
            MiddlwareState.message =  payload > 10000 ? 'Detected your internet connection is too slow, please review your connection (recommend to use cable instead of wifi)' : ""
            return { ...state, ...MiddlwareState }

        case ENotification.SendMessage:
            MiddlwareState.message = payload as string
            return { ...state, ...MiddlwareState }

        default:
            return state
    }
}
