import * as React from 'react';
import { modalScreenActions } from '../stores/reducers/screen_modal.interface';
import { Stores } from '../stores/stores';

/**
 * 
 * @param Node React Node
 */
export async function RenderScreenModal(Node :React.ReactNode){
    Stores.dispatch({ type: modalScreenActions.RenderModal , payload : { show : true , Node } });
}

export function CloseScreenModal(){
    console.log("close")
    Stores.dispatch({ type: modalScreenActions.Hide , payload : { show : false } });
    setTimeout(() =>{
        Stores.dispatch({ type: modalScreenActions.Clear });
    },150)
}