import { createStore,applyMiddleware  } from 'redux'
import { ReduxReducers} from "./reducers"
import createSagaMiddleware from 'redux-saga'
import { DefaultSaga } from './default.sagas'

// Create redux saga middlewares
const sagaMiddleware = createSagaMiddleware()
// Export store with combined reducers, and use saga middlewares
export const Stores = createStore(ReduxReducers, applyMiddleware(sagaMiddleware))
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof Stores.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof Stores.dispatch
// Run the saga
sagaMiddleware.run(DefaultSaga)