import React, { useState } from 'react'
import { AnalyzeStatus, ISceneAnalyze, Supported3dApps } from '../SceneAnalysis.interface'
import { ISceneInfo, SceneInterface } from 'Interfaces/Renderer'
import DropdownButton from './DropdownButton'
import './analysis-item.scss'
import AnalysisCollapse from './AnalysisCollapse'
import RelativeTime from '../../CloudStorage/RelativeTime'
import path from 'path'
import StartRenderAnalyzedScene from 'screens/RenderNow/StartRenderAnalyzedScene'
import { RenderOptionsInterface } from 'screens/RenderNow/RenderNow.interface'
import { PHPBOOL } from 'Interfaces/App.enums'
import { Stores } from 'stores/stores'
import { RenderReducerActionEnum } from 'stores/reducers/render.interface'
import { RenderScreenModal } from 'Helpers/Modal'
import { API as APIPATH } from 'Interfaces/Api.enum'
import { EncryptMd5String } from 'modules/crypto/md5files'
import { API } from 'apis/base.api'
import { AnalysisActionType } from 'stores/reducers/analysis.interface'

interface AnalysisItemProps {
     data: ISceneAnalyze,
     SagaFetch: () => void
}

export default function AnalysisItem({data,SagaFetch}: AnalysisItemProps) {
     
     const [isToggle, setToggleDetail] = useState(false)

     let SceneResultInterface = JSON.parse(data.analyze_result) as SceneInterface
     let AnalyzeStatusClass = data.analysis_status === AnalyzeStatus.Completed ? "text-success" 
                              : data.analysis_status === AnalyzeStatus.Failed ? "text-danger" 
                              : data.analysis_status === AnalyzeStatus.WaitingForAnalyzing || data.analysis_status === AnalyzeStatus.InAnalyzing ? "text-info"
                              : "text-warning"
     let AnalyzeStatusTitle = data.analysis_status === AnalyzeStatus.Completed ? "Analyze Completed" 
                              : data.analysis_status === AnalyzeStatus.Failed ? "Analyze Failed" 
                              : data.analysis_status ===  AnalyzeStatus.WaitingForAnalyzing ? "Waiting For Analyzing" 
                              : data.analysis_status ===  AnalyzeStatus.InAnalyzing ? "In Analyzing" 
                              : data.analysis_status
                         
     let SceneName = path.join( "File Storage", data.scene_path.split("/")[data.scene_path.split("/").length - 2], path.basename(data.scene_path))
     let Params = JSON.parse(data?.scene_info ) as ISceneInfo || {}
     let StartFrame = Params?.scene_param?.startFrame || "Unknown"
     let EndFrame = Params?.scene_param?.endFrame || "Unknown"
     function CreateRenderJob(Analysis: ISceneAnalyze) {
          let SceneResultInterface = JSON.parse(data.analyze_result || "") as SceneInterface
          var Payloads: RenderOptionsInterface = {
              Analysis,
              FarmDirectory: "",
              _3dEngine: data.software_app as Supported3dApps ,
              body: {
                  scene: {
                      ...SceneResultInterface,
                      cameras: Params?.render_cameras? Params?.render_cameras.map( cam => ({ name: cam.camera_name, selected: false })) : [],
                      params: Params,
                  },
                  files: [],
                  maxPath: Analysis.scene_path,
                  version: parseInt(Analysis.software_version),
                  FarmDirectory: "",
                  autoStartRender: true,
              },
              render_configuration: {
                  frame_per_task: 1,
                  rf_compute_advanced_lighting: PHPBOOL.False,
                  rf_convert_area_lights_to_points_sources: PHPBOOL.False,
                  rf_force_two_sided: PHPBOOL.False,
                  rf_perform_color_check: PHPBOOL.False,
                  rf_perform_displacement_mapping: PHPBOOL.False,
                  rf_perform_render_effects: PHPBOOL.False,
                  rf_render_flags: PHPBOOL.False,
                  rf_render_hidden_objects: PHPBOOL.False,
                  rf_toggles_output_dithering_paletted: PHPBOOL.False,
                  rf_toggles_output_dithering_true_color: PHPBOOL.False,
                  rf_use_advanced_lighting: PHPBOOL.False,
                  rf_use_atmospherics_effects: PHPBOOL.False,
                  rf_use_super_black: PHPBOOL.False,
              },
          }
          // create modal
     
          // append render engine version
          // Payloads.body.scene.params.scene_param.render_engine = Analysis.renderer_version
     
          // create render job
          Stores.dispatch({ type: RenderReducerActionEnum.UpdateScene, value: Payloads })
          RenderScreenModal(<StartRenderAnalyzedScene RenderScene={Payloads} />)
      }
      async function Reanalyze(id : number){
          await API.guard().post("/analyze-job/restart/scene-analyze/"+id)
          SagaFetch()
      }
      const HandleDeleteAnalyze = (Analysis: ISceneAnalyze) => {
          const AnalysisPath = Analysis.scene_path
          // Delete method not supported to send params in request body
          // To submit a delete request, we need to send a get request with id inline url
          const api = APIPATH.DeleteAnalyzeScene + EncryptMd5String(AnalysisPath)
          API.guard().delete(api, {
              // Create authorization header
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              }
          })
              .finally(() => {
                  // After all the request is done, we need to update the store
                  Stores.dispatch({ type: AnalysisActionType.RemoveItems, payload: Analysis.id })
                  SagaFetch()
              })
      }
      
     return (
          <div className="analysis-item">
               <div className="d-flex">
                    <button type="button" className="btn toggle-button"
                         onClick={() => !isToggle ? setToggleDetail(true) : setToggleDetail(false)}
                         disabled={data.analysis_status === AnalyzeStatus.WaitingForAnalyzing}
                         >
                         <i className="mt-1 fad fa-chevron-right" style={
                                   isToggle?{transform: "rotate(90deg)",transition:"all .25s",color:"#20c997"} : 
                                             {transform: "rotate(0deg)",transition:"all .25s"}
                                   }></i>
                    </button>
                    <div className="col-7">
                         <div className="analysis-info">
                              <div className="job-name">
                                   <span title={SceneName}>ID #{data.scene_analysis_id} - {path.basename(data.scene_path)}</span>
                              </div>
                              <div className="d-flex gap-2">
                                   <div className="badge bg-success-bright">Frames: {
                                             data.software_app === Supported3dApps.Maya ? Params.startFrame ?  Params.startFrame+"-"+Params.endFrame : "Unknown"
                                             :
                                             StartFrame==="Unknown" || EndFrame==="Unknown" ? "Unknown": StartFrame+"-"+EndFrame 
                                        }
                                   </div>
                                   {
                                    Params.render_layers ?
                                        data.software_app === Supported3dApps.Maya ?
                                             <div className="badge bg-success-bright">Active layer: {Params?.render_layers?Params.render_layers.filter(v=>v.is_default_layer).find(e=>e)?.layer_name:"Unknow"}</div>
                                             : <div className="badge bg-success-bright">Active camera: {Params.camera_param?.activeCamera || "Unknown"}</div> 
                                             
                                        :<></>
                                   }
                                   <div className="badge bg-success-bright">Hardware: {data.gcpu_type}</div>
                                   <div className="badge bg-success-bright">Submitted: {RelativeTime(data.updated_at)}</div>
                              </div>
                         </div>
                    </div>
                    <div className="col d-flex">
                         <span className={`badge badge-status mx-auto ${AnalyzeStatusClass} `}>{AnalyzeStatusTitle}</span>
                    </div>
                    <DropdownButton
                         disable={data.analysis_status !== AnalyzeStatus.Completed }
                         CreateRenderJob={()=>CreateRenderJob(data)} 
                         Reanalyze={()=>Reanalyze(data.id)} 
                         HandleDeleteAnalyze={()=>HandleDeleteAnalyze(data)} 
                         />
               </div>
               <AnalysisCollapse items={SceneResultInterface} id={data.id} isToggle={isToggle} />
          
      </div>
     
         
     )
}