/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { IrradMapMode, IrradMapModeName, LightCacheMode, LightCacheModeName, VrayGIPrimaryType, VrayGISecondaryType, VrayGlobalIlluminationPrimaryName, VrayGlobalIlluminationSecondaryName } from '../../../../../../Interfaces/App.enums'
import { VrayDispatchMapper } from '../vrayDispatch'
import './global-illumination.scss'
import { RootState, Stores } from '../../../../../../stores/stores'
import { RenderOptionsInterface } from 'screens/RenderNow/RenderNow.interface'
import Modal from "react-modal";
import { pathToFileURL } from 'url'
import FileExplorer from 'components/FileExplorer'

const MapStates = (States: RootState) => ({
     Render: States.Render
})

type GlobalIlluminationProps = ReturnType<typeof MapStates> & typeof VrayDispatchMapper & {
     renderOptions: RenderOptionsInterface
}


function getGIPrimaryEngineName(primary: VrayGIPrimaryType) {
     switch (primary) {
          case VrayGIPrimaryType.BruteForce:
               return VrayGlobalIlluminationPrimaryName.BruteForce
          case VrayGIPrimaryType.PhotonMap:
               return VrayGlobalIlluminationPrimaryName.PhotonMap
          case VrayGIPrimaryType.LightCache:
               return VrayGlobalIlluminationPrimaryName.LightCache
          case VrayGIPrimaryType.IrradianceMap:
               return VrayGlobalIlluminationPrimaryName.IrradianceMap
     }
}

function getGISecondaryEngineName(secondary: VrayGISecondaryType) {
     switch (secondary) {
          case VrayGISecondaryType.PhotonMap:
               return VrayGlobalIlluminationSecondaryName.PhotonMap
          case VrayGISecondaryType.LightCache:
               return VrayGlobalIlluminationSecondaryName.LightCache
          case VrayGISecondaryType.BruteForce:
               return VrayGlobalIlluminationSecondaryName.BruteForce
          case VrayGISecondaryType.None:
               return VrayGlobalIlluminationSecondaryName.None

     }
}

function getIrradMapModeName(mode: IrradMapMode) {
     switch (mode) {
          case IrradMapMode.AddToCurrentMap:
               return IrradMapModeName.AddToCurrentMap
          case IrradMapMode.AnimationPrepass:
               return IrradMapModeName.AnimationPrepass
          case IrradMapMode.AnimationRendering:
               return IrradMapModeName.AnimationRendering
          case IrradMapMode.Bucket:
               return IrradMapModeName.Bucket
          case IrradMapMode.FromFile:
               return IrradMapModeName.FromFile
          case IrradMapMode.IncrementalAddToCurrentMap:
               return IrradMapModeName.IncrementalAddToCurrentMap
          case IrradMapMode.MultiFrameIncremental:
               return IrradMapModeName.MultiFrameIncremental
          case IrradMapMode.SingleFrame:
               return IrradMapModeName.SingleFrame

     }
}

function getLightCacheModeName(mode: LightCacheMode) {
     switch (mode) {
          case LightCacheMode.FlyThrough:
               return LightCacheModeName.FlyThrough
          case LightCacheMode.FromFile:
               return LightCacheModeName.FromFile
          case LightCacheMode.ProgressivePathTracking:
               return LightCacheModeName.ProgressivePathTracking
          case LightCacheMode.SingleFrame:
               return LightCacheModeName.SingleFrame
     }
}

function GlobalIllumination(Props: GlobalIlluminationProps) {
     if (!Props.renderOptions.body?.scene?.params?.vray_option) {
          return <></>
     }

     const { vray_option } = Props.renderOptions.body.scene.params
     const { gi_primary_type, gi_secondary_type } = vray_option
     if (gi_primary_type.gi_primary_type_key == undefined || gi_secondary_type.gi_secondary_type_key == undefined || vray_option.lightcache_mode.lightcache_mode_key == undefined) return <div></div>
     const [primaryEngine, setPrimaryEngine] = useState<VrayGIPrimaryType>(gi_primary_type.gi_primary_type_key)
     const [secondaryEngine, setSecondaryEngine] = useState<VrayGISecondaryType>(gi_secondary_type.gi_secondary_type_key)
     const [irradianceMap, setIrradianceMap] = useState<IrradMapMode>(IrradMapMode.AddToCurrentMap)
     const [lightCache, setLightCache] = useState<LightCacheMode>(vray_option.lightcache_mode.lightcache_mode_key)
     const [EnableGI, setEnableGI] = useState(vray_option.gi_on)
     const [lightcache_loadFileName, setlightcache_loadFileName] = useState(vray_option.lightcache_loadFileName)
     const [adv_irradmap_loadFileName, setadv_irradmap_loadFileName] = useState(vray_option.adv_irradmap_loadFileName)
     const [ModalFileExplorer1, setModalFileExplorer1] = useState(false)
     const [ModalFileExplorer2, setModalFileExplorer2] = useState(false)

     console.log(lightcache_loadFileName , adv_irradmap_loadFileName )
     return (
          <div className="card mb-3">
               <div className="card-header">
                    <h6 className="card-title">Global Illumination</h6>
               </div>
               <div className="card-body">
                    <div className="form-check">
                         <input className="form-check-input" type="checkbox" id="flexCheckChecked"
                              checked={EnableGI} 
                              onChange={() => {
                              setEnableGI(!EnableGI)
                              Props.updateVrayProps(
                                   "gi_on",
                                   !EnableGI
                              )
                         }} />
                         <label className="form-check-label" htmlFor="flexCheckChecked">
                              Enable GI
                         </label>
                    </div>
                    <div className="row mb-3">

                         {/* Primary Engine */}
                         <div className="col-6">
                              <span className="input-group-text" >Primary engine </span>
                              <select className="form-select" aria-label="Primary engine" id="vray_primary_engine" onChange={e => {
                                   setPrimaryEngine(parseInt(e.target.value) as VrayGIPrimaryType)
                                   Props.updateVrayProps(
                                        "gi_primary_type",
                                        {
                                             gi_primary_type_key: parseInt(e.target.value) as VrayGIPrimaryType,
                                             gi_primary_type_name: getGIPrimaryEngineName(parseInt(e.target.value) as VrayGIPrimaryType)
                                        }
                                   )
                              }}>
                                   <option value={VrayGIPrimaryType.IrradianceMap} selected={primaryEngine == VrayGIPrimaryType.IrradianceMap}>Irradiance map</option>
                                   <option value={VrayGIPrimaryType.PhotonMap} selected={primaryEngine == VrayGIPrimaryType.PhotonMap}>Photon map</option>
                                   <option value={VrayGIPrimaryType.BruteForce} selected={primaryEngine == VrayGIPrimaryType.BruteForce}>Brute force</option>
                                   <option value={VrayGIPrimaryType.LightCache} selected={primaryEngine == VrayGIPrimaryType.LightCache}>Light cache</option>
                              </select>
                         </div>

                         {/* Secondary Engine */}
                         <div className="col-6">
                              <span className="input-group-text" >Secondary engine </span>
                              <select className="form-select" aria-label="Secondary engine" id="vray_secondary_engine" onChange={e => {
                                   setSecondaryEngine(parseInt(e.target.value) as VrayGISecondaryType)
                                   Props.updateVrayProps(
                                        "gi_secondary_type",
                                        {
                                             gi_secondary_type_key: parseInt(e.target.value) as VrayGISecondaryType,
                                             gi_secondary_type_name: getGISecondaryEngineName(parseInt(e.target.value) as VrayGISecondaryType)
                                        })
                              }}>
                                   <option value={VrayGISecondaryType.None} selected={secondaryEngine === VrayGISecondaryType.None}>None</option>
                                   <option value={VrayGISecondaryType.PhotonMap} selected={secondaryEngine === VrayGISecondaryType.PhotonMap}>Photon map</option>
                                   <option value={VrayGISecondaryType.BruteForce} selected={secondaryEngine === VrayGISecondaryType.BruteForce}>Brute force</option>
                                   <option value={VrayGISecondaryType.LightCache} selected={secondaryEngine === VrayGISecondaryType.LightCache}>Light cache</option>
                              </select>
                         </div>
                    </div>
                    {
                         primaryEngine === 0 ? (
                              <div className="card mb-3" id="irradiance-map">
                                   <div className="card-header">
                                        <h6 className="card-title">Irradiance Map</h6>
                                   </div>
                                   <div className="card-body">
                                        <div className="row mb-3">
                                             <div className="col-12 mb-3">
                                                  <span className="input-group-text" >Mode </span>
                                                  <select id="vray_mode_irradiance_map" className="form-control form-select" name="adv_irradmap_mode" onChange={e => {
                                                       setIrradianceMap(parseInt((e.target.value)) as IrradMapMode)
                                                       Props.updateVrayProps(
                                                            "adv_irradmap_mode",
                                                            {
                                                                 adv_irradmap_mode_key: parseInt((e.target.value)) as IrradMapMode,
                                                                 adv_irradmap_mode_name: getIrradMapModeName(parseInt((e.target.value)) as IrradMapMode)
                                                            })
                                                  }}>
                                                       <option value={IrradMapMode.SingleFrame} selected={Props.renderOptions.body.scene.params.vray_option.adv_irradmap_mode.adv_irradmap_mode_key == IrradMapMode.SingleFrame}>Single frame</option>
                                                       <option value={IrradMapMode.MultiFrameIncremental} selected={Props.renderOptions.body.scene.params.vray_option.adv_irradmap_mode.adv_irradmap_mode_key == IrradMapMode.MultiFrameIncremental}>Multiframe incremental</option>
                                                       <option value={IrradMapMode.FromFile} selected={Props.renderOptions.body.scene.params.vray_option.adv_irradmap_mode.adv_irradmap_mode_key == IrradMapMode.FromFile}>From file</option>
                                                       <option value={IrradMapMode.AddToCurrentMap} selected={Props.renderOptions.body.scene.params.vray_option.adv_irradmap_mode.adv_irradmap_mode_key == IrradMapMode.AddToCurrentMap}>Add to current map</option>
                                                       <option value={IrradMapMode.IncrementalAddToCurrentMap} selected={Props.renderOptions.body.scene.params.vray_option.adv_irradmap_mode.adv_irradmap_mode_key == IrradMapMode.IncrementalAddToCurrentMap}>Incremental add to current map</option>
                                                       <option value={IrradMapMode.Bucket} selected={Props.renderOptions.body.scene.params.vray_option.adv_irradmap_mode.adv_irradmap_mode_key == IrradMapMode.Bucket}>Bucket mode</option>
                                                       <option value={IrradMapMode.AnimationPrepass} selected={Props.renderOptions.body.scene.params.vray_option.adv_irradmap_mode.adv_irradmap_mode_key == IrradMapMode.AnimationPrepass}>Animation (prepass)</option>
                                                       <option value={IrradMapMode.AnimationRendering} selected={Props.renderOptions.body.scene.params.vray_option.adv_irradmap_mode.adv_irradmap_mode_key == IrradMapMode.AnimationRendering}>Animation (rendering)</option>
                                                  </select>
                                             </div>
                                             <div className="col-12">
                                                  {
                                                       irradianceMap === IrradMapMode.FromFile ? (
                                                            <>
                                                            <span className="input-group-text" >File path* </span>
                                                            <div className="input-group">
                                                                 <input type="text" className="form-control" value={adv_irradmap_loadFileName}/>
                                                                 <button className="btn btn-dark" onClick={()=>setModalFileExplorer1(true)}>Browse File</button>
                                                            </div>
                                                            <Modal
                                                                 isOpen={ModalFileExplorer1}
                                                                 onRequestClose={() => setModalFileExplorer1(false)}
                                                                 closeTimeoutMS={250}
                                                                 style={{
                                                                 overlay: {
                                                                      zIndex: 10000,
                                                                      position: "fixed",
                                                                      top: 35,
                                                                      left: 0,
                                                                      right: 0,
                                                                      bottom: 0,
                                                                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                                 },
                                                                 content: {
                                                                      top: "50%",
                                                                      left: "50%",
                                                                      right: "auto",
                                                                      bottom: "auto",
                                                                      marginRight: "-50%",
                                                                      background: "#343434",
                                                                      transform: "translate(-50%, -50%)",
                                                                      border: "none",
                                                                      width: "90%",
                                                                      height: "90%",
                                                                      padding: "0",
                                                                      cursor: "default",
                                                                      overflow: "hidden",
                                                                      borderRadius: "10px",
                                                                 },
                                                                 }}
                                                                 contentLabel="File Explorer"
                                                            >
                                                                 <div className="close-button">
                                                                 <button className="btn" onClick={() => setModalFileExplorer1(false)}>
                                                                      <i className="fal fa-times" />
                                                                 </button>
                                                                 </div>
                                                                 <FileExplorer URLPath={setadv_irradmap_loadFileName} />
                                                              
                                                                 <div className="fixed-bottom m-5">
                                                                 <div className="input-group">
                                                                      <span className="input-group-text">Select file</span>
                                                                      <input className="form-control" disabled value={adv_irradmap_loadFileName} />
                                                                      <button
                                                                      className="btn btn-success"
                                                                      onClick={ ()=>{
                                                                          
                                                                           Props.updateVrayProps(
                                                                                "adv_irradmap_loadFileName",
                                                                                adv_irradmap_loadFileName
                                                                           )
                                                                           &&

                                                                           setModalFileExplorer1(false)
                                                                      }}
                                                                      >
                                                                      Select
                                                                      </button>
                                                                 </div>
                                                                 </div>
                                                            </Modal>
                                                            
                                                       </>
                                                       ) : ""
                                                  }
                                             </div>
                                        </div>

                                   </div>
                              </div>
                         ) : ""
                    }
                    {
                         (primaryEngine === 3 || secondaryEngine === 3) ? (
                              <div className="card" id="light-cache">
                                   <div className="card-header">
                                        <h6 className="card-title">Light Cache</h6>
                                   </div>
                                   <div className="card-body">
                                        <div className="row mb-3">
                                             <div className="col-12 mb-3">
                                                  <span className="input-group-text" >Mode </span>
                                                  <select id="vray_mode_light_cache" name="lightcache_mode" className="form-control form-select"
                                                       value={Props.Render?.body?.scene?.params?.vray_option?.lightcache_mode?.lightcache_mode_key || LightCacheMode.SingleFrame}
                                                       onChange={e => {
                                                            setLightCache(parseInt((e.target.value)) as LightCacheMode)
                                                            Props.updateVrayProps(
                                                                 "lightcache_mode",
                                                                 {
                                                                      lightcache_mode_key: parseInt((e.target.value)) as LightCacheMode,
                                                                      lightcache_mode_name: getLightCacheModeName(parseInt((e.target.value)) as LightCacheMode)
                                                                 })
                                                       }}>
                                                       <option value={LightCacheMode.SingleFrame} selected={true}>Single frame</option>
                                                       <option value={LightCacheMode.FlyThrough}>Fly through</option>
                                                       <option value={LightCacheMode.FromFile}>From file</option>
                                                       <option value={LightCacheMode.ProgressivePathTracking}>Progressive path tracking</option>
                                                  </select>
                                             </div>
                                             <div className="col-12">
                                                  {
                                                       lightCache === LightCacheMode.FromFile ? (
                                                            <>
                                                                 <span className="input-group-text" >File path* </span>
                                                                 <div className="input-group">
                                                                      <input type="text" className="form-control" value={lightcache_loadFileName}/>
                                                                      <button className="btn btn-dark" onClick={()=>setModalFileExplorer2(true)}>Browse File</button>
                                                                 </div>
                                                                 <Modal
                                                                      isOpen={ModalFileExplorer2}
                                                                      onRequestClose={() => setModalFileExplorer2(false)}
                                                                      closeTimeoutMS={250}
                                                                      style={{
                                                                      overlay: {
                                                                           zIndex: 10000,
                                                                           position: "fixed",
                                                                           top: 35,
                                                                           left: 0,
                                                                           right: 0,
                                                                           bottom: 0,
                                                                           backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                                      },
                                                                      content: {
                                                                           top: "50%",
                                                                           left: "50%",
                                                                           right: "auto",
                                                                           bottom: "auto",
                                                                           marginRight: "-50%",
                                                                           background: "#343434",
                                                                           transform: "translate(-50%, -50%)",
                                                                           border: "none",
                                                                           width: "90%",
                                                                           height: "90%",
                                                                           padding: "0",
                                                                           cursor: "default",
                                                                           overflow: "hidden",
                                                                           borderRadius: "10px",
                                                                      },
                                                                      }}
                                                                      contentLabel="File Explorer"
                                                                 >
                                                                      <div className="close-button">
                                                                      <button className="btn" onClick={() => setModalFileExplorer2(false)}>
                                                                           <i className="fal fa-times" />
                                                                      </button>
                                                                      </div>
                                                                      <FileExplorer URLPath={setlightcache_loadFileName}/>
                                                                   
                                                                      <div className="fixed-bottom m-5">
                                                                      <div className="input-group">
                                                                           <span className="input-group-text">Select file</span>
                                                                           <input className="form-control" disabled value={lightcache_loadFileName} />
                                                                           <button
                                                                           className="btn btn-success"
                                                                           onClick={ ()=>{
                                                                               
                                                                                Props.updateVrayProps(
                                                                                     "lightcache_loadFileName",
                                                                                     lightcache_loadFileName
                                                                                )
                                                                                &&

                                                                                setModalFileExplorer2(false)
                                                                           }}
                                                                           >
                                                                           Select
                                                                           </button>
                                                                      </div>
                                                                      </div>
                                                                 </Modal>
                                                                 
                                                            </>
                                                          
                                                       ) : ""
                                                  }
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         ) : ""
                    }
               </div>
          </div>

     )
}

export default connect(MapStates, VrayDispatchMapper)(GlobalIllumination)