import React from 'react'
import './page-404.scss'
import { useHistory } from "react-router-dom";

interface Page404Props {
}

export default function Page404({}: Page404Props) {
     let history = useHistory();
     return (
          <div className="page-404">
               <h4>Oops, Page Not Found</h4>
               <p>The Page you are looking for was not found, please contact admin if you thing this was a mistake.</p>
               <button className="btn btn-outline-success" onClick={()=>history.push("/")}>GO HOME</button>
          </div>
     )
}