import React from 'react'
import './render-flags.scss'
type RenderFlagsProps = {
     performColorCheck: boolean,
     useAsmosphericsEffects: boolean,
     performRenderEffect: boolean,
     togglesOutputDithering: boolean,
     forceTwoSide: boolean,
     useSuperBlack: boolean,
     useAdvancedLight: boolean,
     togglesOutputDitheringTrueColor: boolean,
     renderHiddenObjects: boolean,
     performDisplacementMapping: boolean,
     computeAdvancedLightng: boolean,
     converAreaLightsToPointsSources: boolean,
     setPerformColorCheck: (state: boolean) => void,
     setUseAsmosphericEffects: (state: boolean) => void,
     setPerformRenderEffect: (state: boolean) => void,
     setTogglesOutputDithering: (state: boolean) => void,
     setForceTwoSide: (state: boolean) => void,
     setUseSuperBlack: (state: boolean) => void,
     setUseAdvancedLight: (state: boolean) => void,
     setTogglesOutputDitheringTrueColor: (state: boolean) => void,
     setrenderHiddenObject: (state: boolean) => void,
     setPerformDisplacementMapping: (state: boolean) => void,
     setComputeAdvancedLightng: (state: boolean) => void,
     setConverAreaLightsToPointsSources: (state: boolean) => void,

}
function RenderFlags(Props: RenderFlagsProps) {

     return (
          <div className="row">
               <div className="col-4">
                    <div className="form-check">
                         <input onChange={(e) => { Props.setPerformColorCheck(e.target.checked) }} id="rf_perform_color_check" checked={Props.performColorCheck} name="rf_perform_color_check" type="checkbox" className="form-check-input" />
                         <label htmlFor="rf_perform_color_check" className="form-check-label">Perform Color Check</label>
                    </div>
               </div>
               <div className="col-4">
                    <div className="form-check">
                         <input onChange={(e) => { Props.setForceTwoSide(e.target.checked) }} id="rf_force_two_sided" checked={Props.forceTwoSide} name="rf_force_two_sided" type="checkbox" className="form-check-input" />
                         <label htmlFor="rf_force_two_sided" className="form-check-label">Force Two-Sided</label>
                    </div>
               </div>
               <div className="col-4">
                    <div className="form-check">
                         <input onChange={(e) => { Props.setrenderHiddenObject(e.target.checked) }} id="rf_render_hidden_objects" checked={Props.renderHiddenObjects} name="rf_render_hidden_objects" type="checkbox" className="form-check-input" />
                         <label htmlFor="rf_render_hidden_objects" className="form-check-label">Render Hidden Objects</label>
                    </div>
               </div>
               <div className="col-4">
                    <div className="form-check">
                         <input onChange={(e) => { Props.setUseAsmosphericEffects(e.target.checked) }} id="rf_use_atmospherics_effects" checked={Props.useAsmosphericsEffects} name="rf_use_atmospherics_effects" type="checkbox" className="form-check-input" />
                         <label htmlFor="rf_use_atmospherics_effects" className="form-check-label">Use Atmospherics Effects</label>
                    </div>
               </div>
               <div className="col-4">
                    <div className="form-check">
                         <input onChange={(e) => { Props.setUseSuperBlack(e.target.checked) }} id="rf_use_super_black" checked={Props.useSuperBlack} name="rf_use_super_black" type="checkbox" className="form-check-input" />
                         <label htmlFor="rf_use_super_black" className="form-check-label">Use Super Black</label>
                    </div>
               </div>
               <div className="col-4">
                    <div className="form-check">
                         <input onChange={(e) => { Props.setPerformDisplacementMapping(e.target.checked) }} id="rf_perform_displacement_mapping" checked={Props.performDisplacementMapping} name="rf_perform_displacement_mapping" type="checkbox" className="form-check-input" />
                         <label htmlFor="rf_perform_displacement_mapping" className="form-check-label">Perform Displacement Mapping</label>
                    </div>
               </div>
               <div className="col-4">
                    <div className="form-check">
                         <input onChange={(e) => { Props.setPerformRenderEffect(e.target.checked) }} id="rf_perform_render_effects" checked={Props.performRenderEffect} name="rf_perform_render_effects" type="checkbox" className="form-check-input" />
                         <label htmlFor="rf_perform_render_effects" className="form-check-label">Perform Render Effects</label>
                    </div>
               </div>
               <div className="col-4">
                    <div className="form-check">
                         <input onChange={(e) => { Props.setUseAdvancedLight(e.target.checked) }} id="rf_use_advanced_lighting" checked={Props.useAdvancedLight} name="rf_use_advanced_lighting" type="checkbox" className="form-check-input" />
                         <label htmlFor="rf_use_advanced_lighting" className="form-check-label">Use Advanced Lighting</label>
                    </div>
               </div>
               <div className="col-4">
                    <div className="form-check">
                         <input onChange={(e) => { Props.setComputeAdvancedLightng(e.target.checked) }} id="rf_compute_advanced_lighting" checked={Props.computeAdvancedLightng} name="rf_compute_advanced_lighting" type="checkbox" className="form-check-input" />
                         <label htmlFor="rf_compute_advanced_lighting" className="form-check-label">Compute Advanced Lighting</label>
                    </div>
               </div>
               <div className="col-4">
                    <div className="form-check">
                         <input onChange={(e) => { Props.setTogglesOutputDithering(e.target.checked) }} id="rf_toggles_output_dithering_paletted" checked={Props.togglesOutputDithering} name="rf_toggles_output_dithering_paletted" type="checkbox" className="form-check-input" />
                         <label htmlFor="rf_toggles_output_dithering_paletted" className="form-check-label">Toggles Output Dithering (paletted)</label>
                    </div>
               </div>
               <div className="col-4">
                    <div className="form-check">
                         <input onChange={(e) => { Props.setTogglesOutputDitheringTrueColor(e.target.checked) }} id="rf_toggles_output_dithering_true_color" checked={Props.togglesOutputDitheringTrueColor} name="rf_toggles_output_dithering_true_color" type="checkbox" className="form-check-input" />
                         <label htmlFor="rf_toggles_output_dithering_true_color" className="form-check-label">Toggles Output Dithering (true-color)</label>
                    </div>
               </div>
               <div className="col-4">
                    <div className="form-check">
                         <input onChange={(e) => { Props.setConverAreaLightsToPointsSources(e.target.checked) }} id="rf_convert_area_lights_to_points_sources" checked={Props.converAreaLightsToPointsSources} name="rf_convert_area_lights_to_points_sources" type="checkbox" className="form-check-input" />
                         <label htmlFor="rf_convert_area_lights_to_points_sources" className="form-check-label">Convert Area Lights To Points Sources</label>
                    </div>
               </div>
          </div>
     )
}
export default RenderFlags