import Tippy from '@tippyjs/react';
import { API } from 'apis/base.api';
import { RENDERER_VERSIONS,  SOFTWARES_TYPE, SOFTWARES_VERSION } from 'config';
import { DetectSoftwareFromPath } from 'Helpers/DetectSoftware';
import { dirname, join } from 'path';
import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { SSActionType, SupportedSoftwareTypes } from 'stores/reducers/supported-software.types';
import { RootState, Stores } from 'stores/stores';
import Swal from 'sweetalert2';
import { CloudType, Processor, RenderEngineVersion, SoftwareVersion } from '../../../../../Interfaces/App.enums';
import { IpcSender } from '../../../../../Interfaces/IpcRenderer.enum';
import { CloudStorageAction, SpaceItem } from '../../../../../stores/reducers/spaces.interface';

const { ipcRenderer } = window.electron;

const mapState = (state: RootState) => ({
  supportedSoftwares: state.SupportedSoftwares.list || []
})

export type AnalyzeSceneProps = ReturnType<typeof mapState> & {
  setIsOpenModal: (state: boolean) => void,
  isOpenModal: boolean,
  item: SpaceItem,
  workspace_mel: string[],
}

function AnalyzeSceneModal({item, setIsOpenModal, isOpenModal, workspace_mel, supportedSoftwares}: AnalyzeSceneProps) {
  const [softwareVersion,setSoftwareVersion] = useState<SOFTWARES_VERSION>(SOFTWARES_VERSION.UNDEFINED)
  const [renderEngineVersion,setRenderEngineVersion] = useState<RENDERER_VERSIONS>(RENDERER_VERSIONS.UNDEFINED)
  const [processors, setProcessor] = useState<Processor>(Processor.CPU)
  const [workspacePath, setWorkspacePath] = useState<string>(dirname(item.key));
  useEffect(() => {
      /// select redprojeck directory
      const notMatchAny = workspace_mel.map( ws => {
          const wsRegex = new RegExp(dirname(ws))
          const isMyWorkspace = wsRegex.test(item.key)
          if(isMyWorkspace) {
              setWorkspacePath(dirname(ws))
          }
        })

  }, [item.key])
  // Form controller
  const sourceCloudType = CloudType.AwsS3
  
  // check is item invalid
  if(!item){
    Swal.fire({
      title:  'Item invalid',
      text:  'Something error please check your scene and analyze again!',
      icon: 'error',
      confirmButtonText: 'OK',
      confirmButtonColor: '#218c61',
  })
  }

  async function handleSubmitButton() {
      // payload
      const payloads = {
        source_cloud_type : sourceCloudType,
        software_app : DetectSoftwareFromPath(item.key)==="max" ? "3dsmax":DetectSoftwareFromPath(item.key),
        renderer_version : renderEngineVersion,
        software_version: softwareVersion,
        scene_path: join('/', item.key),
        gcpu_type: processors,
        working_path: join('/', workspacePath),
      }

      // check is valid options
      if( renderEngineVersion === RENDERER_VERSIONS.UNDEFINED || softwareVersion === SOFTWARES_VERSION.UNDEFINED)
      Swal.fire({
              title:  'Missing information',
              text:  'Please select software version and render engine version',
              icon: 'error',
              confirmButtonText: 'OK',
              confirmButtonColor: '#218c61',
      })
      else {
        // passed validate options
        setIsOpenModal(false)
        Swal.fire({
          title:  'Your scene file is being analyzed!',
          text:  'Please wait! It usually takes 5-20 minutes depends on your project size.',
          icon: 'success',
          confirmButtonText: 'OK',
          confirmButtonColor: '#218c61',
          preConfirm(inputValue) {
          
          },
        })
        await API.guard().post('/analyze-job/create/scene-analyze',payloads)
    
    }
      
  }

  return <Modal
      isOpen={isOpenModal}
      onRequestClose={() => {
          setIsOpenModal(false);
      }}
      closeTimeoutMS={250}
      style={{
          overlay: {
              zIndex: 20,
              position: 'fixed',
              top: 35,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
          content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              background: '#343434',
              transform: 'translate(-50%, -50%)',
              border: 'none',
              maxHeight: '720px',
              minHeight: '350px',
              width: '750px',
              padding: '0',
              cursor: 'default',
          },
      }}
      contentLabel="Open Analyze Scene Modal"
  >

      <div className="modal-header">
          <h5>Analyze scene {item.name}</h5>
      </div>
      <div className="modal-body" >
          <small className="form-label semibold">Scene path</small>
          <input type="text" className="form-control mb-3" value={item.key} disabled style={{cursor:"text"}} />
          <small className="form-label semibold" >Select a processors</small>
          <div className="processors-select d-flex gap-5 mb-3">
              <div className={`radio-button ${processors === Processor.CPU ? "active" : ""}`}>
                  <div className="form-check">
                      <input className="form-check-input" 
                        id={Processor.CPU} 
                        type="radio" 
                        name="processors" 
                        checked={processors === Processor.CPU} 
                        onChange={() => {
                          setProcessor(Processor.CPU);
                          setRenderEngineVersion(RENDERER_VERSIONS.UNDEFINED)}
                        } 
                      />
                      <label className="form-check-label" htmlFor={Processor.CPU}>CPU</label>
                  </div>
              </div>
              <div className={`radio-button ${processors === Processor.GPU ? "active" : ""}`}>
                  <div className="form-check">
                      <input className="form-check-input" 
                        id={Processor.GPU} 
                        type="radio" 
                        name="processors" 
                        checked={processors === Processor.GPU}
                        onChange={() => {
                          setProcessor(Processor.GPU);
                          setRenderEngineVersion(RENDERER_VERSIONS.UNDEFINED)}
                        }
                      />
                      <label className="form-check-label" htmlFor={Processor.GPU}>GPU</label>
                  </div>
              </div>
          </div>
          {/* Detect Maya software to show workspace path */}
          {
            DetectSoftwareFromPath(item.key) === SOFTWARES_TYPE.AUTODESK_MAYA  ?
              <div className="form-group mb-3">
                <small className="form-label semibold">Project Directory<span className="text-danger">*</span></small>
                <select className="form-select" onChange={(e) =>setWorkspacePath(e.target.value)} defaultValue={workspacePath}>
                  { workspace_mel.map((i) => {
                        if(i!==''){
                            return (
                                <option key={i} selected={workspacePath === dirname(i)} value={dirname(i)} >
                                        {dirname(i)}
                                </option>
                            )
                        }
                    })}
                    <option value={dirname(item.key)} selected={workspacePath===dirname(item.key) }>
                      {dirname(item.key)}
                    </option>
                </select>
              </div>
            :<></>
          }
          <div className="d-flex gap-3 mb-3">
              <div className="form-group col">
                  <small className="form-label semibold">Software Version<span className="text-danger">*</span></small>
                  <select className="form-select"
                      value={softwareVersion}
                      onChange={(e) => setSoftwareVersion(e.target.value as SOFTWARES_VERSION)}
                  >
                      <option value={SOFTWARES_VERSION.UNDEFINED}>Choose Software Version</option>
                      {    
                          supportedSoftwares
                          .filter(ss => ss.type === DetectSoftwareFromPath(item.key))
                          .map((version, indexs) => 
                              <option key={indexs} value={version.software_version_value}>
                                  {version.display_name}
                              </option>
                              )
                      }
                  </select>
              </div>
              <Tippy disabled={softwareVersion!==SOFTWARES_VERSION.UNDEFINED} content={"Please choose software version first!"}>

              <div className="form-group col">

                  <small className="form-label semibold">Render Engine Version<span className="text-danger">*</span></small>

                  <select className="form-select"
                    disabled={softwareVersion===SOFTWARES_VERSION.UNDEFINED}
                    value={renderEngineVersion}
                    onChange={(e) => {
                        setRenderEngineVersion(e.target.value as RENDERER_VERSIONS)
                    }}
                  >
                    <option value={RENDERER_VERSIONS.UNDEFINED}>Choose Render Engine Version</option>
                      {
                        supportedSoftwares
                          .filter( ss => ss.software_version_value === softwareVersion) 
                          .map((renderer, index) => renderer.supported_engines.map((i,k) => 
                            <optgroup key={k} label={i.name}>
                              { i.versions.map((option, index) => {
                                const optProcessor = option.cpu ? Processor.CPU : Processor.GPU
                                  return (
                                    <option
                                      key={option.id}
                                      value={option.renderer_version_value}
                                      disabled={processors !== optProcessor}
                                    >
                                      {option.name}
                                    </option>
                                  )
                              })
                              }
                            </optgroup>
                          )
                        )
                      }
                  </select>
              </div>
              </Tippy>

          </div>
      </div>
      <div className="modal-footer">
          <button className="ms-auto btn" onClick={() => setIsOpenModal(false)}>Cancel</button>
          <button className="submit btn btn-success ms-2" type="submit" onClick={()=>handleSubmitButton()} >Analyze Scene</button>
      </div>
  </Modal>
}

export default connect(mapState, {})(AnalyzeSceneModal)