/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable no-await-in-loop */
/* eslint-disable */

import React, { useState } from "react";
import DefineIcon from "screens/MainWindow/Components/CloudStorage/DefineIcon";
import FileSize from "screens/MainWindow/Components/CloudStorage/FileSize";
import RelativeTime from "screens/MainWindow/Components/CloudStorage/RelativeTime";
import Aws from "aws-sdk";
import "./file-explorer.scss";
import { IWebUserConfig } from "Interfaces/User";
import path from "path";
import { AwsCredentialKeysType, SpaceItem, SpaceItemType } from "types/spaces.type";
import { SceneExtension, IndexOf } from "Interfaces/App.enums";

function ItemType(name: string, size: number): SpaceItemType {
  const extension = name.split(".").pop() || "";
  // Drive is contain only one character
  if (name.length === 1 && size === 0) return SpaceItemType.Drive;
  // Folder
  if (name.length > 1 && size === 0 && name.endsWith("/"))
    return SpaceItemType.Folder;
  // is scene
  if (SceneExtension.indexOf(extension) !== IndexOf.NotFound) {
    return SpaceItemType.Scene;
  }
  // is compressed file
  if (extension.toLowerCase() === "zip") {
    return SpaceItemType.Compressed;
  }
  // is execuable extension
  if (extension.toLowerCase() === "exe") {
    return SpaceItemType.Executable;
  }
  // Other file type
  return SpaceItemType.Other;
}

function ParseFolderType(name: string): SpaceItemType {
  const PrefixName =
    name
      .split("/")
      .filter((x) => x !== "")
      .pop() || "";
  // Other type
  if (PrefixName === "") return SpaceItemType.Other;
  //
  if (
    PrefixName.length === 1 &&
    isNaN(parseInt(PrefixName, 10)) &&
    window.location.pathname === "/"
  )
    return SpaceItemType.Drive;
  //
  return SpaceItemType.Folder;
}

async function fetchListObjects(PrefixArg: string) {
  let Prefix = PrefixArg;
  const ClientUser = JSON.parse( localStorage.getItem("user_profiles") || "{}") as IWebUserConfig;
  if (!(ClientUser && ClientUser.user.srfspaces)) return;
  const ClientSpaces = ClientUser.user.srfspaces;
  const AWSConfig = JSON.parse( localStorage.getItem('AWSConfig')|| "{}"  ) as AwsCredentialKeysType;
  const Bucket = AWSConfig.AWS_ASSET_BUCKET;

  if (!Prefix.endsWith("/")) {
    Prefix += "/";
  }
  Prefix = path.join(ClientSpaces, Prefix);
  Prefix.replace(/\/\//g, "/");
  Aws.config.update(new Aws.Config({
    credentials: new Aws.Credentials({
        accessKeyId : AWSConfig.AWS_ACCESS_KEY_ID ,
        secretAccessKey : AWSConfig.AWS_SECRET_ACCESS_KEY
      })
  }))
  const AwsClient = new Aws.S3({ apiVersion: "2006-03-01" });
  const PrefixConfigs: Aws.S3.ListObjectsV2Request = {
    Bucket,
    Prefix,
    MaxKeys: 1000,
    Delimiter: "/",
  };
  let ListObjects = AwsClient.listObjectsV2(PrefixConfigs);
  let data: Aws.S3.ListObjectsV2Output = await ListObjects.promise();
  let Items: SpaceItem[] = [];

  do {
    ListObjects = AwsClient.listObjectsV2({
      ...PrefixConfigs,
      ContinuationToken: data.NextContinuationToken,
    });
    data = await ListObjects.promise();

    if (data && data.CommonPrefixes) {
      // Map contents to a list object
      const Prefixes = data.CommonPrefixes.filter((item) => {
        return item.Prefix !== "";
      }).map((item) => {
        return {
          key: item.Prefix || "",
          path: item.Prefix,
          name: item?.Prefix
            ? item.Prefix.split("/")[item.Prefix.split("/").length - 2]
            : "",

          extension: "null",
          extra: {
            ContentLength: 0,
            Region: "us-east-1",
            Etag: "",
            LastModified: new Date(),
          },
          size: 0,
          type: ParseFolderType(item.Prefix || ""),
        } as SpaceItem;
      });
      Items = [...Items, ...Prefixes];
    }
    if (data && data.Contents) {
      // Map contents to a list object
      const PrefixObjects = data.Contents.filter((item) => {
        // remove empty item
        return !item.Key?.endsWith("/");
      }).map(
        (item) =>
          ({
            key: item.Key || "",
            path: item.Key,
            name: item?.Key
              ? item.Key.split("/")[item.Key.split("/").length - 1]
              : "",
            extension: item.Key
              ? item.Key.indexOf(".") !== -1
                ? item.Key.split(".").pop() || ""
                : ""
              : "",
            workspace: item.Key
              ? item.Key.indexOf(".mel") !== -1
                ? path.dirname(item.Key) || ""
                : ""
              : "",
            extra: {
              ContentLength: item.Size || 0,
              Region: "us-east-1",
              Etag: item.ETag || "",
              LastModified: item.LastModified || new Date(),
            },
            size: item.Size || 0,
            type: ItemType(item?.Key ? item.Key || "" : "", item.Size || 0),
          } as SpaceItem)
      );
      Items = [...Items, ...PrefixObjects];
    }

    // Sort with type
    Items = Items.sort((a, b) => b.type - a.type);
  } while (data.NextContinuationToken);

  return Items;
}

type RenderListTypes = {
  Selected: (path: string) => void;
  selectFolder: boolean;
};

function BackItem({
  prefix,
  changePrefix,
}: {
  prefix: string;
  changePrefix: (newPath: string) => void;
}) {
  // root folder
  if (prefix === "" || prefix === ".") return <div></div>;

  return (
    <div
      className="file-item d-flex bg-secondary sticky-top border-0"
      title="Go back"
      onClick={() => {
        changePrefix(path.join(prefix, ".."));
      }}
    >
      <div className="d-flex">
        <div className={`file-icon me-3`}>
          <div style={{ backgroundImage: `url(${DefineIcon(90)})` }} />
        </div>
        <div className="file-name my-auto">
          <i className="fas fa-level-up-alt me-2"></i>( BACK TO PARENT )
        </div>
      </div>
    </div>
  );
}

function RenderList({ Selected, selectFolder }: RenderListTypes) {
  const [list, setList] = useState<SpaceItem[]>([]);
  const [prefix, setPrefix] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<string>("");
  const [isSelectFolder, setIsSelectFolder] = useState<boolean>(selectFolder);
  // Fetch list objects
  React.useEffect(() => {
    // Fetch list objects
    setLoading(true);
    fetchListObjects(prefix)
      .then((data) => {
        if (data) setList(data);
      })
      .then(() => {
        setLoading(false);
      });
  }, [prefix]);

  if (loading)
    return (
      <div className="d-flex h-100">
        <div className="text-center m-auto">
          <div className="spinner-border spinner-border-lg fast" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    );

  return (
    <div className="file-explorer m-5">
      {/* Header */}
      <BackItem
        prefix={prefix}
        changePrefix={(st) => {
          setPrefix(st);
        }}
      />
      {list.map((item) => {
        return (
          <div
            className={`file-item d-flex ${
              item?.path === selectedFile ? "bg-success" : ""
            }`}
            title={item.name}
            onDoubleClick={() => {
              if (
                item.type === SpaceItemType.Folder ||
                item.type === SpaceItemType.Drive
              )
                setPrefix(path.join(prefix, item.name));
            }}
            onClick={() => {
              if (item.path) {
                if (isSelectFolder) {
                  if (
                    item.type !== SpaceItemType.Asset &&
                    item.type !== SpaceItemType.Compressed &&
                    item.type !== SpaceItemType.Other &&
                    item.type !== SpaceItemType.Scene &&
                    item.type !== SpaceItemType.Executable
                  )
                    Selected(item.path);
                  setSelectedFile(item.path);
                } else {
                  if (
                    item.type !== SpaceItemType.Folder &&
                    item.type !== SpaceItemType.Drive
                  ) {
                    Selected(item.path);
                    setSelectedFile(item.path);
                  }
                }
              }
            }}
          >
            <div className="d-flex">
              <div className={`file-icon me-3`}>
                <div
                  style={{
                    backgroundImage: `url(${DefineIcon(
                      item.type,
                      item.extension
                    )})`,
                  }}
                />
              </div>
              <div className="file-name my-auto">{item.name}</div>
            </div>
            {item.size !== 0 ? (
              <div className="ms-auto my-auto">
                <span className="file-size me-4">{FileSize(item.size)}</span>
                <span className="file-modified">
                  {RelativeTime(item.extra?.LastModified)}
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
        );
      })}
    </div>
  );
}

type getFileListTypes = Omit<RenderListTypes, "list"> & { path: string };

function getFileList(props: getFileListTypes) {}

type FileExplorerProps = {
  URLPath?: any;
  selectFolder?: boolean;
};

export default function FileExplorerFileExplorer({
  URLPath,
  selectFolder,
}: FileExplorerProps) {
  return (
    <RenderList
      selectFolder={selectFolder || false}
      Selected={(data) => {
        URLPath(data);
      }}
    />
  );
}
