

export async function GetWaiter<Type>( validator : () => Type, expect ?: Type) {
    try{
        const Payloads = validator() as Type
        return new Promise<Type>((resolve, reject) =>{
            // check is payload ready
            if(Payloads !== null && Payloads !== {} ) {
                return resolve(Payloads)
            }
            // if not, wait for payload
            var timeOut = Date.now()

            const interval = setInterval(() => {
                if(Payloads !== null && Payloads !== {} ) {
                    clearInterval(interval)
                    return resolve(Payloads)
                }

                if(Date.now() - timeOut > 10000) {
                    clearInterval(interval)
                    return reject("Waiter timeout")
                }
            }, 1000)

        })
    }catch(err) {
        return Promise.reject(err)
    }
}