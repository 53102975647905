import { IndexOf,PHPBOOL, RenderEngineVersion } from 'Interfaces/App.enums';
import React from 'react'
import { RootState } from '../../../../../stores/stores'
import { connect } from 'react-redux'
import { IArnoldOptions } from '../../../../../Interfaces/Renderer'
import { RenderReducerActionEnum } from '../../../../../stores/reducers/render.interface'
import { RenderOptionsInterface } from 'screens/RenderNow/RenderNow.interface'
import './arnold.scss'


const mapStates = (states: RootState) => ({
     Render: states.Render
})
const arnoldDispatchMapper = {
     SetAASamples: (filename : number) =>({ type : RenderReducerActionEnum.SetAASamples, value : filename }),
     SetGIDiffuseSamples: (filename : number) =>({ type : RenderReducerActionEnum.SetGIDiffuseSamples, value : filename }),
     SetGIDiffuseDepth: (filename : number) =>({ type : RenderReducerActionEnum.SetGIDiffuseDepth, value : filename }),
     SetGISpecularSamples: (filename : number) =>({ type : RenderReducerActionEnum.SetGISpecularSamples, value : filename }),
     SetGISpecularDepth: (filename : number) =>({ type : RenderReducerActionEnum.SetGISpecularDepth, value : filename }),
     SetGITransmissionSamples: (filename : number) =>({ type : RenderReducerActionEnum.SetGITransmissionSamples, value :
     filename }),
     SetGITransmissionDepth: (filename : number) =>({ type : RenderReducerActionEnum.SetGITransmissionDepth, value : filename
     }),
     SetGISSSSamples: (filename : number) =>({ type : RenderReducerActionEnum.SetGISSSSamples, value : filename }),
     SetGIVolumeSamples: (filename : number) =>({ type : RenderReducerActionEnum.SetGIVolumeSamples, value : filename }),
     SetGIVolumeDepth: (filename : number) =>({ type : RenderReducerActionEnum.SetGIVolumeDepth, value : filename })
}

type ArnoldProps = ReturnType<typeof mapStates> & typeof arnoldDispatchMapper & {
     renderOptions: RenderOptionsInterface
     }

     function Arnold( Props: ArnoldProps) {

     const Scene = Props.Render?.body?.scene
     if (Scene && ! Scene?.params?.scene_param?.render_engine.toLowerCase().includes('arnold') ) {
          return <div></div>
     }

     if (JSON.stringify(Props.renderOptions.body.scene.params.arnold_option) == "{}") {
          return <></>
     }
     return (
     <div className="accordion-item">
          <h2 className="accordion-header" id="heading0">
               <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapse0" aria-expanded="false" aria-controls="collapse0">
                    Arnold options
               </button>
          </h2>
          <div id="collapse0" className="accordion-collapse collapse" aria-labelledby="heading0"
               data-bs-parent="#param-settings">
               <div className="accordion-body">
                    {/* Sampling and Ray Depth */}
                    <div className="card mb-3">
                         <div className="card-header">
                              <h6 className="card-title">Sampling and Ray Depth</h6>
                         </div>
                         <div className="card-body">
                              <div className="d-flex mb-2">
                                   <div className="col-2 mt-auto">
                                        <label className="form-label">Camera (AA) </label>
                                   </div>
                                   <div className="row col-8">
                                        <div className="col-4">
                                             <span className="input-group-text">Samples </span>
                                             <input type="number" className="form-control"
                                                  disabled
                                                  value={Scene?.params.arnold_option?.AA_samples} min={0} onChange={e=>
                                                       {
                                                       Props.SetAASamples(e.target.valueAsNumber)
                                                       }}
                                             />
                                        </div>
                                        <div className="col-4">
                                             <span className="input-group-text">Ray Depth </span>
                                        </div>

                                   </div>
                              </div>
                              <div className="d-flex mb-2">
                                   <div className="col-2 mt-auto">
                                        <label className="form-label">Diffuse </label>
                                   </div>
                                   <div className="row col-8">
                                        <div className="col-4">
                                             <input type="number" className="form-control"
                                                  value={Scene?.params.arnold_option?.GI_diffuse_samples} min={0}
                                                  onChange={e=> {
                                                       Props.SetGIDiffuseSamples(e.target.valueAsNumber)
                                                  }}
                                                  disabled
                                             />
                                        </div>
                                        <div className="col-4">
                                             <input type="number" className="form-control"
                                                  value={Scene?.params.arnold_option?.GI_diffuse_depth} min={0}
                                                  onChange={e=> {
                                                       Props.SetGIDiffuseDepth(e.target.valueAsNumber)
                                                  }}
                                                  disabled />
                                        </div>

                                   </div>
                              </div>
                              <div className="d-flex mb-2">
                                   <div className="col-2 mt-auto">
                                        <label className="form-label">Specular </label>
                                   </div>
                                   <div className="row col-8">
                                        <div className="col-4">
                                             <input type="number" className="form-control"
                                                  value={Scene?.params.arnold_option?.GI_specular_samples} min={0}
                                                  onChange={e=> {
                                                       Props.SetGISpecularSamples(e.target.valueAsNumber)
                                                  }}
                                                  disabled   />
                                        </div>
                                        <div className="col-4">
                                             <input type="number" className="form-control"
                                                  value={Scene?.params.arnold_option?.GI_specular_depth} min={0}
                                                  onChange={e=> {
                                                       Props.SetGISpecularDepth(e.target.valueAsNumber)
                                                  }}
                                             disabled />
                                        </div>

                                   </div>
                              </div>
                              <div className="d-flex mb-2">
                                   <div className="col-2 mt-auto">
                                        <label className="form-label">Transmission </label>
                                   </div>
                                   <div className="row col-8">
                                        <div className="col-4">
                                             <input type="number" className="form-control"
                                                  value={Scene?.params.arnold_option?.GI_transmission_samples} min={0}
                                                  onChange={e=> {
                                                       Props.SetGITransmissionSamples(e.target.valueAsNumber)
                                                  }} 
                                                  disabled />
                                        </div>
                                        <div className="col-4">
                                             <input type="number" className="form-control"
                                                  value={Scene?.params.arnold_option?.GI_transmission_depth} min={0}
                                                  onChange={e=> {
                                                  Props.SetGITransmissionDepth(e.target.valueAsNumber)
                                                  }} 
                                                  disabled/>
                                        </div>

                                   </div>
                              </div>
                              <div className="d-flex mb-2">
                                   <div className="col-2 mt-auto">
                                        <label className="form-label">SSS </label>
                                   </div>
                                   <div className="row col-8">
                                        <div className="col-4">
                                             <input type="number" className="form-control"
                                                  value={Scene?.params.arnold_option?.GI_sss_samples} min={0}
                                                  onChange={e=> {
                                                  Props.SetGISSSSamples(e.target.valueAsNumber)
                                                  }}
                                                  disabled/>
                                        </div>
                                        <div className="col-4">
                                        </div>

                                   </div>
                              </div>
                              <div className="d-flex mb-2">
                                   <div className="col-2 mt-auto">
                                        <label className="form-label">Volume Indirect </label>
                                   </div>
                                   <div className="row col-8">
                                        <div className="col-4">
                                             <input type="number" className="form-control"
                                                  value={Scene?.params.arnold_option?.GI_volume_samples} min={0}
                                                  onChange={e=> {
                                                  Props.SetGIVolumeSamples(e.target.valueAsNumber)
                                                  }}
                                                  disabled
                                             />
                                        </div>
                                        <div className="col-4">
                                             <input type="number" className="form-control"
                                                  value={Scene?.params.arnold_option?.GI_volume_depth} min={0}
                                                  onChange={e=> {
                                                  Props.SetGIVolumeDepth(e.target.valueAsNumber)
                                                  }}
                                                  disabled
                                             />
                                        </div>

                                   </div>
                              </div>

                         </div>
                    </div>

                    {/* AOVs List */}
                    <div className="card">
                         <div className="card-header">
                              <h6 className="card-title">AOVs List</h6>
                         </div>
                         <div className="card-body">
                              <table className="table text-white">
                                   <thead>
                                        <tr>
                                             <th scope="col">No</th>
                                             <th scope="col">Suffix</th>
                                             <th scope="col">Name</th>
                                             <th scope="col">Data</th>
                                        </tr>
                                   </thead>
                                   <tbody>
                                        <tr>
                                             <th colSpan={4} className="text-center">No Elements</th>

                                        </tr>
                                   </tbody>
                              </table>
                         </div>
                    </div>

               </div>
          </div>
     </div>
     )
     }
     export default connect(mapStates, arnoldDispatchMapper)(Arnold)