import { RenderOutputType } from "../../../../../Interfaces/Renderer";
import { RenderReducerActionEnum } from "../../../../../stores/reducers/render.interface";

export const OutputSettingsDistpatchs = {
    // Change output file type to another file type
    SetOutputFileType : (outputExtension:RenderOutputType) => ({ type : RenderReducerActionEnum.SetOutputFileType, value: outputExtension}),
    // Change output file name
    SetOutputFileName : (outputFileName:string) => ({ type : RenderReducerActionEnum.SetOutputFileType, value: outputFileName}),
    // Update output properties
    updateOutputProperties : <Type extends any >(name : string , value: Type ) => ({ type : RenderReducerActionEnum.UpdateOutputProperties, value : {name,value} }),
    updateOutputPropertiesNoReRender : <Type extends any >(name : string , value: Type ) => ({ type : RenderReducerActionEnum.UpdateNrrOutputProperties, value : {name,value} })

}