import React, { useEffect, useState } from 'react'
import './login-form.scss'
import logo from '../../assets/logo.png'
import background from './background.png'
import axios from 'axios'
import { LoginApiResponse } from '../../Interfaces/Http'
import { IpcSender } from '../../Interfaces/IpcRenderer.enum'
import Swal from 'sweetalert2'
import Loading from 'components/Loading'

const { ipcRenderer } = window.electron;
const electron = window.electron;

type LoginFormProps = {

}

export default function LoginForm( Props : LoginFormProps) {
    const [UserEmail, setUserEmail] = useState("")
    const [UserPassword, setUserPassword] = useState("")
    const [isValidateFailed, setIsCorrect] = useState(false)
    const [rememberMe, setRememberMe] = useState<boolean>(true)
    const [LoadingScene, setLoadingScene] = useState(false)

    async function SignInHandler() {
        setLoadingScene(true)
        try {
            setIsCorrect(false)
            let Response = await axios.post<LoginApiResponse>('https://olapi.superrendersfarm.com/api/user/login' , {
                email: UserEmail,
                password: UserPassword
            })

            if(Response?.data?.token) {
                // Send token to main process
                ipcRenderer.send("login-success", Response.data)
                // clear recent logged in sessions
                ipcRenderer.send("clear-authenticated-sessions")
                // save jwt access token to local storage
                localStorage.setItem("access_token", Response.data.token);
                // Request to set user configuration
                ipcRenderer.send(IpcSender.SetCredentials)

                return;
            }
        }catch (error:any) {
            setTimeout(() => {
                setLoadingScene(false)
            },50);
            console.log(error.response)
            Swal.fire({
                title: "Unauthorized" ,
                text:   UserEmail.length===0?"Username or email cannot be empty."
                        :UserPassword.length===0?"Password cannot be empty."
                        :error?.response?.data?.message ,
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#218c61',
                preConfirm() {
                    setIsCorrect(true)
                }
            })
        }

    }

    const handleKeyDown = (event:any) => {
        if (event.key === 'Enter') {
          
          if ( !LoadingScene ){
            SignInHandler()
            console.log('do validate')
          }
        }
    }
    const toggleRememberMe = () => {
        setRememberMe(!rememberMe)
    }

    return (
    <>
    <div className={LoadingScene?"loading":"d-none" }>
        <Loading/>
    </div>
    <div className="login-form" style={{backgroundSize:"cover",backgroundPosition:"left",backgroundRepeat:"no-repeat",backgroundImage:`url("${background}")`}}>
        <div className="title-bar">
            <div className="d-flex">
                <div className="title-brand d-flex">
                    <div className="logo" style={{backgroundImage:`url("${logo}")`}}/>
                    <div className="mt-2">
                        <span className="logo-title-bar-text-super">SUPER</span>
                        <span className="logo-title-bar-text-renders">RENDERS</span>
                    </div>
                </div>
            </div>
            <div className="drag col"></div>
            <div style={{width:138}}></div>
        </div>
        <div className="d-flex h-100">
            <div className="content">
                <div className="row">
                    <div className="col-md-6">
                        <div className="display-5 fw-bold" style={{marginTop:"7rem"}} >SUPER RENDERS<br/>FARM</div>
                    </div>
                    <div className="col-md-6 ps-5">
                        <div className="d-flex mb-3">
                            <div className="col-10">
                                <div className="fw-light fs-6">Welcome,</div>
                                <div className="fw-bold fs-5">Have nice days</div>
                            </div>
                            <div className="col-2">
                                <div className="logo ms-auto" style={{backgroundSize:"cover",height:"60px",width:"60px",backgroundRepeat:"no-repeat",backgroundImage:`url("${logo}")`}}></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-floating mb-3">
                                    <input 
                                        type='text'
                                        value={UserEmail}
                                        onChange={e => {
                                            let Email = e.target.value as string
                                            setUserEmail(Email)
                                        }}
                                        spellCheck={false}
                                        onKeyDown={handleKeyDown}
                                        className={isValidateFailed ? "border border-danger text-danger form-control" : "form-control"} 
                                        id="floatingInput" 
                                        placeholder="name@example.com"
                                    />
                                    <label htmlFor="floatingInput" className={isValidateFailed?"text-danger":""}>Email or Username</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input 
                                        type="password" 
                                        value={UserPassword} 
                                        onChange={ e => {
                                            let Password = e.target.value as string
                                            setUserPassword(Password)
                                        }}
                                        onKeyDown={handleKeyDown}
                                        className={isValidateFailed ? "border border-danger text-danger form-control" : "form-control"}
                                        id="floatingPassword"
                                        placeholder="Password"
                                    />
                                    <label htmlFor="floatingPassword" className={isValidateFailed?"text-danger":""}>Password</label>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" checked={rememberMe} id="remember-me" onChange={toggleRememberMe} />
                                    <label className="form-check-label" htmlFor="remember-me" >
                                        Remember me
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="d-grid mb-3">
                            <button onClick={SignInHandler} className="btn btn-success" disabled={LoadingScene}>Sign in</button>
                        </div>
                        <div className="d-flex">
                            <div className="me-auto sign-up">
                                {/* Sign up */}
                                <button className="btn btn-sm ps-0" onClick={() => {
                                    electron.shell.openExternal("https://superrendersfarm.com/account/register")
                                }}>Sign up</button>
                            </div>
                            <div className="ms-auto forgot-password">
                                <button className="btn btn-sm pe-0" onClick={() => {
                                    electron.shell.openExternal("https://superrendersfarm.com/account/forgot-password")
                                }}>Forgot password</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer content fixed-bottom">
            <div className="d-flex">
                {/* 
                // Currently not in use
                // Srf was not support multiple zone yet
                <div className="me-auto">
                    <div className="server">
                        <i className="fad fa-globe-europe fa-lg"></i>
                        <span className="server-name">North Virginia</span>
                        <span className="server-quality">Good</span>
                    </div>
                </div> */}

            </div>
        </div>
    </div>
    </>
    )
}