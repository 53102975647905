import NotFoundImage from "../../../../../assets/SVG/style-dark-1/53.svg"

export function NoItemInView() {
    return (
        <div className="notification-screen">
            <div className="text-center m-auto">
                <h4>This folder is empty !</h4>
                <div className="logo m-auto my-4" style={{backgroundSize:"cover",height:"220px",width:"210px",backgroundRepeat:"no-repeat",backgroundImage:`url("${NotFoundImage}")`}}/>
                <p>Please <strong>upload</strong> or <strong>create</strong> new folder</p>
            </div>
        </div>
  
    )
}