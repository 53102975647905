import { CloudStorageAction, SpaceItem, SpaceItemType, SpacesAction } from '../../../../stores/reducers/spaces.interface';

const SceneObject : SpaceItem = {
    name : "scene.ma",
    extension : "ma",
    path : "superrenders_hain_srfspaces/Test_Scene/scene.ma",
    size : 2121212,
    isCompressed : false,
    isDownloadable :true,
    type : SpaceItemType.Scene,
    isPublic : true,
    isSelected:false,
    extra : {
        ContentLength : 292919291,
        Region : "us-east-1",
        Etag : "abc7192898f20fd012e",
        LastModified : new Date("2019-01-01T00:00:00.000Z"),
    },
    metadata : [
        {
            key : "owner",
            value : "superrenders",
            description : "Owner of the file"
        }
    ],
    url : "https://s3.amazonaws.com/superrenders/superrenders_hain_srfspaces/Test_Scene/scene.ma",
    key : "srf-official-input/superrenders_hain_srfspaces/Test_Scene/scene.ma",
    parent : "Test_Scene",
}

const DriveObject : SpaceItem  = {
    name : "D",
    extension : "",
    path : "superrenders_hain_srfspaces/D",
    size : 0,
    isCompressed : false,
    isDownloadable :true,
    isPublic : true,
    type : SpaceItemType.Scene,
    isSelected: false ,
    extra : {
        ContentLength : 0,
        Region : "us-east-1",
        Etag : "abc7192898f20fd012e",
        LastModified : new Date,
    },
    metadata : [
        {
            key : "owner",
            value : "superrenders",
            description : "Owner of the file"
        }
    ],
    url : "https://s3.amazonaws.com/superrenders/superrenders_hain_srfspaces/Test_Scene/scene.ma",
    key : "srf-official-input/superrenders_hain_srfspaces/Test_Scene/scene.ma",
    parent : "Test_Scene",
}

const FolderObject  : SpaceItem = {
    name : "D",
    extension : "",
    path : "superrenders_hain_srfspaces/D",
    size : 0,
    isCompressed : false,
    isDownloadable :true,
    type :  SpaceItemType.Folder,
    isPublic : true,
    isSelected: false ,
    extra : {
        ContentLength : 0,
        Region : "us-east-1",
        Etag : "abc7192898f20fd012e",
        LastModified : new Date,
    },
    metadata : [
        {
            key : "owner",
            value : "superrenders",
            description : "Owner of the file"
        }
    ],
    url : "https://s3.amazonaws.com/superrenders/superrenders_hain_srfspaces/Test_Scene/scene.ma",
    key : "srf-official-input/superrenders_hain_srfspaces/Test_Scene/scene.ma",
    parent : "Test_Scene",
}

const items:SpaceItem[] = [{...DriveObject}, {...SceneObject} , {...FolderObject}];
export const CloudStorageDispatch = {
    initializeStorage : async () : Promise<SpacesAction<string>> => ({ type: CloudStorageAction.INITIALIZE_STORAGE, payload : ""}),
    setCurrentPath : (Path :string) : SpacesAction<string> => ({ type: CloudStorageAction.SET_CURRENT_PATH, payload : Path}),
    setRootPath : ( WorkingFolder :string) : SpacesAction<string> => ({ type: CloudStorageAction.SET_ROOT_PATH, payload : ""}),
    setBreadcrumbs : (Path :string) : SpacesAction<string> => ({ type: CloudStorageAction.SET_BREADCRUMBS, payload : Path}),
    Migrate : () : SpacesAction<SpaceItem[]> =>({ type: CloudStorageAction.Migrate, payload :  items }),
    SetItems: (Prefix : string ) : SpacesAction<string> =>  ({ type: CloudStorageAction.SagaFetch, payload :  Prefix }),
    Search : (query : string ) : SpacesAction<string> => ({ type: CloudStorageAction.Search, payload :  query }),
    Load : () : SpacesAction<string> => ({type : CloudStorageAction.Load, payload : ""}),
}