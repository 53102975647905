
export type modalScreenType = {
    Node: React.ReactNode | null;
    show : boolean
    isFocused : boolean
}


export enum modalScreenActions {
    RenderModal = "LazyModal/RenderModal",
    CloseModal = "LazyModal/CloseModal",
    Hide = "LazyModal/Hide",
    Clear = "LazyModal::Clear",
    Focus = "LazyModal/Focus",
}