import React, { useState } from "react";
import "./render-setting.scss";

type RenderSettingProps = {
  framePerTask: number,
  renderPriority: number,
  usePreviousAssetsIfMissing: boolean,
  setFramePerTask: (n: number) => void,
  setRenderPriority: (n: number) => void,
  setUsePreviousAssetsIfMissing: (n: boolean) => void,
}

function RenderSetting(Props: RenderSettingProps) {

  return (
    <div className="render-setting">
      <div className="form-group mb-3">
        <label htmlFor="input_gamma" className="form-label semibold">
          RENDER PRIORITY
        </label>
        <select
          id="render_priority"
          onChange={(e) => {
            var PriorityNumber: number = parseInt(e.target.value);
            // Set renders
            Props.setRenderPriority(PriorityNumber);
          }}
          value={Props.renderPriority}
          className="form-select"
          name="render_priority"
        >
          <option value="10">
            Bronze - 0.004 Render Credits / GHz-hr - 20 render nodes
          </option>
          <option value="20">
            Silver - 0.008 Render Credits / GHz-hr - 80 render nodes
          </option>
          <option value="30">
            Gold - 0.016 Render Credits / GHz-hr - 200+ render nodes
          </option>
        </select>
      </div>
      <div className="form-group mb-3">
        <label htmlFor="input_gamma" className="form-label semibold">
          FRAMES PER NODE<span className="text-danger">*</span>
        </label>
        <input
          value={Props.framePerTask}
          onChange={(e) => Props.setFramePerTask(parseInt(e.target.value))}
          type="number"
          className="form-control"
          id="frame_per_task"
          aria-describedby="frame_per_task_help"
          min="1"
        />{" "}
        <small id="frame_per_task_help" className="form-text">
          Recommend set number of frames per node &gt; 1 if your scene require
          less than 10 minutes to render 1 frame.
        </small>
      </div>

      <div className="form-check">
        <input
          type="checkbox"
          id="checkbox"
          className="form-check-input"
          checked={Props.usePreviousAssetsIfMissing}
          onChange={(e) => Props.setUsePreviousAssetsIfMissing(e.target.checked)}
        />
        <label htmlFor="checkbox" className="form-check-label">
          In case of missing assets, use ones that found in previous render
          jobs.
        </label>
      </div>
    </div>
  );
}
export default RenderSetting
