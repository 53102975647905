import {RENDERER_VERSIONS,SOFTWARES_TYPE,SOFTWARES_VERSION,} from "config";
import React, { useEffect, useState } from "react";
import * as path from 'path';
import { SpaceItem } from "types/spaces.type";
import RenderSetting from "../../../components/RenderNowComponent/ParamSettings/components/RenderSetting";
import axios from "axios";
import { API } from "apis/base.api";
import Swal from "sweetalert2";
import { contentSecurityPolicy } from "helmet";
import { Processor } from "Interfaces/App.enums";
import { RootState } from "stores/stores";
import { connect } from "react-redux";
import Tippy from "@tippyjs/react";

const { ipcRenderer } = window.electron;


const mapState = (state: RootState) => ({
     supportedSoftwares: state.SupportedSoftwares.list || []
})

export type  AfterEfectRenderNowProps = ReturnType<typeof mapState> &  {
     item: SpaceItem,
     closeModal : any,
}

function AfterEfectRenderNow({item,closeModal,supportedSoftwares}: AfterEfectRenderNowProps) {

     const [hardware, setHardware] = useState(Processor.CPU);
     const [scenePath, setScenePath] = useState<string>(item.key);
     const [software, setSoftware] = useState<SOFTWARES_VERSION>(SOFTWARES_VERSION.UNDEFINED);
     const [renderer, setRenderer] = useState<RENDERER_VERSIONS>(RENDERER_VERSIONS.UNDEFINED);
     const [startFrame, setStartFrame] = useState<number>(1);
     const [endFrame, setEndFrame] = useState<number>(10);
     const [frameStep, setFrameStep] = useState<number>(1);
     const [composition, setComposition] = useState<string>("");
     const [renderSettingsTemplate, setRenderSettingsTemplate] = useState<string>("");
     const [outputFileName, setOutputFileName] = useState<string>('');

     // Render configs
     // use ones that found in previous render jobs
     const [useMissingTextureFromPreviusRenderJob,setUseMissingTextureFromPreviusRenderJob,] = useState<boolean>(true);
     const [renderPriority, setRenderPriority] = useState<number>(10);
     const [framePerTask, setFramePerTask] = useState<number>(1);
     // handle on render
     async function handleRenderNow() {
       const json_body = {
          software_version: software,
          renderer_version: renderer,
          scene_3dApp: "aep",
          job_gcpu: hardware,
          render_type:"animation",
          working_folder_path: "A:/"+scenePath.substring(0, scenePath.lastIndexOf("/")),
          scene_path: "A:/"+item.key,
          start_frame: startFrame,
          end_frame: endFrame,
          frame_steps: frameStep,
          scene_name: item.name,
          camera: composition,
          gamma_correction: 0,
          input_gamma: 2.2,
          output_gamma: 1,
          mod_animation_prepass: 0,
          batch: renderSettingsTemplate,
          outputNameType: item.name.split(".")[1],
          renderoutput_path:  item.name.substring(0, item.name.lastIndexOf('.')) +"/"+ outputFileName ,
          render_priority: renderPriority,
          render_frame_per_machine: framePerTask,
          rf_use_default_asset_collection: useMissingTextureFromPreviusRenderJob? 1:0 ,
          client_id: await ipcRenderer.invoke('InvokeHandle::getPcName'),
          movie_format:"", 
          movie_fps: "",
          movie_image_pattern:"", 
          output_driver:"" ,
          multipassoutput_folder: "",
          warning_file_type_error: 1,
          warning_scene_name_error: 1,
       }
       const sender_json_skeleton:any = {
          "software": software ,
          "renderer": renderer ,
          "scenePath": path.join("A:/", item.key),
          "server_scene_name" : path.basename( item.key || ""),
          "projectPath": path.dirname(path.join("A:/", item.key)),
          "working_path_v2" : path.dirname(path.join("A:/", item.key)),
          "job_gcpu": hardware,
          "render_type": "animation",
          "startFrame": startFrame,
          "endFrame": endFrame,
          "incrementStep": frameStep,
          // "width": resolutionWidth,
          // "height": resolutionHeight,
          "pixelaspect": "1.0",
          "render_cameras":  "" ,
          "outputNameFile": outputFileName,
          "outputNameType": outputFileName.split(".")[1],
          "gamma_correction": 0,
          "gamma_bitmap_in": 2.2,
          "gamma_bitmap_out": 1,
          "render_priority": renderPriority,
          "render_frame_per_machine": framePerTask,
          "vray_option": "",
          "corona_option": "",
          "arnold_option": "",
          "srf_user_accepted_to_use_default_asset": useMissingTextureFromPreviusRenderJob,
          // get client_id
          "client_id": await ipcRenderer.invoke("InvokeHandle::getPcName")
      }
          if (renderer === RENDERER_VERSIONS.UNDEFINED || software === SOFTWARES_VERSION.UNDEFINED ) {
          Swal.fire({
          title:  'Missing information',
          text:  'Please select software version and render engine version',
          icon: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: '#218c61',
          })
          }
          if (outputFileName === '' || outputFileName === undefined || outputFileName === null ) {
               Swal.fire({
               title:  'Missing information',
               text:  'Please enter the output file name',
               icon: 'error',
               confirmButtonText: 'OK',
               confirmButtonColor: '#218c61',
               })
          }
          else {
               API.guard().post("/render-job/app/create-new", sender_json_skeleton)

               closeModal()
               Swal.fire({
                    title:  'Render Job Created',
                    text:  `Your render job: ${item.name} has been created!`,
                    icon: 'info',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#218c61',

               })
          }   
     }

  return (
     <div className="render-without-analyze">
          <div className="px-3 pt-2">
               <div className="form-group mb-3">
                    <small className="form-label semibold">
                    Scene Path <span className="text-danger">*</span>
                    <small className="text-muted text-capitalize">
                         <i className="fad fa-folder ms-2" /> Your project saved in SRF Hard drive as N:/.
                    </small>
                    </small>
                    <div
                    className="form-control-wrapper form-control-icon-left"
                    style={{ position: "relative" }}
                    >
                    <input type="text" className="form-control srf_require_field" disabled value={scenePath} placeholder="Scene Path" style={{ paddingLeft: "35px", paddingTop: "7px" }} />
                    <div className="text-light" 
                         style={{
                              width: "38px",
                              height: "37px",
                              lineHeight: "37px",
                              textAlign: "center",
                              position: "absolute",
                              top: "0px",
                         }}
                    >
                         <i className="fa fa-folder" />
                    </div>
                    </div>
               </div>
    
               <div className="form-group row mb-3">
                    <div className="form-group col-6">
                         <small className="form-label semibold">
                              Software Version <span className="text-danger">*</span>
                         </small>
                         <select className="form-select"
                              value={software}
                              onChange={(e) => {
                                   const value = e.target.value as SOFTWARES_VERSION;
                                   setSoftware(value);
                              }}
                         >
                              <option selected={software===SOFTWARES_VERSION.UNDEFINED}>Choose Software Version</option>
                              {
                                   supportedSoftwares.map((version, indexs) => {
                                   if(version.type === SOFTWARES_TYPE.AFTEREFECT) {
                                             return (
                                                  <option
                                                       key={indexs}
                                                       value={version.software_version_value}
                                                  >
                                                       {version.display_name}
                                                  </option>
                                             );
                                        }
                                   })
                              }
                         </select>
                    </div>
                    <Tippy disabled={software!==SOFTWARES_VERSION.UNDEFINED} content={"Please choose software version first!"}>
                         <div className="form-group col-6">
                              <small className="form-label semibold">Render Engine Version <span className="text-danger">*</span></small>
                              <select className="form-select"
                                   disabled={software===SOFTWARES_VERSION.UNDEFINED}
                                   value={renderer}
                                   onChange={(e) => {
                                        const value = e.target.value as RENDERER_VERSIONS;
                                        setRenderer(value);
                                   }}
                              >
                                   <option selected={renderer === RENDERER_VERSIONS.UNDEFINED}>Choose engine version...</option>
                                   {supportedSoftwares
                                        .filter(ss => ss.software_version_value === software)
                                        .map((rrv, index) => 
                                             rrv.supported_engines.map((opt,key) => 
                                                  <optgroup key={opt.id} label={opt.name}>
                                                       {opt.versions.map((option, index) => {
                                                            const optProcessor = option.cpu ? Processor.CPU : Processor.GPU
                                                            return <option key={option.id} value={option.renderer_version_value} disabled={optProcessor !== hardware}>{option.name}</option>
                                                       })}
                                                  </optgroup> 
                                             )
                                        )
                                   }
                              </select>
                         </div>
                    </Tippy>
               </div>
               <div className="row mb-3">
                    <div className="col-4" id="start_frame_form_group">
                         <div className="form-group">
                              <label className="form-label semibold">
                                   Range From <span className="text-danger">*</span>
                              </label>
                              <div className="form-control-wrapper">
                                   <input
                                        type="number"
                                        className="form-control srf_require_field"
                                        placeholder="Start From"
                                        value={startFrame}
                                        min={1}
                                        onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        setStartFrame(value);
                                        }}
                                   />
                              </div>
                         </div>
                    </div>
                    <div className="col-4">
                         <div className="form-group">
                              <label className="form-label semibold">
                                   To <span className="text-danger">*</span>
                              </label>
                              <div className="form-control-wrapper">
                                   <input
                                        type="number"
                                        className="form-control srf_require_field"
                                        placeholder="To"
                                        min={1}
                                        value={endFrame}
                                        onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        setEndFrame(value);
                                        }}
                                   />
                              </div>
                         </div>
                    </div>
                    <div className="col-4">
                         <div className="form-group">
                              <label className="form-label semibold">
                                   Every Nth Frame <span className="text-danger">*</span>
                              </label>
                              <div className="form-control-wrapper">
                                   <input
                                        type="number"
                                        min={1}
                                        className="form-control srf_require_field"
                                        placeholder="Frame Steps"
                                        value={frameStep}
                                        onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        setFrameStep(value);
                                        }}
                                   />
                              </div>
                         </div>
                    </div>
               </div>
               <div className="form-group mb-3">
                    <label className="form-label semibold">
                    Render Output File <span className="text-danger">*</span>
                    </label>
                    <div className="form-control-wrapper">
                         <input
                              value={outputFileName}
                              onChange={(e) => {
                              setOutputFileName(e.target.value);
                              }}
                              type="text"
                              className="form-control"
                              placeholder="e.g: filename"
                         />
                    </div>
               </div>
               <div className="row mb-3">
                    <div className="col-8" id="image_width_form_group">
                         <div className="form-group">
                              <label id="image_width_label" className="form-label semibold">
                                   Composition
                              </label>
                              <div className="form-control-wrapper">
                                   <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Leave blank to select active camera in your scene"
                                        value={composition}
                                        onChange={e=>setComposition(e.target.value)}
                                   />
                              </div>
                         </div>
                    </div>
                    <div className="col-4" id="image_height_form_group">
                         <div className="form-group">
                              <label id="image_height_label" className="form-label semibold">
                                   Render Settings Template
                              </label>
                              <div className="form-control-wrapper">
                                   <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Leave blank if not use"
                                        value={renderSettingsTemplate}
                                        onChange={e=>setRenderSettingsTemplate(e.target.value)}
                                   />
                              </div>
                         </div>
                    </div>
               </div>
               <RenderSetting
                    framePerTask={framePerTask}
                    renderPriority={renderPriority}
                    usePreviousAssetsIfMissing={useMissingTextureFromPreviusRenderJob}
                    setFramePerTask={setFramePerTask}
                    setRenderPriority={setRenderPriority}
                    setUsePreviousAssetsIfMissing={setUseMissingTextureFromPreviusRenderJob}
               />
          </div>
          <div className="fixed-bottom">
               <div className="d-flex m-2">
                    <div className="ms-auto">
                         <button className="btn btn-sm " onClick={() => closeModal()}>Cancel</button>
                         <button className="btn btn-sm btn-success ms-2" type="submit"
                              onClick={()=>{
                                   handleRenderNow()
                                 
                              }}
                         >Render Now
                         </button>
                    </div>
               </div>
          </div>
     </div >
   );
}


export default connect(mapState, {})(AfterEfectRenderNow)