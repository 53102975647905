import React, { useEffect, useState } from "react";
import Camera from "../components/Camera";
import Resolution from "../components/Resolution";
import ScenePath from "../components/ScenePath";
import RenderSetting from "../components/RenderSetting";
import { connect } from "react-redux";
import { RootState } from "../../../../stores/stores";
import FrameRange from "../components/FrameRange";
import { RenderReducerActionEnum } from "stores/reducers/render.interface";
import { OutputFileType } from "typescript";
import camerasUtils from "../components/Camera/camerasUtils";


export function MayaStates(States: RootState) {
  return {
       renderOptions: States.Render
  }
}
export const RenderNowMapDispatchs = {
  switch_render_flags: (key: string) => ({ type: RenderReducerActionEnum.SwitchRenderFlags, value: key }),
  set_height: (value: number) => ({ type: RenderReducerActionEnum.ChangeHeight, value }),
  set_width: (value: number) => ({ type: RenderReducerActionEnum.ChangeWidth, value }),
  set_start_frame_range: (value: number) => ({ type: RenderReducerActionEnum.SetStartFrameRange, value }),
  set_end_frame_range: (value: number) => ({ type: RenderReducerActionEnum.SetEndFrameRange, value }),
  set_incremental_step: (value: number) => ({ type: RenderReducerActionEnum.SetIncrementalStep, value }),
  set_frame_pertask: (value: number) => ({ type: RenderReducerActionEnum.SetFramePerTask, value }),
  set_render_priority: (value: number) => ({ type: RenderReducerActionEnum.SetRenderPriority, value }),
  set_use_missing_assets: (value: number) => ({ type: RenderReducerActionEnum.SetUseMissingAssets, value }),
}

export type MayaProps = {} & ReturnType<typeof MayaStates> & typeof RenderNowMapDispatchs

function Maya(Props: MayaProps) {
  const { renderOptions, switch_render_flags, ...render } = Props
  const [rerender, setRerender] = useState(false);
  const filterLayerDefault = renderOptions.body.scene.params.render_layers.filter(layer => layer.is_default_layer === true ).map(v=>v.layer_name)
  useEffect(() => {
    camerasUtils.clearCameras();
    camerasUtils.addCamera(filterLayerDefault.toString());
    setRerender(!rerender);
    Props.set_height(renderOptions.body.scene.params.height)
    Props.set_width(renderOptions.body.scene.params.width)
    Props.set_end_frame_range(renderOptions.body.scene.params.endFrame)
    Props.set_start_frame_range(renderOptions.body.scene.params.startFrame)
    Props.set_incremental_step(renderOptions.body.scene.params.incrementStep)
  }, [])
  return (
    <>
          <ScenePath />
          <div className="d-flex mb-3 camera">
            <label className="form-label">Layer </label>
              <div className="row col">
                  <div className="col-8">
                    <div className="dropdown">
                        <button type="button" className="form-select text-start" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                        {camerasUtils.getCameras() == 0 ? "Select layer(s)" : `${camerasUtils.getCameras().length} layer selected`}  
                        </button>
                        <div className="dropdown-menu dropdown-menu-dark">
                              <div className="list-group" >
                              { (renderOptions.body.scene.params.render_layers !== undefined ) ? 
                              renderOptions.body.scene.params.render_layers.map(
                              (layer, index) => (
                                  <label className="list-group-item">
                                  <input
                                  className="form-check-input me-1"
                                  type="checkbox"
                                  key={index}
                                  checked={camerasUtils.getCameras().includes(layer.layer_name)}
                                  value={layer.layer_name}
                                  onClick={() => {
                                    camerasUtils.toggleCamera(layer.layer_name);
                                    setRerender(!rerender);
                                  }}
                                  />
                                  {layer.layer_name}
                              </label>
                              )
                              ) : <div className="m-2">No layer</div>}
                              </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
          <Camera />
          <FrameRange 
              EndFrameRange={renderOptions.body.scene.params.scene_param.endFrame}
              IncrementalStep={renderOptions.body.scene.params.scene_param.incrementStep}
              StartFrameRange={renderOptions.body.scene.params.scene_param.startFrame} 
              setEndFrameRange={function (n: number): void {
                    render.set_end_frame_range(n)}}
              setStartFrameRange={
                    function (n: number): void {
                      render.set_start_frame_range(n)} }
              setIncrementalStep={function (n: number): void {
                    render.set_incremental_step(n)}}
        />
          <div className="d-flex mb-3">
               <label className="form-label mb-2">Output Resolution</label>
               <div className="row col">
                    <div className="col-4">
                         <span className="input-group-text" id="scene-path">Width  <span className="text-danger">*</span></span>
                         <input type="number" className="form-control" id="scene-path" aria-describedby="scene-path" value={renderOptions.body.scene.params.scene_param.width} onChange={e=> Props.set_width(e.target.valueAsNumber)} />
                    </div>
                    <div className="col-4">
                         <span className="input-group-text" id="scene-path">Height  <span className="text-danger">*</span></span>
                         <input type="number" className="form-control" id="scene-path" aria-describedby="scene-path" value={renderOptions.body.scene.params.scene_param.height} onChange={e=> Props.set_height(e.target.valueAsNumber)} />
                    </div>
               </div>
          </div>
          <RenderSetting
            framePerTask={renderOptions.render_configuration.frame_per_task}
            renderPriority={renderOptions.render_configuration.render_priority || 10}
            usePreviousAssetsIfMissing={renderOptions.render_configuration.use_missing_assets===1?true:false}
            setFramePerTask={function (n: number): void {
                  render.set_frame_pertask(n)}} 
            setRenderPriority={function (n: number): void {
                  render.set_render_priority(n)}} 
            setUsePreviousAssetsIfMissing={function (n: boolean): void {
                  n ? render.set_use_missing_assets(1) : render.set_use_missing_assets(0) }} 
            />
    </>
  );
}

export default connect(MayaStates, RenderNowMapDispatchs)(Maya)
