import { io, Socket } from "socket.io-client";
import { SocketEvent } from "./Interfaces/Socket.enum";
import { Stores } from "./stores/stores";
import * as jsonwebtoken from 'jsonwebtoken';
import { IJwt } from "./Interfaces/Authorization.interface";
import { AccountReducerType } from "./stores/reducers/accounting.reducer";
import Log from "./Helpers/Log";
import { InvokeHandleMutation, IpcEvents, IpcResponse, IpcSender } from './Interfaces/IpcRenderer.enum';
import { CloudStorageAction } from './stores/reducers/spaces.interface';
import ESImport from "./esimport";
import { ENotification } from "./stores/reducers/nofification.interface";
import { join } from "path";
import { IWebUserConfig } from "./Interfaces/User";
import { RenderListActionType } from "./stores/reducers/render_list.interface";

const { ipcRenderer } = window.electron;

// Handle when server ask for logout immediately
async function ForceLogoutHandler () {
    ipcRenderer.send("logout")
}

enum RenderJobStatus {
    Pending = "Pending",
    InProgress = "InProgress",
    Done = "Done",
    InRendering = "In Rendering"
}
interface SocketUpdateJob {
    client_id: string
    estimated_remaining_time: string
    job_credits: number
    job_id: string
    job_status: string
    job_status_code: number
    num_nodes_run: number
    render_progress: number
    renderoutput_folder_name: string
    user_credits: number
}

// Update for job
async function RenderJobHasUpdate(Data : SocketUpdateJob) {
    console.log(Data);

    if(!(! Data.user_credits || Data.user_credits == 0)) {
        Stores.dispatch({type: AccountReducerType.SetCredits, value : Data.user_credits})
    }

    const item = Stores.getState().RenderList.items.find(job => job.id == parseInt(Data.job_id))

    if(!item) {
        Stores.dispatch({type: RenderListActionType.SagaFetch})
    }

    const appends:any = {};

    if(Data.job_credits !== 0)
        appends.job_credits = Data.job_credits

    if(Data.estimated_remaining_time)
        appends.estimated_remaining_time = Data.estimated_remaining_time

    Stores.dispatch({type : RenderListActionType.setJobStatus, payload : {
        id: Data.job_id,
        nodes : Data.num_nodes_run,
        estimated_remaining_time : Data.estimated_remaining_time,
        status : Data.job_status,
        render_progress: Data.render_progress,
        ...appends
    }})

}

async function ReloadSrfSpaces(){
    Stores.dispatch({ type: CloudStorageAction.SagaFetch, payload :  window.location.pathname })
}

type ExcludeProps = "job_credits" |
                    "job_status" |
                    "job_status_code" |
                    "render_progress" |
                    "user_credits" |
                    "num_nodes_run" |
                    "estimated_remaining_time"

async function FinalizeSyncOutput(Data : Omit<SocketUpdateJob,ExcludeProps>){
    const item = Stores.getState().RenderList.items.find(job => job.id == parseInt(Data.job_id))
    const pcName = await ipcRenderer.invoke(InvokeHandleMutation.getPcName)
    if(Data.client_id != pcName){
        return;
    }
    const Profile = JSON.parse(localStorage.getItem("user_profiles") as string) as IWebUserConfig;
    const SrfSpaces = Profile.user.srfspaces;
    const OutputPath = join(SrfSpaces,"SuperRendersOutput",Data.renderoutput_folder_name,"/");
    ipcRenderer.send(IpcSender.SyncOutput, {Key : OutputPath, JobId : item?.id , AutoSync : true });

}

export function SocketConfiguration(){
    // Register socket events
    const SocketClient = io(`https://socketio4.superrendersfarm.com/`);
    const AuthorizationConfigs = jsonwebtoken.decode(localStorage.getItem("access_token") || "") as IJwt
    // Check for authorization credentials
    if( !AuthorizationConfigs ){
        Log("SOCKET_CLIENT::ERROR","Cannot find authorization credentials, cannot connect to Socket")
        return;
    }


    // listen on socket connection has been failed
    SocketClient.io.on("reconnect_failed", () => {
        // Socket reconnect failed
        // reconnect socket
        SocketClient.io.connect();
    })

    // Subscribe to event room
    SocketClient.emit( SocketEvent.SUBCRIBE , AuthorizationConfigs.socket_public_id , AuthorizationConfigs.socket_private_id)
    Log("SOCKET_CLIENT:OK::SUBCRIBE", "Connected to Socket.io")

    // Server request for client must be logout immediately
    SocketClient.on(SocketEvent.FORCE_LOGOUT_CLIENT_APP, ForceLogoutHandler )
    SocketClient.on(SocketEvent.RENDER_JOB_UPDATE, RenderJobHasUpdate)
    SocketClient.on(SocketEvent.REQUEST_SYNC_SRF_SPACE, ReloadSrfSpaces)
    // Disable auto sync output
    // SocketClient.on(SocketEvent.REQUEST_SYNC_JOB_OUTPUT, FinalizeSyncOutput)

    // sync from main process
    ipcRenderer.on('srfspaces::reload', ReloadSrfSpaces)
}
