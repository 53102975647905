import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import './screen-modal.scss'
import { RootState, Stores } from '../../stores/stores';
import { modalScreenActions } from '../../stores/reducers/screen_modal.interface';
import { CloseScreenModal, RenderScreenModal } from '../../Helpers/Modal';
import { setTimeout } from 'timers';
import { useShortcut, useShortcutMap } from '../../Helpers/Shortcut';
import { removeListener } from 'process';

const MapStates = ({ScreenModal}:RootState) => ({
    ScreenModal
})

const MapDispatch = {
    Render : ( Component : React.ReactNode ) => ({ type: modalScreenActions.RenderModal , payload : { show: true, Node: Component} }),
}

type ScreenModalProps = ReturnType<typeof MapStates> & typeof MapDispatch & {

}
export function focusModal() {
    Stores.dispatch({ type: modalScreenActions.Focus , payload : true })
}

export function unFocusModal() {
    Stores.dispatch({ type: modalScreenActions.Focus , payload : false })
}

function ScreenModal( Props : ScreenModalProps ) {
    const modalRef : React.RefObject<HTMLDivElement> = React.createRef()
    const [show, setShow] = useState(Props.ScreenModal.show);

    if (!Stores.getState().ScreenModal.show) {
        setTimeout(() => {
            modalRef.current?.classList.add('position-relative')
            modalRef.current?.classList.remove('screen-modal-fade-out')
            modalRef.current?.classList.remove('screen-modal-fade-in')
        }, 500);
    }
    else {
        setTimeout(() => {
            modalRef.current?.classList.remove('screen-modal-fade-in')
        }, 500);
    }
    useEffect(() => {
    
        // Stores.dispatch({ type: modalScreenActions.Focus , payload : true })
        document.addEventListener('keyup', (keyE) => {
            if(keyE.key == "Escape" && Stores.getState().ScreenModal.show && Props.ScreenModal.isFocused) {
                CloseScreenModal()
            }
        });
    },[show,Props.ScreenModal.isFocused])

    return (<div ref={modalRef} className={`screen-modal animate delay-1 ${Props.ScreenModal.show ? "screen-modal-fade-in" : "screen-modal-fade-out"}`}>
        {Props.ScreenModal.Node}
    </div>)
}


export default connect(MapStates, MapDispatch )(React.memo(ScreenModal))