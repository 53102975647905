import React, { useCallback, useEffect, useState } from "react";
import "./file-manager.scss";
import Breadcrumb from "./Breadcrumb";
import RelativeTime from "../RelativeTime";
import DefineIcon from "../DefineIcon";
import FileSize from "../FileSize";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import {
  CloudStorageAction,
  SpaceItem,
  SpaceItemType,
} from "../../../../../stores/reducers/spaces.interface";
import { connect } from "react-redux";
import { RootState } from "../../../../../stores/stores";
import { useHistory } from "react-router-dom";
import { IndexOf } from "../../../../../Interfaces/App.enums";
import { IWebUserConfig } from "../../../../../Interfaces/User";
import { LoginApiResponse } from "../../../../../Interfaces/Http";
import {
  IpcResponse,
  IpcSender,
} from "../../../../../Interfaces/IpcRenderer.enum";
import { isLabeledStatement } from "typescript";
import { AskForAnalyzeScene } from "../Modals/AskForAnalyze";
import AnalyzeSceneModal from "../Modals/AnalyzeSceneModal";
import { RenderSceneModal } from "../Modals/RenderSceneModal";
import { NoItemInView } from "./NoItem";
import { RootDirEmpty } from "./RootDirEmpty";
import { NotFound } from "./NotFound";
import { AuthorizationRequest } from "../../../../../apis/default";
import { stringify } from "querystring";
import Swal from "sweetalert2";
import Modal from "react-modal";
import FileExplorer from "components/FileExplorer";
import { basename } from "path";
import SceneAnalyzeStatus from "./SceneAnalyzeStatus";
import { DetectSoftwareFromPath } from "Helpers/DetectSoftware";
import { SOFTWARES_TYPE } from "config";

const { ipcRenderer } = window.electron;

const MapStates = (States: RootState) => ({
  Storage: States.CloudStorage,
});

const GridDispatcher = {
  Load: () => ({ type: CloudStorageAction.Load, payload: "" }),
  Fetch: (Prefix: string) => ({
    type: CloudStorageAction.SagaFetch,
    value: Prefix,
  }),
  RemoveObject: (Key: string) => ({
    type: CloudStorageAction.RemoveObject,
    payload: Key,
  }),
};
type FileManagerType = ReturnType<typeof MapStates> &
  typeof GridDispatcher & {
    Items: SpaceItem[];
    ViewMode: string;
    QuickTips?: boolean;
  };

export function getEnumKeyByEnumValue(
  myEnum: any,
  enumValue: number | string
): string {
  let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
  return keys.length > 0 ? keys[0] : "";
}
// Download object
function DownloadObject(Item: SpaceItem): void {
  ipcRenderer.send('SRFSPACES::DOWNLOADS', {Key: Item.key});
}

type SpaceItemListType = SpaceItem & {
  checked: boolean;
};
function FileManager(Props: FileManagerType) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [moveObjectModalKey, setMoveObjectModalKey] = useState<string>("");
  const [newPathToMove, setNewPathToMove] = useState("");

  const ViewMode = Props.ViewMode;
  const QuickTips = Props.QuickTips;
  const history = useHistory();
  const [Items, setItems] = useState<SpaceItemListType[]>([]);
  const [activeScene, setActiveScene] = useState<SpaceItemListType>();
  const [isAskForAnalyzeOpen, setAskForAnalyzeOpen] = useState(false);
  const [isOpenAnalyzeModal, setIsOpenAnalyzeModal] = useState(false);
  const [isOpenRenderModal, setIsOpenRenderModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState<SpaceItemListType[]>([]);
  const [workspace, setworkspace] = useState<string[]>([]);
  const items = JSON.parse(localStorage.getItem("workspace") || "[]");
  useEffect(() => {
    /**
     * listen from main process, open rename model
     */
    ipcRenderer.on("USER_ACTIONS::OPEN_RENAME_MODAL", async (event, data) => {
      Swal.fire({
        title: "Rename",
        input: "text",
        customClass: "rename",
        showCloseButton: true,
        inputAttributes: {
          spellcheck: "false",
        },
        inputValue: basename(data.key),
        inputValidator: (result) => {
          if (!result) {
            return "You need to write something!";
          }

          // check if client rename from folder to drive letter
          if (
            result.length === 1 &&
            result.toUpperCase() != result &&
            window.location.pathname === "/"
          ) {
            return "Driver name must be uppercase";
          }

          if (new RegExp(/[\\/:*?"<>|]/g).test(result)) {
            return "Invalid characters";
          }

          // return empty string if valid
          return "";
        },
        showCancelButton: true,
        cancelButtonColor: "#d33",
        reverseButtons: true,
        confirmButtonColor: "#218c61",
        preConfirm: (target) => {
          ipcRenderer.send("Srfspace::RenameObject", { key: data.key, target });
          return target;
        },
      });
    });

    /**
     * move folder modal handler
     *
     */
    ipcRenderer.on("Srfspace::MoveObject", async (event, { key }) => {
      console.log("move folder event");
      setMoveObjectModalKey(key as string);
    });

    if (items) {
      setworkspace(items);
    }
  }, []);
  useEffect(() => {
    Items.find((obj) => {
      if (obj.extension === "mel") {
        if (obj.path !== undefined) {
          if (!items.includes(obj.path)) {
            const newArray = [...items, obj.path];
            localStorage.setItem("workspace", JSON.stringify(newArray));
            setworkspace(newArray);
          }
        }
      }
    });
  }, [Items, items]);

  useEffect(() => {
    const theItems = Props.Items.map(
      (i) => ({ ...i, checked: false } as SpaceItemListType)
    );
    setItems(theItems);
  }, [Props.Items]);

  // Delete File function
  const handleDelete = () => {
    for (const item of selectedRows) {
      ipcRenderer.send('SRFSPACES::DELETE',{Key: item.key});
      setSelectedRows([]);
    }
    //ipcRenderer.send(IpcSender.DeleteObject,'')
  };
  const ClearSelected = () => {
    setSelectedRows([]);
  };

  const sceneHistoryResult = () => {
    return <span>No result available </span>;
  };
  useEffect(() => {
    ipcRenderer.on(IpcResponse.DeleteObject, (event, ObjectKey, response) => {
      // Remove objects from list
      Props.RemoveObject(ObjectKey);
      console.log("Remove object key ", ObjectKey);
    });
  }, []);

  const renderItem = () => {
    // Loading
    if (Props.Storage.isLoading) {
      return (
        <div className="d-flex h-100">
          <div className="text-center m-auto">
            <div
              className="spinner-border spinner-border-lg fast"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      );
    }
    // Return if root folder is empty
    if (
      Items.length === 0 &&
      window.location.pathname === "/" &&
      Props.Storage.query === ""
    ) {
      return <RootDirEmpty />;
    }
    // Return if current folder empty
    if (typeof Items === "string") {
      return (
        <div className="notification-screen">
          <div className="text-center m-auto">
            <h4>No Items</h4>
            <p>Please select a folder to see its contents</p>
          </div>
        </div>
      );
    }
    // return if there is no item for search query
    if (Items.length === 0 && Props.Storage.query)
      return <NotFound query={Props.Storage.query} />;
    // Return if current folder is empty
    if (Items.length === 0) return <NoItemInView />;

    function GoItem(item: SpaceItemListType, SubKey: string) {
      setSelectedRows([]);
      console.log(
        `Clicked -> ${getEnumKeyByEnumValue(SpaceItemType, item.type)} :`,
        item.key.split("/").slice(1).join("/").replace(/\/\//g, "/")
      );
      if (item.type === SpaceItemType.Scene) {
        DetectSoftwareFromPath(item.key) === SOFTWARES_TYPE.AFTEREFECT ? setIsOpenRenderModal(true):
        DetectSoftwareFromPath(item.key) === SOFTWARES_TYPE.BLENDER ? setIsOpenAnalyzeModal(true):
        DetectSoftwareFromPath(item.key) === SOFTWARES_TYPE.HOUDINI ? setIsOpenAnalyzeModal(true):
        setAskForAnalyzeOpen(true);
        
        setActiveScene(item);
      }

      // On clicked to Drive or Folder
      if (
        [SpaceItemType.Drive, SpaceItemType.Folder].indexOf(item.type) !==
        IndexOf.NotFound
      ) {
        Props.Load();
        history.push(`${SubKey}`);
      }
    }
    return (
      <div className="row ps-3 me-0 pb-2">
        {Items.map((item, i) => {
          var SliceTimes =
            window.location.pathname
              .split("/")
              .slice(1)
              .filter((e) => e !== "").length + 1;
          var SubKey = item.key.split("/").slice(SliceTimes).join("/");
          var id = i + 1;

          return (
            <div
              className={`item d-flex${
                item.type === SpaceItemType.Drive ||
                item.type === SpaceItemType.Folder
                  ? " folder"
                  : ""
              }`}
              key={id}
            >
              <div
                className="form-check my-auto"
                hidden={
                  [SpaceItemType.Drive, SpaceItemType.Folder].indexOf(
                    item.type
                  ) !== IndexOf.NotFound
                }
              >
                <input
                  className="form-check-input select-item-checkbox"
                  type="checkbox"
                  checked={item.checked}
                  onChange={(e) => {
                    var index = Items.findIndex((i) => i.key === item.key);
                    const newItems = Items;
                    if (e.target.checked) {
                      newItems[index].checked = true;
                      setItems(newItems);
                      return setSelectedRows([...selectedRows, item]);
                    }
                    newItems[index].checked = false;
                    setItems(newItems);
                    setSelectedRows(
                      selectedRows.filter((i) => i.key !== item.key)
                    );
                  }}
                />
              </div>
              <div
                className="item-body d-flex"
                onClick={() => {
                  GoItem(item, SubKey);
                }}
                onContextMenu={() => {
                  ipcRenderer.send("ContextMenu::File", { key: item.key });
                }}
              >
                {/* Tippy: hover to show more content */}
                <Tippy
                  placement="right"
                  content={
                    <div className="row p-1">
                      <div className="col-12 border-bottom mb-3 pb-2">
                        <div className="text-break">
                          {item.type === SpaceItemType.Drive
                            ? `Drive (${item.name}:/)`
                            : item.name}
                        </div>
                      </div>
                      {item.type === SpaceItemType.Scene ? (
                        <>
                          <div className="col-4 text-warning">
                            Scene History:{" "}
                          </div>
                          <div className="col-8">{sceneHistoryResult()}</div>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="col-4 text-warning">Type: </div>
                      <div className="col-8">
                        {item.type === SpaceItemType.Drive
                          ? "Drive"
                          : item.type === SpaceItemType.Asset
                          ? "Asset"
                          : item.type === SpaceItemType.Folder
                          ? "Folder"
                          : item.type === SpaceItemType.Scene
                          ? "Scene"
                          : item.type === SpaceItemType.Other
                          ? "Other"
                          : "Unknown"}
                      </div>

                      {[SpaceItemType.Drive, SpaceItemType.Folder].indexOf(item.type) !== IndexOf.NotFound ? "" :
                          <>
                                <div className="col-4 text-warning">Extension:</div>
                                <div className="col-8">{item.extension}</div>
                                <div className="col-4 text-warning">Modified: </div>
                          <div className="col-8">
                            {RelativeTime(item.extra?.LastModified)}
                          </div>
                        </>
                      }
                      {item.size ? (
                        <>
                          <div className="col-4 text-warning">Size: </div>
                          <div className="col-8">{FileSize(item.size)}</div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  }
                >
                  <div className="item-thumbnail" onClick={() => {}}>
                    {/* Render Icon thumbnail */}
                    <div
                      style={{
                        backgroundImage: `url(${DefineIcon(
                          item.type,
                          item.extension
                        )})`,
                      }}
                    />
                  </div>
                </Tippy>
                {/* Render item name */}
                <div className="item-name">
                  {item.type === SpaceItemType.Drive ? (
                    <>
                      Drive (<span className="text-uppercase">{item.name}</span>
                      :){" "}
                    </>
                  ) : (
                    item.name
                  )}
                </div>
                <div className="list-info">
                  {item.type === SpaceItemType.Scene ? (
                    <div className="col-12 my-auto">{sceneHistoryResult()}</div>
                  ) : (
                    <div className="col-12"></div>
                  )}

                  {[SpaceItemType.Drive, SpaceItemType.Folder].indexOf(
                    item.type
                  ) !== IndexOf.NotFound ? (
                    <div className="col-12"></div>
                  ) : (
                    <div className="col-12 my-auto">
                      {RelativeTime(item.extra?.LastModified)}
                    </div>
                  )}

                  {item.size ? (
                    <div className="col-12 my-auto">{FileSize(item.size)}</div>
                  ) : (
                    <div className="col-12"></div>
                  )}
                </div>
              </div>

              {/* Render dropdown button */}
              <div className="item-button-group m-auto">
                <Tippy content="More action">
                  <button
                    className="btn more-button"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fas fa-ellipsis-h fa-sm"></i>
                  </button>
                </Tippy>
                <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-end">
                  {item.type === SpaceItemType.Scene ? (
                    <li>
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={() => {
                          GoItem(item, SubKey);
                        }}
                      >
                        Analysis
                      </button>
                    </li>
                  ) : (
                    <></>
                  )}
                  <li>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => {
                        DownloadObject(item);
                      }}
                    >
                      Download
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => {
                        if (item.key.includes("autopackage"))
                          AuthorizationRequest.post(
                            "uwf/update-autopackage-folder"
                          );
                        Swal.fire({
                          title: "Are you sure?",
                          text: "You won't be able to revert this!",
                          icon: "warning",
                          reverseButtons: true,
                          showCancelButton: true,
                          confirmButtonColor: "#218c61",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Yes, delete it!",
                          preConfirm() {
                            ipcRenderer.send('SRFSPACES::DELETE',{Key: item.key});
                          },
                        }).then((result) => {
                          if (result.isConfirmed) {
                            Swal.fire(
                              "Deleted!",
                              "Your file has been deleted.",
                              "success"
                            );
                          }
                        });
                      }}
                    >
                      Delete
                    </button>
                  </li>
                </ul>
                {item.type === SpaceItemType.Scene ? <SceneAnalyzeStatus path={item.key} />:<></> }
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  //  Render File manager

  return (
    <div className="file-manager">
      {/* Render Breadcrumb */}
      <Breadcrumb
        Selected={selectedRows.length != 0}
        DeleteHandle={handleDelete}
        LengthItem={selectedRows.length}
        ViewMode={ViewMode}
        ClearSelected={ClearSelected}
      />

      {/* Render Item */}
      <div
        className={
          (ViewMode === "list" ? "list-view" : "grid-view") +
          " " +
          (QuickTips ? "quick-tips-show" : "quick-tips-hide")
        }
      >
        {Props.Storage.isLoading ? (
          ""
        ) : Items.length === 0 ? (
          ""
        ) : ViewMode === "list" ? (
          <ul className="list-view-header">
            <li
              style={{ width: "40%", marginLeft: 12, marginRight: 45 }}
              className="d-flex"
            >
              <div className="form-check my-auto" hidden>
                <input
                  className="form-check-input mt-0"
                  type="checkbox"
                  checked={selectedRows.length != 0}
                  onChange={(e) => {
                    if (e.target.checked) {
                      const SelectedAllItems = Items.map(
                        (subItem) =>
                          ({ ...subItem, checked: true } as SpaceItemListType)
                      );
                      setSelectedRows(SelectedAllItems);
                      return setItems(SelectedAllItems);
                    }
                    setSelectedRows([]);
                    return setItems(
                      Items.map((subItem) => ({ ...subItem, checked: false }))
                    );
                  }}
                />
              </div>
              <span className="ms-4 ps-2">File name</span>
            </li>
            <ul style={{ width: "20%", paddingRight: 20 }}>
              <li className="col-12">Scene History</li>
              <li className="col-12">Modified</li>
              <li className="col-12">Size</li>
            </ul>
            <li className="ms-auto">Action</li>
          </ul>
        ) : (
          <></>
        )}
        {renderItem()}
        <Modal
          isOpen={moveObjectModalKey.length > 0}
          onRequestClose={() => setMoveObjectModalKey("")}
          closeTimeoutMS={250}
          style={{
            overlay: {
              zIndex: 20,
              position: "fixed",
              top: 35,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              background: "#343434",
              transform: "translate(-50%, -50%)",
              border: "none",
              width: "90%",
              height: "90%",
              padding: "0",
              cursor: "default",
              overflow: "hidden",
              borderRadius: "10px",
            },
          }}
          contentLabel="Explorer"
        >
          <FileExplorer
            URLPath={(selectedPath: string) => {
              setNewPathToMove(selectedPath);
            }}
            selectFolder={true}
          />
          <div className="fixed-bottom m-5">
            <div className="input-group">
              <span className="input-group-text">Select file</span>
              <input className="form-control" disabled value={newPathToMove} />
              <button
                className="btn btn-success"
                onClick={() => {
                  setMoveObjectModalKey("");
                  //
                  console.log("newPathToMove", newPathToMove);

                  // get change from key
                  if (newPathToMove.length > 0)
                    ipcRenderer.send("Srfspace::RenameObject", {
                      key: moveObjectModalKey,
                      target: newPathToMove,
                    });
                }}
              >
                Select
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={isOpenModal}
          onRequestClose={() => setIsOpenModal(false)}
          closeTimeoutMS={250}
          style={{
            overlay: {
              zIndex: 20,
              position: "fixed",
              top: 35,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              background: "#343434",
              transform: "translate(-50%, -50%)",
              border: "none",
              height: "400px",
              width: "800px",
              padding: "0",
              cursor: "default",
            },
          }}
          contentLabel="Open Render Scene Modal"
        >
          <div className="title d-flex bg-dark py-2">
            <div className="col m-auto ms-3">Choose Action</div>
            <button
              className="btn button-close ms-auto"
              type="button"
              onClick={() => setIsOpenModal(false)}
            >
              <i className="fal fa-times"></i>
            </button>
          </div>
          <div className="card-body">
            <input
              className="form-control"
              value={"new path to move :" + newPathToMove}
            />

            <button
              className="btn rounded-btn"
              onClick={() =>
                Swal.fire({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  reverseButtons: true,
                  showCancelButton: true,
                  confirmButtonColor: "#218c61",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                  preConfirm() {},
                }).then((result) => {
                  if (result.isConfirmed) {
                    Swal.fire({
                      title: "Deleted!",
                      text: "Your file has been deleted.",
                      icon: "success",
                      confirmButtonColor: "#218c61",
                    });
                  }
                })
              }
            >
              Delete
            </button>
          </div>
        </Modal>
        <AskForAnalyzeScene
          isOpenModalAnalyze={isAskForAnalyzeOpen}
          setIsOpenModalAnalyze={(state) => {
            setAskForAnalyzeOpen(state);
          }}
          openAnalyzeModalCallback={(state) => {
            setIsOpenAnalyzeModal(state);
          }}
          openRenderModalCallback={(state) => {
            setIsOpenRenderModal(state);
          }}
        />
        {(() => {
          if (activeScene)
            return (
              <>
                <AnalyzeSceneModal
                  isOpenModal={isOpenAnalyzeModal}
                  setIsOpenModal={(state) => {
                    setIsOpenAnalyzeModal(state);
                  }}
                  item={activeScene}
                  workspace_mel={workspace}
                />
                <RenderSceneModal
                  item={activeScene}
                  workspace_mel={workspace}
                  isOpenModal={isOpenRenderModal}
                  setIsOpenModal={(state) => {
                    setIsOpenRenderModal(state);
                  }}
                />
              </>
            );
        })()}
      </div>
      <div className="bottom-bar d-flex">
        <small className="my-auto ms-1">
          <i className="fad fa-file me-2 ms-2 mt-1"></i>
          {Props.Items.length} items
        </small>
      </div>
    </div>
  );
}

export default connect(MapStates, GridDispatcher)(FileManager);
