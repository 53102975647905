import React, { useEffect, useState } from "react";
import {ISceneAnalyze,SceneAnalyzeResponse,AnalyzeStatus,Supported3dApps} from "./SceneAnalysis.interface";
import * as path from "path";
import { connect } from "react-redux";
import "./scene-analysis.scss";
import { AnalysisActionType } from "../../../../stores/reducers/analysis.interface";
import { RootState } from "../../../../stores/stores";
import AnalysisItem from "./AnalysisItem";
import AnalysisEmpty from "./AnalysisEmpty";
import Loading from "components/Loading";

const MapStates = (States: RootState) => ({
  Analysis: States.Analysis,
});

const AnalysisMapDispatch = {
  SagaFetch: () => ({ type: AnalysisActionType.SagaFetch }),
  SetLoading: (loading: boolean) => ({
    type: AnalysisActionType.SetLoading,
    payload: loading,
  }),
  RemoveItem: (id: number) => ({
    type: AnalysisActionType.RemoveItems,
    payload: id,
  }),
};

type SceneAnalysisProps = ReturnType<typeof MapStates> &
  typeof AnalysisMapDispatch & {
  };

function SceneAnalysis(Props: SceneAnalysisProps) {
  const ListSceneAnalysis = Props.Analysis.items;
  useEffect(() => {
    // Set application state is loading to show loading component
    // After called saga fetch
    // Redux saga will call api to get scene analysis
    // then saga will dispatch loading state to failed
    // then saga will dispatch scene analysis to redux store
    Props.SetLoading(true);
    // Using reducer middleware to fetch all scenes
    Props.SagaFetch();
  }, []);

  // Fetching list scene analyze
  if (Props.Analysis.isLoading) {
    return <Loading />
  }

  // Render list scene analysis empty
  if (!Props.Analysis.isLoading && ListSceneAnalysis.length === 0) {
    return <AnalysisEmpty />
  }

  return (
    <div className="scene-analysis">
      {/* Analysis List*/}
        {ListSceneAnalysis.map((Analysis, index) => {
            return <AnalysisItem data={Analysis} SagaFetch={Props.SagaFetch} key={index}/>
        })}
    </div>
  );
}
export default connect(MapStates,AnalysisMapDispatch)(React.memo(SceneAnalysis))