export type SpaceItemMetadata = {
  key: string;
  description?: string;
  value: any;
};

export type SpaceItemExtra = {
  Region: "us-east-1" | "ap-southeast-1";
  Etag: string;
  LastModified?: Date;
  ContentLength: number;
};

//export type SpaceItemType = "DRIVE" | "FOLDER" | "SCENE" | "ASSET" | "OTHER"
export enum SpaceItemType {
  Drive = 100,
  Folder = 90,
  Scene = 80,
  Compressed = 70,
  Executable = 60,
  Asset = 50,
  Other = 40
}

export type SpaceItem = {
  name: string;
  path?: string;
  key: string;
  url?: string;
  type: SpaceItemType;
  isCompressed?: boolean;
  isSelected?: boolean;
  isDownloadable?: boolean;
  extension?: string;
  isPublic?: boolean;
  size?: number;
  parent?: string;
  metadata?: SpaceItemMetadata[];
  extra?: SpaceItemExtra;
  workspace ?:string;
};

export type Spaces = {
  breadcrumbs: string[];
  currentPath: string;
  parentPath?: string;
  rootPath: string;
  items: SpaceItem[];
  query_results: SpaceItem[];
  query?: string;
  isLoading: boolean;
  validator: {
    hasZipFiles: boolean;
    hasWrongFileName: boolean;
    hasVideoFiles: boolean;
    hasMbFiles: boolean;
    hasExeFiles: boolean;
  };
};

export type SpacesAction<Type> = {
  type: string;
  payload: Type;
};

export enum SpacesReducerAction {
  INITIALIZE_STORAGE = "INITIALIZE_STORAGE",
  SET_BREADCRUMBS = "SET_BREADCRUMBS",
  SET_CURRENT_PATH = "SET_CURRENT_PATH",
  SET_PARENT_PATH = "SET_PARENT_PATH",
  SET_ROOT_PATH = "SET_ROOT_PATH",
  SET_ITEMS = "SET_ITEMS",
  SET_IS_SELECTED = "SET_IS_SELECTED",
  SET_IS_DOWNLOADABLE = "SET_IS_DOWNLOADABLE",
  SET_METADATA = "SET_METADATA",
  SET_EXTRA = "SET_EXTRA",
  SET_IS_COMPRESSED = "SET_IS_COMPRESSED",
  SET_IS_PUBLIC = "SET_IS_PUBLIC",
  SET_SIZE = "SET_SIZE",
  SET_UPDATED_AT = "SET_UPDATED_AT",
  SET_PARENT = "SET_PARENT",
  SET_NAME = "SET_NAME",
  SET_PATH = "SET_PATH",
  SET_KEY = "SET_KEY",
  SET_URL = "SET_URL",
  SET_TYPE = "SET_TYPE",
  SET_IS_SELECTED_ALL = "SET_IS_SELECTED_ALL",
  SET_IS_DOWNLOADABLE_ALL = "SET_IS_DOWNLOADABLE_ALL",
  Migrate = "Migrate",
  SetItems = "CloudStorage::SetItems",
  SagaFetch = "CloudStorage::SagaFetch",
  SagaFinder = "CloudStorage::SagaFinder",
  Search = "CloudStorage::Finder",
  Load = "CloudStorage::Load",
  Reload = "CloudStorage::Reload",
  Loaded = "CloudStorage::Loaded",
  Delete = "CloudStorage::Delete",
  RemoveObject = "CloudStorage::RemoveObject",
  Validate = "CloudStorage::Validate"
}
export interface AwsCredentialKeysType {
  AWS_ACCESS_KEY_ID: string
  AWS_SECRET_ACCESS_KEY: string
  AWS_ASSET_BUCKET: string
  AWS_JSON_CONFIG_BUCKET: string
  AWS_REGION: string
  AWS_USE_ACCELERATOR: boolean
} 