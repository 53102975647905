import * as Aws from "aws-sdk"
import { put, takeEvery, all } from 'redux-saga/effects'
import { CloudStorageAction, SpaceItem, SpaceItemType } from '../reducers/spaces.interface';
import { Stores } from '../stores';
import * as path from 'path';
import { useHistory } from "react-router-dom";
import { IWebUserConfig } from "../../Interfaces/User";
import { IndexOf, SceneExtension } from "../../Interfaces/App.enums";
import { ENotification } from "../reducers/nofification.interface";
import { API } from "apis/base.api";
import { AwsCredentialKeysType } from "types/spaces.type";

export function* FetchAsync( {payload}:any ) {
    window.electron.ipcRenderer.invoke('Get-AWS-Cfg').then((result) => localStorage.setItem('AWSConfig', JSON.stringify(result)) );
    const AWSConfig = JSON.parse( localStorage.getItem('AWSConfig')|| "{}"  ) as AwsCredentialKeysType;
    Aws.config.update(new Aws.Config({
        credentials: new Aws.Credentials({
            accessKeyId : AWSConfig.AWS_ACCESS_KEY_ID ,
            secretAccessKey : AWSConfig.AWS_SECRET_ACCESS_KEY
          })
    }))
    var StartFetchTime = Date.now()
    const ClientUser = JSON.parse( localStorage.getItem("user_profiles") || "{}" ) as IWebUserConfig
    // Return if cannot get user configs
    if(!(ClientUser && ClientUser.user.srfspaces)) return ;
    var Prefix = payload as string
    const Bucket = AWSConfig.AWS_ASSET_BUCKET
    const ClientSpaces = ClientUser.user.srfspaces
   
  
    if(!Prefix.endsWith("/")){
        Prefix += "/"
    }
    Prefix = path.join(ClientSpaces, Prefix)
    Prefix.replace(/\/\//g, "/")
    const AwsClient = new Aws.S3({apiVersion: '2006-03-01'});
    const PrefixConfigs  : Aws.S3.ListObjectsV2Request = {
        Bucket,
        Prefix ,
        MaxKeys: 1000,
        Delimiter: '/'
    }
    const ListObjects = AwsClient.listObjectsV2(PrefixConfigs)
    function ItemType(name : string, size :number) : SpaceItemType {
        var extension = name.split(".").pop() || ""
        // Drive is contain only one character
        if( name.length == 1 && size ==0) return SpaceItemType.Drive
        // Folder
        if( name.length > 1 && size ==0 && name.endsWith("/")) return SpaceItemType.Folder
        // is scene
        if( SceneExtension.indexOf(extension) !== IndexOf.NotFound ) {
            return SpaceItemType.Scene
        }
        // is compressed file
        if( extension.toLowerCase() == "zip" ) {
            return SpaceItemType.Compressed
        }
        // is execuable extension
        if( extension.toLowerCase() == "exe" ) {
            return SpaceItemType.Executable
        }
        // Other file type
        return SpaceItemType.Other
    }

    function ParseFolderType(name : string) : SpaceItemType {
        const PrefixName = name.split("/").filter(x => x !== "").pop() || ""
        // Other type
        if( PrefixName == "") return SpaceItemType.Other
        //
        if(PrefixName.length == 1 && isNaN(parseInt(PrefixName)) && window.location.pathname == "/") return SpaceItemType.Drive
        //
        return SpaceItemType.Folder
    }
    var Items : SpaceItem[] = []
    var SortedItems : SpaceItem[] = []
    yield ListObjects
    .promise()
    .then(  (data) => {
        if(data && data.CommonPrefixes){
            // Map contents to a list object
            var Prefiexs = data.CommonPrefixes
            .filter( item => {
                return item.Prefix !== ""
            } )
            .map( (item) => {
                let ThisSpaceItem : SpaceItem = {
                    key : item.Prefix || "",
                    path : item.Prefix,
                    name : item?.Prefix ? item.Prefix.split("/")[item.Prefix.split("/").length -2] :"",
                    
                    extension : "null",
                    extra : {
                        ContentLength : 0,
                        Region : "us-east-1",
                        Etag : "",
                        LastModified : new Date,
                    },
                    size : 0,
                    type : ParseFolderType(item.Prefix || ""),
                }
                return ThisSpaceItem
            })
            Items = [...Items, ...Prefiexs]
        }
        if(data && data.Contents){
            // Map contents to a list object
            var PrefixObjects = data.Contents
            .filter( item => {
                // remove empty item
                return !item.Key?.endsWith("/")
            }).map( (item) => {
                let ThisSpaceItem : SpaceItem = {
                    key : item.Key || "",
                    path : item.Key,
                    name : item?.Key ? item.Key.split("/")[item.Key.split("/").length -1] :"",
                    extension : item.Key ? item.Key.indexOf(".") != -1 ? item.Key.split(".").pop() || "" : "" : "",
                    extra : {
                        ContentLength : item.Size || 0,
                        Region : "us-east-1",
                        Etag : item.ETag || "",
                        LastModified : item.LastModified || new Date,
                    },
                    size : item.Size || 0,
                    type : ItemType(item?.Key ? item.Key|| "" : "" , item.Size || 0 ),
                }
                return ThisSpaceItem
            }) as SpaceItem[]
            Items = [...Items, ...PrefixObjects]
        }

        // Sort with type
        SortedItems = Items.sort( (a,b) => b.type - a.type )
    })
    .catch(() => {
        console.log(`Please connect internet to use Super Renders Farm Console`)
    })
    .finally( () => {
        // skip dispatch to store if location and prefix not matched
        if (decodeURIComponent(window.location.pathname.slice(1)) !== decodeURIComponent(Prefix.split('/').slice(1).join('/'))) {
            console.log('Skipping store because location and prefix not matched', Prefix);
            return;
        }
        var EndFetchTime = Date.now() - StartFetchTime
        Stores.dispatch( { type : ENotification.SetLoadTime , payload : EndFetchTime})
        Stores.dispatch({ type:CloudStorageAction.SetItems , payload : SortedItems  })
        Stores.dispatch({ type:CloudStorageAction.Loaded})
    })
}