import NotFoundImage from "../../../../../assets/SVG/style-dark-1/9.svg"
import { Stores } from "../../../../../stores/stores"

export function NotFound( {query } : {query ?: string}) {
    return (
        <div className="notification-screen">
            <div className="text-center m-auto">
                <h4>No Items was found !</h4>
                <div className="logo my-4 mx-auto" style={{backgroundSize:"cover",height:"210px",width:"240px",backgroundRepeat:"no-repeat",backgroundImage:`url("${NotFoundImage}")`}}/>
                <div className="d-flex">
                    <div>There is no items was found for "</div><div className="d-inline-block text-truncate ms-auto" style={{maxWidth:200}}>{ query }</div><div className="me-auto">"</div>
                </div>   
            </div>
        </div>
     

    )
}