import React, { useState, useEffect } from "react";
import "./main-window.scss";
import BottomBar from "./Layout/BottomBar";
import CloudStorage from "./Components/CloudStorage";
import RenderJobs from "./Components/RenderJobs";
import PluginsManager from "./Components/PluginsManager";
import { useShortcut, useShortcutMap } from "../../Helpers/Shortcut";
import Titlebar from "./Layout/Titlebar";
import { GetWaiter } from "../../Helpers/Waiter";
import { IWebUserConfig } from "../../Interfaces/User";
import Page404 from "../Page404";
import SceneAnalysis from "./Components/SceneAnalysis";
import Loading from "components/Loading";

const electron = window.electron;
const { ipcRenderer } = window.electron;

ipcRenderer.on("set_jwt", (event, jwt) => {
  localStorage.setItem("access_token", jwt);
});

interface MainWindowProps { }
export default function MainWindow({ }: MainWindowProps) {
  const [tab, setTab] = useState(3);
  const [isLoading, setIsLoading] = useState(false);
  const refresh = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 250);
  };
  useShortcut('Ctrl+R', () => {
    refresh ()
  })
  useShortcutMap((shortcut) => {
    switch (shortcut) {
      case "Ctrl+1":
        return setTab(1);
      case "Ctrl+2":
        return setTab(2);
      case "Ctrl+3":
        return setTab(3);
      case "Ctrl+4":
        return setTab(4);
      case "Ctrl+0":
        return console.log("Requesting support ...");
    }
  });

  useEffect(() => {
    // check is user_profiles has enabled
    GetWaiter<IWebUserConfig>(() =>
      JSON.parse(localStorage.getItem("user_profiles") || "{}")
    )
      .then(() => {
        console.log("Storage initialed");
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      <Titlebar />
      <div className="App">
        <div className="AppContent">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button
                className={`nav-link ${tab === 1 ? "active" : ""}`}
                onClick={() => {
                  refresh();
                  setTab(1);
                }}
              >
                <i className="fad fa-hdd me-2"></i>SRF SPACES
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${tab === 2 ? "active" : ""}`}
                onClick={() => {
                  refresh();
                  setTab(2);
                }}
              >
                <i className="fad fa-tasks-alt me-2"></i>SCENE ANALYSIS
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${tab === 3 ? "active" : ""}`}
                onClick={() => {
                  refresh();
                  setTab(3);
                }}
              >
                <i className="fad fa-tasks me-2"></i>RENDER JOBS
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${tab === 4 ? "active" : ""}`}
                onClick={() => {
                  refresh();
                  setTab(4);
                }}
              >
                <i className="fad fa-terminal me-2"></i>PLUGIN
              </button>
            </li>
            <li className="nav-item ms-auto">
              <button className="nav-link" onClick={refresh} disabled={isLoading}>
                <i className="fad fa-redo-alt me-2"></i>REFRESH
              </button>
            </li>
      
          </ul>
          <div className="tabContent">
            {
              !isLoading ? (
                  tab === 1 ? <CloudStorage />
                : tab === 2 ? <SceneAnalysis />
                : tab === 3 ? <RenderJobs />
                : tab === 4 ? <PluginsManager />
                : <Page404 />
                )
              : <Loading />
            }
          </div>
        </div>
      </div>
      <BottomBar />
    </>
  );
}
