import React from 'react'
import { connect } from 'react-redux'
import { RenderOptionsInterface } from 'screens/RenderNow/RenderNow.interface'
import { RenderReducerActionEnum } from 'stores/reducers/render.interface'
import { RootState } from 'stores/stores'
import FrameRange from '../components/FrameRange'
import OutputSettings from '../components/OutputSettings'
import RenderSetting from '../components/RenderSetting'
import ScenePath from '../components/ScenePath'



export function MapBlenderStates(States: RootState) {
     return {
          renderOptions: States.Render
     }
}

export const RenderNowMapDispatchs = {
     switch_render_flags: (key: string) => ({ type: RenderReducerActionEnum.SwitchRenderFlags, value: key }),
     set_frame_pertask: (value: number) => ({ type: RenderReducerActionEnum.SetFramePerTask, value }),
     set_render_priority: (value: number) => ({ type: RenderReducerActionEnum.SetRenderPriority, value }),
     set_use_prerender_assets: (value: boolean) => ({ type: RenderReducerActionEnum.SetUseMissingAssets, value }),
     set_start_frame_range: (value: number) => ({ type: RenderReducerActionEnum.SetStartFrameRange, value }),
     set_end_frame_range: (value: number) => ({ type: RenderReducerActionEnum.SetEndFrameRange, value }),
     set_incremental_step: (value: number) => ({ type: RenderReducerActionEnum.SetIncrementalStep, value }),

}
export type BlenderProps = {} & ReturnType<typeof MapBlenderStates> & typeof RenderNowMapDispatchs

function Blender(Props: BlenderProps) {
     return <>
               <ScenePath />
               <OutputSettings />
               <FrameRange 
                    StartFrameRange={Props.renderOptions.body.scene.params.scene_param.startFrame || 0}
                    setStartFrameRange={function (n: number): void {
                         Props.set_start_frame_range(n)
                    }}
                    EndFrameRange={Props.renderOptions.body.scene.params.scene_param.endFrame || 0}
                    setEndFrameRange={function (n: number): void {
                         Props.set_end_frame_range(n)
                    }}
                    IncrementalStep={Props.renderOptions.body.scene.params.scene_param.incrementStep || 0}
                    setIncrementalStep={function (n: number): void {
                         Props.set_incremental_step(n)
                    }} />
               <RenderSetting
                    framePerTask={Props.renderOptions.render_configuration.frame_per_task}
                    renderPriority={Props.renderOptions.render_configuration.render_priority || 10}
                    usePreviousAssetsIfMissing={false}
                    setFramePerTask={function (n: number): void {
                         Props.set_frame_pertask(n)
                    }}
                    setRenderPriority={function (n: number): void {
                         Props.set_render_priority(n)
                    }}
                    setUsePreviousAssetsIfMissing={function (n: boolean): void {
                         throw new Error('Function not implemented.')
                    }} />
          </>
}
export default connect(MapBlenderStates, RenderNowMapDispatchs)(Blender)