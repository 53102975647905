import { CloudStorageAction, SpaceItem, Spaces, SpacesAction } from "./spaces.interface";

const InitialSpaceState : Spaces = {
    breadcrumbs : [],
    currentPath : "",
    parentPath : "",
    rootPath : "",
    items : [],
    query_results : [],
    query : "",
    isLoading : true,
}

export const SpacesReducer =  (state = InitialSpaceState, action : SpacesAction<any> ) => {

  var RefreshState = state
  var Query = RefreshState.query || ""

  switch (action.type) {
    // Reset state to default
    case "RESET":
      return { ...state };

    // Migrate list objects
    case CloudStorageAction.Migrate :
      RefreshState.items = action.payload
      return { ...state ,...RefreshState};

    // Set items
    case CloudStorageAction.SetItems :
      var NewItems =  action.payload as SpaceItem[]
      RefreshState.items = NewItems
      RefreshState.isLoading = false
      RefreshState.query_results = NewItems.filter(item => item.name.toLowerCase().includes(Query.toLowerCase()))
      return {...state, ...RefreshState};
    
    // Find from data
    case CloudStorageAction.Search :
      var FinderQuery = action.payload as string
      RefreshState.query = FinderQuery
      RefreshState.isLoading = false
      RefreshState.query_results = RefreshState.items.filter(item => item.name.toLowerCase().includes(FinderQuery.toLowerCase()))
      return {...state, ...RefreshState};

    case CloudStorageAction.RemoveObject:
      RefreshState.items = RefreshState.items.filter( item => item.key !== action.payload as string)
      return  {...state, ...RefreshState};

    case CloudStorageAction.Load :
      RefreshState.isLoading = true
      return {...state, ...RefreshState};


    case CloudStorageAction.Loaded :
      RefreshState.isLoading = false
      return {...state, ...RefreshState};

    case CloudStorageAction.Reload :
      return {...state}

    default:
      return {...state}
  }

};
