import React from 'react'
import './empty-render-job.scss'
// import pic1 from './Untitled-1.jpg'
// import pic2 from './Untitled-2.jpg'
// import pic3 from './Untitled-3.jpg'
// import pic4 from './Untitled-4.jpg'
import NoAction from "../../../../../assets/SVG/style-dark-1/36.svg";

/// URLS list of images
const pic1 = "https://statics.superrendersfarm.com/superrenders/images/super-renders/how_to_submit.jpg"
const pic2 = "https://statics.superrendersfarm.com/superrenders/images/super-renders/how_to_upload.jpg"
const pic3 = "https://statics.superrendersfarm.com/superrenders/images/super-renders/how_to_download.jpg"
const pic4 = "https://statics.superrendersfarm.com/superrenders/images/super-renders/how_to_upgrade.jpg"
const electron = window.electron;

interface EmptyRenderJobProps {
}

export default function EmptyRenderJob({}: EmptyRenderJobProps) {
     return (
          <div className="d-flex h-100">
               <div className="text-center m-auto">
                    <div className="logo mx-auto mb-3"
                    style={{
                         backgroundSize: "cover",
                         height: "200px",
                         width: "200px",
                         backgroundRepeat: "no-repeat",
                         backgroundImage: `url("${NoAction}")`,
                    }}
                    />
                    <h5 className="mb-5 pb-5">
                    You have no active render jobs at the moment.
                    </h5>
               </div>
               <div className="fixed-bottom mb-4 pb-2 mx-3">
                    <div className="quick-tips-for-newb row">
                         <div className="item col-3">
                              <figure className="figure" onClick={()=>electron.shell.openExternal('https://support.superrendersfarm.com/hc/en-us/articles/360062299043-how-to-submit-render-job-from-submisson-plugin')}>
                                   <img src={pic1} className="figure-img img-fluid rounded" alt="How to submit render job submisson plugin" />
                                   <figcaption className="figure-caption">How to submit render job submisson plugin.</figcaption>
                              </figure>
                         </div>
                         <div className="item col-3">
                              <figure className="figure" onClick={()=>electron.shell.openExternal('https://support.superrendersfarm.com/hc/en-us/articles/360062299044-upload-by-superrenders-client-app')}>
                                   <img src={pic2} className="figure-img img-fluid rounded" alt="How to upload files/folders" />
                                   <figcaption className="figure-caption">How to upload files/folders.</figcaption>
                              </figure>
                         </div>
                         <div className="item col-3">
                              <figure className="figure" onClick={()=>electron.shell.openExternal('https://support.superrendersfarm.com/hc/en-us/articles/360062299045-download-the-output-by-superrenders-client-app')}>
                                   <img src={pic3} className="figure-img img-fluid rounded" alt="How to download render output" />
                                   <figcaption className="figure-caption">How to download render output.</figcaption>
                              </figure>
                         </div>
                         <div className="item col-3">
                              <figure className="figure" onClick={()=>electron.shell.openExternal('https://support.superrendersfarm.com/hc/en-us/articles/360062299046-update-3d-software-submission-plugin')}>
                                   <img src={pic4} className="figure-img img-fluid rounded" alt="How to upgrade submission plugin" />
                                   <figcaption className="figure-caption">How to upgrade submission plugin.</figcaption>
                              </figure>
                         </div>
                    </div>
               </div>
          </div>
     )
}