import React, { useState } from 'react'
import './vray.scss'
import AutoGIMode from '../../components/AutoGIMode'
import Tips from '../../components/Tips'
import GlobalIllumination from './GlobalIllumination'
import ImageSamplesAndFilter from './ImageSamplesAndFilter'
import VrayFrameBuffer from './VrayFrameBuffer'
import { RootState } from '../../../../../stores/stores'
import { connect } from 'react-redux'
import { IndexOf, PHPBOOL, RenderEngineVersion } from '../../../../../Interfaces/App.enums'
import { IVrayOptions } from '../../../../../Interfaces/Renderer'
import { RenderReducerActionEnum } from '../../../../../stores/reducers/render.interface'
import { RenderOptionsInterface } from 'screens/RenderNow/RenderNow.interface'
import Ajxo from './Ajxo'

const mapStates = (states: RootState) => ({
     Render: states.Render
})
const vrayDispatchMapper = {
     updateVrayOption: <Type extends any>(name: keyof IVrayOptions, value: Type) => ({ type: RenderReducerActionEnum.SetVrayOptions, value: { name, value } })
}

type VrayProps = ReturnType<typeof mapStates> & typeof vrayDispatchMapper & {
     renderOptions: RenderOptionsInterface
}

// Vray options configuration
function Vray(Props: VrayProps) {
     if (!Props.renderOptions.body?.scene?.params?.vray_option) {
          return <></>
     }
     const Scene = Props.Render?.body?.scene
     // is this scene using vray engine
     // if this scene analyzed and it is not using vray engine, then return null
     if (Scene && ! Scene?.params?.scene_param?.render_engine.toLowerCase().includes('vray') ) {
          return <div></div>
     }

     if (JSON.stringify(Props.renderOptions.body.scene.params.vray_option) == "{}") {
          return <></>
     }

     // this scene using vray engine
     return (
          <div className="accordion-item">

               <h2 className="accordion-header" id="heading0">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse0" aria-expanded="false" aria-controls="collapse0">
                         Vray option
                    </button>
               </h2>
               <div id="collapse0" className="accordion-collapse collapse" aria-labelledby="heading0" data-bs-parent="#param-settings">
                    <div className="accordion-body">
                         {/* GI MODE */}
                         <AutoGIMode
                              // check is GlobalIllumination checked
                              giMode={Props?.Render?.body?.scene?.params?.vray_option?.user_select_auto_mode || false}
                              // Callback handle
                              setGiMode={(user_select_auto_mode: PHPBOOL) => {
                                   console.log(user_select_auto_mode);
                                   Props.updateVrayOption("user_select_auto_mode", user_select_auto_mode)
                              }}
                         />
                         {/* Tips */}
                         <Tips />
                         {/* Global Illumination */}
                         {
                              !Props?.Render?.body?.scene?.params?.vray_option?.user_select_auto_mode ? <GlobalIllumination renderOptions={Props.renderOptions} /> : <></>
                         }

                         {/* Image Samples and Image Filter */}
                         <ImageSamplesAndFilter renderOptions={Props.renderOptions} />
                         {/* Vray frame buffer */}
                         <VrayFrameBuffer renderOptions={Props.renderOptions} />
                    </div>
               </div>
          </div>
     )
}


export default connect(mapStates, vrayDispatchMapper)(Vray)