import { LatestVersionItemTypes } from "./latest-from-api.interface";

export interface UpToDateCheckerProps {
  current: number;
  latest: number;
}
export function UpToDateChecker({ current, latest }: UpToDateCheckerProps) {
  return (
    <div className="badge bg-success-bright ms-auto my-auto">
      {current === latest ? (
        <span>Up to date ✅</span>
      ) : (
        <span>Out of date ❌</span>
      )}
    </div>
  );
}

export function getIconType(software: LatestVersionItemTypes["software"]) {
  if (software === "3dsmax") return "max";
  if (software === "maya") return "ma";
  return "";
}
